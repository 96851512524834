<template>
    <div class="Overview_dashboard_map_section my-4">
        <div class="card p-4">
            <div class="card-body p-0" id="pdfLineChart">
                <div>
                    <h3 style="font-weight: 600;">Trends in Assessment Token Usage
                        <!-- <img src="../../../../public/dashboard-assets/images/M7/Actions.png" width="25"
                            class="cursor-pointer ms-2" alt="" data-bs-toggle="tooltip" data-bs-placement="top" title=" Analyzing the frequency of assessment usage over time provides valuable insights into the number of
                                    candidates assessed during defined intervals." /> -->
                    </h3>
                    <div class="mt-5">
                        <div class="col-12 col-lg-12 col-xl-12 col-md-12 col-sm-12">
                            <div ref="lineChartContainer" class="chart-container w-100"></div>
                        </div>
                        <div class="mt-2" v-if="this.hideLines === false">
                            <ul>
                                <li class="mt-3" v-for="descriptorObj in descriptorsForMaxUsage" :key="descriptorObj"> 
                                    <span style="font-weight: 600;">Peak Assessment Activity:</span> Between  <span style="font-weight: 600;">{{ displayDateTime(descriptorObj.from) }}</span> and <span style="font-weight: 600;">{{ displayDateTime(descriptorObj.to) }}</span>, your team conducted  <span style="font-weight: 600;">{{ descriptorObj.assessmentCount }}</span> assessments, marking the highest activity in this period. Review the strategies and campaigns implemented during this time to replicate these successes and sustain high candidate engagement in future periods.

                                </li>
                                <li class="mt-3" v-for="descriptorObj in descriptorsForMinUsage" :key="descriptorObj">
                                    <span style="font-weight: 600;">Low Assessment Activity:</span> From <span style="font-weight: 600;">{{ displayDateTime(descriptorObj.from) }}</span> to <span style="font-weight: 600;">{{ displayDateTime(descriptorObj.to) }}</span> , assessments dropped to a low of <span style="font-weight: 600;">{{ descriptorObj.assessmentCount }}</span>. This could be due to less hiring activity or other external factors. Investigate these potential causes and consider adjusting your engagement strategies to maintain a consistent assessment flow.
                                </li>
                                <li class="mt-3">
                                    <span style="font-weight: 600;">{{ usageTrendsMessage}}: </span> Your token usage shows varying candidate engagement levels over recent <span style="font-weight: 600;">{{ usageTrendsText }}</span>, 
                                    with notable peaks on specific dates. Analyze these trends to identify patterns and 
                                    anticipate future needs. Leveraging this data can help you optimize resource 
                                    allocation and improve the overall efficiency of your assessment process.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Highcharts from 'highcharts';
import { Tooltip } from "bootstrap/dist/js/bootstrap.esm.min.js";
import moment from 'moment';

export default {
    name: 'LineChart',
    data() {
        return {
            assessmentList: [],
            descriptorsForMinUsage: [],
            descriptorsForMaxUsage: [],
            hideLines: true
        }
    },
    props: {
        assessmentData: Array,
        daysCount : Number
    },
    created() {
        const data = this.assessmentData.map(item => {
            const date = new Date(item.to);
            const formattedDate = date.toISOString().split('T')[0];
            return [formattedDate, item.assessmentCount];
        });
        data.forEach(function (point) {
            point[0] = Date.parse(point[0])
        });
        this.assessmentList = data;
        const assessmentCountList = this.assessmentData.map(i => i.assessmentCount);
        this.hideLines = assessmentCountList.every((count) => {
            return count === 0
        });
        const minAssessmentCount = Math.min(...assessmentCountList.slice(1));
        const maxAssessmentCount = Math.max(...assessmentCountList);
        this.descriptorsForMaxUsage = this.assessmentData.filter(element => element.assessmentCount == maxAssessmentCount);
        this.descriptorsForMinUsage = this.assessmentData.slice(1).filter(element => element.assessmentCount == minAssessmentCount);
    },
    async mounted() {

        Array.from(
            document.querySelectorAll('img[data-bs-toggle="tooltip"]')
        ).forEach((tooltipNode) => new Tooltip(tooltipNode));

        // const lineChartConfig = {
        //     chart: {
        //         type: 'area'
        //     },
        //     credits: {
        //         enabled: false
        //     },
        //     exporting: {
        //         enabled: false
        //     },
        //     title: {
        //         text: this.hideLines === true ? 'No data found for selected parameters' : ''
        //     },
        //     yAxis: {
        //         min: 0,
        //         title: {
        //             text: 'No. of Candidates',
        //             style: {
        //                 fontWeight: "600"
        //             }
        //         }
        //     },
        //     tooltip: {
        //         pointFormat: '<span style="font-family: DM Sans, sans-serif">{series.name}: <b>{point.y}</b></span>'
        //     },
        //     xAxis: {
        //         type: 'datetime',
        //         title: {
        //             text: 'Time Interval',
        //             style: {
        //                 fontWeight: "600"
        //             }
        //         },
        //         labels: {
        //             formatter: function () {
        //                 return Highcharts.dateFormat('%d %b %Y', this.value);
        //             }
        //         },
        //     },
        //     legend: {
        //         enabled: false
        //     },

        //     plotOptions: {
        //         series: {
        //             marker: {
        //                 fillColor: '#FFFFFF',
        //                 lineWidth: 2,
        //                 lineColor: null // inherit from series
        //             },

        //             fillColor: {
        //                 linearGradient: [0, 0, 0, 400],
        //                 stops: [
        //                     [0, '#7B68EE'],
        //                     [1, Highcharts.color('#7B68EE').setOpacity(0).get('rgba')]
        //                 ]
        //             }
        //         },
        //     },
        //     series: [{
        //         name: 'Candidates(N)',
        //         data: this.hideLines === true ? [] : this.assessmentList,
        //         color: "#7B68EE"
        //     }],

        //     responsive: {
        //         rules: [{
        //             condition: {
        //                 maxWidth: 500
        //             },
        //             chartOptions: {
        //                 legend: {
        //                     layout: 'horizontal',
        //                     align: 'center',
        //                     verticalAlign: 'bottom'
        //                 }
        //             }
        //         }]
        //     }
        // };
        const lineChartConfig = {
            chart: {
                type: 'area'
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            title: {
                text: this.hideLines === true ? 'No data found for selected parameters' : ''
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'No. of Candidates',
                    style: {
                        fontWeight: "600"
                    }
                }
            },
            tooltip: {
                pointFormat: '<span style="font-family: DM Sans, sans-serif">{series.name}: <b>{point.y}</b></span>'
            },
            xAxis: {
                type: 'datetime',
                title: {
                    text: 'Time Interval',
                    style: {
                        fontWeight: "600"
                    }
                },
                labels: {
                    formatter: function () {
                        return Highcharts.dateFormat('%d %b %Y', this.value);
                    }
                },
            },
            legend: {
                enabled: false
            },

            plotOptions: {
                series: {
                    marker: {
                        fillColor: '#FFFFFF',
                        lineWidth: 2,
                        lineColor: null // inherit from series
                    },

                    fillColor: {
                        linearGradient: [0, 0, 0, 400],
                        stops: [
                            [0, '#7B68EE'],
                            [1, Highcharts.color('#7B68EE').setOpacity(0).get('rgba')]
                        ]
                    }
                },
            },
            series: [{
                name: 'Candidates(N)',
                data: this.hideLines === true ? [] : this.assessmentList,
                color: "#7B68EE"
            }],

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            }
        };

        Highcharts.chart(this.$refs.lineChartContainer, lineChartConfig);
    },
    methods: {
        displayDateTime: (val) => {
            return moment(String(val)).format("DD MMM YYYY");
        }
    },
    computed: {
    usageTrendsMessage() {
      if (this.daysCount < 15) {
        return 'Weekly Token Usage Trends';
      } else if (this.daysCount < 30) {
        return 'BiWeekly Token Usage Trends';
      } else {
        return 'Monthly Token Usage Trends';
      }
    },
    usageTrendsText() {
      if (this.daysCount < 15) {
        return 'weeks';
      } else if (this.daysCount < 30) {
        return 'biweekly';
      } else {
        return 'month';
      }
    }
  },
};


</script>

<style scoped>
#lineChartContainer {
    margin: 0 auto;
}

.chart-container {
    max-width: 100%;
    width: 100vh;
    text-align: center;
}

.chart-container>div {
    display: inline-block;
}
</style>