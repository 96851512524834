import moment from "moment";

export const validateEmail = (email) => {
    const re =
        /^(([^`<>()[\]\\.,;:\s@'"]+(\.[^<>()[\]\\.,;:\s@'"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const validatePhoneNumber = (phoneNumber) => {
    const re = /^[0-9]{10}$/gm;
    return re.test(String(phoneNumber));
};

export const validateUserNum = (userNum) => {
    const re = /^([1-9]{1,5})?$/;
    return re.test(String(userNum));
};

export const validateGST = (gstNo) => {
    const re = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    return re.test(String(gstNo));
};

export const validatePassword = (password) => {
    const re =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    return re.test(String(password));
};

export const validateName = (name) => {
    const re = /^[A-Za-z\s]+$/;
    return re.test(String(name));
  };

export const getFormattedDate = (value) => {
    let date = new Date(value);
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");

    return month + "/" + day + "/" + year;
};

export const dateFormat = (date) => {
    const months = [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
    ];
    let current_datetime = new Date(date);
    let formatted_date =
        current_datetime.getDate() +
        " " +
        months[current_datetime.getMonth()] +
        " " +
        current_datetime.getFullYear();
    return formatted_date;
};

export const momentDateFormat = (date) => {
    return moment(date).format("MM-DD-YYYY hh:mm A");
};

export const isEmpty = (inputObject) => {
    return Object.keys(inputObject).length === 0;
};

