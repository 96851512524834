<template>
  <div class="page-content">
    <!-- mobile view Searching -->
    <div class="Searching_input_box_mobile_view mb-3 position-relative">
      <div class="w-100" v-if="this.show">
        <input type="text" v-model="searchinput" placeholder="Search..." @keyup.enter="searchpartnerlst(searchinput)" />
        <img src="../../public/dashboard-assets/images/M2/Close_icon.png" @click="closemobilesearch" alt=""
          class="close-icon" />
      </div>
    </div>

    <div class="d-flex justify-content-between align-items-center flex-wrap mb-2">
      <div class="my-2">
        <h3 class="mb-2 mb-md-0">Assigned Groups</h3>
      </div>

      <div class="d-flex">
        <!-- Search  web view-->
        <!-- <div class="top_heading_CreatePartner_btn position-relative">
          <div class="Group_top_three_dot">
            <div class="Searching_input-box">
              <input
                type="text"
                v-model="input"
                @keyup="filteredList"
                placeholder="Search..."
              />
              <div class="search_detail_list">
                <div
                  v-for="(item, index) in this.result"
                  :key="index"
                  class="search_detail_list_item"
                >
                  <span :id="index" @click="searchregionname(item)">
                    {{ stringTOHtml(item, index) }}
                  </span>
                </div>
              </div>
              <span
                class="icon Group_top_three_dot_dropdown"
                @click="opensearch"
              >
                <img
                  src="../../public/dashboard-assets/images/M2/Icon-Set.png"
                  alt=""
                  class="Searching_search_icon"
                />
              </span>
              <img
                src="../../public/dashboard-assets/images/M2/Close_icon.png"
                @click="closesearch"
                alt=""
                class="close-icon"
              />
            </div>
          </div>
        </div> -->

        <!-- Mobile View Searching Button -->
        <div class="top_heading_CreatePartner_btn position-relative Searching_button_mobileView">
          <div class="Group_top_three_dot">
            <span class="icon Group_top_three_dot_dropdown" :style="{ display: this.show == true ? 'none' : 'block' }"
              @click="openmobilesearch">
              <img src="../../public/dashboard-assets/images/M2/Icon-Set.png" alt="" class="Searching_search_icon" />
            </span>
          </div>
        </div>

        <div class="top_heading_CreatePartner_btn ms-2" v-if="assignedgrpsList.length != 0">
          <button type="button" class="btn btn-primary btn-icon-text" @click="openAssignPartner()">
            <i class="fa-solid fa-plus"></i> Link Groups
          </button>
        </div>
      </div>

      <div class="mobile_Create_Partner_button" v-if="getUserPermissionData({
          parent: 'Groups Management',
          child: 'Partner Groups',
          permission: 'Can_Create_Regions_Linking',
        })
        ">
        <button type="button" class="btn" @click="openAssignPartner">
          <i class="fa-solid fa-plus"></i>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-5 col-lg-8 partners_top_menu_section_hp">
        <div class="row">
          <div class="col-6">
            <router-link :to="{ name: 'ManagementPartners' }" class="w-100 btn">
              Partners <span>({{ this.totalPartnerCount }})</span>
            </router-link>
          </div>
          <div class="col-6">
            <router-link :to="{ name: 'ManagementPartnersAssignTeam' }"
              class="w-100 active btn btn-outline-primary btn-icon-text">Assigned Groups
              <span>({{ this.totalGroupCount }})</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>


    <section class="Partner_empty_main_section_hp" v-if="assignedgrpsList.length == 0">
      <div class="row">
        <div class="Partner_empty_img_hp m-auto text-center">
          <img src="../../public/dashboard-assets/images/M2/Partner_blank.png" alt="" />
        </div>
        <div class="Partner_empty_content_hp m-auto text-center">
          <div class="mb-4">
            <h2>Hey, you still alone?</h2>
            <p class="text-muted mt-2">
              This is the best time to Assign Group Mapping and start working.
            </p>
          </div>
          <!-- <div
          class="top_heading_CreatePartner_btn ms-2"
          v-if="assignedgrpsList.length != 0"
        >
          <button
            type="button"
            class="btn btn-primary btn-icon-text"
            @click="openAssignPartner()">
            <i class="fa-solid fa-plus"></i> Link Groups
          </button>
        </div> -->
          <button type="button" class="btn btn-primary btn-icon-text" @click="openAssignPartner()">
            <i class="fa-solid fa-plus"></i> Assign Groups
          </button>
        </div>
      </div>
    </section>

    <section class="Partner_empty_main_section_kj mt-3" v-else>
      <div class="row">
        <div style="position: relative;" class="col-md-6 col-lg-4 col-xl-3 mb-3 stretch-card" v-for="item in grpsList"
          :key="item">
          <div class="dropdown" style="position: absolute; right: 25px;top:20px; z-index: 1;">
            <button class="btn p-0" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <i class="fa-solid fa-ellipsis"></i>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton" v-if="getUserPermissionData({
                parent: 'Groups Management',
                child: 'Partner Groups',
                permission: 'Can_Delete_Regions_Linking',
              })
              ">
              <li>
                <div class="dropdown-item d-flex align-items-center" data-bs-toggle="modal"
                  data-bs-target="#DeactivatePartnerModal" style="cursor: pointer"
                  @click="removefun(item.regionId, item.regionName)">
                  Remove
                </div>
              </li>
            </ul>
          </div>
          <router-link :to="{
              name: 'AssignedTeamDetails',
              params: {
                grpid: encode(item.regionId),
                offset: encode(this.offset),
              },
            }" class="card">
            <div class="card-body p-0">

              <div class="Partner_management_top-kj mb-3">
                <h5 class="text-dark">{{ item.regionName }}</h5>

              </div>

              <div class="Partner_management_top-kj">
                <div class="Partner_management_contant-kj">
                  <div class="">
                    <i class="fa-regular fa-handshake"></i>
                    <span class="text-dark ms-1">Partners</span>
                    <div>
                      <span>{{ item.partnerCount }}</span>
                    </div>
                  </div>
                </div>
                <div class="Partner_management_contant-2-kj">
                  <div class="">
                    <i class="fa-solid fa-users"></i><span class="text-dark"> Groups</span>
                  </div>
                  <div>
                    <span>{{ item.groupCount }}</span>
                  </div>
                </div>
              </div>
            </div>
          </router-link>

        </div>
      </div>
      <paginate v-model="currentPage" :page-count="Page_count" :margin-pages="1" :page-range="1"
        :click-handler="clickCallback" :prev-text="'< Prev'" :next-text="'Next >'" :container-class="'pagination'"
        :page-class="'page-item'">
      </paginate>
    </section>
    <div class="modal fade" id="DeactivatePartnerModal" tabindex="-1" data-bs-backdrop="static"
      aria-labelledby="RemovePartnerModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-body p-4">
            <div class="">
              <h3 class="mb-3">Remove Group?</h3>
              <p class="text-muted">
                Are you sure you want to Remove
                <strong style="color: #000000"> {{ this.regionname }} </strong>
                group.
              </p>
            </div>
            <div class="Deactivate_buttons row mt-5 justify-content-center">
              <button id="close" class="col-5 Deactivate_Cancel_buttons" data-bs-dismiss="modal">
                Cancel
              </button>
              <button type="button" class="col-5 btn Deactivate_Deactivate_buttons" @click="confirmdelete()">
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="AssignPartnersLinkTeamsModal" tabindex="-1" data-bs-backdrop="static"
      aria-labelledby="AssignPartnersLinkTeamsModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header model-header-bg-kj">
            <h5 class="modal-title text-centerm-auto" id="AssignPartnersLinkTeamsModalLabel">
              Assign Partners to Groups
            </h5>
            <button type="button" class="btn-close" aria-label="btn-close" @click="resetLinkTeamForm"></button>
          </div>
          <div class="modal-body text-center py-4">
            <div class="row">
              <div class="form-group text-start">
                <div class="custom_select_dropdown_form">
                  <!-- <Multiselect
                  class="input-hero input_area placeholder-kj"
                  v-model="team"
                  mode="tags"
                  :searchable="true"
                  :options="options"
                  :classes="{
                    tag: 'multiselect-tag text-wrap',
                  }"
                /> -->
                  <div class="Search_input_section" style="position: relative">
                    <label class="input-hero">
                      <input v-if="partnerTeams.outputString == ''" type="text" placeholder="Choose Partners Groups"
                        style="height: 50px" class="form-control form-select input_area" @click="openDropDown()"
                        v-model="partnerTeams.searchTerm" />
                      <textarea v-if="partnerTeams.outputString != ''" type="text" placeholder="Choose Partners Groups"
                        style="height: 65px; resize: none" class="form-control form-select input_area"
                        @keyup="search($event)" @click="partnerTeams.isOpen = true" v-model="partnerTeams.outputString" />
                      <i class="fa-solid fa-chevron-down"></i>
                      <span class="input-name" style="position: absolute; top: -13px">Partners teams <span
                          class="text-danger">*</span></span>
                    </label>
                  </div>
                </div>
                <div :class="[
                    'custom_select_dropdown_section',
                    { 'd-none': !partnerTeams.isOpen },
                  ]">
                  <div class="custom_select_dropdown_form">
                    <div class="Search_input_section"></div>
                    <div class="custom_select_dropdown_option-gruop">
                      <div class="form-check pt-3 pb-1">
                        <input type="checkbox" id="all_Checkbox" class="form-check-input" v-model="partnerTeams.allCheck"
                          @change="toggleAll" :disabled="partnerTeams.searchTerm !== ''" />
                        <label for="all_Checkbox">Select all groups </label>
                      </div>
                      <div class="accordion accordion-flush" id="AssignPartnersLinkTeams_Custom_select">
                        <div v-for="team in filteredPartnerTeams" :key="team.accordion.value"
                          class="accordion-item px-3 border-0 my-0">
                          <h2 class="accordion-header" id="flush-headingOne">
                            <button class="accordion-button px-0 py-1" type="button" data-bs-toggle="collapse"
                              :class="{ collapsed: team.show == false }" @click="showTeam(team.accordion.value)"
                              aria-expanded="ture" :aria-controls="`#collapse${team.accordion.value}`">
                              <div class="form-check">
                                <input type="checkbox" class="form-check-input"
                                  @change="toggleTeams(team.accordion.value)" v-model="team.accordion.isChecked" />
                                <label class="font-weight-normal">{{
                                  team.accordion.label
                                }}</label>
                              </div>
                            </button>
                          </h2>
                          <div :class="{ show: team.show }" class="accordion-collapse collapse"
                            aria-labelledby="headingOne" data-bs-parent="#AssignPartnersLinkTeams_Custom_select">
                            <div class="accordion-body px-4">
                              <div v-for="region in team.regions" :key="region.value" class="form-check">
                                <input type="checkbox" class="form-check-input" :id="region.value" :value="region.value"
                                  @change="toggleRegions(
                                        team.accordion.value,
                                        region,
                                        $event
                                      )
                                      " v-model="region.isChecked" />
                                <label :for="region.value">{{ region.label }}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text-center pt-3 mb-3">
                    <a class="btn btn-outline-dark mx-2 px-3" @click="reset()">Reset</a>
                    <a class="btn btn-dark mx-2 px-3" @click="partnerTeams.isOpen = false">Done</a>
                  </div>
                </div>
              </div>
            </div>

            <img src="../../public/dashboard-assets/images/M2/fluent.png" class="managent_partner_assign-img my-4"
              alt="" />
            <div class="row text-start mx-2">
              <label class="input-hero" for="PartnerCompany">
                <Multiselect class="input_area placeholder-kj" :disabled="this.partnerTeams.outputString == 0"
                  v-model="value" :options="childTeamdArray" placeholder="Choose My Group" />
                <span class="input-name" style="position: absolute; top: -13px">My teams <span
                    class="text-danger">*</span>
                </span>
              </label>
            </div>

            <div class="text-end mt-4">
              <button type="button" class="modail_submit_button py-1" @click="linkteam" :disabled="isDisabled()"
                :class="{ active_form_button: !isDisabled() }">
                Link
              </button>
              <!-- <button @click="savepartner(0)" class="modail_submit_button" :disabled="isDisabled()"
                  :class="{ active_form_button: !isDisabled() }">
                  Create
                </button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</template>
<script>
import Multiselect from "@vueform/multiselect";
import { mapState, mapActions, mapMutations } from "vuex";
import Paginate from "vuejs-paginate-next";
import { getUserPermissionData } from "../utils/helper";

export default {
  name: "ManagementPartnersAssignTeam",
  components: {
    Multiselect,
    paginate: Paginate,
  },
  data() {
    return {
      isAccordionOpen: false,
      options: [
        "Maharashtra",
        "Mumbai",
        "Malegaon",
        "mks",
        "raj",
        "sdfyjk",
        "sdfghjkl",
        "dfghjkl",
      ],
      // partnerTeam: "",
      GetTeamList: [],
      searchinput: "",
      filters: {
        partnerList: "",
      },
      teamName: [],
      region: [],
      regionname: "",
      value: "",
      array: [],
      check: [],
      childTeamList: [],
      childTeamdArray: [],
      grpsList: [],
      regions: "",
      regionId: "",
      // teamName: "",
      PartnerTeamId: "",
      partnerTeams: {
        isOpen: false,
        searchTerm: "",
        allCheck: false,
        teams: [],
        outputString: "",
      },
      partnerList: [],
      offset: 1,
      limit: 10,
      assignedgrpsList: [],
      partners: "",
      groups: "",
      Page_count: 0,
      currentPage: 1,
      show: false,
      totalPartnerCount: 0,
      totalGroupCount: 0,
    };
  },

  async mounted() {
    if (this.$route.query.offset) {
      this.offset = window.atob(this.$route.query.offset);
    }
    await this.getPartnerList();

    await this.list();
    var teams = await this.Getpartners({
      clientId: this.clientId,
    });

    if (teams.status) {
      this.GetTeamList = Object.freeze(
        JSON.parse(JSON.stringify(teams.object.data))
      );

      Object.keys(this.Fngroups).forEach((element) => {
        var ddlData = this.Fngroups[element];
        // this.array.push({
        //   label: element.regionName,
        //   value: element.regionId,
        // });
        // if (idx === 0) {
        // element.partnerTeam.forEach((element1) => {
        const team = {
          accordion: {
            label: ddlData[0].partnerName,
            value: element,
            isChecked: false,
          },
          regions: ddlData.map((region) => ({
            label: region.regionName,
            value: region.regionId,
            isChecked: false,
          })),
        };

        this.check.push(team);
        // });
        // }
      });
    }
    await this.getAssignedgroupList();
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticate,
      user: (state) => state.auth.user,
      clientId: (state) => state.auth.user.clientId,
    }),

    filteredPartnerTeams() {
      const searchTerm = this.partnerTeams.searchTerm;
      let filteredPartnerTeams = this.check;
      filteredPartnerTeams.forEach((elm) => {
        elm.show = false;
      });
      if (searchTerm) {
        filteredPartnerTeams = filteredPartnerTeams.filter((team) =>
          team.accordion.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }
      return filteredPartnerTeams;
    },
    Fngroups() {
      return this.groupBy(this.GetTeamList, "partnerId");
    },
  },
  watch: {
    "partnerTeams.teams"(newValue) {
      var outputString = newValue.map((item) => item.label);
      this.partnerTeams.outputString = outputString.join(",");
    },
    "partnerTeams.outputString"(value) {
      if (value == "") {
        this.partnerTeams = {
          isOpen: false,
          searchTerm: "",
          allCheck: false,
          teams: [],
          outputString: "",
        };
        this.value = "";
        this.check = this.check.map((team) => {
          team.accordion.isChecked = false;
          team.regions.forEach((region) => {
            region.isChecked = false;
          });
          return team;
        });
      }
    },
  },
  methods: {
    getUserPermissionData,
    ...mapActions([
      "GetTeams",
      "Getpartners",
      "AssignTeams",
      "ShowWarningMessage",
      "AllPartnerList",
      "AllAssignedGroups",
      "DeleteGroup",
    ]),

    ...mapMutations(["setLoading"]),
    encode(item) {
      return window.btoa(item);
    },
    openAssignPartner() {
      window.$("#AssignPartnersLinkTeamsModal").modal("show");
    },
    search(evt) {
      var arrays = evt.target.value.split(/[,]+/);
      arrays.forEach((element) => {
        this.partnerTeams.searchTerm = element;
      });
      if (evt.which == 8 || evt.which == 46) {
        var outStr = this.partnerTeams.outputString.trim().split(/[,]+/);

        var teamLabel = [];
        this.partnerTeams.teams.forEach((el) => {
          teamLabel.push(el.label);
        });

        var result = teamLabel.filter((elem) => !outStr.includes(elem));

        var resLabel = "";
        this.partnerTeams.teams.forEach((el) => {
          if (el.label == result) {
            resLabel = el;
          }
        });
        this.check.forEach((el) => {
          if (el.accordion.value == resLabel.value) {
            el.accordion.isChecked = false;
            el.regions.forEach((elm) => {
              elm.isChecked = false;
            });
          }
        });
        this.partnerTeams.teams.splice(
          this.partnerTeams.teams.findIndex((el) => el.value == resLabel.value),
          1
        );
        this.partnerTeams.teams = [...this.partnerTeams.teams];
        this.partnerTeams.searchTerm = "";
      }
    },
    isDisabled() {
      return this.value == "";
    },
    clickCallback(pageNum) {
      this.offset = (pageNum - 1) * this.limit + 1;
      this.getAssignedgroupList();
    },
    async removefun(id, name) {
      this.regionname = name;
      this.regionId = id;
    },
    async confirmdelete() {
      await this.DeleteGroup({
        regionId: this.regionId,
        clientId: this.clientId,
      });
      document.getElementById("close").click();
      window.$("#DeactivatePartnerModal").modal("hide");
      this.offset = 1;
      this.currentPage = 1;
      //await this.list();
      await this.getAssignedgroupList();
    },

    // Searching box
    opensearch() {
      document.querySelector(".Searching_input-box").classList.add("open");
    },
    closesearch() {
      document.querySelector(".Searching_input-box").classList.remove("open");
      this.result = [];
      this.input = "";
    },
    // closemobilesearch() {
    //   this.show = false;
    //   document
    //     .querySelector(".Searching_input_box_mobile_view")
    //     .classList.remove("open");
    // },
    openmobilesearch() {
      document
        .querySelector(".Searching_input_box_mobile_view")
        .classList.add("open");
      this.result = [];
      this.input = "";
      this.show = true;
    },
    // Searching box

    resetLinkTeamForm() {
      this.partnerTeams = {
        isOpen: false,
        searchTerm: "",
        allCheck: false,
        teams: [],
        outputString: "",
      };
      this.value = "";
      this.check = this.check.map((team) => {
        team.accordion.isChecked = false;
        team.regions.forEach((region) => {
          region.isChecked = false;
        });
        return team;
      });
      window.$("#AssignPartnersLinkTeamsModal").modal("hide");
    },
    reset() {
      this.partnerTeams = {
        isOpen: false,
        searchTerm: "",
        allCheck: false,
        teams: [],
        outputString: "",
      };
      this.value = "";
      this.check = this.check.map((team) => {
        team.accordion.isChecked = false;
        team.regions.forEach((region) => {
          region.isChecked = false;
        });
        return team;
      });
    },
    async list() {
      var childTeamList = await this.GetTeams({
        clientId: this.clientId,
      });
      if (childTeamList.status) {
        this.childTeamList = Object.freeze(
          JSON.parse(JSON.stringify(childTeamList.object.data.regions))
        );
        this.childTeamList.forEach((element) => {
          this.childTeamdArray.push({
            label: element.regionName,
            value: element.regionId,
          });
        });
      }
    },
    async linkteam() {
      if (this.partnerTeams.outputString == 0) {
        return this.ShowWarningMessage("Please select Partners Teams.");
      }
      if (this.value == 0 || this.value == null) {
        return this.ShowWarningMessage("Please select Team.");
      }
      var result = [];
      this.partnerTeams.teams.forEach((element) => {
        this.check.forEach((elm) => {
          if (elm.accordion.value == element.value) {
            elm.regions.forEach((el) => {
              if (el.isChecked == true) {
                result.push(el.value);
              }
            });
          }
        });
      });

      await this.AssignTeams({
        clientId: this.clientId,
        regionId: this.value,
        // PartnerTeamId: this.partnerTeams.teams.map((team) => parseInt(team.value)),
        PartnerTeamId: result,
      });
      // window.$("#AssignPartnersLinkTeamsModal").modal("hide");
      this.resetLinkTeamForm();
      await this.getAssignedgroupList();
    },
    toggleAll() {
      let isChecked = false;

      if (this.partnerTeams.allCheck) {
        isChecked = true;
      }
      this.check.forEach((team) => {
        team.accordion.isChecked = isChecked;
        team.regions.forEach((el) => {
          el.isChecked = team.accordion.isChecked;
        });
        if (isChecked) {
          var indx = this.partnerTeams.teams.findIndex(
            (x) => x.value == team.accordion.value
          );
          if (indx < 0) {
            this.partnerTeams.teams = [
              ...this.partnerTeams.teams,
              {
                label: team.accordion.label + "(" + team.regions.length + ")",
                value: team.accordion.value,
              },
            ];
          }
        } else {
          this.partnerTeams.teams = [];
        }
      });
    },

    toggleTeams(idx) {
      this.partnerTeams.allCheck = this.check.every(
        (team) => team.accordion.isChecked
      );

      const checkItem = this.check.find((team) => team.accordion.value === idx);
      checkItem.regions.forEach((region) => {
        region.isChecked = checkItem.accordion.isChecked;
      });
      var x = checkItem.regions.length;
      var indx = this.partnerTeams.teams.findIndex((x) => x.value == idx);
      if (checkItem.accordion.isChecked) {
        if (indx < 0) {
          this.partnerTeams.teams = [
            ...this.partnerTeams.teams,
            {
              label: checkItem.accordion.label + "(" + x + ")",
              value: checkItem.accordion.value,
            },
          ];
        } else {
          this.partnerTeams.teams[indx].label =
            checkItem.accordion.label + "(" + x + ")";
          this.partnerTeams.teams = [...this.partnerTeams.teams];
        }
      } else {
        var index = this.partnerTeams.teams.findIndex(
          (team) => team.value == checkItem.accordion.value
        );
        this.partnerTeams.teams.splice(index, 1);
        this.partnerTeams.teams = [...this.partnerTeams.teams];
      }
    },

    toggleRegions(idx, region) {
      const checkItem = this.check.find((team) => team.accordion.value === idx);
      checkItem.accordion.isChecked = checkItem.regions.every(
        (region) => region.isChecked
      );
      this.partnerTeams.allCheck = this.check.every((team) => {
        return team.accordion.isChecked;
      });
      var x = checkItem.regions.filter((x) => x.isChecked == true);
      var indx = this.partnerTeams.teams.findIndex((x) => x.value == idx);
      if (region.isChecked) {
        if (indx < 0) {
          this.partnerTeams.teams = [
            ...this.partnerTeams.teams,
            {
              label: checkItem.accordion.label + "(" + x.length + ")",
              value: checkItem.accordion.value,
            },
          ];
        } else {
          this.partnerTeams.teams[indx].label =
            checkItem.accordion.label + "(" + x.length + ")";
          this.partnerTeams.teams = [...this.partnerTeams.teams];
        }
      } else {
        this.partnerTeams.teams[indx].label =
          checkItem.accordion.label + "(" + x.length + ")";
        if (x.length == 0) {
          var index = this.partnerTeams.teams.findIndex(
            (team) => team.value == checkItem.accordion.value
          );
          this.partnerTeams.teams.splice(index, 1);
          this.partnerTeams.teams = [...this.partnerTeams.teams];
        }
        this.partnerTeams.teams = [...this.partnerTeams.teams];
      }
    },

    async getPartnerList() {
      var partners = await this.AllPartnerList({
        clientId: this.clientId,
        offset: this.offset,
        limit: this.limit,
      });
      if (partners != undefined) {
        this.partnerList = partners.object.data.partnerData;
        this.totalPartnerCount = partners.object.data.totalPartners;
      }
    },
    async getAssignedgroupList() {
      var modal = {
        clientId: this.clientId,
        offset: this.offset,
        limit: this.limit,
      }
      if(this.filters.partnerList)
      {
        modal.Search = this.filters.partnerList;
      }
      var assignedgrps = await this.AllAssignedGroups(modal);

      if (assignedgrps.status) {
        this.assignedgrpsList = assignedgrps.object.data.partnerRegion;
        this.grpsList = Object.freeze(
          JSON.parse(JSON.stringify(this.assignedgrpsList))
        );
        this.totalGroupCount = assignedgrps.object.data.totalRegions;

        this.Page_count = this.grpsList
          ? Math.ceil(this.totalGroupCount / this.limit)
          : 1;
      } else {
        this.assignedgrpsList = [];
      }
    },

    groupBy(array, key) {
      const result = {};
      array.forEach((item) => {
        if (!result[item[key]]) {
          result[item[key]] = [];
        }
        result[item[key]].push({
          partnerId: item.partnerId,
          partnerName: item.partnerName,
          regionId: item.regionId,
          regionName: item.regionName,
        });
      });
      return result;
    },
    async searchpartnerlst(val) {
      this.searchinput = val.trim();
      this.offset = 1;
      this.filters.partnerList = this.searchinput;
      this.currentPage = 1;
      this.searchresult = [];
      await this.getAssignedgroupList();
    },
    async closemobilesearch() {
      this.show = false;
      document
        .querySelector(".Searching_input_box_mobile_view")
        .classList.remove("open");
      this.searchresult = [];
      this.filters.partnerList = "";
      this.searchinput = "";
      await this.getAssignedgroupList();
    },
    showTeam(id) {
      this.filteredPartnerTeams.forEach((elm) => {
        if (elm.accordion.value == id && elm.show != true) elm.show = true;
        else elm.show = false;
      });
    },
    openDropDown() {
      if (this.partnerTeams.isOpen) this.partnerTeams.isOpen = false;
      else this.partnerTeams.isOpen = true;
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style>
.multiselect-placeholder {
  color: var(--ms-placeholder-color, #000);
}

.namesp {
  display: block;
}

.nameInp {
  display: none;
}

.searchice {
  position: absolute;
  top: 13px;
  right: 18px;
}

.custom_select_dropdown_option-gruop {
  height: 230px;
  overflow-x: hidden;
  overflow-y: visible;
}

/* width */
.custom_select_dropdown_option-gruop::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.custom_select_dropdown_option-gruop::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.custom_select_dropdown_option-gruop::-webkit-scrollbar-thumb {
  background: #7c68ee;
}

/* Handle on hover */
.custom_select_dropdown_option-gruop::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
