<template>
    <div class="page-content">
      <!-- mobile view Searching -->
      <Tour :page="componentName"/>
      <div class="Searching_input_box_mobile_view position-relative">
        <div class="w-100  mb-3 " v-if="this.show">
          <input type="text" v-model="input" :disabled="searchDisable" @keyup.enter="searchregionname(input)" placeholder="Search..." />
          <!--update-->
          <img src="../../../public/dashboard-assets/images/M2/Close_icon.png" @click="closemobilesearch" alt=""
            class="close-icon" />
        </div>
      </div>
  
      <!--  Activated Groups-Toast -->
      <div class="d-flex justify-content-between align-items-center flex-wrap mb-2">
        <div class="my-2">
          <h4 class="mb-2 mb-md-0 fw-bold">Token Management</h4>
        </div>
  
        <div class="d-flex justify-content-between align-items-center">
          <!-- Mobile View Searching Button -->
          <div class="top_heading_CreatePartner_btn position-relative Searching_button_mobileView">
            <div class="Group_top_three_dot">
              <span class="icon Group_top_three_dot_dropdown" :style="{ display: this.show == true ? 'none' : 'block' }"
                @click="openmobilesearch">
                <img src="../../../public/dashboard-assets/images/M2/Icon-Set.png" alt="" class="Searching_search_icon" />
              </span>
            </div>
          </div>
        </div>
      </div>
  
      <div class="row">
        <div class="col-xl-5 col-lg-8 partners_top_menu_section_hp">
          <div class="row">
            <div class="col-6">
              <router-link :to="{ name: 'OverviewTokenManagementTour' }" class="w-100 btn active btn-outline-primary btn-icon-text">
                Overview <span>({{ TokelistTotallength }})</span>
              </router-link>
            </div>
            <div class="col-6">
              <router-link :to="{ name: 'TestTokenManagementTour' }" class="w-100 btn">
                View Test
              </router-link>
            </div>
          </div>
        </div>
      </div>
  
      <!-- empty section -->
  
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="Token_Overview_hp" role="tabpanel"
          aria-labelledby="Token_Overview_hp-tab">
          <section class="my-4 token_secand_header_section mt-3">
            <!-- <div class="col-md-12"> -->
            <div class="card p-0">
              <div class="card-body px-3 py-3">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex token_secand_header_company align-items-center" id="show-own-company-tokens">
                    <div class="PartnerTeamMembersProfile-area-kj">
                      <div class="PartnerTeamMembersProfile-kj name-logo-1" v-if="this.photo == null">
                        {{ parentCompanyName?.charAt(0) }}
                      </div>
                      <div class="" v-else>
                        <img :src="this.photo" class="profile" />
                      </div>
                    </div>
                    <!-- <img src="../../public/dashboard-assets/images/M2/logo.png" alt="" /> -->
                    <div class="ms-2">
                      <h5 v-if="
                        this.screenWidth <= 1441 &&
                        this.screenWidth > 767 &&
                        parentCompanyName.trim().length > 8
                      ">
                        {{
                          parentCompanyName.replace(parentCompanyName.slice(9), '..')
                        }}
                        &nbsp;({{ tokenList.length }})
                      </h5>
                      <h5 v-else-if="
                        this.screenWidth <= 767 && parentCompanyName.trim().length > 8
                      ">
                        {{
                          parentCompanyName.replace(parentCompanyName.slice(9), '..')
                        }}&nbsp;({{ tokenList.length }})
                      </h5>
                      <h4 v-else>
                        {{ parentCompanyName }}&nbsp;({{ TokelistTotallength }})
                      </h4>
                      <p class="font-size-12" v-if="!superadmin()">
                        Tokens:
                        <span class="color_blue">{{ parentCompany.remainingToken }}</span>/
                        <span>{{ parentCompany.allocatedToken }}</span>
                      </p>
                    </div>
                  </div>
                  <div class="top_heading_CreatePartner_btn d-flex justify-content-between align-items-center">
                    <!-- Search  web view-->
                    <div class="top_heading_CreatePartner_btn d-none d-md-block position-relative">
                      <div class="Group_top_three_dot">
                        <div class="Searching_input-box">
                          <input type="text" v-model="input" :disabled="searchDisable" @keyup.enter="searchregionname(input)"
                            placeholder="Search..." />
                          <!--update-->
                          <span class="icon Group_top_three_dot_dropdown" @click="opensearch">
                            <img src="../../../public/dashboard-assets/images/M2/Icon-Set.png" alt=""
                              class="Searching_search_icon" />
                          </span>
                          <img src="../../../public/dashboard-assets/images/M2/Close_icon.png" @click="closesearch" alt=""
                            class="close-icon" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- </div> -->
          </section>
  
          <section class="Partner_empty_main_section_hp mt-4" v-if="tokenList.length == 0">
            <div class="row">
              <div class="Partner_empty_img_hp m-auto text-center">
                <img src="../../../public/dashboard-assets/images/M2/empty_record.png" alt="" />
                <h2>No Record Found</h2>
              </div>
            </div>
          </section>
  
          <section class="row role_item_list_section mt-4" v-else>
            <div class="col-xl-3 col-lg-4 col-md-6 col-6 mb-4" v-for="item in tokenList" :key="item">
              <div class="role_item_box p-0 box_hover_effect">
                <div class="pb-0 d-flex justify-content-between role_item_box_new_item align-items-center">
                  <router-link :to="{
                    name: 'TestManagementDetail',
                    params: { id: encode(item.clientId) },
                    query: { offset: encode(this.page), current: encode(this.currentPage) },
                  }" class="text-dark w-100">
                    <div class="p-3">
  
  
                      <p class="mb-2"><span class="text-muted me-1" style="background: none;font-size: 12px;padding: 0;">
                          ClientId:</span> <span class="test-dark fw-bold"
                          style="background: none;font-size: 12px;padding: 0;color:#000">{{ item.clientId }}</span></p>
                      <img :src=item.photo v-if="item.photo" class="logoPartner" />
                      <!-- <img
                    src="../../public/dashboard-assets/images/M2/Token_logo.png"
                    alt=""
                    v-else
                  /> -->
                      <div class="partner_item_img" v-else>
                        <div class="partner_FirstWord">
                          {{ item.company.charAt(0) }}
                        </div>
                      </div>
                      <span class="Requested_token_button" v-if="item.requestCount != 0">Requested</span>
                      <!-- </div> -->
  
                      <div class="px-3 pb-3">
                        <h4 class="role_item_box_heading border-0 pb-1" style="overflow-wrap: break-word;">
                          {{ item.company }}
                        </h4>
                        <p class="font-size-12 mr-2" v-if="item.clientId != 1001" >
                            <span id="show-partner-tokens" class="color_span" style="background: none;">
                              {{ item.consumedToken }}/{{
                              item.allocatedToken }}
                            </span>
                        </p>
                      </div>
                    </div>
  
                  </router-link>
                </div>
                <div v-if="superadmin()">
                  <div class="user_item_Reactivate" v-if="item.requestCount != 0">
                    <button type="button" class="btn w-100 text-center parnter_item_date border-0 p-0 card-footer-bg-kj"
                      data-bs-toggle="modal" data-bs-target="#RequestedTokenModal" @click="
                        ViewReqList(
                          item.company,
                          item.remainingToken,
                          item.clientId
                        )
                      ">
                      View {{ item.requestCount }} Requests
                    </button>
                  </div>
  
                  <div class="user_item_Reactivate" v-else>
                    <button type="button" class="btn w-100 text-center parnter_item_date border-0 p-0 card-footer-bg-kj"
                      data-bs-toggle="modal" data-bs-target="#AllocateTokens" @click="
                        getCompanyName(
                          item.company,
                          item.remainingToken,
                          item.clientId
                        )
                      ">
                      Allocate Tokens
                    </button>
                  </div>
                </div>
              </div>
            </div>
  
            <paginate v-model="currentPage" :page-count="Page_count" :margin-pages="1" :page-range="1"
              :click-handler="clickCallback" :prev-text="'< Prev'" :next-text="'Next >'" :container-class="'pagination'"
              :page-class="'page-item'" v-if="this.tokenList != 0">
            </paginate>
          </section>
        </div>
      </div>
  
      <!-- model Request Token -->
      <div class="modal fade" id="RequestTokenModal" tabindex="-1" aria-labelledby="IncreaseTokenModalLabel"
        aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable model-sm">
          <div class="modal-content border-radius-kj-20">
            <div class="modal-header token-model-header-color">
              <h5 class="modal-title text-centerm-auto" id="IncreaseTokenModalLabel">
                <img src="../../../public/dashboard-assets/images/M2/BackButton.png" v-if="modalData.clientId != this.clientId"
                  alt="" data-bs-toggle="modal" data-bs-target="#RequestedTokenModal" />
                Request Tokens
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" data-bs-toggle="modal" aria-label="btn-close"
                id="closerequest1" @click="clearbtn()"></button>
            </div>
            <div class="modal-body">
              <!-- <h5 class="my-4 request-color-token" v-if="modalData.clientId != this.clientId">
                How many tokens do you need {{ modalData.company }}?
              </h5> -->
              <h5 class="my-4 request-color-token">
                How many tokens do you need ?
              </h5>
              <form class="row my-3" method="">
                <div class="tokenPrice token-model-kj d-flex">
                  <div class="value-button-min" id="decrease" value="Decrease Value" :disabled="decDisableButton()"
                    :class="{ active_inc_dec_button: decDisableButton() }" @click="decreaseValue()">
                    <i class="fa-solid fa-minus"></i>
                  </div>
  
                  <input type="number" class="w-75 input-number-token-kj" id="number" placeholder="0"
                    v-model="requestAmount"
                    :class="{ input_error_border: requestAmount > 5000 && modalData.clientId != 1001 }"
                    oninput="validity.valid||(value='');" />
                  <div class="value-button-max" id="increase" :disabled="inrDisableButton()"
                    :class="{ active_inc_dec_button: inrDisableButton() }" @click="increaseValue()" value="Increase Value">
                    <i class="fa-solid fa-plus"></i>
                  </div>
                </div>
                <p v-if="requestAmount > 5000 && modalData.clientId != 1001" style="color: red">
                  You cannot Request more than 5000 tokens.
                </p>
                <h5 class="my-3 request-color-token">
                  Balance Tokens: {{ this.modalData.remainingToken }}
                </h5>
                <div class="text-end mt-2">
                  <button type="button" class="btn btn-token-color-kj" :disabled="!isButtonDisable(modalData.clientId)"
                    :class="{
                      actived_form_button:
                        ((requestAmount > 0 && requestAmount < 5001) || (modalData.clientId == 1001 && requestAmount >= 5001)),
                    }" @click="incdrcTokens('RequestTokenModal', modalData.clientId)">
                    Request
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Requests Token Modal Popup -->
      <div class="modal fade CreatePartnerModal_hp RequestTokenModal_secction" id="RequestedTokenModal" tabindex="-1"
        aria-labelledby="RequestedTokenModal" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title text-centerm-auto" id="RequestedTokenModal">
                Allocate Tokens to {{ modalData.company }}
              </h4>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close" id="closerequest"
                @click="clearbtn()"></button>
            </div>
            <div class="modal-body">
              <div class="d-flex justify-content-between align-items-center">
                <h5>{{ reqList.length }}-Pending Requests</h5>
  
                <p class="request-color-muted font-size-12" :class="{ error: this.redtext }">
                  <!-- Balance Tokens:&nbsp;{{ this.remainingToken - this.tokenamt }} -->
                  Balance Tokens:{{
                    this.parentCompany.remainingToken - this.remainingToken
                  }}
                </p>
              </div>
              <div class="d-flex justify-content-between align-items-center mt-3 RequestTokenModal_item_box"
                v-for="(item, index) in reqList" :key="item">
                <div class="RequestTokenModal_item_box_company">
                  <img src="../../../public/dashboard-assets/images/M2/UsersDP2.png" alt="" />
                  <div>
                    <h5>{{ item.firstName }}&nbsp;{{ item.lastName }}</h5>
                    <p class="request-color-token">
                      Requested {{ item.tokenAmount }} Tokens
                    </p>
  
                    <p class="request-color-token-date">
                      {{ dateConvert(item.requestedDate) }}&nbsp;{{
                        timeConverter(item.requestedDate)
                      }}
                    </p>
                  </div>
                </div>
                <!-----<div class="RequestTokenModal_item_box_button">--->
                <div class="d-flex">
                  <button class="modail_submit_button RequestTokenModal_item_box_CheckButton px-5 py-1 mx-1" id="right"
                    :hidden="!this.approvedReq.includes(item.requestId)">
                    <i class="fa-solid fa-check"></i>
                  </button>
                  <button class="modail_submit_button px-3 py-1 mx-1 text-muted float-start border-secondary"
                    data-bs-toggle="modal" data-bs-target="#RejectTokenModal" id="remove"
                    :hidden="this.approvedReq.includes(item.requestId)" @click="
                      rejectBtn(
                        item.firstName,
                        item.lastName,
                        item.tokenAmount,
                        item.requestId,
                        item.clientId
                      )
                    ">
                    Reject
                  </button>
                  <button class="modail_submit_button px-3 py-1 mx-1" id="aprove" @click="
                    approverequest(
                      item.requestId,
                      'Approved',
                      index,
                      item.tokenAmount,
                      item.clientId
                    )
                  " :hidden="this.approvedReq.includes(item.requestId)" :disabled="
    this.parentCompany.remainingToken < item.tokenAmount
  ">
                    Approve
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <!-- AllocateTokens -->
    <div class="modal fade" id="AllocateTokens" tabindex="-1" aria-labelledby="IncreaseTokenModalLabel" aria-hidden="true"
      data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable model-sm" data-bs-backdrop="static"
        data-bs-keyboard="false">
        <div class="modal-content border-radius-kj-20">
          <div class="modal-header token-model-header-color">
            <h5 class="modal-title text-centerm-auto" id="IncreaseTokenModalLabel">
              Allocate Tokens
            </h5>
            <button type="button" id="close" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"
              @click="clearbtn()"></button>
          </div>
          <div class="modal-body">
            <h5 class="my-4 request-color-token">
              How many tokens do you want to allocate to
              <strong class="text-dark"> {{ CompanyName }}? </strong>
            </h5>
            <form class="row my-3" method="">
              <div class="tokenPrice token-model-kj d-flex">
                <div class="value-button-min" id="decrease" value="Decrease Value" :disabled="decDisableAllocatedButton()"
                  :class="{ active_inc_dec_button: decDisableAllocatedButton() }" @click="decreaseallocatevalue()">
                  <i class="fa-solid fa-minus"></i>
                </div>
                <input type="number" class="w-75 input-number-token-kj" id="number" placeholder="0" v-model="AllocateAmount"
                  oninput="validity.valid||(value='');" />
                <div class="value-button-max" id="increase" @click="increaseallocateValue()" value="Increase Value">
                  <i class="fa-solid fa-plus"></i>
                </div>
              </div>
              <h5 class="my-3 request-color-token">
                Balance Tokens:{{ balanceToken }}
              </h5>
              <div class="text-end mt-2">
                <button type="button" class="btn btn-token-color-kj" :disabled="!allocatedButtonDisable()"
                  :class="{ actived_form_button: this.AllocateAmount > 0 }" @click="postAmountAllocation(viewclientId)">
                  Allocate
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  
    <!-- RejectTokenModal -->
    <div class="modal fade" id="RejectTokenModal" tabindex="-1" aria-labelledby="RejectTokenModalLabel" aria-hidden="true"
      data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-body p-4">
            <div class="">
              <h3 class="mb-3">Reject Tokens?</h3>
              <p class="text-muted">
                Are you sure you want to reject
                <strong class="text-dark"> {{ this.amt }}</strong> tokens
                requested from {{ this.firstname }}&nbsp;{{ this.lastname }}.
              </p>
            </div>
            <div class="Deactivate_buttons row mt-3 justify-content-center">
              <button class="col-5 Deactivate_Cancel_buttons" data-bs-dismiss="modal" aria-label="btn-close"
                @click="closeModal()">
                Cancel
              </button>
              <button type="button" class="col-5 btn Deactivate_Deactivate_buttons"
                @click="rejectToken(this.reqId, 'Rejected', this.amt)">
                Reject
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { mapState, mapActions, mapMutations } from "vuex";
  import moment from "moment";
  import Paginate from "vuejs-paginate-next";
  import { superadmin } from "../../utils/helper";
  import overviewTokenMgmtObject from '../../assets/TourObjects/overviewTokenManagementTourObject.json';
  import Tour from '../TourDemo.vue';
  
  export default {
    name: "TestManagement",
    components: {
      paginate: Paginate,
      Tour
    },
    data() {
      return {
        tokenList: [],
        reqList: [],
        TokelistTotallength: 0,
        AllocateAmount: null,
        requestAmount: null,
        rejToken: "",
        requestid: "",
        Status: "",
        page: 1,
        limit: 8,
        search: "",
        partners: "",
        Page_count: 1,
        modalData: "",
        parentCompany: "",
        CompanyName: "",
        balanceToken: 0,
        remainingToken: 0,
        viewData: [],
        listData: "",
        groupData: [],
        reqId: "",
        offset: 8,
        redtext: false,
        isActive: true,
        approvedReq: [],
        tokenamt: "",
        result: [],
        input: "",
        show: false,
        currentPage: 1,
        company: [],
        filters: {
          company: "",
        },
        viewclientId: "",
        screenWidth: 0,
        approveData: [],
        firstname: "",
        lastname: "",
        amt: "",
        clientid: "",
        parentCompanyName: "",
        photo: '',
        searchDisable:false,
        componentName: ''
      };
    },
    async created() {
      this.componentName = this.$route.name;
      // console.log(this.componentName);
      this.photo = null;
      this.companyName = 'PMaps Demo';
      // var company = await this.Companyheader({
      //   clientId: this.clientId,
      // });
      // var photo = company.object.data.profileImage;
      // if (photo != null) {
      //   var resp = await this.companylogo({
      //     fileName: photo,
      //   });
      //   this.photo = resp.object.data;
      // } else {
      //   this.photo = null;
      // }
    //   if (this.$route.query.offset && this.$route.query.current) {
    //     this.page = parseInt(window.atob(this.$route.query.offset));
    //     this.currentPage = parseInt(window.atob(this.$route.query.current));
    //   }
      // this.screenWidth = window.outerWidth;
      await this.getData();
      await this.GetToken();
    },
  
    computed: {
      ...mapState({
        authenticated: (state) => state.auth.authenticate,
        user: (state) => state.auth.user,
        clientId: (state) => state.auth.user.clientId,
        PartnerId: (state) => state.auth.user.PartnerId,
      }),
    },
  
    methods: {
      ...mapActions([
        "GetTokenAllocation",
        "requestedTokens",
        "IncrOrDecTokens",
        "rejectReq",
        "viewtestList",
        "viewGroupsList",
        "AllocateTokenAmount",
        "Companyheader"
      ]),
      ...mapMutations(["setLoading"]),
      dateConvert: (value) => {
        return moment(String(value)).format("DD MMM YYYY");
      },
      superadmin,
  
      myEventHandler(e) {
        this.screenWidth = window.outerWidth;
        e.preventDefault();
      },
      encode(item) {
        return window.btoa(item);
      },
      //time-converter
      timeConverter(val) {
        const today = new Date(val);
        let h = today.getHours();
        let m = today.getMinutes();
        var meridian = h >= 12 ? "PM" : "AM";
        h = h % 12;
        h = h ? h : 12;
        m = m < 10 ? "0" + m : m;
        var strTime = h + ":" + m + " " + meridian;
        return strTime;
      },
  
      //allocate token
      decDisableAllocatedButton() {
        if (
          this.AllocateAmount == null ||
          this.AllocateAmount == 0 ||
          this.AllocateAmount == "" ||
          this.AllocateAmount < 0
        ) {
          return true;
        } else {
          return false;
        }
      },
      decreaseallocatevalue() {
        if (
          this.AllocateAmount == null ||
          this.AllocateAmount == 0 ||
          this.AllocateAmount == "" ||
          this.AllocateAmount < 0
        ) {
          this.AllocateAmount = null;
        } else {
          this.AllocateAmount = this.AllocateAmount - 1;
        }
      },
      increaseallocateValue() {
        if (
          this.AllocateAmount == null ||
          this.AllocateAmount == "" ||
          this.AllocateAmount == 0
        ) {
          this.AllocateAmount = 1;
        } else {
          this.AllocateAmount = this.AllocateAmount + 1;
        }
      },
  
      //request token
      decDisableButton() {
        if (
          this.requestAmount == null ||
          this.requestAmount == 0 ||
          this.requestAmount == "" ||
          this.requestAmount < 0
        ) {
          return true;
        } else {
          return false;
        }
      },
      decreaseValue() {
        if (
          this.requestAmount == null ||
          this.requestAmount == 0 ||
          this.requestAmount == "" ||
          this.requestAmount < 0
        ) {
          this.requestAmount = null;
        } else {
          this.requestAmount = this.requestAmount - 1;
        }
      },
      inrDisableButton() {
        return this.requestAmount > 5000;
      },
      increaseValue() {
        if (
          this.requestAmount == null ||
          this.requestAmount == "" ||
          this.requestAmount == 0
        ) {
          this.requestAmount = 1;
        } else {
          if (parseInt(this.requestAmount) <= 5000) {
            this.requestAmount = parseInt(this.requestAmount) + 1;
          }
        }
      },
  
      // Searching box
      opensearch() {
        document.querySelector(".Searching_input-box").classList.add("open");
      },
      async closesearch() {
        document.querySelector(".Searching_input-box").classList.remove("open");
        this.result = [];
        this.input = "";
        this.filters.company = "";
        await this.getData();
      },
      async closemobilesearch() {
        this.show = false;
        document
          .querySelector(".Searching_input_box_mobile_view")
          .classList.remove("open");
        this.result = [];
        this.filters.company = "";
        this.input = "";
        await this.getData();
      },
      openmobilesearch() {
        document
          .querySelector(".Searching_input_box_mobile_view")
          .classList.add("open");
        this.result = [];
        this.input = "";
        this.show = true;
      },
  
      async filteredList() {
        if (this.input != "") {
          this.company = [];
          this.result = [];
          var list = await this.GetTokenAllocation({
            clientId: this.clientId,
            Page: 1,
            Limit: 100,
            Search: this.input,
          });
          var x = list.object.data.items;
          x.forEach((element) => {
            if (this.company.indexOf(element.company) < 0) {
              this.company.push(element.company);
            }
          });
          this.result = this.company.filter((element) =>
            element.toLowerCase().startsWith(this.input.toLowerCase())
          );
          this.result = Object.freeze(JSON.parse(JSON.stringify(this.result)));
        } else {
          this.result = [];
          this.filters.company = "";
          await this.getData();
        }
      },
      stringTOHtml(item, idx) {
        setTimeout(() => {
          if (this.input != "") {
            let re = new RegExp(this.input.toLowerCase(), "g");
            var html = document.getElementById(idx);
            var ex = item
              .toLowerCase()
              .replace(
                re,
                `<span style="color: #7b68ee !important">${this.input}</span>`
              );
            if (html) html.innerHTML = ex;
          }
        }, 100);
      },
      async searchregionname(val) {
        this.input = val.trim();
        this.page = 1;
        this.filters.company = val;
        this.result = [];
        this.currentPage = 1;
        await this.getData();
      },
      //*********search end*********
  
      isButtonDisable(clientId) {
        if (clientId == 1001 && this.requestAmount > 0) {
          return true;
        } else {
          if (this.requestAmount > 0 && this.requestAmount < 5001) {
            return true;
          }
          else if (this.requestAmount > 5000) {
            return false;
          }
        }
      },
      allocatedButtonDisable() {
        return this.AllocateAmount > 0;
      },
  
      allocatedecreasevalue() {
        if (
          this.AllocateAmount == null ||
          this.AllocateAmount == 0 ||
          this.AllocateAmount == "" ||
          this.AllocateAmount < 0
        ) {
          this.AllocateAmount = null;
        } else {
          this.AllocateAmount = this.AllocateAmount - 1;
        }
      },
  
      async clickCallback(pageNum) {
        this.page = pageNum;
        await this.getData();
      },
  
      async rejectBtn(firstName, lastName, amt, reqid, clientId) {
        (this.firstname = firstName),
          (this.lastname = lastName),
          (this.amt = amt),
          (this.reqId = reqid),
          (this.clientid = clientId);
      },
  
      async getData() {
        this.searchDisable = true;
  
        // var list = await this.GetTokenAllocation({
        //   clientId: this.clientId,
        //   Page: this.page,
        //   Limit: this.limit,
        //   Search: this.filters.company,
        // });
        this.searchDisable = false;
        // var x = [];
        
          this.tokenList = overviewTokenMgmtObject[0].object.data.items;
          this.TokelistTotallength = overviewTokenMgmtObject[0].object.data.totalItems;
  
        //   this.tokenList.forEach(async (element) => {
        //     x.push(element.clientId);
        //     var request = await this.requestedTokens({
        //       clientId: element.clientId,
        //     });
        //     if (request.status == false) {
        //       element.requestCount = 0;
        //     } else {
        //       element.requestCount = await request.object.data.length;
        //     }
        //   });
          this.Page_count = overviewTokenMgmtObject[0].object.data.totalPages;
          // this.partnerLogoList();
        
        // if (this.input.trim() != "") {
        //   list.Search = this.input;
        // }
      },
  
      async partnerLogoList() {
        this.tokenList.forEach(async (elm) => {
          // var company = await this.Companyheader({
          //   clientId: elm.clientId,
          // });
          // var photo = company.object?.data?.profileImage;
          // if (photo != null) {
          //   var resp = await this.companylogo({
          //     fileName: photo,
          //   });
          //   elm.photo = resp.object.data;
          // } else {
          //   elm.photo = null;
          // }
          elm.photo = null;
        });
      },
  
      async incdrcTokens(modal, id) {
        await this.IncrOrDecTokens({
          clientId: id,
          amount: this.requestAmount,
        });
        this.requestAmount = 0;
        if (modal == "RequestToken_IncreaseTokenModal") {
          document.getElementById("closerequest").click();
          window.$("#RequestToken_IncreaseTokenModal").modal("hide");
        } else {
          document.getElementById("closerequest1").click();
          window.$("#RequestTokenModal").modal("hide");
        }
        this.getData();
      },
      async approverequest(reqId, status, index, tokenamount, id) {
        this.rejectrequest = await this.rejectReq({
          clientId: id,
          requestId: reqId,
          status: status,
          tokenAmount: tokenamount,
          company: this.modalData.company,
        });
        this.isActive = false;
        if (this.rejectrequest.status) {
          this.approvedReq.push(reqId);
  
          setTimeout(async () => {
            var request = await this.requestedTokens({
              clientId: id,
            });
            this.reqList = request.object.data;
            if (this.reqList.length == 0) {
              // document.getElementById('aprove').click();
              document.getElementById("closerequest").click();
            } else {
              this.reqList.forEach((element) => {
                if (element.tokenAmount > this.parentCompany.remainingToken) {
                  this.redtext = true;
                } else {
                  this.redtext = false;
                }
              });
            }
          }, 3000);
        } else {
          // this.parentCompany.remainingToken =
          //   this.parentCompany.remainingToken - tokenamount;
        }
  
        this.remainingToken = this.reqList[index].tokenAmount;
        this.GetToken();
        this.getData();
      },
  
      async rejectToken(reqId, status, tokenamount) {
        this.rejToken = await this.rejectReq({
          clientId: this.clientid,
          requestId: reqId,
          status: status,
          tokenAmount: tokenamount,
          company: this.modalData.company,
        });
        document.getElementById("remove").click();
        document.getElementById("closerequest").click();
        window.$("#RejectTokenModal").modal("hide");
        this.getData();
      },
      async postAmountAllocation(id) {
        await this.AllocateTokenAmount({
          clientId: id,
          amount: this.AllocateAmount,
          companyName: this.CompanyName,
        });
        this.AllocateAmount = null;
        document.getElementById("close").click()
        window.$("#AllocateTokens").modal("hide");
        this.getData();
        this.GetToken();
      },
  
      async GetToken() {
        var tokens = overviewTokenMgmtObject[1]; // await this.GetTAllocation({
        if (tokens != undefined) {
          this.parentCompany = tokens.object.data;
  
          this.parentCompanyName = this.parentCompany.company;
        }
      },
  
      decreaseAllocateAmountToken() {
        if (
          this.AllocateAmount == null ||
          this.AllocateAmount == 0 ||
          this.AllocateAmount == "" ||
          this.AllocateAmount < 0
        ) {
          this.true;
        } else {
          return false;
        }
      },
      AllocateAmountDecToken() {
        if (
          this.AllocateAmount == null ||
          this.AllocateAmount == 0 ||
          this.AllocateAmount == "" ||
          this.AllocateAmount < 0
        ) {
          this.AllocateAmount = null;
        } else {
          this.AllocateAmount = this.AllocateAmount - 1;
        }
      },
  
      async getCompanyName(companyName, balanceToken, id) {
        this.CompanyName = companyName;
        this.balanceToken = balanceToken;
        this.viewclientId = id;
      },
  
      async ViewReqList(companyName, balanceToken, id) {
        this.modalData = {
          company: companyName,
          remainingToken: balanceToken,
          clientId: id,
        };
        var request = await this.requestedTokens({
          clientId: id,
        });
        if (request != undefined) {
          this.reqList = request.object.data;
          this.checkBalance();
          this.reqList.forEach((element) => {
            if (element.tokenAmount > this.parentCompany.remainingToken) {
              this.redtext = true;
            } else {
              this.redtext = false;
            }
          });
        }
        // this.getData();
      },
  
      checkBalance(amt) {
        Array.prototype.sum = function (prop) {
          var total = 0;
          for (var i = 0, len = this.length; i < len; i++) {
            total += this[i][prop];
          }
          return total;
        };
        var total = this.reqList.sum("tokenAmount");
        if (total > amt) return true;
        else return false;
      },
  
      openTokenModal(type) {
        if (type == 1) {
          this.modalData = this.parentCompany;
        }
        this.GetToken();
      },
  
      clearbtn() {
        this.AllocateAmount = null;
        this.requestAmount = null;
      },
      closeModal() {
        document.getElementById('closerequest').click();
      }
    },
  };
  </script>
  
  <style>
  .page-item.disabled .page-link {
    background-color: #fff !important;
    border-color: #fff !important;
  }
  
  .pagination .page-item .page-link {
    width: 30px;
    height: 32px;
    background: transparent;
    display: flex;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    margin: 5px;
    color: #000 !important;
    border: none;
    cursor: pointer;
  }
  
  .pagination {
    justify-content: flex-end;
  }
  
  .pagination .page-item.active .page-link {
    font-weight: 600;
    background: #ffc801;
  }
  
  .pagination .page-item:last-child .page-link {
    background-color: transparent;
    border: none;
    width: 100% !important;
  }
  
  .pagination .page-item:first-child .page-link {
    background-color: transparent;
    border: none;
    width: 100% !important;
  }
  
  .pagination .page-item.disabled .page-link {
    color: rgb(130, 130, 130) !important;
    cursor: no-drop !important;
    background: #7fffd400 !important;
  }
  
  .actived_form_button {
    background-color: #7b68ee !important;
    color: #fff !important;
  }
  
  .active_inc_dec_button {
    background-color: #e8e4ff !important;
    color: grey !important;
  }
  
  .input-error-border:focus-visible {
    border: 2px solid red !important;
    outline: none !important;
  }
  
  .input-error-border:focus {
    border: 1px solid red !important;
  }
  
  .error {
    color: red !important;
  }
  
  .request-color-muted {
    color: #7987a1;
  }
  
  .logoPartner {
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
  
  input[type=number] {
    -moz-appearance: textfield;
  }
  </style>
  