<template>
    <div></div>
</template>
<script>
import Shepherd from "shepherd.js";
import { mapState } from "vuex";

export default {
    name: "App",
    computed: {
        ...mapState({
            user: (state) => state.auth.user,
        }),
    },
    data() {
        return {
            TestLibraryTourSteps: [
                //   {
                //       text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Test Card<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>Quick overview of Test details, Candidate Data, and navigation to Detailed Test view.</div>",
                //       attachTo: {
                //           element: ".tour-test-card",
                //           on: "right"
                //       },
                //       beforeShowPromise: function () {
                //           const dropdownParent = document.querySelector('.sidebar-test-management .collapse');
                //           if (dropdownParent) {
                //               dropdownParent.classList.add('show');
                //           }
                //           const nextDropdownParent = document.querySelector('.sidebar-team-management-item .collapse.show');
                //           if (nextDropdownParent) {
                //               nextDropdownParent.classList.remove('show');
                //               nextDropdownParent.classList.add('hide');
                //           }

                //           return new Promise(function (resolve) {
                //               setTimeout(resolve, 100);
                //           });
                //       },
                //       buttons: [
                //           {
                //               action: this.tourCancel,
                //               classes: "shepherd-button-third",
                //               text: "Skip"
                //           },
                //           // {
                //           //   action: this.tourGoBack,
                //           //   text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                //           // },
                //           {
                //               action: this.tourGoNext,
                //               text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                //           }
                //       ]
                //   },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Test Name<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>Displays the name of each test, providing quick identification and selection.</div>",
                    attachTo: {
                        element: ".tour-test-name",
                        on: "right"
                    },
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Test Time<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>Indicates the estimated time required to complete the test, helping users manage their schedules.</div>",
                    attachTo: {
                        element: ".tour-test-time",
                        on: "right"
                    },
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Test Level<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>Shows the difficulty level of the test, such as Entry, Middle or Senior level ensuring the right match for candidates' skill levels.</div>",
                    attachTo: {
                        element: ".tour-job-level",
                        on: "right"
                    },
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Competency Tags<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>Lists key competencies assessed by the test, such as Empathy, Self Management, Accountability, and more, allowing users to understand the focus areas.</div>",
                    attachTo: {
                        element: ".tour-competencies",
                        on: "right"
                    },
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Test Description<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>Provides a brief overview of what the test measures and its purpose, helping users understand the test's relevance and application.</div>",
                    attachTo: {
                        element: ".tour-description",
                        on: "bottom"
                    },
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.OpenModalNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Check Details<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Opens a detailed view of the test to help you understand the purpose and structure of the test before requesting access.</div>",
                    attachTo: {
                        element: ".tour-check-details",
                        on: "right"
                    },

                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.CloseModalBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.CloseModalNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Add Test<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Click the 'Add Test' button to use the test , streamlining the activation process </div>"
                    ,

                    attachTo: {
                        element: ".tour-request-access",
                        on: "right"
                    },

                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.OpenModalBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },

                //   {
                //       text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Share Test<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Use the share icon to distribute the test link via email or other platforms, making it easy to share with candidates or colleagues.</div>",
                //       attachTo: {
                //           element: ".tour-share-web-icon",
                //           on: "bottom"
                //       },

                //       buttons: [
                //           {
                //               action: this.tourCancel,
                //               classes: "shepherd-button-third",
                //               text: "Skip"
                //           },
                //           {
                //               action: this.tourGoBack,
                //               text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                //           },
                //           {
                //               action: this.tourGoNext,
                //               text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                //           }
                //       ]
                //   },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Test Bookmark<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Click the bookmark icon to save the test for easy access later, helping you keep track of important or frequently used tests.</div>",
                    attachTo: {
                        element: ".tour-test-bookmark",
                        on: "bottom"
                    },

                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                //   {
                //       text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Action Dots<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Clicking the three dots on the test card opens a dropdown menu with additional options such as Request Access.</div>",
                //       attachTo: {
                //           element: ".action-dots",
                //           on: "bottom"
                //       },

                //       buttons: [
                //           {
                //               action: this.tourCancel,
                //               classes: "shepherd-button-third",
                //               text: "Skip"
                //           },
                //           {
                //               action: this.tourGoBack,
                //               text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                //           },
                //           {
                //               action: this.tourGoNext,
                //               text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                //           }
                //       ]
                //   },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Search<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Utilize the search bar to quickly find specific tests by entering keywords or identifiers, improving efficiency in locating tests.</div>",
                    attachTo: {
                        element: ".tour-web-search",
                        on: "bottom"
                    },

                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Filter<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Use the Filter function to refine your search results by Job Level or Job Family.</div>",
                    attachTo: {
                        element: ".tour-filter",
                        on: "bottom"
                    },

                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Check Bookmark Details<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Access your bookmarked tests to view saved tests and manage them easily, ensuring you can quickly find and use important assessments.</div>",
                    attachTo: {
                        element: ".tour-check-bookmark-details",
                        on: "bottom"
                    },

                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourComplete,
                            text: "Finish <i class='fa-solid fa-thumbs-up fa-lg mx-2'></i>"
                        }
                    ]
                },
            ],

            TestLibraryTourEnterpriseSteps: [
                //   {
                //       text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Test Card<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>Quick overview of Test details, Candidate Data, and navigation to Detailed Test view.</div>",
                //       attachTo: {
                //           element: ".tour-test-card",
                //           on: "right"
                //       },
                //       beforeShowPromise: function () {
                //           const dropdownParent = document.querySelector('.sidebar-test-management .collapse');
                //           if (dropdownParent) {
                //               dropdownParent.classList.add('show');
                //           }
                //           const nextDropdownParent = document.querySelector('.sidebar-team-management-item .collapse.show');
                //           if (nextDropdownParent) {
                //               nextDropdownParent.classList.remove('show');
                //               nextDropdownParent.classList.add('hide');
                //           }

                //           return new Promise(function (resolve) {
                //               setTimeout(resolve, 100);
                //           });
                //       },
                //       buttons: [
                //           {
                //               action: this.tourCancel,
                //               classes: "shepherd-button-third",
                //               text: "Skip"
                //           },
                //           // {
                //           //   action: this.tourGoBack,
                //           //   text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                //           // },
                //           {
                //               action: this.tourGoNext,
                //               text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                //           }
                //       ]
                //   },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Test Name<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>Displays the name of each test, providing quick identification and selection.</div>",
                    attachTo: {
                        element: ".tour-test-name",
                        on: "right"
                    },
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Test Time<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>Indicates the estimated time required to complete the test, helping users manage their schedules.</div>",
                    attachTo: {
                        element: ".tour-test-time",
                        on: "right"
                    },
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Test Level<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>Shows the difficulty level of the test, such as Entry, Middle or Senior level ensuring the right match for candidates' skill levels.</div>",
                    attachTo: {
                        element: ".tour-job-level",
                        on: "right"
                    },
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Competency Tags<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>Lists key competencies assessed by the test, such as Empathy, Self Management, Accountability, and more, allowing users to understand the focus areas.</div>",
                    attachTo: {
                        element: ".tour-competencies",
                        on: "right"
                    },
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Test Description<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>Provides a brief overview of what the test measures and its purpose, helping users understand the test's relevance and application.</div>",
                    attachTo: {
                        element: ".tour-description",
                        on: "bottom"
                    },
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.OpenModalNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Check Details<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Opens a detailed view of the test to help you understand the purpose and structure of the test before requesting access.</div>",
                    attachTo: {
                        element: ".tour-check-details",
                        on: "right"
                    },

                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.CloseModalBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.CloseModalNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text:
                        "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Request Access<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Click the 'Request Access' button to ask for permission to use the test, streamlining the access request process.</div>"
                    ,

                    attachTo: {
                        element: ".tour-request-access",
                        on: "right"
                    },

                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.OpenModalBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },

                //   {
                //       text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Share Test<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Use the share icon to distribute the test link via email or other platforms, making it easy to share with candidates or colleagues.</div>",
                //       attachTo: {
                //           element: ".tour-share-web-icon",
                //           on: "bottom"
                //       },

                //       buttons: [
                //           {
                //               action: this.tourCancel,
                //               classes: "shepherd-button-third",
                //               text: "Skip"
                //           },
                //           {
                //               action: this.tourGoBack,
                //               text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                //           },
                //           {
                //               action: this.tourGoNext,
                //               text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                //           }
                //       ]
                //   },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Test Bookmark<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Click the bookmark icon to save the test for easy access later, helping you keep track of important or frequently used tests.</div>",
                    attachTo: {
                        element: ".tour-test-bookmark",
                        on: "bottom"
                    },

                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                //   {
                //       text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Action Dots<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Clicking the three dots on the test card opens a dropdown menu with additional options such as Request Access.</div>",
                //       attachTo: {
                //           element: ".action-dots",
                //           on: "bottom"
                //       },

                //       buttons: [
                //           {
                //               action: this.tourCancel,
                //               classes: "shepherd-button-third",
                //               text: "Skip"
                //           },
                //           {
                //               action: this.tourGoBack,
                //               text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                //           },
                //           {
                //               action: this.tourGoNext,
                //               text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                //           }
                //       ]
                //   },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Search<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Utilize the search bar to quickly find specific tests by entering keywords or identifiers, improving efficiency in locating tests.</div>",
                    attachTo: {
                        element: ".tour-web-search",
                        on: "bottom"
                    },

                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Filter<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Use the Filter function to refine your search results by Job Level or Job Family.</div>",
                    attachTo: {
                        element: ".tour-filter",
                        on: "bottom"
                    },

                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Check Bookmark Details<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Access your bookmarked tests to view saved tests and manage them easily, ensuring you can quickly find and use important assessments.</div>",
                    attachTo: {
                        element: ".tour-check-bookmark-details",
                        on: "bottom"
                    },

                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourComplete,
                            text: "Finish <i class='fa-solid fa-thumbs-up fa-lg mx-2'></i>"
                        }
                    ]
                },
            ],


            TestLibraryTourResponsiveSteps: [
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Test Name<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Displays the name of each test, providing quick identification and selection.</div>",
                    attachTo: {
                        element: ".tour-test-name",
                        on: "bottom"
                    },
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Test Time<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Indicates the estimated time required to complete the test, helping users manage their schedules.</div>",
                    attachTo: {
                        element: ".tour-test-time",
                        on: "bottom"
                    },
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Test Level<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Shows the difficulty level of the test, such as Entry, Middle or Senior level ensuring the right match for candidates' skill levels.</div>",
                    attachTo: {
                        element: ".tour-job-level",
                        on: "bottom"
                    },
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Competency Tags<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Lists key competencies assessed by the test, such as Empathy, Self Management, Accountability, and more, allowing users to understand the focus areas.</div>",
                    attachTo: {
                        element: ".tour-competencies",
                        on: "bottom"
                    },
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Test Description<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Provides a brief overview of what the test measures and its purpose, helping users understand the test's relevance and application.</div>",
                    attachTo: {
                        element: ".tour-description",
                        on: "bottom"
                    },
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.OpenModalNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Check Details<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Opens a detailed view of the test to help you understand the purpose and structure of the test before requesting access.</div>",
                    attachTo: {
                        element: ".tour-check-details",
                        on: "bottom"
                    },

                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.CloseModalBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.CloseModalNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Add Test<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Click the 'Add Test' button to use the test , streamlining the activation process.</div>",
                    attachTo: {
                        element: ".tour-request-access",
                        on: "bottom"
                    },
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.OpenModalBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                //   {
                //       text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Share Test<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Use the share icon to distribute the test link via email or other platforms, making it easy to share with candidates or colleagues.</div>",
                //       attachTo: {
                //           element: ".tour-share-web-icon",
                //           on: "bottom"
                //       },

                //       buttons: [
                //           {
                //               action: this.tourCancel,
                //               classes: "shepherd-button-third",
                //               text: "Skip"
                //           },
                //           {
                //               action: this.tourGoBack,
                //               text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                //           },
                //           {
                //               action: this.tourGoNext,
                //               text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                //           }
                //       ]
                //   },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Test Bookmark<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Click the bookmark icon to save the test for easy access later, helping you keep track of important or frequently used tests.</div>",
                    attachTo: {
                        element: ".tour-test-bookmark",
                        on: "bottom"
                    },

                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                //   {
                //       text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Action Dots<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Clicking the three dots on the test card opens a dropdown menu with additional options such as Request Access.</div>",
                //       attachTo: {
                //           element: ".action-dots",
                //           on: "bottom"
                //       },

                //       buttons: [
                //           {
                //               action: this.tourCancel,
                //               classes: "shepherd-button-third",
                //               text: "Skip"
                //           },
                //           {
                //               action: this.tourGoBack,
                //               text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                //           },
                //           {
                //               action: this.tourGoNext,
                //               text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                //           }
                //       ]
                //   },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Search<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px; padding-left:2% ;padding-right:2%'>Utilize the search bar to quickly find specific tests by entering keywords or identifiers, improving efficiency in locating tests.</div>",
                    attachTo: {
                        element: ".tour-mobile-search",
                        on: "bottom"
                    },

                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Filter<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Use the Filter function to refine your search results by Job Level or Job Family.</div>",
                    attachTo: {
                        element: ".tour-filter",
                        on: "bottom"
                    },

                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Check Bookmark Details<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Access your bookmarked tests to view saved tests and manage them easily, ensuring you can quickly find and use important assessments.</div>",
                    attachTo: {
                        element: ".tour-check-bookmark-details",
                        on: "bottom"
                    },

                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourComplete,
                            text: "Finish <i class='fa-solid fa-thumbs-up fa-lg mx-2'></i>"
                        }
                    ]
                },
            ],

            TestLibraryTourResponsiveEnterpriseSteps: [
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Test Name<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Displays the name of each test, providing quick identification and selection.</div>",
                    attachTo: {
                        element: ".tour-test-name",
                        on: "bottom"
                    },
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Test Time<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Indicates the estimated time required to complete the test, helping users manage their schedules.</div>",
                    attachTo: {
                        element: ".tour-test-time",
                        on: "bottom"
                    },
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Test Level<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Shows the difficulty level of the test, such as Entry, Middle or Senior level ensuring the right match for candidates' skill levels.</div>",
                    attachTo: {
                        element: ".tour-job-level",
                        on: "bottom"
                    },
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Competency Tags<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Lists key competencies assessed by the test, such as Empathy, Self Management, Accountability, and more, allowing users to understand the focus areas.</div>",
                    attachTo: {
                        element: ".tour-competencies",
                        on: "bottom"
                    },
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Test Description<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Provides a brief overview of what the test measures and its purpose, helping users understand the test's relevance and application.</div>",
                    attachTo: {
                        element: ".tour-description",
                        on: "bottom"
                    },
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.OpenModalNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Check Details<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Opens a detailed view of the test to help you understand the purpose and structure of the test before requesting access.</div>",
                    attachTo: {
                        element: ".tour-check-details",
                        on: "bottom"
                    },

                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.CloseModalBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.CloseModalNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text:
                        "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Request Access<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Click the 'Request Access' button to ask for permission to use the test, streamlining the access request process.</div>",
                    attachTo: {
                        element: ".tour-request-access",
                        on: "bottom"
                    },
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.OpenModalBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                //   {
                //       text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Share Test<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Use the share icon to distribute the test link via email or other platforms, making it easy to share with candidates or colleagues.</div>",
                //       attachTo: {
                //           element: ".tour-share-web-icon",
                //           on: "bottom"
                //       },

                //       buttons: [
                //           {
                //               action: this.tourCancel,
                //               classes: "shepherd-button-third",
                //               text: "Skip"
                //           },
                //           {
                //               action: this.tourGoBack,
                //               text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                //           },
                //           {
                //               action: this.tourGoNext,
                //               text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                //           }
                //       ]
                //   },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Test Bookmark<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Click the bookmark icon to save the test for easy access later, helping you keep track of important or frequently used tests.</div>",
                    attachTo: {
                        element: ".tour-test-bookmark",
                        on: "bottom"
                    },

                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                //   {
                //       text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Action Dots<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Clicking the three dots on the test card opens a dropdown menu with additional options such as Request Access.</div>",
                //       attachTo: {
                //           element: ".action-dots",
                //           on: "bottom"
                //       },

                //       buttons: [
                //           {
                //               action: this.tourCancel,
                //               classes: "shepherd-button-third",
                //               text: "Skip"
                //           },
                //           {
                //               action: this.tourGoBack,
                //               text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                //           },
                //           {
                //               action: this.tourGoNext,
                //               text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                //           }
                //       ]
                //   },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Search<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px; padding-left:2% ;padding-right:2%'>Utilize the search bar to quickly find specific tests by entering keywords or identifiers, improving efficiency in locating tests.</div>",
                    attachTo: {
                        element: ".tour-mobile-search",
                        on: "bottom"
                    },

                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Filter<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Use the Filter function to refine your search results by Job Level or Job Family.</div>",
                    attachTo: {
                        element: ".tour-filter",
                        on: "bottom"
                    },

                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Check Bookmark Details<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Access your bookmarked tests to view saved tests and manage them easily, ensuring you can quickly find and use important assessments.</div>",
                    attachTo: {
                        element: ".tour-check-bookmark-details",
                        on: "bottom"
                    },

                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourComplete,
                            text: "Finish <i class='fa-solid fa-thumbs-up fa-lg mx-2'></i>"
                        }
                    ]
                },
            ],
        };
    },
    props: {
        page: String,
        tourStatus: String,
        shareModalStatus: Boolean
    },
    mounted() {
        const windowWidth = window.innerWidth;
        if (this.page === 'TestLibrary') {
            this.TestLibraryTour = new Shepherd.Tour({
                useModalOverlay: true,
                tourName: "TestLibraryTour",
                defaultStepOptions: {
                    scrollTo: {
                        behavior: "smooth",
                        block: "center"
                    }
                }
            });

            if (windowWidth < 999) {
                if (this.user.isEnterprise) {
                    this.TestLibraryTourResponsiveEnterpriseSteps.forEach(step => {
                        this.TestLibraryTour.addStep(step);
                    });
                }
                else {
                    this.TestLibraryTourResponsiveSteps.forEach(step => {
                        this.TestLibraryTour.addStep(step);
                    });
                }

            }
            else {
                if (this.user.isEnterprise) {

                    this.TestLibraryTourEnterpriseSteps.forEach(step => {
                        this.TestLibraryTour.addStep(step);
                    });
                }
                else {
                    this.TestLibraryTourSteps.forEach(step => {
                        this.TestLibraryTour.addStep(step);
                    });
                }

            }
            this.TestLibraryTour.start();
        }

    },
    methods: {
        tourGoNext() {
            if (this.page === 'TestLibrary') {
                this.TestLibraryTour.next();
            }
        },
        tourGoBack() {
            if (this.page === 'TestLibrary') {
                this.TestLibraryTour.back();
            }
        },
        tourCancel() {
            if (this.page === 'TestLibrary') {
                this.$emit('tour-close');
                localStorage.setItem("isNewUser", false);
                this.TestLibraryTour.cancel();
            }
        },
        tourComplete() {
            if (this.page === 'TestLibrary') {
                localStorage.setItem("isNewUser", false);
                this.$emit('share-modal-close');
                this.$emit('tour-close');
                this.TestLibraryTour.complete();
            }
        },
        OpenModalNext() {
            this.$emit('share-modal-open');
            this.TestLibraryTour.next();
        },
        OpenModalBack() {
            this.$emit('share-modal-open');
            this.TestLibraryTour.back();
        },
        CloseModalBack() {
            this.$emit('share-modal-close');
            this.TestLibraryTour.back();
        },
        CloseModalNext() {
            this.$emit('share-modal-close');
            this.$emit('dropdown-open');

            this.TestLibraryTour.next();
        },
    }
};
</script>
