<template>
    <div class="page-content">
        <input type="color" v-model="currentColor" @input="calculateRatioColor">
        <div :style="{ backgroundColor: currentColor }">
            Current Color: {{ currentColor }}<br>
        </div>
        <div :style="{ backgroundColor: ratioColor }">
            Ratio Color: {{ ratioColor }}
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            currentColor: '#7b68ee',
            ratioColor: ''
        };
    },
    mounted() {
        this.calculateRatioColor();
    },
    methods: {
        calculateRatioColor() {
            const currentColor = this.currentColor;
            this.ratioColor = this.calculateRatio(currentColor);
        },
        calculateRatio(color) {
            color = color.replace(/^#/, '');
            const r = parseInt(color.substr(0, 2), 16);
            const g = parseInt(color.substr(2, 2), 16);
            const b = parseInt(color.substr(4, 2), 16);
            const max = Math.max(r, g, b);
            const ratioR = Math.round(r + (max - r) * 0.9);
            const ratioG = Math.round(g + (max - g) * 0.9);
            const ratioB = Math.round(b + (max - b) * 0.9);
            return '#' + this.componentToHex(ratioR) + this.componentToHex(ratioG) + this.componentToHex(ratioB);
        },
        componentToHex(c) {
            const hex = c.toString(16);
            return hex.length === 1 ? '0' + hex : hex;
        }
    }
};
</script>
