<template>
  <div class="page-content">
    <h3 class="mb-2 mb-md-0">Billing</h3>
    <div class="d-flex justify-content-between align-items-center flex-wrap mb-2 ">

      <!--  <button class="nav-link btn px-3 active" data-bs-toggle="pill"
             type="button" role="tab" 
            aria-selected="true" @click="changeTab('Completed')">
            Completed ({{ this.completedCandidates }})
          </button> -->
      <ul class="nav nav-pills mb-3 partners_top_menu_section_hp p-2 mt-4 user_tabing_nav_section " id="pills-tab"
        role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link btn px-3 active" id="pills-overview-tab" data-bs-toggle="pill"
            data-bs-target="#pills-overview" type="button" role="tab" aria-controls="pills-home"
            aria-selected="true">Overview</button>
        </li>


        <li class="nav-item" role="presentation">
          <button class="nav-link btn px-3" id="pills-plan-tab" data-bs-toggle="pill" data-bs-target="#pills-plan"
            type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Plans</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link btn px-3" id="pills-invoice-tab" data-bs-toggle="pill" data-bs-target="#pills-invoice"
            type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Invoices</button>
        </li>
        <li v-if="invoiceUser.length == 1" class="nav-item" role="presentation">
          <button class="nav-link btn px-3" id="pills-preferences-tab" data-bs-toggle="pill"
            data-bs-target="#pills-preferences" type="button" role="tab" aria-controls="pills-contact"
            aria-selected="false">Preferences</button>
        </li>
        <!-- <li class="nav-item" role="presentation">
        <button class="nav-link btn px-3"  data-bs-toggle="pill"
          data-bs-target="#review-candidates" type="button" role="tab" aria-controls="review-candidates"
          aria-selected="false" @click="changeTab('Review')">
          Additional Details
        </button>
      </li> -->
      </ul>

    </div>
    <!-- overview tab -->
    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane fade show active" id="pills-overview" role="tabpanel" aria-labelledby="pills-overview-tab">

        <div>
          <!-- <h3>Current plan</h3> -->
          <div class="card mt-3">
            <div class="card-header">
              <div style="display: flex; justify-content: space-between">
                <div>
                  <h3 style="font-weight: 600;">
                    {{ this.currentPlan }}
                  </h3>
                </div>
                <div class="float-end">
                  <span v-if="this.currentPlan != 'Enterprise Plan'">
                    <router-link :to="{ name: 'BillingPlan' }" class="btn btn-primary">
                      <span v-if="this.PlanStatus == false && this.currentPlan != 'Free Trial'">
                        Switch Plan
                      </span>
                      <span v-else>Choose a plan</span>
                    </router-link>
                  </span>

                </div>
              </div>
            </div>
            <div class="card-body">
              <div style="font-size:large; color: #2F3A4C; ">
                Pay as you go
              </div>


              <div class=" mt-5">
                <div class="container">
                  <div class="row">
                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-4 ">
                      <div class="row">
                        <div class="col-md-2 mb-4">
                          <div class="d-flex align-items-center justify-content-center"
                            style="background-color: #7c68ee; width: 100%; height: 100%; min-height: 60px; max-width: 60px; min-width: 60px; border-radius: 16px; place-content:center"
                            @click="openPlanTab()">
                            <i class="fa-solid fa-gear fa-xl" style="color: #ffffff;"></i>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <h4 class="mx-2" style="font-weight: 600;">Preferences</h4>
                          <p class="mx-2 mt-1" style="font-size: small;">Manage billing information</p>
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-4">
                      <div class="row">
                        <div class="col-md-2 mb-4">
                          <div class="d-flex align-items-center justify-content-center "
                            style="background-color: #7c68ee; width: 100%; height: 100%; min-height: 60px; max-width: 60px; min-width: 60px; border-radius: 16px; place-content:center">
                            <i class="fa-solid fa-file-invoice fa-xl" style="color: #ffffff;"></i>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <h4 style="font-weight: 600;" class="mx-2">Invoice</h4>
                          <p class="mt-1 mx-2" style="font-size: small;">View past and current invoices</p>
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-4">
                      <div class="row ">
                        <div class="col-md-2 mb-4">
                          <div class="d-flex align-items-center justify-content-center"
                            style="background-color: #7c68ee; width: 100%; height: 100%; min-height: 60px; max-width: 60px; min-width: 60px; border-radius: 16px; place-content:center">
                            <i class="fa-solid fa-chart-simple fa-2xl" style="color: #ffffff;"></i>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <h4 style="font-weight: 600;" class="mx-2">Pricing</h4>
                          <p class="mt-1 mx-2" style="font-size: small;">View Pricing and FAQ's</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <span class="card-text" v-if="this.Remainingdays>=0">
                    {{ this.Remainingdays }}
                    Days Left</span>
                    <span v-else style="color: crimson;"> Your plan has been expired</span>
                <span v-if="this.Remainingdays >= 0">
                        <span class="float-end mx-2" style="font-size: small; color: rgb(56, 56, 255); cursor: pointer;"
                @click="this.PlanStatus ? reactiveSubscription() : ValidationModal()" 
                v-if="this.currentPlan != 'Free Trial'">
                {{ this.PlanStatus ? 'Reactivate Subscription' : 'Cancel Subscription' }}
            </span>
        </span> -->

            </div>
          </div>

          <div class="modal fade" id="ValidationModal" tabindex="-1" aria-labelledby="DeactivatePartnerModalLabel"
            aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog  modal-dialog-centered modal-dialog-scrollable border-radius-kj-20">
              <div class="modal-content border-radius-kj-20">
                <div class="modal-header">
                  <h4 style="font-weight: 600;">Deactivating your
                    <!-- {{ this.billing.object.data.plan.planName }}  --> Demo
                  </h4>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"></button>
                </div>
                <div class="modal-body">
                  Do you really want to cancel your existing subscription?
                  <span class="float-end mt-5">
                    <button type="button" class="btn btn-primary secondary " data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class=" mx-2 btn btn-primary float-end" @click="CancelSubscription()">Continue
                      to Cancel Plan</button>

                    <!-- Exciting News! <i class="fa-solid fa-rocket fa-beat" style="color: #ffc801;"></i></h3> -->
                  </span>





                </div>


              </div>
            </div>
          </div>
        </div>


      </div>

      <!-- plan tab -->

      <div class="tab-pane fade" id="pills-plan" role="tabpanel" aria-labelledby="pills-plan-tab">
        <div class="card p-0" style="border-radius: 15px;">
          <div class="table-responsive">
            <table class="table text-center">
              <thead>
                <tr>
                  <th>
                    <span style="display: flex; align-items: center; justify-content: center; margin-bottom: 25%;">
                      <div style="color: #7c68ee; font-size:17px; display: block; margin-right: 10%;">Monthly</div>
                      <div class="form-check form-switch">
                        <input class="form-check-input custom-toggle" type="checkbox" id="flexSwitchCheckChecked"
                          checked v-model="planType">
                      </div>
                      <div style="color: #7c68ee; font-size:17px; display: block;margin-left: 4%; ">Yearly</div>
                    </span>
                    <!-- <span style="display: block; margin-top: 5%;">Per Organization/Month</span> -->
                    <span
                      style="display: block; margin-top: 12%; color: #7c68ee;font-size:20px; font-weight: 600x;">Features</span>
                  </th>

                  <th v-for="plan in filteredPlans" :key="plan">
                    <span class="planheading">{{ plan.displayName }}</span>
                    <span style="display: block; margin-top: 10%;">
                      <button type="button" class="currentPlanButton" v-if="this.currentPlan == plan.planCode"
                        disabled>Current Plan</button>
                      <button type="button" class="UpgradeButton" @click="paymentPage(plan.planCode)" v-else>Select
                        Plan</button>

                    </span>
                    <span style="display: block; margin-top: 5%; color: #7c68ee; font-size:20px; font-weight: 600;">
                      {{ this.fetchCurrency(plan.currencyCode) }} {{ plan.price }}
                      <span style="font-size: 12px;">per Candidate</span>
                    </span>
                    <!-- <span  style="font-size: 10px;">billed at $5,640 per year</span> -->

                  </th>

                  <th>
                    <span class="planheading">Enterprise</span>
                    <span style="display: block; margin-top: 9%;">
                      <!-- <button type="button" class="UpgradeButton" >
                    </button> -->

                      <button type="button" class="currentPlanButton" v-if="this.currentPlan == 'Enterprise Plan'"
                        disabled>Current Plan</button>
                      <a :href="'https://calendly.com/nkamble/pmaps-quick-support-new-requirements'" v-else
                        type="button" class="UpgradeButton" target="_blank">
                        Connect Now
                      </a>



                    </span>
                    <span
                      style="display: block;  margin-top: 7.5%; color: #a898ff; font-size:17.5px; font-weight: 600;">Custom
                      Plan</span>
                  </th>
                </tr>
              </thead>

              <tbody v-for="module in PlanFeatures" :key="module.moduleName">
                <tr>
                  <td style="font-size: 26px; text-align: left; font-weight: 600;">
                    {{ module.moduleName }}
                  </td>
                </tr>
                <tr v-for="feature in module.features" :key="feature.id">
                  <td style="text-align: left; width: 30%;">{{ feature.name }}</td>
                  <td v-for="plan in feature.plans" :key="plan.planName">
                    <span v-if="plan.value != null">{{ plan.value }} {{ plan.unit }}</span>
                    <span v-else>
                      <i :class="plan.hasFeature ? 'fa-solid fa-circle-check fa-lg' : 'fa-regular fa-circle-xmark fa-lg'"
                        style="color: #7c68ee;"></i>
                    </span>
                  </td>
                  <td class="col-md-2"><span><i class="fa-solid fa-circle-check fa-lg"
                        style="color: #7c68ee;"></i></span></td>

                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="tab-pane fade" id="pills-invoice" role="tabpanel" aria-labelledby="pills-invoice-tab">

      </div>



      <div v-if="this.invoiceUser.length == 1" class="tab-pane fade" id="pills-preferences" role="tabpanel" aria-labelledby="pills-preferences-tab">
        <div class="card mt-3">
          <div class="card-header">
            <div style="display: flex; justify-content: space-between">
              <div>
                <h3 style="font-weight: 600;">
                  Preferences
                </h3>
              </div>
              <div class="float-end" style="color: #7b68ee;">
                <span @click="this.IsEdit()" :class="{ active_form_button: !isDisabled() }">
                  <i class="fa-solid fa-pen-to-square fa-lg mx-1"></i> Edit
                </span>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div class="form-group mb-4">
                  <label class="input-hero" for="preferenceDisplayName">


                    <input class="input_area" type="text" :disabled="this.EditStatus"
                      v-model="this.preferenceDisplayName" required /><span class="input-name">Registered Company
                      Name<span class="text-danger">*</span></span>
                  </label>
                </div>

                <div v-if="this.user.countryCode == 'IN'" class="form-group mb-4">
                  <label class="input-hero" for="preferenceGstNumber">
                    <input class="input_area" type="text" :disabled="this.EditStatus"
                      v-model="this.preferenceGstNumber" required /><span class="input-name">GST Number<span
                        class="text-danger">*</span></span>
                  </label>
                </div>
                <div class="form-group mb-4">
                  <label class="input-hero" for="preferenceStreet">
                    <input class="input_area" type="text" :disabled="this.EditStatus"
                      v-model="this.preferenceStreet" required /><span class="input-name">Address<span
                        class="text-danger">*</span></span>
                  </label>
                </div>
                <div class="form-group mb-4">
                  <label class="input-hero" for="preferenceZip">
                    <input class="input_area" type="text" :disabled="this.EditStatus" v-model="this.preferenceZip"
                      required /><span class="input-name">Zip<span class="text-danger">*</span></span>
                  </label>
                </div>
                <div class="form-group mb-4">
                  <label class="input-hero" for="preferenceCountry">
                    <select class="form-select input_area select_ca" v-model="this.preferenceCountry"
                      @change="getStates" :disabled="this.EditStatus">
                      <option>Select Preferred Country</option>
                      <option v-for="item in countries" :key="item" :value="item.iso2">
                        {{ item.name }}
                      </option>
                    </select>

                    <span class="input-name">Country<span class="text-danger">*</span></span>
                  </label>
                </div>

                <div class="form-group mb-4">
                  <label class="input-hero" for="preferenceState">
                    <select class="form-select input_area select_ca" v-model="this.preferenceState" @change="getCities"
                      :disabled="this.EditStatus">
                      <option>Select Preferred State</option>
                      <option v-for="item in states" :key="item" :value="item.iso2">
                        {{ item.name }}
                      </option>
                    </select>
                    <span class="input-name">State<span class="text-danger">*</span></span>
                  </label>
                </div>

                <div class="form-group mb-4">
                  <label class="input-hero" for="preferenceCity">
                    <select class="form-select input_area select_ca" v-model="preferenceCity"
                      :disabled="this.EditStatus">
                      <option>Select Preferred City</option>
                      <option v-for="item in cities" :key="item" :value="item.name">
                        {{ item.name }}
                      </option>

                    </select>

                    <span class="input-name">City <span class="text-danger">*</span></span>
                  </label>
                </div>
                <div class="form-group mb-4">
                  <label class="input-hero" for="ConcernedPersonName">
                    <select class="form-select input_area select_ca" v-model="this.ConcernedPersonName" mode="tags"  
                      :close-on-select="false" :searchable="true" :disabled="this.EditStatus" @change=addEmail();>
                      <option>Select Primary Contact</option>
                      <option v-for="item in userlist.users" :key="item" :value="item.name">
                        {{ item.name }}
                      </option>
                    </select>

                    <span class="input-name input-group-kj">Primary Contact Name <span
                        class="text-danger">*</span></span>
                  </label>
                </div>

                <div class="form-group mb-4">
                  <label class="input-hero" for="preferenceToEmails">
                    <Multiselect v-model="preferenceToEmails" @deselect="spliceemail" mode="tags"
                      :disabled="this.EditStatus" @close="searchTag" :createOption="true" :searchable="true"
                      :showOptions="false" :addOptionOn="['enter', 'space', ';', ',', 'tab']" :clearOnSearch="true"
                      :object="true" ref="multiselect">
                    </Multiselect>
                    <span class="input-name input-group-kj">
                      To Email Ids <span class="text-danger">*</span>
                    </span>
                  </label>
                </div>
                <div class="form-group mb-4">
                  <label class="input-hero" for="preferenceCCEmails">
                    <Multiselect v-model="preferenceCCEmails" @deselect="spliceccemail" mode="tags"
                      :disabled="this.EditStatus" @close="searchccTag" :createOption="true" :searchable="true"
                      :showOptions="false" :addOptionOn="['enter', 'space', ';', ',', 'tab']" :clearOnSearch="true"
                      :object="true" ref="multiselect">
                    </Multiselect>
                    <span class="input-name input-group-kj">
                      CC Email Ids
                    </span>
                  </label>
                </div>
                <div><button type="button" class="modail_submit_button" @click="this.UpdatePreference()"
                    :disabled="isDisabled()"  :class="{ active_form_button: !isDisabled() }" >Save</button></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import { superadmin } from "../utils/helper";
import { mapState, mapActions, mapMutations } from "vuex";
import moment from "moment";
import { calculateRemainingDays } from '../platformFeatures/dateUtils';
import { getEncryptedData } from '../platformFeatures/featureUtils';
import { validateName } from "../utils";
import { validateEmail } from "@/utils";
import { validateGST } from "@/utils";

export default {
  name: "BillingInvoice",
  components: {
    Multiselect
  },
  data() {
    return {
      billing: JSON.parse(localStorage.getItem('billingData')),
      PlanStatus: false,
      currentPlan: '',
      PlanFeatures: [],
      allFeatures: [],
      PlanDetails: [],
      planType: false,
      show: false,
      countries: [],
      states: [],
      cities: [],
      selectedCountry: '',
      selectedState: '',
      selectedCity: '',
      data: [],
      clientId: '',
      ConcernedPersonName: '',
      preferenceDisplayName: '',
      preferenceGstNumber: '',
      preferenceEmail: '',
      preferenceStreet: '',
      preferenceState: '',
      preferenceCity: '',
      preferenceCountry: '',
      preferenceZip: '',
      CountryCode: '',
      Edit: '',
      preferenceCCEmails: [],
      preferenceToEmails: [],
      isSmsSettingEnabled: {},
      isEmailSelected: '',
      EditStatus: true,
      userlist: [],
      invoiceUser:{}
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    filteredPlans() {
      let type = this.planType ? 'Yearly' : 'Monthly';
      return this.PlanDetails.filter((plan) => plan.type === type);
    },

  },
  async created() {
    this.dataFrom = getEncryptedData(this.user.extId);

    if (this.billing == null) {
      this.currentPlan = 'Enterprise Plan';
    }
    else {
      this.Remainingdays = calculateRemainingDays(this.billing.object.data.subscription.expiryDate);
      this.PlanStatus = this.billing.object.data.subscription.isCancelled;
      this.currentPlan = this.billing.object.data.plan.planName;
      // this.currentPlan= this.billing.object.data.plan.planCode;

    }
    await this.planContent();
    await this.GetPreference();

    var invoiceUserList = await this.GetInvoiceUser({ clientId: this.user.clientId });

    if(invoiceUserList.status == true){
    
    this.invoiceUser = invoiceUserList.object.data;}

    var list = await this.GetUserList({ clientId: this.user.clientId });
    this.userlist = list.object.data;

    var response = await this.Getlocation();
    this.countries = response.object.data;

    var stsresponse = await this.GetStateLocation({ countryCode: this.preferenceCountry })
    this.states = stsresponse.object.data;

    var ctresponse = await this.GetCityLocation({
      stateCode: this.preferenceState,
      countryCode: this.preferenceCountry
    })
    this.cities = ctresponse.object.data;
  },


  methods: {
    ...mapActions([
      "putSubscriptionCancel",
      "RefreshBillingDetails",
      "postReactivateSubscription",
      "GetPlanCardDetails",
      "getPaymentPage",
      "GetInvoicePreference",
      "PutInvoicePreference",
      "Getlocation",
      "GetStateLocation",
      "GetCityLocation",
      "ShowWarningMessage",
      "Getusersearchlist",
      "GetUserList",
      "GetInvoiceUser"
    ]),
    ...mapMutations(["setLoading"]),
    superadmin,
    dateConvert: function (value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    async ValidationModal() {
      window.$('#ValidationModal').modal('show');

    },
    CheckStatus(dueDate) {
      let currentDate = new Date();
      let due_date = new Date(dueDate);
      let diff = due_date.getTime() - currentDate.getTime();
      let days = Math.ceil(diff / (1000 * 60 * 60 * 24));
      this.Remainingdays = Math.abs(days);
      return this.Remainingdays;
    },
    openPlanTab() {
      document.getElementById('pills-plans').click();
    },

    showallinvite() {
      var toEmailList = [...this.preferenceToEmails];
      this.preferenceToEmails = [];
      toEmailList.forEach((element) => {
        this.preferenceToEmails.push({
          value: element,
          label: element,
        });
      });
    },

    showccinvite() {
      var ccEmailList = [...this.preferenceCCEmails];
      this.preferenceCCEmails = [];
      ccEmailList.forEach((element) => {
        this.preferenceCCEmails.push({
          value: element,
          label: element,
        });
      });
    },

    isDisabled() {
      return (
        this.EditStatus
      );
    },
    async GetPreference() {
      var preferData = await this.GetInvoicePreference({
        clientId: this.user.clientId
      });

      if (preferData.status) 
      {
        var data = preferData.object.data;
        this.ConcernedPersonName = data.attentionName;
        this.preferenceDisplayName = data.displayName;
        if (this.user.countryCode == "IN") {
          this.preferenceGstNumber = data.gstNo;
        }
        this.preferenceStreet = data.street;
        this.preferenceZip = data.zip;
        this.preferenceState = data.state;
        this.preferenceCity = data.city;
        this.preferenceCountry = data.country;
        this.preferenceToEmails = data.toEmails;
        this.preferenceCCEmails = data.ccEmails;
        this.showallinvite();
        this.showccinvite();
      }
    },

    async getStates() {
      var response = await this.GetStateLocation({ countryCode: this.preferenceCountry })
      this.states = response.object.data;
      this.preferenceState = '';
      this.cities = [];
      this.preferenceCity = '';
    },

    async getCities() {
      var response = await this.GetCityLocation({
        stateCode: this.preferenceState,
        countryCode: this.preferenceCountry
      });

      this.cities = response.object.data;
      this.selectedCity = '';
    },

    IsEdit() {
      this.EditStatus = !this.EditStatus;
    },

    addEmail() {
      var useremail = this.userlist.users.filter((x) => x.name == this.ConcernedPersonName);
  
      var list = this.preferenceToEmails.map(x => x.value)
      if (!list.includes(useremail[0].email)) {
        this.preferenceToEmails.push({ value: useremail[0].email, label: useremail[0].email });
      }
    },

    async UpdatePreference() {

      if (this.preferenceDisplayName == null || this.preferenceDisplayName == "") {
        return this.ShowWarningMessage(
          "Please enter display name."
        );
      }

      if (this.preferenceDisplayName.length > 100) {
        return this.ShowWarningMessage(
          "Please enter display name upto 100 character."
        );
      }

      if (this.user.countryCode == "IN") {
        if (this.preferenceGstNumber == null || this.preferenceGstNumber == "") {
          return this.ShowWarningMessage("Please enter GST number.");
        }
      
      if (this.preferenceGstNumber.length > 15 || this.preferenceGstNumber.length < 15) {
        return this.ShowWarningMessage("Please enter valid GST number.");
      }

      if(this.preferenceGstNumber != null || this.preferenceGstNumber != ""){
      if (!validateGST(this.preferenceGstNumber)) {
            return this.ShowWarningMessage("Please enter valid GST.");
      }}

      }

      if (this.preferenceStreet == null || this.preferenceState == "") {
        return this.ShowWarningMessage("Please enter address.");
      }


      if (this.preferenceZip == null || this.preferenceZip == "") {
        return this.ShowWarningMessage("Please enter zip.");
      }

      if (this.preferenceZip != null) {
        if (this.preferenceZip.length > 6 || this.preferenceZip.length < 6) {
          return this.ShowWarningMessage("Please enter valid zip.");
        }
      }
    
      if (this.preferenceCountry == null || this.preferenceCountry == "") {
        return this.ShowWarningMessage("Please select country.");
      }

      if (this.preferenceState == null || this.preferenceState == "") {
        return this.ShowWarningMessage("Please select state.");
      }

      if (this.preferenceCity == null || this.preferenceCity == "") {
        return this.ShowWarningMessage("Please select city.");
      }

      if(this.ConcernedPersonName == null){
        return this.ShowWarningMessage("Please select Primary Contact Name.");
      }

      if (this.ConcernedPersonName != null){
      if (!validateName(this.ConcernedPersonName)) {
        return this.ShowWarningMessage("First Name must be alphabetic .");
      }}

      if (this.preferenceToEmails == null || this.preferenceToEmails == "") {
        return this.ShowWarningMessage("Please enter To Emails.");
      }

      var errorMsg = "";

      if (this.preferenceToEmails.length > 0) {
        this.preferenceToEmails.forEach(element => {
          if (!validateEmail(element.value)) {
            errorMsg = "Please enter valid To Emails.";
          }
        });
      }

      if (this.preferenceCCEmails.length > 0) {
        this.preferenceCCEmails.forEach(element => {
          if (!validateEmail(element.value)) {
            errorMsg = "Please enter valid CC Emails.";
          }
        });
      }
      
      if(errorMsg == "")
      {
        const model = {
        clientId: this.user.clientId,
        DisplayName: this.preferenceDisplayName,
        AttentionName: this.ConcernedPersonName,
        GstNo: this.user.countryCode == "IN" ? this.preferenceGstNumber : "",
        Street: this.preferenceStreet,
        City: this.preferenceCity,
        Country: this.preferenceCountry,
        State: this.preferenceState,
        Zip: this.preferenceZip,
        ToEmails: this.preferenceToEmails.map(x => x.value),
        CCEmails: this.preferenceCCEmails.map(x => x.value)
      };
      await this.PutInvoicePreference(model);
      this.EditStatus = true;
      }
      if(errorMsg != ""){
      return this.ShowWarningMessage(errorMsg);
      }
    },

    async CancelSubscription() {
      await this.putSubscriptionCancel({
        subscriptionId: this.billing.object.data.subscription.subScriptionId
      });
      await this.RefreshBillingDetails();
      location.reload();
    },


    async reactiveSubscription() {
      await this.postReactivateSubscription({
        subscriptionId: this.billing.object.data.subscription.subScriptionId
      });
      await this.RefreshBillingDetails();
      location.reload();
    },
    async planContent() {
      var planData = await this.GetPlanCardDetails({
        currency: ""
      });
      this.PlanFeatures = planData.object.data.features;
      this.PlanDetails = planData.object.data.allPlans;
      this.show = true;
    },
    async paymentPage(code) {
      const data = {
        subscription_id: this.billing.object.data.subscription.subScriptionId,
        plan: {
          plan_code: code,
        },
      };
      const response = await this.getPaymentPage(data);
      var url = await response.object.data.hostedpage.url;
      window.open(url, '_self');
    },
    fetchCurrency(data) {
      if (data == 'INR') {
        return '₹';
      } else {
        return '$';
      }
    },

  },
};
</script>


<style scoped>
/* .datepicker table thead th, */
.secondary {
  background-color: rgb(211, 211, 211);
  color: rgb(30, 30, 30);
}

.table-responsive {
  height: 600px;
  overflow-y: auto;
  display: block;
}

.table-responsive thead th {
  position: sticky;
  top: 0;
  background-color: #f2f0ff;
  z-index: 2;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

.UpgradeButton {
  border-radius: 5px !important;
  border: 1px solid #7c68ee !important;
  background: #fff !important;
  color: #7c68ee !important;
  margin: 0px 6px 0 0;
  width: 180px;
  height: 35px;
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;

}

.currentPlanButton {
  border-radius: 5px !important;
  border: 1px solid #7c68ee !important;
  background: #9a89ff !important;
  color: #fff !important;
  margin: 0px 6px 0 0;
  width: 180px;
  height: 35px;
  font-size: 16px;
  text-decoration: none;
  text-align: center !important;
  padding-top: 5px;
  padding-bottom: 5px;
}

.UpgradeButton:hover {
  background: #7c68ee !important;
  color: #ffffff !important;
  margin: 0px 6px 0 0;
  text-decoration: none;

}


.planheading {
  color: #7c68ee;
  font-size: 25px;
  font-weight: 600;
  display: block
}

.custom-toggle {
  padding: 10px 25px;
  font-size: 20px;
}

.custom-toggle:checked {
  background-color: #7c68ee !important;

}

.accordion-button:not(.collapsed) {
  box-shadow: none !important;
}

.tab-pane {
  padding: 0px 0px;
}

.layoutcard :hover {

  /* border-color: ; */
  border: 5px solid #7c68ee;

}
</style>