<template>
  <div
    v-if="isLoading"
    style="
      position: fixed;
      width: 100%;
      text-align: center;
      padding-top: 45vh;
      height: 100vh;
      background-color: #8e98a157;
      z-index: 9999999;
    ">
    <img src="@/assets/Images/loading.png" alt="" id="loading" width="100" />
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "Loader",
  computed: {
    ...mapState({ isLoading: (state) => state.loader.isLoading }),
  },
};
</script>
<style>
#loading {
  -webkit-animation: rotation 2s infinite linear;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
</style>
