<template>
  <div class="page-content">
    <!-- header -->
    <div class="d-flex justify-content-between align-items-center flex-wrap mb-2">
      <div class="top_heading_CreatePartner_btn d-block me-2">
        <div class="Group_top_three_dot">
          <div class="dropdown" v-for="item in tokenList" :key="item">
            <router-link class="d-flex align-items-center"
              :to="{ name: 'TestManagement', query: { offset: this.$route.query.offset, current: this.$route.query.current } }">
              <img src="../../public/dashboard-assets/images/M2/BackButton.png" />
              <span class="ms-2 font-size-20 text-dark"> {{ item.company }}</span>
            </router-link>
            <!-- <router-link
                :to="{
                  name: 'ActiveTests',
                  query: {
                    id: this.routesearch ? encode(this.routesearch) : '',
                    offset: this.routeoffset ? encode(this.routeoffset) : '',
                    current: this.routecurrent ? encode(this.routecurrent) : '',
                  },
                }"
                class="p-0"
                type="button"
              >
                <img
                  src="../../public/dashboard-assets/images/M2/BackButton.png"
                />
              </router-link> -->
          </div>
        </div>
      </div>
      <!-- <div class="my-2">
        <h3 class="mb-2 mb-md-0" v-for="item in tokenList" :key="item">
          <router-link
            :to="{ name: 'TestManagement', query: { offset: this.$route.query.offset, current: this.$route.query.current } }"
            class="me-2 RequestToken_IncreaseTokenModal_backButton btn">
            <i class="fa-solid fa-chevron-left" style="line-height: 1.8;"></i>
          </router-link>
          {{ item.company }}
        </h3>
      </div> -->
      <div class="d-flex justify-content-between align-items-center">
        <!-- Search -->
        <div class="card p-0 border-radius-100 ms-3 token-view-d-none-kj">
          <div class="card-body px-3 py-3">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex token_secand_header_company align-items-center">
                <div v-if="companyData?.profileImage != null">
                  <img :src="companyData?.profileImage" alt="" height="29" width="29"/>
                </div>
                <div class="partner_item_img" v-else>
                  <div class="partner_FirstWord">
                    {{ companyData?.companyName != null ? companyData.companyName.charAt(0) : '' }}
                  </div>
                </div>
                <div class="mx-3">
                  <h6>Test Tokens:</h6>
                  <p class="font-size-12"> <span class="color_blue">{{ display?.remainingToken }}</span>/{{
                    display?.allocatedToken
                  }}</p>
                </div>
              </div>
              <div class="
                    d-flex
                    justify-content-between
                    align-items-center
                    token_secand_header_section
                  ">
                <!-- <div class="top_heading_CreatePartner_btn d-block mx-2">
                  <button type="button" class="btn btn-primary btn-icon-text" data-bs-toggle="modal"
                    data-bs-target="#RequestTokenModal">
                    <i class="fa-solid fa-plus me-2"></i> Request Token
                  </button>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end -->

    <section class="Partner_empty_main_section_kj mt-3">
      <div class="row">
        <div class="col-md-6 col-lg-4 col-xl-3 mb-3 stretch-card" v-for="item in allocateList" :key="item">
          <div class="card p-0 box_hover_effect">
            <!-- <router-link :to="{ name: '' }"> -->
            <div class="card-body p-3">
              <div class="Partner_management_top-kj mb-2">
                <!-- <h5 class="text-dark" v-if="(item.testName.length > 40)">
                  {{ item.testName.substring(0, item.testName.lastIndexOf(item.testName.substring(40))) + "..." }}</h5>
                <h5 class="text-dark" v-else>{{ item.testName }}</h5> -->

                <h5 class="text-dark" style="overflow-wrap: anywhere;">{{ item.testName }}</h5>


                <!-- <div class="dropdown">
                  <button class="btn p-0" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <i class="fa-solid fa-ellipsis"></i>
                  </button> -->
                <!-- <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <li>
                      <div
                        class="dropdown-item d-flex align-items-center"
                        data-bs-toggle="modal"
                        data-bs-target="#DeleteUserModal"
                      >
                        Archive
                      </div>
                    </li>
                  </ul> -->
                <!-- </div> -->
              </div>
              <div class="my-3" v-for="item in tokenList" :key="item">
                <h6 class="text-muted">Partner</h6>
                <h4 class="text-dark" v-if="(item.company.length > 40)">{{
                  item.company.substring(0, item.company.lastIndexOf(item.company.substring(40))) + "..."
                }}</h4>
                <h4 class="text-dark" v-else>{{ item.company }}</h4>
              </div>

              <div class="Partner_management_top-kj">
               
                <div class="Partner_management_contant-2-kj">
                  <div class="mb-3">
                    <span class="text-dark"> Tokens Remaining</span>
                  </div>
                  <h6>{{ item.allocatedToken === 0 ? 'NA' : item.remainingToken }}</h6>
                </div>
                <div class="Partner_management_contant-kj">
                  <div class="mb-3">
                    <span class="text-dark"> Tokens Allocated</span>
                  </div>
                  <h6>{{ item.allocatedToken === 0 ? 'NA' : item.allocatedToken }}</h6>
                </div>
              </div>
            </div>
            <!-- </router-link> -->
            <!-- <div class="card-footer card-footer-bg-kj" data-bs-toggle="modal" data-bs-target="#AllocateTokens"
              @click="getCompanyName(item.testName, item.clientId, item.remainingToken, item.testId)">
              Allocate Tokens
            </div> -->
          </div>
        </div>
      </div>
      <paginate :page-count="Page_count" :margin-pages="1" :page-range="1" :click-handler="clickCallback"
        :prev-text="'< Prev'" :next-text="'Next >'" :container-class="'pagination'" :page-class="'page-item'"
        v-if="allocateList != 0">
      </paginate>

    </section>

    <!-- AllocateTokens -->
    <div class="modal fade" id="AllocateTokens" tabindex="-1" aria-labelledby="IncreaseTokenModalLabel" aria-hidden="true"
      data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="
            modal-dialog modal-dialog-centered modal-dialog-scrollable
            model-sm
          ">
        <div class="modal-content border-radius-kj-20">
          <div class="modal-header token-model-header-color">
            <h5 class="modal-title text-centerm-auto" id="IncreaseTokenModalLabel">
              Allocate Tokens
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close" id="alocatetokenclose"
              @click="clearAmountButton('AllocateTokens')"></button>
          </div>
          <div class="modal-body">
            <h5 class="my-4">
              How many tokens do you want to allocate to
              <strong class="text-dark">{{ CompanyName }}?</strong>
            </h5>
            <form class="row my-3" method="">
              <div class="tokenPrice token-model-kj d-flex">
                <div class="value-button-min" id="decrease" @click="allocatedTokenDecreaseValue()" value="Decrease Value"
                  :disabled="requestTokenDecreaseAmount()"
                  :class="{ active_inc_dec_button: requestTokenDecreaseAmount() }">
                  <i class="fa-solid fa-minus"></i>
                </div>
                <input type="number" class="w-75 input-number-token-kj" id="number" placeholder="0"
                  v-model="allocatedTokenAmount" oninput="validity.valid||(value='');" />
                <div class="value-button-max" id="increase" @click="allocatedTokenIncreaseValue()" value="Increase Value">
                  <i class="fa-solid fa-plus"></i>
                </div>
              </div>
              <h5 class="my-3">Balance Tokens: {{ remainingToken }}</h5>
              <div class="text-end mt-2">
                <button type="button" class="btn btn-token-color-kj" :disabled="!isButtonDisable()"
                  :class="{ active_form_button: isButtonDisable() }" @click="postAmountAllocation">Allocate</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- model Request Token -->
    <div class="modal fade" id="RequestTokenModal" tabindex="-1" aria-labelledby="IncreaseTokenModalLabel"
      aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="
            modal-dialog modal-dialog-centered modal-dialog-scrollable
            model-sm
          ">
        <div class="modal-content border-radius-kj-20">
          <div class="modal-header token-model-header-color">
            <h5 class="modal-title text-centerm-auto" id="IncreaseTokenModalLabel">
              Request Tokens
            </h5>
            <button type="button" id="Requestclose" class="btn-close" data-bs-dismiss="modal" data-bs-toggle="modal"
              aria-label="btn-close" @click="clearAmountButton('RequestTokenModal')"></button>
          </div>
          <div class="modal-body">
            <h5 class="my-4">How many tokens do you need?</h5>
            <form class="row my-3" method="">
              <div class="tokenPrice token-model-kj d-flex">
                <div class="value-button-min" id="decrease" value="Decrease Value" :disabled="decDisableButton()"
                  :class="{ active_inc_dec_button: decDisableButton() }" @click="requestedTokenDecreaseValue()">
                  <i class="fa-solid fa-minus"></i>
                </div>
                <input type="number" class="w-75 input-number-token-kj" id="number" placeholder="0"
                  v-model="requestTokenAmount" :class="{ input_error_border: requestTokenAmount > 5000 }"
                  oninput="validity.valid||(value='');" />
                <div class="value-button-max" id="increase" value="Increase Value"
                  :class="{ active_inc_dec_button: this.requestTokenAmount > 5000 }"
                  @click="requestedTokenIncreaseValue()">
                  <i class="fa-solid fa-plus"></i>
                </div>
              </div>
              <p v-if="this.requestTokenAmount > 5000" style="color:red">You cannot Request more than 5000 tokens.</p>
              <h5 class="my-3">Balance Tokens: {{ display?.remainingToken }}</h5>
              <div class="text-end mt-2">
                <button type="button" class="btn btn-token-color-kj" :disabled="!isRequestedButtonDisable()"
                  :class="{ actived_form_button: requestTokenAmount > 0 && requestTokenAmount < 5001 }"
                  @click="postRequestedAmount">Request</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import Paginate from "vuejs-paginate-next";

export default {
  name: "TestManagementDetail",
  components: {
    paginate: Paginate,
  },

  data() {
    return {
      allocateList: [],
      tokenList: [],
      allocatedTokenAmount: null,
      CompanyName: "",
      partnerId: "",
      page: 1,
      limit: 8,
      search: "",
      partners: "",
      remainingToken: 0,
      requestTokenAmount: null,
      Page_count: 1,
      testId: "",
      companyData: {},
      profilelogo: ""
    }
  },
  async created() {
    var companyDetails = await this.Companyheader({ clientId: window.atob(this.$route.params.id) });
    this.companyData = companyDetails.object.data;
    if(companyDetails.object?.data?.profileImage != null) {
      var clientLogo = await this.companylogo({ fileName: companyDetails.object.data.profileImage });
      this.companyData.profileImage = clientLogo.object.data;
      // console.log(this.companyData);
    }
  },
  async mounted() {
    await this.getAllocateList();
    await this.getData();
    await this.partnerLogoList();
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticate,
      user: (state) => state.auth.user,
      clientId: (state) => state.auth.user.clientId,
      testId: (state) => state.auth.user.testId,
    }),
  },
  methods: {
    isButtonDisable() {
      return this.allocatedTokenAmount > 0;
    },

    requestTokenDecreaseAmount() {
      if (this.reqtoken == null || this.reqtoken == 0 || this.reqtoken == "" || this.reqtoken < 0) {
        return true;
      } else {
        return false;
      }
    },

    isRequestedButtonDisable() {
      if (this.requestTokenAmount > 0 && this.requestTokenAmount < 5001) {
        return true;
      } else if (this.requestTokenAmount > 5000) {
        return false;
      }
    },

    clearAmountButton(modalID) {
      if (modalID == "RequestTokenModal") {
        this.requestTokenAmount = null;
      } else {
        this.allocatedTokenAmount = null;
      }
    },
    clickCallback(pageNum) {
      this.page = pageNum
      this.getAllocateList();
    },
    ...mapActions([
      "viewtestList",
      "AmountAllocation",
      "IncrOrDecTokens",
      "GetTAllocation",
      "Companyheader",
      "companylogo"
    ]),
    ...mapMutations(["setLoading"]),

    async getData() {
      var list = await this.GetTAllocation({
        clientId: window.atob(this.$route.params.id),
      });
      if (list != undefined) {
        this.tokenList.push(list.object.data);
        this.tokenList = Object.freeze(
          JSON.parse(JSON.stringify(this.tokenList)))
          ;

        this.display = Object.freeze(
          JSON.parse(JSON.stringify(list.object.data))
        );
      }
    },

    async getAllocateList() {
      var allocateToken = await this.viewtestList({
        clientId: window.atob(this.$route.params.id),
        Page: this.page,
        Limit: this.limit,
        Search: this.search,
        Partners: this.partners
      });
      var ar = [];
      if (allocateToken != undefined) {
        this.allocateList = allocateToken.object.data.items;
        this.allocateList.forEach(element => {
          ar.push(element.clientId)
        });
        this.Page_count = allocateToken.object.data.totalPages;
      }
    },
    async postAmountAllocation() {
      await this.AmountAllocation({
        testId: this.testId,
        amount: this.allocatedTokenAmount,
        companyName: this.CompanyName
      });

      window.$("#AllocateTokens").modal("hide");
      document.getElementById('alocatetokenclose').click();
      this.allocatedTokenAmount = null;
      this.getAllocateList();
    },

    async postRequestedAmount() {
      await this.IncrOrDecTokens({
        clientId: window.atob(this.$route.params.id),
        amount: this.requestTokenAmount,
      });
      window.$("#RequestTokenModal").modal("hide");
      document.getElementById("Requestclose").click();
      this.requestTokenAmount = null;
    },

    async getCompanyName(companyName, id, balanceToken, testID) {
      this.CompanyName = companyName;
      this.partnerId = id;
      this.remainingToken = balanceToken;
      this.testId = testID;

    },
    // Requested Token Increase test token Popup
    async requestedTokenIncreaseValue() {
      if (this.requestTokenAmount == null || this.requestTokenAmount == "" || this.requestTokenAmount == 0) {
        this.requestTokenAmount = 1;
      }
      if (parseInt(this.requestTokenAmount) <= 5000) {
        this.requestTokenAmount = parseInt(this.requestTokenAmount) + 1;
      }
    },

    async requestedTokenDecreaseValue() {
      this.requestTokenAmount = (this.requestTokenAmount != 0) ? this.requestTokenAmount - 1 : 0;
    },
    decDisableButton() {
      if (this.requestTokenAmount == null || this.requestTokenAmount == 0 || this.requestTokenAmount == "" || this.requestTokenAmount < 0) {
        return true;
      } else {
        return false;
      }
    },

    //Allocated Token Increase test Popup
    async allocatedTokenIncreaseValue() {
      if (this.allocatedTokenAmount == null || this.allocatedTokenAmount == "" || this.requestTokenAmount == 0) {
        this.allocatedTokenAmount = 1;
      } else {
        this.allocatedTokenAmount = parseInt(this.allocatedTokenAmount) + 1;
      }

    },

    async allocatedTokenDecreaseValue() {
      if (this.allocatedTokenAmount == null || this.allocatedTokenAmount == 0 || this.allocatedTokenAmount == "" || this.allocatedTokenAmount < 0) {
        this.allocatedTokenAmount = null;
      } else {
        this.allocatedTokenAmount = this.allocatedTokenAmount - 1
      }

    },
    async partnerLogoList() {
      this.tokenList.forEach(async (elm) => {
        var company = await this.Companyheader({
          clientId: elm.clientId,
        });
        var photo = company.object?.data?.profileImage;

        if (photo != null) {
          var resp = await this.companylogo({
            fileName: photo,
          });
          this.profilelogo = resp.object.data;
        } else {
          this.profilelogo = null;
        }
      });
    },
  },

};
</script>
<style scoped>
.active_form_button {
  background-color: #7b68ee !important;
  color: #fff !important;
}

.active_inc_dec_button {
  background-color: #E8E4FF !important;
  color: gray !important;
}

.page-item.disabled .page-link {
  background-color: #fff !important;
  border-color: #fff !important;
}

.pagination .page-item .page-link {
  width: 30px;
  height: 32px;
  background: transparent;
  display: flex;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  margin: 5px;
  color: #000 !important;
  border: none;
  cursor: pointer;
}

.pagination {
  justify-content: flex-end;
}

.pagination .page-item.active .page-link {
  font-weight: 600;
  background: #ffc801;
}

.pagination .page-item:last-child .page-link {
  background-color: transparent;
  border: none;
  width: 100% !important;
}

.pagination .page-item:first-child .page-link {
  background-color: transparent;
  border: none;
  width: 100% !important;
}

.pagination .page-item.disabled .page-link {
  color: rgb(130, 130, 130) !important;
  cursor: no-drop !important;
}
</style>