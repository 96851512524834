const state = {
    isLoading: false,
    cardLoading: false,
};

const mutations = {
    setLoading(state, flag) {
        state.isLoading = flag;
    },
    setCardLoading(state , flag){
        state.cardLoading = flag;
    }
};

export default {
    state,
    mutations,
};