<template>
    <div class="page-content">
        <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
            <div class="my-2">
                <h3 class="mb-2 mb-md-0">Descriptive Analytics</h3>
                <p class="font-size-12"> {{ this.displayDate }} </p>
            </div>
            <div class="d-flex justify-content-between align-items-end">
                <div class="top_heading_CreatePartner_btn d-block" data-bs-toggle="modal" data-bs-target="#choose-Date">
                    <div class="Group_top_three_dot">
                        <div class="dropdown">
                            <button class="btn p-0 Group_top_three_dot_dropdown" type="button">
                                <img src="../../../../public/dashboard-assets/images/M2/date-icon.png"
                                    @click="opencal" />
                                <input type="text" name="daterange" hidden="true" id="calendar_icon" class="fade" />
                            </button>
                        </div>
                    </div>
                </div>
                <!-- <div v-if="hidePdfDownload === false" class="top_heading_CreatePartner_btn d-block mx-3">
                    <div class="Group_top_three_dot">
                        <div class="dropdown">
                            <a :href="reportUrl" target="_blank">
                                <button class="btn p-0 Group_top_three_dot_dropdown" type="button">
                                    <img src="../../../../public/dashboard-assets/images/M2/download-icon.png" />
                                </button>
                            </a>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <div id="pdfContent" v-if="PropStatus">
            <PieChart :table="pieTable" :to="toDate" :from="fromDate" />
            <LineChart v-if="assessmentFlows.length > 0" :assessmentData="assessmentFlows" :daysCount="NumberOfDays"  />
            <div class="Overview_dashboard_map_section" v-if="this.testWiseData.length>0">

                <div class="card p-2" style="height: 100%;">
                    <div class="card-body p-0">
                        <h3 style="font-weight: 600;" class="mx-3 my-3 mb-4">List of Selected Active Tests
                            <!-- <img src="../../../../public/dashboard-assets/images/M7/Actions.png" width="25"
                                class="cursor-pointer ms-2" alt="" data-bs-toggle="tooltip" data-bs-placement="top"
                                title=" Analyzing the frequency of assessment usage over time provides valuable insights into the number of
                                    candidates assessed during defined intervals." /> -->
                            <span class="float-end" style="font-weight: 500; color: #333333;">
                                <p class="font-size-12"> [{{ this.displayDate }}] </p>
                            </span>
                        </h3>
                        <div class="table-responsive" style="width: 100%; max-height: 500px;">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Test Id</th>
                                        <th style="max-width: 300px; word-wrap: break-word; white-space: normal;">Test
                                            Name</th>
                                        <th>Candidate Count</th>
                                        <th>Test Construct</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in testWiseData" :key="item.testId"
                                        :class="{ 'hover-row': isHovered === index }" @mouseover="isHovered = index"
                                        @mouseleave="isHovered = null" @click="handleRowClick(item)">
                                        <td>{{ item.testId }}</td>
                                        <td style=" max-width: 300px; word-wrap: break-word; white-space: normal;">{{
                                            item.testName }}</td>
                                        <td>{{ item.totalCount }}</td>
                                        <td>
                                            <div class="demo-preview" style="cursor: default;">
                                                <div class="badge mb-2"
                                                    style=" color:#7C68EE; border: #7C68EE solid 2px;"
                                                    v-if="item.behavioural">
                                                    Behavioural
                                                </div>
                                                <div class="badge mb-2"
                                                    style="color:#00AFED;  border: #00AFED solid 2px;"
                                                    v-if="item.cognitive">
                                                    Cognitive
                                                </div>
                                                <div class="badge mb-2"
                                                    style=" color:#FF2A87;  border: #FF2A87 solid 2px;"
                                                    v-if="item.language">
                                                    Language
                                                </div>
                                                <div class="badge mb-2"
                                                    style=" color:#FF9900;  border: #FF9900 solid 2px;"
                                                    v-if="item.protocor">
                                                    Proctor
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <button class="btn btn-primary">View</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Highcharts from 'highcharts';
import moment from "moment";
import LineChart from './LineChart.vue';
import PieChart from './PieChart.vue';


export default {
    name: "dashboard-descriptive-analystics",
    components: {
        PieChart,
        LineChart,
    },
    data() {
        return {
            fromDate: this.dateformat(new Date(new Date().setMonth(new Date().getMonth() - 1))),
            toDate: this.todateformat(new Date()),
            displayDate: '',
            lastLoggedInDate: "",
            pageNumber: 1,
            reportUrl: '',
            counter: 0,
            assessmentFlows: [],
            isParent: 'false',
            hidePdfDownload: false,
            testWiseData: [],
            isHovered: null,
            // pieData: [],
            // pieTable: [],
            PropStatus: false,
            NumberOfDays:0,
            PlanMapping:false,
            billing: JSON.parse(localStorage.getItem('billingData')),
            clientRole:'',


        };
    },
    computed: {
        ...mapState({
            authenticated: (state) => state.auth.authenticate,
            user: (state) => state.auth.user,
            Date: (state) => state.auth.user.lastLoggedInDate,
        }),
    },
    async created() {
        var parent = localStorage.getItem("Userpermission");
        this.clientRole = JSON.parse(parent);
        if (this.billing != null) {
            if (this.billing.object.data.plan.planName == 'Scale' || this.billing.object.data.plan.planName == 'Grow') {
                this.PlanMapping = true;
            }
        }
        else if (this.user.isEnterprise == true) {
            this.PlanMapping = true;
        }
        
        if (!((this.clientRole.roleName == 'Owner' || this.clientRole.roleName == 'Admin' || this.clientRole.roleName == 'PMaps SuperAdmin') && this.PlanMapping)) {
            this.$router.push({
                name: "DashboardGroup"
            });
            return;
        }


        if (this.$route.query.to) {
            var routeTo = window.atob(this.$route.query.to);
            var routeFrom = window.atob(this.$route.query.from);
            if (routeTo != undefined && routeFrom != undefined) {
                this.fromDate = routeFrom;
                this.toDate = routeTo;
            }
        }
        await this.graphData();
    },
    methods: {
        ...mapActions(["ShowWarningMessage", "GetOverallDetailsOfDashboardGraph", "DescriptiveDashboardAllocation", "DescriptiveDashboardScheduled"]),
        dateformat: (val) => {
            return moment(String(val)).format("YYYY-MM-DD 00:00:00");
        },
        handleRowClick(item) {
            this.$router.push({
                name: 'DescriptiveTestWise',
                query: {
                    id: this.encode(item.testId),
                    name: this.encode(item.testName),
                    to: this.encode(this.toDate),
                    from: this.encode(this.fromDate),
                },
            });
        },
        todateformat: (val) => {
            return moment(String(val)).format("YYYY-MM-DD 23:59:59");
        },
        hideWelcome() {
            window.$("div").removeClass("modal-backdrop");
            window.$("#WelcomePopupModal").modal("hide")
        },
        //     generatePDF() {
        //   const element = document.getElementById('pdfContent');
        //   html2pdf().from(element).save();
        // }
        opencal() {
            document.getElementById("calendar_icon").click();
        },
        encode(item) {
            return window.btoa(item);
        },
        async graphData() {
            this.PropStatus = false;
            await this.fetchLineAndRegionWiseGraph();
            await this.pieChartData();
            this.PropStatus = true;
        },
        async fetchLineAndRegionWiseGraph() {
            var graphData = await this.GetOverallDetailsOfDashboardGraph({
                clientId: this.user.clientId,
                TestId: 0,
                RegionId: 0,
                FromDate: this.fromDate,
                ToDate: this.toDate
            });
            const startDate = new Date(this.fromDate);
            const endDate = new Date(this.toDate);
            const timeDifference = endDate - startDate;
            const daysDifference = timeDifference / (1000 * 3600 * 24);
    
            this.NumberOfDays = Math.round(daysDifference);
            this.assessmentFlows = graphData.object.data.assessmentFlows;
            this.testWiseData = graphData.object.data.testDesignSummary;

        },

        showOrHidePdfDownload(value) {
            this.hidePdfDownload = value;
        },

    async pieChartData() {
            var response = await this.DescriptiveDashboardAllocation({
                clientId: this.user.clientId,
                mode: 'Group',
                From: this.fromDate,
                To: this.toDate
            });
            this.pieTable = response.object.data.details;


            // var res = await this.DescriptiveDashboardScheduled({
            //     clientId: this.user.clientId,
            //     mode: 'Group',
            //     From: this.fromDate,
            //     To: this.toDate
            // });

            // this.pieData = res.object.data.details;

        },
    },
    async mounted() {
        this.isParent = localStorage.getItem('isParent');
        this.displayDate = moment(String(this.fromDate)).format("YYYY MMM DD") + " - " + moment(String(this.toDate)).format("YYYY MMM DD");
        window.$('input[name="daterange"]').daterangepicker(
            {
                maxDate: new Date(),
                locale: { cancelLabel: "Clear" },
                opens: "center",
            },
            async (start, end) => {
                if (start && end) {
                    var x = start.format("YYYY MMM DD");
                    var y = end.format("YYYY MMM DD");
                    this.fromDate = this.dateformat(x);
                    this.toDate = this.todateformat(y);
                    // this.showw = false;
                }
            }
        );
        window
            .$('input[name="daterange"]')
            .on("cancel.daterangepicker", async (ev, picker) => {
                picker.startDate._d = new Date();
                picker.endDate._d = new Date();
                this.fromDate = this.dateformat(
                    new Date(new Date().setMonth(new Date().getMonth() - 1))
                ),
                    this.toDate = this.todateformat(new Date(new Date())),
                    window.$('input[name="daterange"]').val("");
                var x = moment(String(this.fromDate)).format("DD MMM YYYY");
                var y = moment(String(this.toDate)).format("DD MMM YYYY");
                this.displayDate = x + " - " + y;
                await this.graphData();

            });
        window
            .$('input[name="daterange"]')
            .on("apply.daterangepicker", async (ev, picker) => {
                var x = moment(String(picker.startDate._d)).format("YYYY MMM DD");
                var y = moment(String(picker.endDate._d)).format("YYYY MMM DD");
                var start = moment(String(picker.startDate._d)).format("YYYY-MM-DD 00:00:00")
                var end = moment(String(picker.endDate._d)).format("YYYY-MM-DD 00:00:00")
                if (
                    Math.ceil(
                        Math.abs(new Date(start) - new Date(end)) /
                        (1000 * 60 * 60 * 24)
                    ) <= 30
                ) {
                    this.displayDate = x + " - " + y;
                    var encodeObject = {
                        ClientId: this.user.clientId,
                        TestId: 0,
                        RegionId: 0,
                        From: this.fromDate,
                        To: this.toDate
                    }
                    var base64EncodedString = btoa(JSON.stringify(encodeObject));
                    this.reportUrl = window.app_base_url + "/descriptiveDashboard/DownloadDashboard?token=" + base64EncodedString;
                    await this.graphData();
                    this.counter++;
                } else {
                    return this.ShowWarningMessage("Please select range within 1 months");
                }
            });

        // await this.fetchLineAndRegionWiseGraph();

        var encodeObject = {
            ClientId: this.user.clientId,
            TestId: 0,
            RegionId: 0,
            From: this.fromDate,
            To: this.toDate
        }
        var base64EncodedString = btoa(JSON.stringify(encodeObject));
        this.reportUrl = window.app_base_url + "/descriptiveDashboard/DownloadDashboard?token=" + base64EncodedString;

        //highchartsExporting(Highcharts);
        Highcharts.setOptions({
            exporting: {
                enabled: false,
                // chartOptions: {
                //     print: {
                //         dpi: 300
                //     },
                //     exportFormat: 'svg',
                //     style: {
                //         fontFamily: 'DM Sans, sans-serif'
                //     },
                //     exporting: {
                //         enabled: false,

                //     }
                // }
            },
            chart: {
                style: {
                    fontFamily: 'DM Sans, sans-serif'
                }
            }
        });
    },
};
</script>
<style scoped>
.badge {
    display: inline-block;
    font-size: 13px;
    font-weight: 600;
    padding: 8px 10px;
    border: 1px solid transparent;
    min-width: 10px;
    line-height: 1;
    color: #4d4d4d;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    /* background-color: #f1f4f9; */
    /* border-radius: 99999px */
}

.demo-preview .badge {
    margin-right: 10px;
}

.table-responsive thead th {
    position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 2;
    /* box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4); */
}

.table-title {
    font-size: 18px;
    font-weight: bold;
}

.hover-row {
    background-color: #f0f0f0;
    cursor: pointer;
}
</style>