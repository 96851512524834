<template>
    <div class="page-content">
        <h3 class="mb-2 mb-md-0">PMaps Invoice Preference</h3>
        <div>
            <div class="my-4">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card p-0 " style="border-radius: 25px;max-width: 1000px; ">
                            <div class="table-responsive" style="border-radius: 25px;">
                                <table class="table" style="max-width: 1000px;">
                                    <thead style="background-color: #F1F0FF !important;">
                                        <tr>
                                            <th>Name</th>
                                            <th style="max-width: 300px; word-wrap: break-word; white-space: normal;">Value</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="item in PreferenceData" :key="item">
                                        <tr>
                                            <td>{{ item.preferenceName }}</td>
                                            <td style="max-width: 300px; word-wrap: break-word; white-space: normal;">{{ item.preferenceValue }}</td>
                                            <td v-if="item.preferenceId == 1019"
                                                @click="openEditInvoicePreference(item)" style="cursor: pointer;">
                                                <button class="btn btn-primary">Edit</button>
                                            </td>
                                            <td v-else style="color: red;">No Permission</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="EditInvoicePreference" tabindex="-1" aria-labelledby="DeactivatePartnerModalLabel"
            aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable border-radius-kj-20 model-sm">
                <div class="modal-content border-radius-kj-20">
                    <div class="modal-header token-model-header-color">
                        <h5 class="modal-title text-centerm-auto" id="IncreaseTokenModalLabel">
                            <span class="font-size-20-kj">Edit Invoice Preference</span>
                        </h5>
                        <button type="button" id="close" class="btn-close" data-bs-dismiss="modal"
                            aria-label="btn-close" @click="resetModal()"></button>
                    </div>
                    <div class="modal-body ">
                        <div class="my-2">
                            <span>Id: {{ this.preferenceId }} </span>
                            <span class="mx-2">Name: {{ this.preferenceName }} </span>
                        </div>
                        <div class="row mt-5">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                                <div class="form-check mb-4" style="padding-left: 0px;">
                                    <label class="input-hero">
                                        <input type="number" class="form-control" v-model="this.preferenceValue" />
                                        <span class="input-name">
                                            PreferenceValue
                                        </span>
                                    </label>
                                </div>
                            </div>

                        </div>
                        <div class="text-end mt-3">
                            <button type="button" class="btn btn-primary btn-download" @click="editInvoicePreference()">
                                Change
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
    name: "InvoicePreferenceSetting",
    components: {

    },
    computed: {
        ...mapState({
            user: (state) => state.auth.user,
            clientId: (state) => state.auth.user.clientId,
        }),
    },

    data() {
        return {
            PreferenceData: [],
            preferenceValue: '',
            preferenceId: 0,
            preferenceName: ''
        };
    },
    async created() {
        var Invoicedata = await this.GetInvoicePreferencesData();

        this.PreferenceData = Invoicedata.object.data;
    },
    methods: {
        ...mapActions(["GetInvoicePreferencesData", "PutInvoiceSequences"]),

        ...mapMutations(["setLoading"]),

        async editInvoicePreference() {
            var data = await this.PutInvoiceSequences({
                SequenceNumber: this.preferenceValue
            });
            if (data.status) {
                var Invoicedata = await this.GetInvoicePreferencesData();
                this.PreferenceData = Invoicedata.object.data;

                window.$("#EditInvoicePreference").modal("hide");
                this.preferenceName = '';
                this.preferenceId = 0;
                this.preferenceValue = '';
            }
        },

        openEditInvoicePreference(item) {
            this.preferenceName = item.preferenceName;
            this.preferenceId = item.preferenceId;
            this.preferenceValue = item.preferenceValue;
            window.$("#EditInvoicePreference").modal("show");
        },

        resetModal() {
            this.preferenceName = '';
            this.preferenceId = 0;
            this.preferenceValue = '';
        }
    }
}
</script>

<style scoped></style>