<template>
    <div class="page-content">
        <!-- <Tour v-if="this.startTour" :page="componentName" /> -->
        <Tour v-if="(this.user.redirectUrl != null || this.user.isEnterprise) && this.startTour"
            :page="componentName" />
        <!-- <DashboardTour v-if="this.componentTour" :page="componentName" @tour-close="CloseComponentTour" /> -->
        <!-- Header Start with Name , Date , User , Partner -->
        <div class="d-flex flex-column flex-md-row justify-content-between align-items-start">
            <div class="mb-3">
                <h3 style="font-weight: 500;">Hi there, {{ user.firstName }}</h3>
                <h5 class="mt-1" style="color: grey;">{{ this.displayDate }}</h5>
            </div>
            <div class="d-flex data-flow mb-3">
                <div class="card headerCard me-4 mb-2" v-if="this.basicDetails.partnersCount > 0">
                    <div class="d-flex align-items-center">
                        <img src="../../../../public/dashboard-assets/images/dashboard/group-icon.png"
                            class="header-icon" />
                        <span class="mx-2">
                            <!-- {{ this.isParent ? 'Partners:':'Groups:' }} -->
                            Partners: <span style="font-weight: 600;">
                                <!-- {{ this.isParent ? this.basicDetails.partnersCount : this.basicDetails.groupsCount }} -->
                                {{ this.basicDetails.partnersCount }}
                            </span>
                        </span>
                    </div>
                </div>
                <div class="card headerCard me-4 mb-2">
                    <div class="d-flex align-items-center">
                        <img src="../../../../public/dashboard-assets/images/dashboard/group-icon.png"
                            class="header-icon" />
                        <span class="mx-2">
                            <!-- {{ this.isParent ? 'Partners:':'Groups:' }} -->
                            Groups: <span style="font-weight: 600;">
                                <!-- {{ this.isParent ? this.basicDetails.partnersCount : this.basicDetails.groupsCount }} -->
                                {{ this.basicDetails.groupsCount }}
                            </span>
                        </span>
                    </div>
                </div>
                <div class="card headerCard mb-2">
                    <div class="d-flex align-items-center">
                        <img src="../../../../public/dashboard-assets/images/dashboard/user-icon.png"
                            class="header-icon" />
                        <span class="mx-2">
                            Users: <span style="font-weight: 600;">{{ this.basicDetails.userCount }}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <!-- Header End with Name , Date , User , Partner -->



        <!-- Body Graphs Start -->
        <div class="Overview_dashboard_map_section">
            <div class="mt-4 card px-4 py-4">
                <h3>Overview</h3>
                <h5 class="mt-2" style="color: #333333">{{ formatDate(this.startDate) }} -
                    {{ formatDate(this.endDate) }}</h5>
                <div class="mt-4">
                    <div class="mx-1 mb-3" :class="this.weekStatus ? 'primary-button' : 'secondary-button'"
                        @click="SetTime(7)">
                        Last 1 Week
                    </div>
                    <div class="mx-1 mb-3" :class="this.monthStatus ? 'primary-button' : 'secondary-button'"
                        @click="SetTime(30)">
                        Last 1 Month
                    </div>
                    <div class="mx-1 mb-3" :class="this.threeMonthsStatus ? 'primary-button' : 'secondary-button'"
                        @click="SetTime(90)">
                        Last 3 Months
                    </div>
                    <div class="mx-1 mb-3" :class="this.customStatus ? 'primary-button' : 'secondary-button'"
                        @click="CustomTimeFilter()">
                        Custom Range
                    </div>
                </div>
                <div class="mt-4 p-4" style="background-color:#F9FAFB; border-radius: 24px;" v-if="this.customStatus">
                    <h5 style="font-weight: 600;">Custom Date Range</h5>
                    <div class="row mt-4">
                        <div class="col-md-3 form-group mb-3">
                            <label class="input-hero" for="startDate">
                                <input type="date" v-model="startDate" class="input_area" id="startDate"
                                    :max="maxDateTime">
                                <span class="input-name">From Date<span class="text-danger">*</span></span>
                            </label>
                        </div>
                        <div class="col-md-3 form-group mb-3">
                            <label class="input-hero" for="endDate">
                                <input type="date" v-model="endDate" class="input_area" id="endDate" :max="maxDateTime">
                                <span class="input-name">To Date<span class="text-danger">*</span></span>
                            </label>
                        </div>
                        <div class="col-md-3">
                            <button class="btn btn-primary me-3 mb-3" type="button" data-bs-dismiss="modal"
                                @click="selectedCustomTime()">
                                Check
                            </button>
                        </div>

                    </div>
                </div>

                <div class="mt-5">
                    <!-- <h4 class="mt-3 mb-4">Last Months Insight</h4> -->
                    <div class="row">
                        <div class="col-12 col-sm-6 col-lg-3 mb-4">
                            <div class="card main-card">
                                <div class="card-heading mt-3">Active Test</div>
                                <div class="card-icon mt-3">
                                    <img src="../../../../public/dashboard-assets/images/dashboard/edit-test.png"
                                        class="main-icon" />
                                </div>
                                <div class="card-count mt-3">{{ this.basicDetails.activeTestCount }}</div>
                                <!-- Placeholder div to maintain spacing -->
                                <!-- <div class="mt-3 card-insight"></div> -->

                                <div class="card-button my-4" style="color:#7b68ee;">
                                    <router-link :to="'/active-tests'" class="custom-link p-0" type="button"
                                        style="color:#7b68ee;">
                                        View Details
                                    </router-link>
                                </div>

                                <!-- <div class="card-button my-4">
                                    <router-link :to="{
                                        name: 'ActiveTests',
                                    }" class="p-0 custom-link " type="button" style="color: #7B68EE;">
                                        View All
                                    </router-link>
                                </div> -->
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-3 mb-4">
                            <div class="card main-card">
                                <div class="card-heading mt-3">Private Invites</div>
                                <div class="card-icon mt-3">
                                    <img src="../../../../public/dashboard-assets/images/dashboard/invite-candidate.png"
                                        class="main-icon" />
                                </div>
                                <div class="card-count mt-3">{{ this.basicDetails.mode == "Partner" ? 'N.A' :
                                    this.basicDetails.invitedCount }}</div>
                                <div class="mt-3 card-insight"
                                    :class="this.invitedStatus ? 'card-insight-success' : 'card-insight-failure'"
                                    v-if="this.basicDetails.mode == 'Group'">
                                    <img :src="invitedStatus ? successSpike : failureSpike" style="width: 25px;" />
                                    {{ inviteCandidateStatus(this.basicDetails.invitedPercentage) }}%
                                </div>
                                <div class="card-button my-4" style="cursor: pointer;"
                                    :style="{ color: this.basicDetails.candidateCount ? '#FF3E92' : '#FFFFFF' }"
                                    @click="navigateToId('invite-div')">
                                    View Analysis
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-3 mb-4">
                            <div class="card main-card">
                                <div class="card-heading mt-3">Candidate Test Count</div>
                                <div class="card-icon mt-3">
                                    <img src="../../../../public/dashboard-assets/images/dashboard/candidate-test-count.png"
                                        class="main-icon" />
                                </div>
                                <div class="card-count mt-3">{{ this.basicDetails.mode == "Partner" ? 'N.A' :
                                    this.basicDetails.candidateCount }}</div>
                                <div class="mt-3 card-insight"
                                    :class="this.candidateCountStatus ? 'card-insight-success' : 'card-insight-failure'"
                                    v-if="this.basicDetails.mode == 'Group'">
                                    <img :src="candidateCountStatus ? successSpike : failureSpike"
                                        style="width: 25px;" />
                                    {{ candidateCount(this.basicDetails.candidatePercentage) }}%
                                </div>
                                <!-- #3BC3F4; -->
                                <div class="card-button my-4" style="color: #FFFFFF;">View Analysis</div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-3 mb-4">
                            <div class="card main-card">
                                <div class="card-heading mt-3">Balance Tokens</div>
                                <div class="card-icon mt-3">
                                    <img src="../../../../public/dashboard-assets/images/dashboard/balance-tokens.png"
                                        class="main-icon" />
                                </div>
                                <div class="card-count mt-3">{{ this.basicDetails.availableTestCount }}</div>
                                <!-- Placeholder div to maintain spacing -->
                                <!-- <div class="mt-3 card-insight"></div> -->
                                <div class="card-button my-4" style="color:#FFA71B;">
                                    <router-link :to="'/test-management'" class="custom-link p-0" type="button"
                                        style="color: #FFA71B; ">
                                        View Details
                                    </router-link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="Overview_dashboard_map_section mt-3" v-if="this.listDataStatus">
                        <div class="card" v-if="PropStatus" id=invite-div>
                            <InviteCandidateGraph :inviteData="listData" :userData="userList" :start="startDate"
                                :end="endDate" v-if="this.listData != null" />
                        </div>
                    </div>
                    <div class="Overview_dashboard_map_section card" v-else style="min-height: 350px;">
                        <div class="mb-3 p-4">
                            <h3 style="font-weight: 500;">Recent Invited Candidates</h3>
                            <h6 class="mt-1" style="color: grey;">{{ this.displayDateRange }}</h6>
                        </div>
                        <!-- v-if="!this.clientRole.roleName == 'Owner' || !this.clientRole.roleName == 'Admin' || !this.clientRole.roleName == 'PMaps SuperAdmin'" -->
                        <div v-if="this.PropStatus" class="text-center pt-5">
                            <!-- <img src="../../../../public/dashboard-assets/images/M2/empty_record.png" style="width: 15%"
                                class="my-5" /> -->
                            <h4 class="ms-5 mt-2" style="line-height: 35px;">The insights couldn't be generated because
                                there wasn't enough data available. </h4>
                            <!-- v-html="this.basicDetails.activeTestCount > 0 ? `Hey!<br> Could you please invite candidates to view the Invite Candidate Insights?` : `<b>Don’t</b> forget to add the test to your <b>Active Tests!</b><br>If you’re missing active tests, check out the <b>Test Library</b> for great options!`" -->



                            <h4 class="ms-5 my-4 secondary-button mt-4"
                                style="font-weight: 600; border-radius: 30px; padding: 1rem;">
                                <router-link
                                    :to="this.basicDetails.activeTestCount > 0 ? '/active-tests' : '/test-library'"
                                    style="text-decoration: none !important;">
                                    {{ this.basicDetails.activeTestCount > 0 ? 'View Active Test' : 'View Test Library'
                                    }}
                                    <i class="fa-solid fa-arrow-right mx-2"></i>
                                </router-link>
                            </h4>

                        </div>


                    </div>
                    <div class="Overview_dashboard_map_section card mt-3 p-4" style="min-height: 350px;"
                        v-if="!this.listDataStatus">
                        <h3 class="">Private Invite Insight</h3>
                        <h6 class="mt-1" style="color: grey;">{{ this.displayDateRange }}</h6>

                        <div v-if="this.PropStatus" class="text-center pt-5">
                            <!-- <img src="../../../../public/dashboard-assets/images/M2/empty_record.png" style="width: 15%"
                                class="my-5" /> -->
                            <h4 class="ms-5 mt-5" style="line-height: 35px;">The insights couldn't be generated because
                                there wasn't enough data available. </h4>
                            <!-- v-html="this.basicDetails.activeTestCount > 0 ? `Hey!<br> Could you please invite candidates to view the Invite Candidate Insights?` : `<b>Don’t</b> forget to add the test to your <b>Active Tests!</b><br>If you’re missing active tests, check out the <b>Test Library</b> for great options!`" -->

                            <!-- <h4 class="ms-5 my-4 secondary-button mt-4"
                                style="font-weight: 600; border-radius: 30px; padding: 1rem;">
                                <router-link
                                    :to="this.basicDetails.activeTestCount > 0 ? '/active-tests' : '/test-library'"
                                    style="text-decoration: none !important;">
                                    {{ this.basicDetails.activeTestCount > 0 ? 'View Active Test' : 'View Test Library'
                                    }}
                                    <i class="fa-solid fa-arrow-right mx-2"></i>
                                </router-link>
                            </h4> -->

                        </div>
                    </div>
                </div>
                <!-- 
                <div class="Overview_dashboard_map_section mt-4">
                    <div class="card" v-if="PropStatus" id=invite-div>
                        hi
                    </div>
                </div> -->
            </div>
        </div>
        <!-- Body Graphs End -->

        <!-- Welcome Modal -->
        <div class="modal fade" id="WelcomePopupModal" tabindex="-1" aria-labelledby="WelcomePopupModalLabel"
            aria-hidden="false">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body p-4 text-center">
                        <h3 class="text-center mb-3">
                            Welcome to PMaps, {{ this.user?.firstName }}
                        </h3>

                        <p class="text-muted text-justify" style="font-size: 13px">
                            Sends assessment to candidates and access to real-time reports on
                            their performance and suitability.
                        </p>

                        <div class="loadPopupImage my-4">
                            <img src="../../../../public/dashboard-assets/images/M2/TourImages/ActiveTestStart.svg"
                                class="loadPopupImage_img" alt="" />
                        </div>

                        <button class="btn btn-primary w-100" @click="hideWelcome()" aria-label="btn-close">
                            Start tour
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Show Tour Modal -->
        <div class="modal fade" id="showTourModal" tabindex="-1" aria-labelledby="IncreaseTokenModalLabel"
            aria-hidden="true" data-bs-backdrop="static">
            <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable model-sm ">
                <div class="modal-content border-radius-kj-20 ">
                    <div class="modal-body">
                        <div class="float-end" style="color: #333333;">
                            <button type="button" class="btn-close" data-bs-dismiss="modal"
                                aria-label="btn-close"></button>
                        </div>
                        <div class="row my-4 mx-1">
                            <div class="col-12 col-sm-6 col-lg-6 col-md-6 col-xl-6">
                                <div style="font-weight: 600; font-size: 26px;">Ready to see how we can make your hiring
                                    process a
                                    breeze?</div>
                                <div style="color: #333333;" class="mt-2">
                                    Explore our interactive tour and discover how to easily manage Test invites,
                                    Candidate Reports, and
                                    many more!

                                    Our platform is designed with you in mind, making everything smooth and
                                    straightforward.

                                    Let's make your hiring process easier together!
                                </div>
                                <div class="mt-5">
                                    <div class="row">
                                        <!-- <div class="col-12 col-sm-6 col-lg-6 col-md-6 col-xl-6 mb-3"><button
                        class="tour-primary-button">Watch Video</button></div> -->
                                        <div class="col-12 col-sm-6 col-lg-6 col-md-6 col-xl-6 mb-3"><button
                                                class="tour-secondary-button" @click="StartComponentTour()">Start
                                                Tour</button></div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-6 col-md-6 col-xl-6" style="align-content:center">
                                <img src="../../../../public/dashboard-assets/images/M2/TourImages/ActiveTestStart.svg"
                                    alt="" style="width:100%;" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Show Tour Complete Modal -->
        <div class="modal fade" id="TourCompleteModal" tabindex="-1" aria-labelledby="IncreaseTokenModalLabel"
            aria-hidden="true" data-bs-backdrop="static">
            <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable model-sm ">
                <div class="modal-content border-radius-kj-20 ">
                    <div class="modal-body">
                        <div class="my-6" style="text-align: center;">
                            <h1 style="font-weight: 600; color: #333333;">Nice Work !</h1>
                            <h2 style="font-weight: 600; color: #333333;" class="mt-2">Your dashboard overview summary
                                is completed
                            </h2>
                            <div style="color: #111111; font-size: 18px;" class="mt-2">Time to hire best candidates
                                through
                                assessments </div>
                            <div class="mt-5">
                                <button type="button" class="tour-primary-button" data-bs-dismiss="modal"
                                    aria-label="btn-close" style="max-width:200px">Tour Completed</button>
                                <div class="mt-4" @click="this.OpenTourModal();"
                                    style="color: #7b68ee; cursor: pointer;"><i
                                        class="fa-solid fa-arrow-rotate-left"></i><span class="mx-2">Restart Tour</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";
import InviteCandidateGraph from '../Overview/InviteCandidateGraph.vue';
// import Tour from "../components/TourDemo.vue";
// import eventBus from './TourComponents/eventBus.js'
// import DashboardTour from './TourComponents/DashboardTour.vue'
import Tour from "../../TourDemo.vue";
// import eventBus from '../../TourComponents/eventBus.js';
// import DashboardTour from '../../TourComponents/DashboardTour.vue';

export default {
    components: {
        InviteCandidateGraph,
        Tour,
        // DashboardTour
    },
    data() {
        return {
            displayDate: "",
            basicDetails: [],
            invitedStatus: false,
            candidateCountStatus: false,
            successSpike: require('../../../../public/dashboard-assets/images/dashboard/success-arrow.png'),
            failureSpike: require('../../../../public/dashboard-assets/images/dashboard/failure-arrow.png'),
            listData: [],
            userList: [],
            listDataStatus: false,
            PropStatus: false,
            startDate: '',
            endDate: '',
            flag: false,
            weekStatus: true,
            monthStatus: false,
            threeMonthsStatus: false,
            customStatus: false,
            maxDateTime: new Date().toISOString().slice(0, 10),
            clientRole: '',
            displayDateRange: '',
            modalStatus: true,
            startTour: false,
            componentTour: false,
            componentName: ''

            // isParent: false
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.auth.user,
        }),
    },
    async created() {
        //this.componentName = 'DashboardGroup';
        this.componentName = this.$route.name;
        const date = new Date();
        //Below Name Display Date
        this.displayDate = moment(date).format("dddd, DD MMM YYYY");
        // var partner = localStorage.getItem("isParent");
        // this.isParent = JSON.parse(partner);

        this.PropStatus = false;
        await this.SetTime();
        // await this.inviteDetails();
        this.PropStatus = true;
    },
    async mounted() {
        // eventBus.emit('data-from-child-component', true);
        // eventBus.on('data-from-header-component', data => {
        //     this.receivedData = data;
        //     this.OpenTourModal();
        // });
        var parent = localStorage.getItem("Userpermission");
        this.clientRole = JSON.parse(parent);
        this.displayDateRange = `${this.formatDate(this.startDate)} - ${this.formatDate(this.endDate)} `;

        if (this.user != null && Object.keys(this.user).length !== 0) {
            if (!this.user.termsAndConditionsAccepted) {
                await this.GetAccessToken({ userId: this.user.userId });
                this.$router.push({
                    name: "TermsPop",
                });
            } else if (!this.user.passwordSet) {
                await this.GetAccessToken({ userId: this.user.userId });
                this.$router.push({
                    name: "SetPassword",
                });
            }
            else {
                //     this.lastLoggedInDate = this.Date;

                // if (this.lastLoggedInDate === null && this.modalStatus == 'true')
                //     window.$("#WelcomePopupModal").modal("show");
                window.$("#WelcomePopupModal").modal("hide");
                this.modalStatus = localStorage.getItem("pageStatus");
                if (this.modalStatus == 'true') {
                    window.$("#WelcomePopupModal").modal("show");
                }

            }

        }

    },
    methods: {
        ...mapActions(["GetAccessToken", "GetOverviewDashboard", "GetInvitationAnalysis", "GetTestClientUserAnalysis", "ShowWarningMessage"]),
        formatDate(date) {
            return moment(date).format("DD MMM YYYY (ddd)");
        },
        formatApiDate(date) {
            return moment(date).format("YYYY-MM-DD");
        },
        async OpenTourModal() {
            window.$("#TourCompleteModal").modal("hide");
            window.$("#showTourModal").modal("show");
        },
        async StartComponentTour() {
            window.$("#showTourModal").modal("hide");
            this.componentTour = true;
        },
        async CloseComponentTour() {
            window.$("#TourCompleteModal").modal("show");
            this.componentTour = false;
            window.scrollTo(0, 0);
        },
        hideWelcome() {
            // this.modalStatus = false;
            this.startTour = true;
            localStorage.setItem("pageStatus", false);
            window.$("div").removeClass("modal-backdrop");
            window.$("#WelcomePopupModal").modal("hide");
            // router.push('/test-library-tour');
        },
        async OverallDetails() {

            // var mode;
            // console.log(typeof(this.isParent), this.isParent);
            // if(this.isParent){
            //     console.log();
            //     mode = "Partner";
            // }
            // else{
            //     mode = "Group";
            // }

            var date = moment(String(this.endDate)).add(1, 'days');
            var endDate = this.formatApiDate(date);
            var data = await this.GetOverviewDashboard({
                ClientId: this.user.clientId,
                startDate: this.startDate,
                endDate: endDate,
                mode: "Group"
            });
            this.basicDetails = data.object.data;

            // console.log("GetOverviewDashboard:", this.basicDetails);

        },

        ResetStatus() {
            this.weekStatus = false;
            this.monthStatus = false;
            this.threeMonthsStatus = false;
            this.customStatus = false;
        },

        navigateToId(id) {
            const element = document.getElementById(id);
            if (element) {
                const offsetTop = element.offsetTop + 600;
                window.scrollTo({
                    top: offsetTop,
                    behavior: 'smooth'
                });
            }
        },

        async CustomTimeFilter() {
            this.ResetStatus();
            this.customStatus = true;
        },
        async selectedCustomTime() {
            const date = new Date();
            if (this.startDate >= this.endDate) {
                return this.ShowWarningMessage("From Date can't be greater than To Date");
            }
            else if (this.startDate > date || this.endDate > date) {
                return this.ShowWarningMessage("Date can't be greater than Current Date");
            }
            this.PropStatus = false;
            await this.OverallDetails();
            await this.inviteDetails();
            this.PropStatus = true;
        },
        async SetTime(value) {
            this.PropStatus = false;
            this.ResetStatus();
            const date = new Date();
            var endDate = moment(String(date)).format("YYYY-MM-DD");
            var startDate = '';

            switch (value) {
                case 7:
                    startDate = moment(endDate, "YYYY-MM-DD").subtract(7, 'days').format("YYYY-MM-DD");
                    this.startDate = startDate;
                    this.endDate = endDate;
                    this.flag = true;
                    this.weekStatus = true;
                    break;
                case 30:
                    startDate = moment(endDate, "YYYY-MM-DD").subtract(30, 'days').format("YYYY-MM-DD");
                    this.startDate = startDate;
                    this.endDate = endDate;
                    this.flag = true;
                    this.monthStatus = true;
                    break;
                case 90:
                    startDate = moment(endDate, "YYYY-MM-DD").subtract(90, 'days').format("YYYY-MM-DD");
                    this.startDate = startDate;
                    this.endDate = endDate;
                    this.flag = true;
                    this.threeMonthsStatus = true;
                    break;
                default:
                    startDate = moment(endDate, "YYYY-MM-DD").subtract(7, 'days').format("YYYY-MM-DD");
                    this.startDate = startDate;
                    this.endDate = endDate;
                    this.flag = true;
                    this.weekStatus = true;
                    break;
            }

            await this.OverallDetails();
            await this.inviteDetails();

            this.PropStatus = true;
        }
        ,
        async inviteDetails() {
            var date = moment(String(this.endDate)).add(1, 'days');
            var endDate = this.formatApiDate(date);
            var data = await this.GetInvitationAnalysis({
                clientId: this.user.clientId,
                FromDate: this.startDate,
                ToDate: endDate
            });


            var user = await this.GetTestClientUserAnalysis({
                clientId: this.user.clientId,
                TestId: 0,
                RegionId: 0,
                FromDate: this.startDate,
                ToDate: endDate
            });

            if (user.status) {
                this.userList = user.object.data.inviteUseSumarries;
            }
            if (data.status) {
                this.listData = data.object.data;
                this.listDataStatus = this.listData.invitation_Model.publicInvite > 0 || this.listData.invitation_Model.privateInvite > 0;
            }

            // console.log("GetInvitationAnalysis:", this.listData);
            // console.log("GetTestClientUserAnalysis:", this.userList);

        },

        inviteCandidateStatus(data) {
            if (data < 0) {
                this.invitedStatus = false;
            } else {
                this.invitedStatus = true;
            }
            return data;
        },

        candidateCount(data) {
            if (data < 0) {
                this.candidateCountStatus = false;
            }
            else {
                this.candidateCountStatus = true;
            }
            return data;
        }
    }
};
</script>

<style scoped>
.headerCard {
    padding: 15px;
    border-radius: 16px;
    min-width: 160px;
    box-shadow: 1px 1px 7px 0.1px #e0e0e0;
}

.header-icon {
    width: 50px;
}

.main-card {
    border-radius: 16px;
    box-shadow: 1px 1px 7px 0.1px #e0e0e0;
    text-align: center;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.main-icon {
    width: 100px;
}

.card-heading {
    font-size: large;
    font-weight: 600;
    color: #333333;
}

.card-count {
    font-weight: 600;
    font-size: 28px;
    color: #333333;
}

.card-insight {
    font-size: medium;
    margin-top: 20px;
}

.card-insight-success {
    background-color: #E9F3E6;
    padding: 5px;
    color: #1D8102;
    width: max-content;
    align-self: center;
    border-radius: 14px;
    border: 1px solid #E9F3E6;
    font-weight: 600;
}

.card-insight-failure {
    background-color: #FBEBE8;
    padding: 5px;
    color: #D13212;
    width: max-content;
    align-self: center;
    border-radius: 14px;
    border: 1px solid #FBEBE8;
    font-weight: 600;
}

.card-button {
    text-decoration: underline;
    font-size: large;
    color: inherit;
}

.custom-link {
    text-decoration: none !important;
    font-size: large;
}

.data-flow {
    flex-direction: row;
}

@media (max-width: 768px) {
    .main-card {
        width: 175px;
        margin: auto;
    }

    .data-flow {
        flex-direction: column;
    }

    .header-icon {
        width: 40px;
    }

    .main-icon {
        width: 80px;
    }

    .card-count {
        font-size: 24px;
    }

    .card-heading,
    .card-button {
        font-size: medium;
    }

    .row {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .col-12 {
        flex: 0 0 auto;
        max-width: 50%;
        display: flex;
        justify-content: center;
    }
}

.primary-button {
    background-color: #7b68ee;
    font-size: medium !important;
    /* font-weight: 600; */
    border-radius: 4px;
    color: #FFFFFF;
    border: 2px solid #7b68ee !important;
    /* width: 100%; */
    /* padding-top: 5px;
  padding-bottom: 5px; */
    display: inline-block;
    /* font-weight: 400; */
    /* color: #495057; */
    text-align: center;
    vertical-align: middle;
    padding: 0.375rem 0.75rem;
    cursor: pointer;
}

.secondary-button {
    /* background-color: #FFFFFF; */
    font-size: medium !important;
    font-weight: 600;
    border-radius: 4px;
    color: #7b68ee;
    border: 2px solid #7b68ee !important;
    /* width: 100%; */
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    padding: 0.375rem 0.75rem;
    cursor: pointer;
}
</style>
