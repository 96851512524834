const getDefaultState = () => {
    return {
        heatmapData: null,
        testConstruct: null,
        tokenUsage: null,
        overallData: null,
        scoreBenchmark: null,
        proctorData: null,
        attemptAnalysis: null,
        lineData: null,
    };
};

const state = getDefaultState();

const mutations = {
    setHeatmapData(state, payload) {
        state.heatmapData = payload;
    },
    setTestConstruct(state, payload) {
        state.testConstruct = payload;
    },
    setTokenUsageData(state, payload) {
        state.tokenUsage = payload;
    },
    setTokenUsageDaysData(state, payload) {
        state.tokenUsageDays = payload;
    },
    setOverallResultAnalysisData(state, payload) {
        state.overallData = payload;
    },
    setScoreBenchmarkData(state, payload) {
        state.scoreBenchmark = payload;
    },
    setProctorData(state, payload) {
        state.proctorData = payload;
    },
    setAttemptAnalysis(state, payload) {
        state.attemptAnalysis = payload;
    },
    setLineData(state, payload) {
        state.lineData = payload;
    },
    resetState(state) {
        Object.assign(state, getDefaultState());
    }
};

const actions = {
    setHeatmapData({ commit }, data) {
        commit('setHeatmapData', data);
    },
    setTestConstruct({ commit }, data) {
        commit('setTestConstruct', data);
    },
    setTokenUsageData({ commit }, data) {
        commit('setTokenUsageData', data);
    },
    setTokenUsageDaysData({ commit }, data) {
        commit('setTokenUsageDaysData', data);
    },
    setOverallResultAnalysisData({ commit }, data) {
        commit('setOverallResultAnalysisData', data);
    },
    setScoreBenchmarkData({ commit }, data) {
        commit('setScoreBenchmarkData', data);
    },
    setProctorData({ commit }, data) {
        commit('setProctorData', data);
    },
    setAttemptAnalysis({ commit }, data) {
        commit('setAttemptAnalysis', data);
    },
    setLineData({ commit }, data) {
        commit('setLineData', data);
    },
    resetState({ commit }) {
        commit('resetState');
    }
};

const getters = {
    getHeatmapData(state) {
        return state.heatmapData;
    },
    getTestConstruct(state) {
        return state.testConstruct;
    },
    getTokenUsageData(state) {
        return state.tokenUsage;
    },
    getTokenUsageDaysData(state) {
        return state.tokenUsageDays;
    },
    getOverallResultAnalysisData(state) {
        return state.overallData;
    },
    getScoreBenchmarkData(state) {
        return state.scoreBenchmark;
    },
    getProctorData(state) {
        return state.proctorData;
    },
    getAttemptAnalysis(state) {
        return state.attemptAnalysis;
    },
    getLineData(state) {
        return state.lineData;
    },
};

export default {
    state,
    mutations,
    actions,
    getters
};
