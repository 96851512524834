// import { platformFeatures } from '@/api/api';
import { platformFeaturesUpdate } from '@/api/api';

// platformFeaturesUpdate
const state = {
  features: [],
};

const getters = {
  allFeatures: (state) => state.features,
};

const mutations = {
  setFeatures: (state, features) => {
    state.features = features;
  },
};

const actions = {
  fetchFeatures: async ({ commit }, planCode) => {
    try {
      // const response = await platformFeatures({ PlanCode: planCode });
      const response = await platformFeaturesUpdate({ PlanCode: planCode });
      if (response.status === 200) {
        commit('setFeatures', response.data);
        return response;
      }
    } catch (error) {
      console.error(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};