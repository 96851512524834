<template>
  <div class="page-content">
    <h3 class="mb-2 mb-md-0">Candidate Details</h3>
    <div class="my-5">
      <div class="form-group mb-4">
        <div class="row">
          <div class="col-12 col-lg-4 col-md-12">
            <label class="input-hero">
              <input type="text" class="form-control" id="candidateValue" v-model="searchCandidateData"
                placeholder=" Enter Candidates Name, Email, or Mobile Number" @keyup.enter="GetData()"
                @keydown="preventSpace" />
            </label>
          </div>
          <div class="col-12 col-lg-1 col-md-12">
            <button class=" test_instance_button btn btn-primary" type="button" @click="GetData()">
              <i class=" mx-1 fa-solid fa-magnifying-glass " style="color: #ffffff;"></i>
              Find
            </button>
          </div>
        </div>
      </div>
      <div v-for="item in CandidateDetails" :key="item.candidateId">
        <div class="accordion-item border-radius-kj">
          <h2 class="accordion-header" :id="'heading' + item.candidateId">
            <button class="accordion-button text-dark accordion-color-kj" type="button" data-bs-toggle="collapse"
              :data-bs-target="'#collapse' + item.candidateId" aria-expanded="true"
              :aria-controls="'collapse' + item.candidateId">
              <div class="container">
                <div class="row">
                  <h4>
                    {{ item.firstName }} {{ item.lastName }}
                    <!-- <img src="../../public/dashboard-assets/images/M2/Actions.png" alt="" /> -->
                  </h4>
                </div>
                <div class="accordion-button-p-kj">

                  <div class="row my-2">
                    <div class="col-12 col-md-4 col-lg-3 col-sm-12"> Candidate Unique Id: {{ item.candidateUniqueId }}
                    </div>

                    <div class=" col-12 col-md-4 col-lg-3 col-sm-12">
                      <span>Test Id: {{ item.testId }}</span>

                    </div>
                    <div class="col-12 col-md-4 col-lg-3 col-sm-12">
                      <span>Partner Id: {{ item.client_Id }}</span>
                    </div>
                    <div class="col-12 col-md-4 col-lg-3 col-sm-12" v-if="this.superadmin()">
                      <span>Candidate Id: {{ item.candidateId }}</span>
                    </div>
                  </div>
                </div>

              </div>

              <!-- {{ item.candidateUniqueId }} -->

            </button>
          </h2>
          <div :id="'collapse' + item.candidateId" class="accordion-collapse collapse"
            :aria-labelledby="'heading' + item.candidateId">
            <div class="accordion-body p-0 managementPartner-Acc-p-kj">
              <div class="container">
                <div class="row">
                  <div class="col-12 col-md-6 col-lg-3 col-sm-12">
                    <div class="p-3 ">
                      <h5><b>Email Address:</b></h5>
                      {{ item.emailAddress }}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-3 col-sm-12">
                    <div class="p-3 ">
                      <h5><b>Invite From:</b></h5>
                      {{ item.inviteFrom }}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-3 col-sm-12">
                    <div class="p-3 ">
                      <h5><b>Exam Start Time: </b></h5>
                      {{ dateConvert(item.examStartTime) }}
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-lg-3 col-sm-12">
                    <!-- <div class="p-3 ">
                      <h5><b>Status:</b></h5>
                      <span v-if="item.isCompleted == true">
                        <i class="fa-solid fa-square-check fa-lg" style="color: #048848;"></i>
                      </span>
                      <span v-else>
                        <i class="fa-solid fa-square-xmark fa-lg" style="color: #e11900;"></i>
                      </span>
                    </div> -->
                    <div class="p-3">
                      <h5><b>Result:</b></h5>


                      <div style="margin-left: -1%; margin-top: 1%;">
                        <SwitchCase :expected="item.candidateStatus" :result="0">
                          <template v-slot="{ current }">
                            <span class="View-Candidates-clock" v-if="current">Pending <i
                                class="fa-solid fa-clock-rotate-left"></i></span>
                          </template>
                        </SwitchCase>
                        <SwitchCase :expected="item.candidateStatus" :result="1">
                          <template v-slot="{ current }">
                            <span class="View-Candidates-progress" v-if="current">InProgress <i
                                class="fa-solid fa-stopwatch me-2"></i></span>
                          </template>
                        </SwitchCase>
                        <SwitchCase :expected="item.candidateStatus" :result="2">
                          <template v-slot="{ current }">
                            <span class="View-Candidates-review" v-if="current">Review <i
                                class="fa-regular fa-clock me-2"></i></span>
                          </template>
                        </SwitchCase>
                        <SwitchCase :expected="item.candidateStatus" :result="3">
                          <template v-slot="{ current }">
                            <span class="View-Candidates-pass" v-if="current">Pass <i
                                class="fa-solid fa-check me-2"></i></span>
                          </template>
                        </SwitchCase>
                        <SwitchCase :expected="item.candidateStatus" :result="4">
                          <template v-slot="{ current }">
                            <span class="View-Candidates-xmark" v-if="current">Fail <i
                                class="fa-solid fa-xmark me-2"></i></span>
                          </template>
                        </SwitchCase>
                      </div>


                    </div>

                  </div>

                </div>
                <div class="row">
                  <div class="col-12 col-md-6 col-lg-3 col-sm-12">
                    <div class="p-3">
                      <h5><b>Mobile No.</b></h5> {{ item.mobileNo }}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-3 col-sm-12">
                    <div class="p-3">
                      <h5><b>Created On: </b></h5>{{ dateConvert(item.createdOn) }}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-3 col-sm-12">
                    <div class="p-3">
                      <h5><b>Exam End Time:</b></h5>{{ dateConvert(item.examEndTime) }}
                    </div>
                  </div>

                </div>
                <div class="row">
                  <div class="col-12 col-md-6 col-lg-3 col-sm-12">
                    <div>
                      <router-link :to="{
                        name: 'ActiveTestViewCandidatesNew',
                        params: {
                          testId: encode(item.testId),
                          clientId: encode(item.client_Id),
                          isParent: encode(1), //update
                        },
                        query: {
                          search: encode(item.testId),
                          offset: encode(1),
                          current: encode(1),
                          email: encode(item.emailAddress)
                        },
                      }" target="_blank">
                        <button class="mx-2 my-3 test_instance_button btn btn-primary" type="button">
                          View
                        </button>
                      </router-link>
                      <button class="mx-2 my-3 test_instance_button btn btn-primary" type="button"
                        @click="UpdateData(item.candidateId)" v-if="this.superadmin()">
                        Update Result
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <paginate v-model="currentPage" :page-count="Page_count" :margin-pages="1" :page-range="1"
        :click-handler="clickCallback" :prev-text="'< Prev'" :next-text="'Next >'" :container-class="'pagination'"
        :page-class="'page-item'"></paginate>
    </div>
    <div class="text-center" v-if="CandidateDetails == false">
      <img src="../../public/dashboard-assets/images/M2/empty_record.png" style="width: 20%" class="my-5" />
      <h3 class="ms-5 mt-2">No Candidate Details Found</h3>
    </div>
  </div>
</template>

<script>
import { superadmin } from "../utils/helper";
import Paginate from "vuejs-paginate-next";
import { mapState, mapActions, mapMutations } from "vuex";
import moment from "moment";
import SwitchCase from './SwitchCase.vue';

export default {
  name: "CandidateDetails",
  components: {
    paginate: Paginate,
    SwitchCase
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      clientId: (state) => state.auth.user.clientId,
    }),
  },

  data() {
    return {
      searchCandidateData: "",
      CandidateDetails: [],
      Page_count: 1,
      Offset: 1,
      Limit: 10,
      currentPage: 1,
      filterCount: 1,
      type: ''
    };
  },

  methods: {
    ...mapActions(["GetCandidateDetails", "updateCandidateDetails"]),

    ...mapMutations(["setLoading"]),
    superadmin,
    dateConvert: function (value) {
      return moment(String(value)).format("DD/MM/YYYY HH:mm");
    },
    encode: function (item) {
      return window.btoa(item);
    },
    async UpdateData(id) {
      await this.updateCandidateDetails({
        candidateId: id
      });
    },


    async GetData(pageNum) {
      this.CandidateDetails = [];
      var value = await this.GetCandidateDetails({
        search: this.searchCandidateData === '' ? this.searchCandidateData : encodeURIComponent(this.searchCandidateData),
        offset: this.Offset,
        limit: this.Limit,
      });

      if (value.object.data.candidateDetails !== null) {
        this.CandidateDetails = value.object.data.candidateDetails;
      }

      this.Page_count = value
        ? Math.ceil(value.object.data.filterCount / this.Limit)
        : 0;
      this.currentPage = pageNum;
    },
    // To be implemented later
    // async candidateStatusDetails(status){

    //   switch(status){
    //     case 0:
    //     return (
    //         <span class="View-Candidates-clock">
    //           Pending <i class="fa-solid fa-clock-rotate-left"></i>
    //         </span>
    //       );

    //     case 1:
    //     return (
    //         <span class="View-Candidates-progress">
    //           InProgress <i class="fa-solid fa-stopwatch me-2"></i>
    //         </span>
    //       );

    //       case 2:
    //       return (
    //         <span class="View-Candidates-review">
    //           Review <i class="fa-regular fa-clock me-2"></i>
    //         </span>
    //       );
    //         var1 = "View-Candidates-review";
    //         var2 = "fa-regular fa-clock me-2";
    //     case 3:
    //       return (
    //         <span class="View-Candidates-pass">
    //           Pass <i class="fa-solid fa-check me-2"></i>
    //         </span>
    //       );
    //     case 4:
    //       return (
    //         <span class="View-Candidates-xmark">
    //           Failed <i class="fa-solid fa-xmark me-2"></i>
    //         </span>
    //       );
    //     default:
    //       return null; 

    //   }

    // },

    clickCallback: function (pageNum) {
      this.Offset = (pageNum - 1) * this.Limit + 1;
      this.GetData(pageNum);
    },
    preventSpace(event) {
      const input = event.target.value;
      if (event.key === ' ' && input.length === 0) {
        event.preventDefault();
      }
    },
  },
};
</script>

<style scoped>
.pagination {
  justify-content: flex-end;
}

.pagination .page-item.active .page-link {
  font-weight: 600;
  background: #ffc801;
}

.pagination .page-item:last-child .page-link {
  background-color: transparent;
  border: none;
  width: 100% !important;
}

.pagination .page-item:first-child .page-link {
  background-color: transparent;
  border: none;
  width: 100% !important;
}

.pagination .page-item.disabled .page-link {
  color: rgb(130, 130, 130) !important;
  cursor: no-drop !important;
}

.text-label {
  font-size: 100px;
}</style>
