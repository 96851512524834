<template>
    <div class="Overview_dashboard_map_section my-4">
        <div class="card p-4">
            <div class="card-body p-0">
                <div v-for="testObj in assessmentData" :key="testObj">
                    <TestWiseRegionConsumption :testData="testObj" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TestWiseRegionConsumption from './TestWiseRegionConsumption.vue';

export default {
    name: 'TestWiseRegionSummary',
    components: {
        TestWiseRegionConsumption
    },
    props: {
        assessmentData: Array
    }
};
</script>