<template>
  <section class="container-fluid login_page_section">
    <div class="row">
      <div class="col-md-6 login_left_section">
        <img src="../assets/Images/forgot_password_top.png" alt="" class="forgot_password_top_img" />
        <div class="text-center">
          <img src="../assets/Images/code.png" alt="" width="55%" />
        </div>
        <img src="../assets/Images/forgot_password_bottom.png" alt="" class="forgot_password_bottom_img" />
      </div>

      <div class="col-md-6 login_right_section">
        <div class="logo_section">
          <img src="../assets/Images/logo.png" alt="" width="120" />
        </div>
        <div style="clear: both"></div>
        <div class="row justify-content-center">
          <div class="col-xl-8 col-lg-10 col-md-11">
            <form ref="form" id="otpform" class="otpCode-form-section">
              <div class="login_page_heading">
                <h3 class="font-weight-800">Enter the code</h3>
                <p class="text-muted font-size-17">
                  Please confirm your account by entering the verification code
                  sent to your email ID: {{ userEmailAddress }}
                </p>
              </div>
              <div class="my-5">
                <div class="text-muted">
                  Request new token in <span id="timer">{{ Timer }}</span>
                </div>
                <div class="navbar navbar_otp_area_hp">
                  <input @change="handlePaste($event)" type="number" name="otp1" @input="tabChange(1)"
                    class="otpCode-input" />

                  <input type="number" name="otp2" class="otpCode-input" @change="handlePaste($event)"
                    @input="tabChange(2)" />
                  <input type="number" name="otp3" class="otpCode-input" @change="handlePaste($event)"
                    @input="tabChange(3)" />
                  <input type="number" name="otp4" class="otpCode-input" @change="handlePaste($event)"
                    @input="tabChange(4)" />
                  <input type="number" name="otp5" class="otpCode-input" @change="handlePaste($event)"
                    @input="tabChange(5)" />
                  <input type="number" name="otp6" class="otpCode-input" @change="handlePaste($event)"
                    @input="tabChange(6)" />
                </div>
              </div>
              <p class="text-muted font-size-14">
                It may take a minute to receive your code. <br />
                Have not received it?

                <a :class="timerclass" @click="resendOTP" class=" text-decoration-none">Resend a new code</a>
              </p>

              <div class="login_button_area mt-4">
                <!-- <button class="btn btn-outline-primary me-2 mb-3" type="submit">
                    Cancel
                  </button> -->
                <button class="btn btn-primary mb-3" type="button" @click="submit">
                  Confirm
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions } from "vuex";
import { getAuthUserData } from "@/utils/auth";
import {
  setOtpAttempts,
  getOtpAttempts,
  removeOtpAttempts,
} from "../utils/auth";
import { encryptData } from '@/platformFeatures/security'

export default {
  data() {
    return {
      Timer: "03:00",
      timerclass: 'disabled-link',
      TimeVar: '',
      userId: 0,
      userEmailAddress: '',
      vToken:''
    }
  },
  created() {
    this.userId = atob(this.$route.params.userId);
    this.vToken = this.$route.params.token;
    this.userEmailAddress = atob(this.$route.params.emailAddress);
  },
  mounted() {
    this.TimeVar = setTimeout(this.startTimerM, 1000);
  },
  methods: {
    ...mapActions(["OtpCode","OtpCodeJourney", "GetAccessToken", "GetBillingDetails", "VerifyNew2faToken"]),
    handlePaste(ev) {
      if (ev.target.value) {
        var el = document.querySelectorAll('.otpCode-input');
        var otp = [...ev.target.value];
        if (otp.length > 1) el.forEach((element, i) => {
          this.tabChange(i + 1);
          element.value = otp[i];
        });
      }
    },
    tabChange(val) {
      let ele = document.querySelectorAll("input");
      if (ele[val - 1].value != "") {
        if (ele[val]) {
          ele[val].focus();
        }
        if (val == 6) ele[0].focus();
        this.otpCode += ele[val - 1].value;
      } else if (ele[val - 1].value == "") {
        if (ele[val - 2]) ele[val - 2].focus();
      }
    },
    async submit() {
      this.otpCode =
        this.$refs.form.otp1.value +
        this.$refs.form.otp2.value +
        this.$refs.form.otp3.value +
        this.$refs.form.otp4.value +
        this.$refs.form.otp5.value +
        this.$refs.form.otp6.value;
      if (this.otpCode === "" || this.otpCode.length < 6) {
        return this.ShowWarningMessage("Please enter otp.");
      }
      this.isLoading = true;

      const model = {
          type: "Email",
          token: this.otpCode,
          vToken: this.vToken
      };
      var response = await this.VerifyNew2faToken(model);
      const attempt = getOtpAttempts();
      if (!response.status) {
        if (attempt < 5) {
          // this.timerclass = "text-primary";
        } else {
          removeOtpAttempts();
        }
      } else {
        clearTimeout(this.TimeVar);
        var userObj = getAuthUserData();
        var accessToken = await this.GetAccessToken({ userId: this.userId });
        if (accessToken.status) {
          if(userObj.passwordSet == false){
            this.$router.push("/set-password");
          }
          else if (userObj.isEnterprise == false) {
            var data = await this.GetBillingDetails({
              clientId: userObj.clientId
            })
            localStorage.setItem("billingData", JSON.stringify(data));

            this.planCode = data.object.data.plan.planCode;
            localStorage.setItem("plan", this.planCode);

            if (this.planCode != '') {
              const secretKey = userObj.extId;
              const data = await this.$store.dispatch('fetchFeatures', this.planCode);
              const plandata = data.data.object.data;
              if (data.status === 200) {
                const encryptedData = encryptData(plandata, secretKey);
                localStorage.setItem("featuresData", encryptedData);
              }
            }
            this.$router.push("/dashboard-group");
          }
        }
      }
    },
    startTimerM() {
      var presentTime = this.Timer;
      var timeArray = presentTime.split(/[:]+/);
      var m = timeArray[0];
      var s = this.checkSecond(timeArray[1] - 1);
      if (s == 59) {
        m = m - 1;
      }
      if ((m + "").length == 1) {
        m = "0" + m;
      }
      if (m < 0) {
        clearTimeout(this.TimeVar);
        this.timerclass = "text-primary";

        // return this.ShowWarningMessage(
        //   "Time has been expired, do you want to resend OTP?"
        // );
      } else {
        this.Timer = m + ":" + s;
        this.TimeVar = setTimeout(this.startTimerM, 1000);
      }
    },
    checkSecond(sec) {
      if (sec < 10 && sec >= 0) {
        sec = "0" + sec;
      } // add zero in front of numbers < 10
      if (sec < 0) {
        sec = "59";
      }
      return sec;
    },
    async resendOTP(e) {
      clearTimeout(this.TimeVar);
      this.Timer = "03:00";
      this.timerclass = "disabled-link";
      await this.OtpCodeJourney({ type: "Email", token: this.vToken });
      this.TimeVar = setTimeout(this.startTimerM, 1000);
      let ele = document.querySelectorAll("input");
      for (var i = 0; i < ele.length; i++) {
        ele[i].value = "";
      }
      this.otpCode = "";
      setOtpAttempts(1);
      e.preventDefault();
    }
  }
}
</script>

<style>
.disabled-link {
  pointer-events: none;
  cursor: default;
  color: #c1c1c1 !important;
  text-decoration: underline !important;
  text-decoration-color: #c1c1c1 !important;
}
</style>
