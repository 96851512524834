<template>
    <div class="Overview_dashboard_map_section my-4">
        <div class="card p-2">
            <div class="card-body p-0" id="attempt-analysis-chart">
                <h3 style="font-weight: 600;" class="mb-5 mt-3 mx-3">Attempt Analysis - {{ completionTypeLabel }} Completion
                    <!-- <img src="../../../../public/dashboard-assets/images/M7/Actions.png" width="25"
                        class="cursor-pointer ms-2" alt="" data-bs-toggle="tooltip" data-bs-placement="top"
                        title=" Analyzing the frequency of assessment usage over time provides valuable insights into the number of candidates assessed during defined intervals." /> -->
                    <span class="float-end">
                        <div class="dropdown">
                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <span class="mx-2">{{ displayItem }}</span> <i class="fa-solid fa-caret-down fa-lg"
                                    style="color:#333333;"></i>
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><a class="dropdown-item" href="#" @click.prevent="selectItem('avgCompletion')">Avg Completion</a></li>
                                <li><a class="dropdown-item" href="#" @click.prevent="selectItem('maxCompletion')">Max Completion</a></li>
                                <li><a class="dropdown-item" href="#" @click.prevent="selectItem('minCompletion')">Min Completion</a></li>
                            </ul>
                        </div>
                    </span>
                </h3>

                <div class="table-responsive mb-4" style=" width: 100%; height: 250px;">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Region Name</th>
                                <th>Overall {{ completionTypeLabel }} Completion</th>
                                <th v-for="section in sections" :key="section">{{ section }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="region in transformedData" :key="region.regionName">
                                <td>{{ region.regionName }}</td>
                                <td>{{ region.overallCompletion.toFixed(2) }}</td>
                                <td v-for="section in sections" :key="section">{{
                                    region.sectionCompletion[section]?.toFixed(2) ?? '-' }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>
</template>
  <script>
  export default {
    data() {
      return {
        selectedCompletionType: 'avgCompletion',
        displayItem:'Avg Completion'
      };
    },
    props: {
        assessmentData: Array
    },
    computed: {
      transformedData() {
        const regions = this.assessmentData.tests[0].regions;
        return regions.map(region => {
          const overallCompletion = region.sections.reduce((sum, section) => sum + section[this.selectedCompletionType], 0) / region.sections.length;
          const sectionCompletion = {};
          region.sections.forEach(section => {
            sectionCompletion[section.sectionName] = section[this.selectedCompletionType];
          });
          return {
            regionName: region.regionName,
            overallCompletion,
            sectionCompletion
          };
        });
      },
      sections() {
        const regions = this.assessmentData.tests[0].regions;
        const sectionNames = new Set();
        regions.forEach(region => {
          region.sections.forEach(section => {
            sectionNames.add(section.sectionName);
          });
        });
        return Array.from(sectionNames);
      },

      completionTypeLabel() {
        switch (this.selectedCompletionType) {
          case 'avgCompletion':
            return 'Avg';
          case 'maxCompletion':
            return 'Max';
          case 'minCompletion':
            return 'Min';
          default:
            return '';
        }
      }
    },
    methods:{
        selectItem(item) {
            this.selectedCompletionType = item;

            if(item=='avgCompletion'){
              this.displayItem = 'Avg Completion';
            }
            else if(item=='maxCompletion'){
              this.displayItem = 'Max Completion';
            }
            else if(item=='minCompletion'){
              this.displayItem = 'Min Completion';
            }
            this.$emit('update-completion-type', this.selectedCompletionType);

        },
    },
  };
  </script>
  
  <style scoped>
.table-responsive thead th {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 1;
}
.table-header {
  text-align: center;
  margin-bottom: 10px;
}

.table-title {
  font-size: 18px;
  font-weight: bold;
}
.dropdown-toggle {
    background-color: white !important;
    border: 1px solid #cdcdcd !important;
    color: black !important;
    min-width: 120px;
    border-radius: 8px;
    z-index: 3;
}

.dropdown-menu {
    width: 100%;
    z-index: 3;

}

.dropdown-item {
    cursor: pointer;
    z-index: 3;

}
  </style>