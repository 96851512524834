import { createStore } from "vuex";
import AuthModule from "./modules/auth/index";
// import TeamsModule from "./modules/teams/index";
import PopupModule from "../utils/popup";
import LoaderModule from "../utils/index1";
import descriptivePdf  from './component/testwisepdf';


export default createStore({
    modules: {
        auth: AuthModule,
        // team: TeamsModule,
        loader: LoaderModule,
        popup: PopupModule,
        descriptivePdf 
    },
});