<template>
  <div class="Overview_dashboard_map_section my-4">
    <div class="card p-4">
      <div class="card-body p-0 mt-2">
        <div class="table-responsive">
          <h3 style="font-weight: 600;"> ASSESSMENT DESIGN SUMMARY
            <img src="../../../../public/dashboard-assets/images/M7/Actions.png" width="25" class="cursor-pointer ms-2"
              alt="" data-bs-toggle="tooltip" data-bs-placement="top" title=" Provides a list of assessments conducted, detailing the frequency of each assessment's usage and the
 percentage of candidates who successfu ly passed them, thereby providing insights into both the
 effectiveness of the assessments and the performance of candidates" />
          </h3>
          <table class="table table-hover table-bordered mt-4 assessment-usage-table">
            <thead style="color: #7B68EE">
              <tr>
                <th>TestId</th>
                <th>Test Design</th>
                <th>Tokens Used</th>
                <th>Overall Fit(%)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="testObj in assessmentData" :key="testObj">
                <td> {{ testObj.testId }} </td>
                <td> {{ testObj.testName }} </td>
                <td> {{ testObj.assessmentUsed }} </td>
                <td> {{ testObj.overallPassPercent === null ? 'NA' : testObj.overallPassPercent + '%' }} </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { Tooltip } from "bootstrap/dist/js/bootstrap.esm.min.js";

export default {
  name: 'TestSummary',
  props: {
    assessmentData: Array
  },
  mounted() {
    Array.from(
      document.querySelectorAll('img[data-bs-toggle="tooltip"]')
    ).forEach((tooltipNode) => new Tooltip(tooltipNode));
  }
}
</script>

<style scoped>
.assessment-usage-table th {
  background-color: #F2F0FF;
}

.assessment-usage-table th,
.assessment-usage-table td {
  border: 3px solid #F2F0FF;
  padding: 8px;
}
</style>
