<template>
  <div></div>
</template>
<script>
import Shepherd from "shepherd.js";
import { mapState } from "vuex";

export default {
  name: "App",
  computed: {
      ...mapState({
          user: (state) => state.auth.user,
      }),
  },
  data() {
      return {
          dashboardGroupTourSteps: [
          {
                  text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Tour Overview<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>The Overview header displays the date range of the data currently shown, which by default covers the past 6 months.</div>",
                  attachTo: {
                      element: "#tour-overview",
                      on: "right"
                  },
                  buttons: [
                      {
                          action: this.tourCancel,
                          classes: "shepherd-button-third",
                          text: "Skip"
                      },
                      // {
                      //   action: this.tourGoBack,
                      //   text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                      // },
                      {
                          action: this.tourGoNext,
                          text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                      }
                  ]
              },
              {
                  text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Calender<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>Shows the past 30 days by default. Use it to select or change the date range, customizing your data view as needed.</div>",
                  attachTo: {
                      element: "#tour-dateTime",
                      on: "bottom"
                  },
                  buttons: [
                      {
                          action: this.tourCancel,
                          classes: "shepherd-button-third",
                          text: "Skip"
                      },
                      {
                          action: this.tourGoBack,
                          text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                      },
                      {
                          action: this.tourGoNext,
                          text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                      }
                  ]
              },
              {
                  text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Group / Partner Dropdown<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>Quickly switch between different groups or partners to view specific data and test activities relevant to each.</div>",
                  attachTo: {
                      element: "#tour-dropdown",
                      on: "top"
                  },
                  buttons: [
                      {
                          action: this.tourCancel,
                          classes: "shepherd-button-third",
                          text: "Skip"
                      },
                      {
                          action: this.tourGoBack,
                          text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                      },
                      {
                          action: this.tourGoNext,
                          text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                      }
                  ]
              },
              {
                  text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Group Card<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>Shows the number of groups. Gives an overview of all the groups involved in the test activities.</div>",
                  attachTo: {
                      element: "#tour-group-card",
                      on: "bottom"
                  },
                  buttons: [
                      {
                          action: this.tourCancel,
                          classes: "shepherd-button-third",
                          text: "Skip"
                      },
                      {
                          action: this.tourGoBack,
                          text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                      },
                      {
                          action: this.OpenModalNext,
                          text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                      }
                  ]
              },
              {
                  text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>User Card<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Displays the total number of users. Provides a quick count of all the users engaged in the testing process.</div>",
                  attachTo: {
                      element: "#tour-user-card",
                      on: "bottom"
                  },
                  
                  buttons: [
                      {
                          action: this.tourCancel,
                          classes: "shepherd-button-third",
                          text: "Skip"
                      },
                      {
                          action: this.tourGoBack,
                          text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                      },
                      {
                          action: this.tourGoNext,
                          text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                      }
                  ]
              },
              {
                  text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Token Card<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Indicates the number of tokens available. Shows the remaining tokens for allocating and scheduling tests.</div>",
                  attachTo: {
                      element: "#tour-token-card",
                      on: "bottom"
                  },
                  
                  buttons: [
                      {
                          action: this.tourCancel,
                          classes: "shepherd-button-third",
                          text: "Skip"
                      },
                      {
                          action: this.tourGoBack,
                          text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                      },
                      {
                          action: this.tourGoNext,
                          text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                      }
                  ]
              },
              {
                  text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Test Activity<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Displays the total number of tests used in the past 6 months. The bar chart shows monthwise candidate attempt.</div>",
                  attachTo: {
                      element: "#tour-textActivity",
                      on: "bottom"
                  },
                  
                  buttons: [
                      {
                          action: this.tourCancel,
                          classes: "shepherd-button-third",
                          text: "Skip"
                      },
                      {
                          action: this.tourGoBack,
                          text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                      },
                      {
                          action: this.tourGoNext,
                          text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                      }
                  ]
              },
              {
                  text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Test Allocation & Scheduled<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>The center of the chart displays the total number of tests distributed across all selected partners & Displays the total number of tests scheduled, along with a breakdown of their status - Passed, Failed, or are Still in progress.</div>",
                  attachTo: {
                      element: ".tour-allocation-scheduled",
                      on: "bottom"
                  },
                  
                  buttons: [
                      {
                          action: this.tourCancel,
                          classes: "shepherd-button-third",
                          text: "Skip"
                      },
                      {
                          action: this.tourGoBack,
                          text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                      },
                      {
                        action: this.tourComplete,
                          text: "Finish <i class='fa-solid fa-thumbs-up fa-lg mx-2'></i>"
                      }
                  ]
              },
          ],

          dashboardGroupResponsiveTourSteps: [
          {
                  text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Tour Overview<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>The Overview header displays the date range of the data currently shown, which by default covers the past 6 months.</div>",
                  attachTo: {
                      element: "#tour-overview",
                      on: "bottom"
                  },
                  buttons: [
                      {
                          action: this.tourCancel,
                          classes: "shepherd-button-third",
                          text: "Skip"
                      },
                      // {
                      //   action: this.tourGoBack,
                      //   text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                      // },
                      {
                          action: this.tourGoNext,
                          text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                      }
                  ]
              },
              {
                  text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Calender<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Shows the past 30 days by default. Use it to select or change the date range, customizing your data view as needed.</div>",
                  attachTo: {
                      element: "#tour-dateTime",
                      on: "bottom"
                  },
                  buttons: [
                      {
                          action: this.tourCancel,
                          classes: "shepherd-button-third",
                          text: "Skip"
                      },
                      {
                          action: this.tourGoBack,
                          text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                      },
                      {
                          action: this.tourGoNext,
                          text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                      }
                  ]
              },
              {
                  text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Group / Partner Dropdown<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%; font-size:14px ; padding-left:2% ;padding-right:2%'>Quickly switch between different groups or partners to view specific data and test activities relevant to each.</div>",
                  attachTo: {
                      element: "#tour-dropdown",
                      on: "top"
                  },
                  buttons: [
                      {
                          action: this.tourCancel,
                          classes: "shepherd-button-third",
                          text: "Skip"
                      },
                      {
                          action: this.tourGoBack,
                          text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                      },
                      {
                          action: this.tourGoNext,
                          text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                      }
                  ]
              },
              {
                  text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Group Card<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Shows the number of groups. Gives an overview of all the groups involved in the test activities.</div>",
                  attachTo: {
                      element: "#tour-group-card",
                      on: "bottom"
                  },
                  buttons: [
                      {
                          action: this.tourCancel,
                          classes: "shepherd-button-third",
                          text: "Skip"
                      },
                      {
                          action: this.tourGoBack,
                          text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                      },
                      {
                          action: this.OpenModalNext,
                          text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                      }
                  ]
              },
              {
                  text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>User Card<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Displays the total number of users. Provides a quick count of all the users engaged in the testing process.</div>",
                  attachTo: {
                      element: "#tour-user-card",
                      on: "bottom"
                  },
                  
                  buttons: [
                      {
                          action: this.tourCancel,
                          classes: "shepherd-button-third",
                          text: "Skip"
                      },
                      {
                          action: this.tourGoBack,
                          text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                      },
                      {
                          action: this.tourGoNext,
                          text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                      }
                  ]
              },
              {
                  text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Token Card<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Indicates the number of tokens available. Shows the remaining tokens for allocating and scheduling tests.</div>",
                  attachTo: {
                      element: "#tour-token-card",
                      on: "bottom"
                  },
                  
                  buttons: [
                      {
                          action: this.tourCancel,
                          classes: "shepherd-button-third",
                          text: "Skip"
                      },
                      {
                          action: this.tourGoBack,
                          text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                      },
                      {
                          action: this.tourGoNext,
                          text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                      }
                  ]
              },
              {
                  text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Test Activity<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Displays the total number of tests used in the past 6 months. The bar chart shows monthwise candidate attempt.</div>",
                  attachTo: {
                      element: "#tour-textActivity",
                      on: "bottom"
                  },
                  
                  buttons: [
                      {
                          action: this.tourCancel,
                          classes: "shepherd-button-third",
                          text: "Skip"
                      },
                      {
                          action: this.tourGoBack,
                          text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                      },
                      {
                          action: this.tourGoNext,
                          text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                      }
                  ]
              },
              {
                  text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Test Allocation & Scheduled<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>The center of the chart displays the total number of tests distributed across all selected partners & Displays the total number of tests scheduled, along with a breakdown of their status - Passed, Failed, or are Still in progress.</div>",
                  attachTo: {
                      element: ".tour-allocation-scheduled",
                      on: "bottom"
                  },
                  
                  buttons: [
                      {
                          action: this.tourCancel,
                          classes: "shepherd-button-third",
                          text: "Skip"
                      },
                      {
                          action: this.tourGoBack,
                          text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                      },
                      {
                        action: this.tourComplete,
                          text: "Finish <i class='fa-solid fa-thumbs-up fa-lg mx-2'></i>"
                      }
                  ]
              },
          ],

      };
  },
  props: {
      page: String,
      tourStatus: String,
      shareModalStatus: Boolean
  },
  mounted() {
      const windowWidth = window.innerWidth;
      if (this.page === 'DashboardGroup') {
          this.DashboardGroupTour = new Shepherd.Tour({
              useModalOverlay: true,
              tourName: "DashboardGroupTour",
              defaultStepOptions: {
                  scrollTo: {
                      behavior: "smooth",
                      block: "center"
                  }
              }
          });

          if (windowWidth < 999) {
              this.dashboardGroupResponsiveTourSteps.forEach(step => {
                  this.DashboardGroupTour.addStep(step);
              });
          }
          else {
              this.dashboardGroupTourSteps.forEach(step => {
                  this.DashboardGroupTour.addStep(step);
              });

          }
          this.DashboardGroupTour.start();
      }

  },
  methods: {
      // ...mapActions(["AllActiveTest"]),
      tourGoNext() {
          if (this.page === 'DashboardGroup') {
              this.DashboardGroupTour.next();
          }
      },
      tourGoBack() {
          if (this.page === 'DashboardGroup') {
              this.DashboardGroupTour.back();
          }
      },
      tourCancel() {
          if (this.page === 'DashboardGroup') {
              this.$emit('tour-close');
              localStorage.setItem("isNewUser", false);
              this.DashboardGroupTour.cancel();
          }
      },
      tourComplete() {
          if (this.page === 'DashboardGroup') {
              localStorage.setItem("isNewUser", false);
              this.$emit('share-modal-close');
              this.$emit('tour-close');
              this.DashboardGroupTour.complete();
          }
      },
      OpenModalNext() {
          this.$emit('share-modal-open');
          this.DashboardGroupTour.next();
      },
      OpenModalBack() {
          this.$emit('share-modal-open');
          this.DashboardGroupTour.back();
      },
      CloseModalBack() {
          this.$emit('share-modal-close');
          this.DashboardGroupTour.back();
      },
      CloseModalNext() {
          this.$emit('share-modal-close');
          this.$emit('dropdown-open');

          this.DashboardGroupTour.next();
      },
  }
};
</script>
