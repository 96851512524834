<template>
    <div class="Overview_dashboard_map_section my-4">
        <div class="card p-4">
            <div class="card-body p-0" id="time-analysis-chart">
                    <h3 style="font-weight: 600;" class="mb-5">Test Time Spent Analysis {{ selectedItem =='By Sections' ? ' By Sections': ' By Regions' }} 

                    <!-- <img src="../../../../public/dashboard-assets/images/M7/Actions.png" width="25"
                        class="cursor-pointer ms-2" alt="" data-bs-toggle="tooltip" data-bs-placement="top" title=" Analyzing the frequency of assessment usage over time provides valuable insights into the number of
                                    candidates assessed during defined intervals." /> -->

                    <span class="float-end">

                        <div class="dropdown">
                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <span class="mx-2">{{ selectedItem }}</span> <i
                                    class="fa-solid fa-caret-down fa-lg" style="color:#333333;"></i>
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><a class="dropdown-item" href="#" @click.prevent="selectItem('By Sections')">By
                                    Sections</a></li>
                                <li><a class="dropdown-item" href="#" @click.prevent="selectItem('By Regions')">By
                                        Region</a></li>
                               
                            </ul>
                        </div>
                    </span>
                </h3>
                <div class="stacked-column-chart" >
                    <div ref="chartContainer"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Highcharts from 'highcharts';
// import { Tooltip } from "bootstrap/dist/js/bootstrap.esm.min.js";

export default {
    name: 'LineChartTestWise',
    data() {
        return {
            selectedItem: 'By Sections'
        }
    },
    props: {
        assessmentData: Array
    },
    methods: {
        selectItem(item) {
            this.selectedItem = item;
        },
        createChart() {
            var regions = {};
            var data = this.assessmentData;
            var categories = [];
            var overAllTimeSpendData = [];
            var passTimeSpendData = [];
            var failTimeSpendData = [];
            var sections = {};
            var title = ''; 
            if (this.selectedItem == 'By Regions') {
                data.tests.forEach(test => {
                    test.regions.forEach(region => {
                        if (!regions[region.regionName]) {
                            regions[region.regionName] = {
                                overAllTimeSpend: 0,
                                passTimeSpend: 0,
                                failTimeSpend: 0,
                                count: 0
                            };
                        }

                        region.sections.forEach(section => {
                            regions[region.regionName].overAllTimeSpend += section.overAllTimeSpend;
                            regions[region.regionName].passTimeSpend += section.passTimeSpend;
                            regions[region.regionName].failTimeSpend += section.failTimeSpend;
                            regions[region.regionName].count += 1;
                        });
                    });
                });
                categories = Object.keys(regions);

                categories.forEach(regionName => {
                    const region = regions[regionName];
                    overAllTimeSpendData.push(region.overAllTimeSpend);
                    passTimeSpendData.push(region.passTimeSpend);
                    failTimeSpendData.push(region.failTimeSpend);
                });

            title="Regions";
            }

            if (this.selectedItem == 'By Sections') {
                data.tests.forEach(test => {
                    test.regions.forEach(region => {
                        region.sections.forEach(section => {
                            if (!sections[section.sectionName]) {
                                sections[section.sectionName] = {
                                    overAllTimeSpend: 0,
                                    passTimeSpend: 0,
                                    failTimeSpend: 0,
                                    count: 0
                                };
                            }
                            sections[section.sectionName].overAllTimeSpend += section.overAllTimeSpend;
                            sections[section.sectionName].passTimeSpend += section.passTimeSpend;
                            sections[section.sectionName].failTimeSpend += section.failTimeSpend;
                            sections[section.sectionName].count += 1;
                        });
                    });
                });

                categories = Object.keys(sections);

                categories.forEach(sectionName => {
                    const section = sections[sectionName];
                    overAllTimeSpendData.push(section.overAllTimeSpend );
                    passTimeSpendData.push(section.passTimeSpend );
                    failTimeSpendData.push(section.failTimeSpend );
                });
                title="Sections";

            }

   

            Highcharts.chart(this.$refs.chartContainer, {
                chart: {
                    type: 'line',
                },
                title: {
                    text: 'Time Spent by Region',
                    style: {
                        fontWeight: "600",
                        display: 'none',
                    }
                },
                xAxis: {
                    categories,
                    title: {
                        text: title,
                        style: {
                        fontWeight: "600",
                    }
                        
                    },
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'Avg Time Spent (mins)',
                        style: {
                        fontWeight: "600",
                    }
                    },
                },
                legend: {
                    align: 'center',
                    verticalAlign: 'bottom',
                    backgroundColor: '#FFFFFF',
                    shadow: false
                },
                credits: {
                    enabled: false
                },
                tooltip: {
                    headerFormat: '<b>{point.x}</b><br/>',
                    pointFormat: '{series.name}: {point.y} mins <br/> '
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: true
                        }
                    }
                },
                series: [
                    {
                        name: 'Overall',
                        data: overAllTimeSpendData,
                        color: '#F8B200'
                    },
                    {
                        name: 'Pass Candidates',
                        data: passTimeSpendData,
                        color: '#7C68EE'
                    },
                    {
                        name: 'Fail Candidates',
                        data: failTimeSpendData,
                        color: '#FF90C0'
                    }
                ]
            });

            this.$emit('update-type', this.selectedItem);

            // Array.from(
            //     document.querySelectorAll('img[data-bs-toggle="tooltip"]')
            // ).forEach((tooltipNode) => new Tooltip(tooltipNode));
        }
    },
    watch: {
        selectedItem() {
            this.createChart();
        }
    },
    async mounted() {
        this.createChart();
    }
};
</script>

<style scoped>
.stacked-column-chart {
    width: 100%;
    height: 400px;
}

.dropdown-toggle {
    background-color: white !important;
    border: 1px solid #cdcdcd !important;
    color: black !important;
    min-width: 120px;
    border-radius: 8px;
}

.dropdown-menu {
    width: 100%;
}

.dropdown-item {
    cursor: pointer;
}
</style>
