<template>
  <div class="page-content">
    <div class="Searching_input_box_mobile_view mb-3 position-relative"    v-if="this.Remainingdays>=0" >
      <div class="w-100" v-if="this.show">
        <input
          type="text"
          v-model="input"
          @keyup.enter="filteredList()"
          :disabled="searchDisable"
          placeholder="Search..."
        />
        <img
          src="../../public/dashboard-assets/images/M2/Close_icon.png"
          @click="closemobilesearch"
          alt=""
          class="close-icon"
        />
      </div>
    </div>
    <!-- header -->
    <div class="d-flex justify-content-between align-items-center flex-wrap mb-2">
      <div class="my-2 d-flex align-items-center">
        <div class="top_heading_CreatePartner_btn d-block me-2">
          <div class="Group_top_three_dot">
            <div class="dropdown" v-if="this.companyName">
              <router-link :to="{
                name: 'ActiveTests',
                query: {
                  id: this.routesearch ? encode(this.routesearch) : '',
                  offset: this.routeoffset ? encode(this.routeoffset) : '',
                  current: this.routecurrent ? encode(this.routecurrent) : '',
                  filter:true,
                },
              }" class="p-0" type="button">
                <img src="../../public/dashboard-assets/images/M2/BackButton.png" />
              </router-link>
            </div>
          </div>
        </div>
        <div>
          <h5 class="mb-1 font-size-MV" v-if="this.screenWidth <= 1441 &&
            this.screenWidth > 767 &&
            this.companyName
            ">
            {{
              companyName.substring(
                0,
                companyName.lastIndexOf(companyName.substring(35))
              ) + "..."
            }}
          </h5>
          <h5 class="mb-1 font-size-MV" v-else-if="this.screenWidth <= 767 && this.companyName">
            {{
              companyName.substring(
                0,
                companyName.lastIndexOf(companyName.substring(10))
              ) + "..."
            }}
          </h5>
          <h4 class="mb-1 font-size-MV" v-else>
            {{ companyName }}
          </h4>
          <h6 class="text-muted" v-if="this.candidateList.length != 0">
            Candidates ({{ totalCandidates }})
          </h6>
          <h6 class="text-muted" v-else>
            Candidates ({{ this.candidateList.length }})
          </h6>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center"    v-if="this.Remainingdays>=0" >
        <!-- Search -->
        <div class="top_heading_CreatePartner_btn position-relative z-index-999 me-2">
          <div class="Group_top_three_dot" >
            <div class="Searching_input-box">
              <input type="text" v-model="input" :disabled="searchDisable" @keydown.enter="filteredList()"
                placeholder="Search..." />
              <!--update @keyup="filteredList"-->
              <!-- <div class="search_detail_list" v-if="this.result.length > 0">
                <div
                  class="search_detail_list_item"
                  style="cursor: pointer"
                  v-for="(item, index) in this.result"
                  :key="index"
                  @click="searchregionname(item)"
                >
                  <span :id="index" @click="searchregionname(item)">
                    {{ stringTOHtml(item, index) }}
                  </span>
                </div>
              </div> -->
              <!--update-->

              <span class="icon Group_top_three_dot_dropdown" @click="opensearch">
                <img src="../../public/dashboard-assets/images/M2/Icon-Set.png" alt="" class="Searching_search_icon" />
              </span>
              <img src="../../public/dashboard-assets/images/M2/Close_icon.png" alt="" class="close-icon"
                @click="closesearch" />
            </div>
          </div>
        </div>

        
        <div class="
            top_heading_CreatePartner_btn
            position-relative
            Searching_button_mobileView mx-2">
            <div class="Group_top_three_dot">
              <span class="icon Group_top_three_dot_dropdown" :style="{ display: this.show == true ? 'none' : 'block' }"
                @click="openmobilesearch">
                <img src="../../public/dashboard-assets/images/M2/Icon-Set.png" alt="" class="Searching_search_icon" />
              </span>
            </div>
          </div>
        <div class="top_heading_CreatePartner_btn d-block">
          <div class="Group_top_three_dot">
            <div class="dropdown">
              <button class="btn p-0 Group_top_three_dot_dropdown" type="button">
                <img src="../../public/dashboard-assets/images/M2/date-icon.png" @click="opencal" data-bs-toggle="tooltip" data-bs-placement="top"  title="Filter by Date"/>
                <span class="cal-alert translate-middle" v-if="this.fromDate!=0">
                  <span class="visually-hidden"></span>
                </span> 
                <input type="text" name="daterange" hidden="true" id="calendar_icon" />
              </button>
            </div>
          </div>
        </div>
        <!-- Filter -->
        <div class="top_heading_CreatePartner_btn top_heading_CreatePartner_btn-kj d-block mx-2">
          <div class="Group_top_three_dot">
            <div class="dropdown">
              <button class="btn p-0 Group_top_three_dot_dropdown position-relative" type="button" id="filterDropdown"
                data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" aria-haspopup="true"
                style="line-height: 0.3">
                <img src="../../public/dashboard-assets/images/M2/Filter_icon.png" data-bs-toggle="tooltip" data-bs-placement="top"  title="Filter" />
                <span class="Noti-alert-kj translate-middle" v-if="this.applyfilter > 0">
                  {{ this.applyfilter }} <span class="visually-hidden"></span>
                </span>
              </button>

              <ul class="dropdown-menu dropdown-menu-kj" aria-labelledby="filterDropdown">
                <div class="modal-header">
                  <h4 class="modal-title text-centerm-auto" id="CreateGroupModalLabel">
                    Filter
                  </h4>
                  <button type="button" class="btn-close" data-bs-toggle="dropdown" data-bs-auto-close="inside"
                    @click="filterclose"></button>
                </div>

                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                  <li class="nav-item w-50 text-center" role="presentation">
                    <button class="nav-link m-auto active" id="pills-home-tab" data-bs-toggle="pill"
                      data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                      aria-selected="true" @click="
                        this.filterTab = 1;
                      this.searchfilter = '';
                      this.Filterresult();
                      ">
                      Status
                    </button>
                  </li>
                  <li class="nav-item w-50 text-center" role="presentation">
                    <button class="nav-link m-auto" id="pills-profile-tab" data-bs-toggle="pill"
                      data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                      aria-selected="false" @click="
                        this.filterTab = 2;
                      this.searchfilter = '';
                      this.Filterresult();
                      ">
                      Region
                    </button>
                  </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div class="tab-pane fade show active p-0" id="pills-home" role="tabpanel"
                    aria-labelledby="pills-home-tab">
                    <div class="search-bar">
                      <form class="search-form">
                        <input type="text" placeholder="Search" name="" class="form-control bg-white"
                          v-model="searchfilter" @keyup="Filterresult" /><button class="btn">
                          <i class="fas fa-search"></i>
                        </button>
                      </form>
                      <div class="ul-li-checkbox-kj">
                        <ul v-if="this.filterTab == 1">
                          <li v-for="item in StatusDataList" :key="item">
                            <input type="checkbox" :value="item.key" @change="addFilter(item.key, $event)"
                              :checked="this.filters.status.includes(item.key)" />
                            <span>{{ item.value }} </span>
                          </li>
                        </ul>
                        <ul v-else>
                          <li v-for="item in regionCategories" :key="item">
                            <input type="checkbox" :value="item.regionId" :checked="this.filters.region.includes(item.regionId)
                              " @change="addFilter(item.regionId, $event)" />
                            <span>{{ item.regionName }} </span>
                          </li>
                        </ul>
                        <div class="text-end">
                          <button type="button" class="btn-Apply-kj-color" v-if="this.applyfilter > 0"
                            @click="clearFilters(true)">
                            Clear Filter
                          </button>

                          <button type="button" class="modail_submit_button text-end" :class="{
                            active_form_button: this.applyfilter > 0,
                          }" :disabled="applyfilter == 0" @click="applyFilters()">
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <!--update-->
        <div class="top_heading_CreatePartner_btn d-block" data-bs-toggle="modal" data-bs-target="#ConsolidatedSheet"
          v-if="this.routeclientId !== '1025'" @click="groupLists">
          <!--update-->
            <!--   @click="ConsolidatedSheet()" -->
<!-- 
  <img
                          src="../../public/dashboard-assets/images/M7/Actions.png"
                          width="20"
                          class="cursor-pointer ms-2"
                          alt=""
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Includes conversational pointers for the recruiters to refer while interviewing the candidates "
                        />
 -->
          <div class="Group_top_three_dot">
            <div class="dropdown">
              <button class="btn p-0 Group_top_three_dot_dropdown" type="button" 
    aria-haspopup="true" aria-expanded="false" style="line-height: 0.3"  
    @click="this.fromDate != 0 ? ConsolidatedSheet() : DemoModal()">
    <img src="../../public/dashboard-assets/images/M2/download-icon.png" data-bs-toggle="tooltip" data-bs-placement="top"  title="Download Consolidated Sheet"/>        
</button>
            </div>
          </div>
        </div>
        <!--update-->
        <div v-else class="top_heading_CreatePartner_btn d-block" data-bs-toggle="modal" data-bs-target="#MedusindModal">
          <div class="Group_top_three_dot">
            <div class="dropdown">
              <button class="btn p-0 Group_top_three_dot_dropdown" type="button" id="dropdownMenuButton"
                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="line-height: 0.3">
                <img src="../../public/dashboard-assets/images/M2/download-icon.png" /> 
              </button>
            </div>
          </div>
        </div>
        <!--update-->
      </div>
    </div>
    <!--update-->
    <div class="modal fade CreatePartnerModal_hp" id="MedusindModal" data-bs-toggle="modal" tabindex="-1" data-bs-backdrop="modal"
      aria-labelledby="CreateGroupModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-header" style="background-color: #ffff; border: none;">
              <!--update-->
              <button type="button" id="closeModal" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"
                @click="resetForm('MedusindModal')"></button>
              <!--update--> 
            </div>
            <div class="row">
              <div class="col-6">
                <button type="button" class="btn" data-bs-toggle="modal" data-bs-target="#ConsolidatedSheet">
                  <!-- Create Group -->
                  Consolidated Sheet
                </button>
              </div>
              <div class="col-6">
                <button type="button" class="btn" data-bs-toggle="modal" data-bs-target="#MedusindBulkPopUp">
                  <!-- Assign Group -->
                  Medusind Bulk Data
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--update-->
    <!--   style="filter: blur(8px);"  -->
    <!-- <div class="row disable-text-selection" v-if="this.showcandidateList == true" id="showData" :style="Remainingdays > 0 ? '' : 'filter: blur(8px);'" > -->
      <div class="row" 
     :class="{ 'disable-text-selection': Remainingdays < 0 }" 
     :style="{ filter: Remainingdays < 0 ? 'blur(8px)' : '' }" 
     v-if="this.showcandidateList == true" 
     id="showData">      
    
      
        <div class="col-md-12">
        <div class="card p-0">
          <div class="table-responsive">
            <table class="table ActiveTestTable">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email/Mobile Number</th>
                  <th>Test End Time</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <!-- 1 -->
                <tr v-for="item in candidateList" :key="item">
                  <td>
                    <div class="d-flex align-items-center font-weight">
                      <div class="PartnerTeamMembersProfile-area-kj">
                        <div class="PartnerTeamMembersProfile-kj name-logo-1 position-relative">
                          {{ item.firstName?.charAt(0) }}
                          <span v-if="item.attemptcount > 1 && (TestStatus[item.candidateStatus] == 'Pass'
                            || TestStatus[item.candidateStatus] == 'Fail')" class="translate-middle notialert-kj-view">{{
                            item.attemptcount }}</span>
                        </div>
                      </div>
                      <span>
                        <span class="me-4">{{
                          (typeof item.firstName === "object"
                            ? ""
                            : item.firstName) +
                          " " +
                          (typeof item.lastName === "object"
                            ? ""
                            : item.lastName)
                        }}
                        </span>
                        <!-- ({{
                              item.attemptcount + " " + "Attempt"
                            }}) -->
                      </span>
                    </div>
                  </td>
                  <td>
                    <div v-if="item.mobileNo === null">
                      {{ item.emailAddress }}
                    </div>
                    <div v-else-if="item.emailAddress === null">
                      {{ item.mobileNo }}
                    </div>
                    <div v-else>
                      {{ item.emailAddress }} / {{ item.mobileNo }}
                    </div>
                  </td>
                  <td v-if="item.examEndTime != null">
                    {{ dateConvert(item?.examEndTime) }}&nbsp;{{
                      timeConverter(item?.examEndTime)
                    }}
                  </td>
                  <td v-else>NA</td>
                  <td v-if="TestStatus[item.candidateStatus] == 'Pending'">
                    <span class="View-Candidates-clock"><i class="fa-solid fa-clock-rotate-left me-2"></i>
                      {{ TestStatus[item.candidateStatus] }}
                    </span>
                  </td>
                  <td v-else-if="TestStatus[item.candidateStatus] == 'InProgress'">
                    <span class="View-Candidates-progress"><i class="fa-solid fa-stopwatch me-2"></i>
                      {{ TestStatus[item.candidateStatus] }}</span>
                  </td>
                  <td v-else-if="TestStatus[item.candidateStatus] == 'Review'">
                    <span class="View-Candidates-review"><i class="fa-regular fa-clock me-2"></i>
                      {{ TestStatus[item.candidateStatus] }}</span>
                  </td>
                  <td v-else-if="TestStatus[item.candidateStatus] == 'Pass'">
                    <span class="View-Candidates-pass"><i class="fa-solid fa-check me-2"></i>{{
                      TestStatus[item.candidateStatus] }}</span>
                  </td>
                  <td v-else-if="TestStatus[item.candidateStatus] == 'Fail'">
                    <span class="View-Candidates-xmark"><i class="fa-solid fa-xmark me-2"></i>
                      {{ TestStatus[item.candidateStatus] }}</span>
                  </td>
                  <td v-else>NA</td>
                  <td>
                    <div class="">
                      <div class="Group_top_three_dot"  v-if="this.Remainingdays >= 0">
                        <div class="dropdown">
                          <button class="btn p-0 Group_top_three_dot_dropdown" type="button" id="dropdownMenuButton"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" @click="
                              openViewReportModal(
                                item.firstName,
                                item.lastName,
                                item.emailAddress,
                                item.mobileNo
                              )
                              ">
                            <i class="fa-solid fa-ellipsis"></i>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <li v-if="(TestStatus[item.candidateStatus] == 'Fail' ||
                              TestStatus[item.candidateStatus] == 'Pass') &&
                              ('attemptcount' in item === false || item.attemptcount > 0) &&
                              getUserPermissionData({
                                parent: 'Candidate Management',
                                child: 'Candidates',
                                permission: 'Can_View_Candidate_Report',
                              })
                              ">
                              <button type="button" class="btn w-100 text-start" data-bs-toggle="modal"
                                data-bs-target="#View-report" @click="selectedCandidate(item)">
                                View report
                              </button>
                            </li>
                            <!-- <button class="btn w-100 text-start">
                              <router-link :to="{
                                name: 'ActiveTestReportOverview',
                                params: {
                                  // candidateId: encode(10098/*item.candidateId*/),
                                  candidateId: encode(item.candidateId),
                                  testId: encode(this.routetestId),
                                  // testId: encode(3532),
                                },
                                query: {
                                  name: encode(item.name)
                                }
                              }">Report Demo link</router-link>
                            </button> -->
                            <!-- update-->

                            <li v-if="getUserPermissionData({
                              parent: 'Candidate Management',
                              child: 'Candidates',
                              permission: 'Can_Reinvite_Candidate',
                            }) && (TestStatus[item.candidateStatus] == 'InProgress' || TestStatus[item.candidateStatus] == 'Pending'
                              || TestStatus[item.candidateStatus] == 'Pass' || TestStatus[item.candidateStatus] == 'Fail')
                              ">
                              <!-- update-->
                              <button type="button" class="btn w-100 text-start" @click="ResendInvite(item.candidateId)">
                                Resend invite
                              </button>
                            </li>
                            <li v-if="TestStatus[item.candidateStatus] == 'Pending' &&
                              getUserPermissionData({
                                parent: 'Candidate Management',
                                child: 'Candidates',
                                permission: 'Can_Delete_Candidate',
                              })
                              ">
                              <button type="button" class="btn w-100 text-start"
                                @click="removeCandidate(item.candidateId)">
                                Remove Candidate
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
         
        </div>
      </div>
      <!-- Archive  -->
      <div class="modal fade" id="View-report" tabindex="-1" aria-labelledby="DeactivatePartnerModalLabel"
        aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable border-radius-kj-20">
          <div class="modal-content border-radius-kj-20">
            <div class="modal-header token-model-header-color">
              <h5 class="modal-title text-centerm-auto" id="IncreaseTokenModalLabel">
                <span class="font-size-20-kj"> Report</span>
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close" id="reportclose"
                @click="this.clearbtn()"></button>
            </div>
            <div class="modal-body p-4">
              <div>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex align-items-center">
                    <div class="PartnerTeamMembersProfile-area-kj">
                      <div class="PartnerTeamMembersProfile-kj name-logo-1">
                        {{ this.firstName?.charAt(0) }}
                      </div>
                    </div>
                    <div v-if="this.uniqueCandidateId">
                      {{
                        this.firstName +
                        " " +
                        this.lastName +
                        " - " +
                        this.uniqueCandidateId
                      }}
                    </div>
                    <div v-else>
                      {{ this.firstName + " " + this.lastName }}
                    </div>
                  </div>
                </div>
                <div class="mt-4">
                  <div class="form-group mb-4">
                    <label class="input-hero" for="PartnerCompany" @click="clickcalendarData">
                      <input class="input_area" type="text" required="" aria-autocomplete="list"
                        v-model="this.dateData" />
                      <span class="input-name">Date & Time <span class="text-danger">*</span></span>
                      <span class="toggle-password" @click="toggle = !toggle">
                        <i class="fa-solid fa-calendar-days"></i>
                      </span>
                    </label>
                  </div>
                </div>
                <div>
                  <div v-if="this.selectedCandidateId != 0">
                    <div v-if="this.reportType == 1">
                      <div class="d-flex justify-content-between" v-if="superadmin()">
                        <div class="mb-2">
                          <label class="form-check-label" for="flexCheckChecked">
                            Answer Sheet
                          </label>
                        </div>
                        <div class="text-end">
                          <button type="button" class="btn btn-primary btn-download btn-active-TestView" :class="{
                            active_inc_dec_button: !viewbuttondisabled(),
                          }" :disabled="!viewbuttondisabled()"
                            @click="downlodedViewReports('AnswerSheet', this.routetestId, this.cal)">
                            View
                          </button>
                        </div>
                      </div>

                      <div class="d-flex justify-content-between" v-for="item in report" :key="item">
                        <div class="mb-2">
                          <label class="form-check-label" for="flexCheckChecked">
                            {{ item }}
                          </label>
                        </div>
                        <div class="text-end">
                          <button type="button" class="btn btn-primary btn-download btn-active-TestView" :class="{
                            active_inc_dec_button: !viewbuttondisabled(),
                          }" :disabled="!viewbuttondisabled()" @click="downlodedViewReports(item, this.routetestId, this.cal
)">
                            View
                          </button>
                        </div>
                      </div>

                      <div class="d-flex justify-content-between" v-for="item in behavioural" :key="item">
                        <div class="mb-2">
                          <label class="form-check-label" for="flexCheckChecked">
                            {{ item.name }}
                          </label>
                        </div>
                        <div class="text-end">
                          <button type="button" class="btn btn-primary btn-download btn-active-TestView" :class="{
                            active_inc_dec_button: !viewbuttondisabled(),
                          }" :disabled="!viewbuttondisabled()"
                            @click="downlodedViewReports('Psychometry', this.routetestId, this.cal, item.psySecId)">
                            View
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between" v-if="this.reportType == 2">
                      <div class="mb-2">
                        <label class="form-check-label" for="flexCheckChecked">
                          Overall Report
                        </label>
                      </div>
                      <div class="text-end">
                        <router-link type="button" target="_blank"
                          class="btn btn-primary btn-download btn-active-TestView" @click="this.clearbtn()" :to="{
                            name: 'ActiveTestReportOverview', params: {
                              
                              candidateId: encode(selectedCandidateId),
                              testId: encode(this.routetestId),
                            }, query: {
                              name: encode(this.activeCandidate.firstName + ' ' + this.activeCandidate.lastName),
                              clientId: encode(this.routeclientId),
                              isParent: encode(this.routeisParent),
                              offset: this.routeoffset ? encode(this.routeoffset) : '',
                              id: this.routesearch ? encode(this.routesearch) : '',
                              current: this.routecurrent ? encode(this.routecurrent) : '',
                            }
                          }">View</router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- iframe -->
      <!-- <div class="modal fade" id="iframe" tabindex="-1" aria-labelledby="DeactivatePartnerModalLabel" aria-hidden="true"
        data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable border-radius-kj-20">
          <div class="modal-content border-radius-kj-20">
            <div class="modal-header token-model-header-color">
              <h5 class="modal-title text-centerm-auto" id="IncreaseTokenModalLabel"><span class="font-size-20-kj">
                  Report</span>
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"
                @click="this.clearbtn()"></button>
            </div>
            <div class="modal-body p-4">
              <iframe :src="this.url" width="100%" height="650px"></iframe>
            </div>
          </div>
        </div>
      </div> -->

      <!-- choose  -->
      <div class="modal fade" id="choose-Date-time" tabindex="-1" aria-labelledby="DeactivatePartnerModalLabel"
        aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable border-radius-kj-20">
          <div class="modal-content border-radius-kj-20">
            <div class="modal-header token-model-header-color">
              <h5 class="modal-title text-centerm-auto" id="IncreaseTokenModalLabel">
                <span class="font-size-20-kj"> Choose Date & Time</span>
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"
                id="choosedatetimebtn" @click="closechoosedate()"></button>
            </div>
            <div class="modal-body p-4">
              <div v-for="item in calendarList" :key="item">
                <div class="d-flex justify-content-between align-items-center mb-3 ">
                  <div class="form-check cursor-pointer" @click="showReport()">
                    <input class="form-check-input" type="radio" name="exampleRadios1" :value="item.examEndTime" @click="
                      selectDate(
                        dateConvert(item.examEndTime),
                        timeConvert(item.examEndTime),
                        item.tokenId,
                        item.candidateId,
                        item.candidateUniqueId
                      )
                      " />
                    <label class="form-check-label" for="exampleRadios1" @click="
                      selectDate(
                        dateConvert(item.examEndTime),
                        timeConvert(item.examEndTime),
                        item.tokenId,
                        item.candidateId,
                        item.candidateUniqueId
                      )
                      ">
                      {{ dateConvert(item?.examEndTime) }} -
                      {{ timeConvert(item?.examEndTime) }}
                    </label>
                  </div>
                  <span class="View-Candidates-clock" v-if="TestStatus[item.candidateStatus] == 'Pending'"><i
                      class="fa-solid fa-clock-rotate-left me-2" v-if="TestStatus[item.candidateStatus] == 'Pending'"></i>
                    {{ TestStatus[item.candidateStatus] }}</span>
                  <span class="View-Candidates-progress" v-else-if="TestStatus[item.candidateStatus] == 'InProgress'"><i
                      class="fa-solid fa-stopwatch me-2" v-if="TestStatus[item.candidateStatus] == 'InProgress'"></i>
                    {{ TestStatus[item.candidateStatus] }}</span>
                  <span class="View-Candidates-review" v-else-if="TestStatus[item.candidateStatus] == 'Review'"><i
                      class="fa-regular fa-clock me-2" v-if="TestStatus[item.candidateStatus] == 'Review'"></i>
                    Review</span>
                  <span class="View-Candidates-pass" v-else-if="TestStatus[item.candidateStatus] == 'Pass'"><i
                      class="fa-solid fa-check me-2" v-if="TestStatus[item.candidateStatus] == 'Pass'"></i>
                    {{ TestStatus[item.candidateStatus] }}</span>
                  <span class="View-Candidates-xmark" v-else-if="TestStatus[item.candidateStatus] == 'Fail'"><i
                      class="fa-solid fa-xmark me-2" v-if="TestStatus[item.candidateStatus] == 'Fail'"></i>
                    {{ TestStatus[item.candidateStatus] }}</span>
                  <div v-else>NA</div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      
      <!-- choose  -->
      <!-- <div class="modal fade" id="ConsolidatedSheet" tabindex="-1" aria-labelledby="DeactivatePartnerModalLabel"
        aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable border-radius-kj-20 model-sm">
          <div class="modal-content border-radius-kj-20">
            <div class="modal-header token-model-header-color">
              <h5 class="modal-title text-centerm-auto" id="IncreaseTokenModalLabel">
                <span class="font-size-20-kj"> Consolidated Sheet</span>
              </h5> -->
              <!--update-->
              <!-- <button type="button" id="close" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"
                @click="resetForm('ConsolidatedSheet')"></button> -->
              <!--update-->
            <!-- </div>
            <div class="modal-body p-4"> -->
              <!-- <div class="form-group mb-4">
                <label class="input-hero" for="Category">
                  <Multiselect v-model="this.grp" :options="this.groupList" mode="tags" :searchable="true" :classes="{
                    tag: 'multiselect-tag text-wrap',
                  }" :close-on-select="false">
                  </Multiselect>
                  <span class="input-name input-group-kj"> From Group
                    <span class="text-danger"></span></span>
                </label>
              </div>
              <div class="form-group mb-4">
                <label class="input-hero" for="Category">
                  <Multiselect v-model="this.users" :options="this.fromuser" mode="tags" :searchable="true" :classes="{
                    tag: 'multiselect-tag text-wrap',
                  }" :close-on-select="false">
                  </Multiselect>
                  <span class="input-name input-group-kj"> Invited By
                    <span class="text-danger"></span></span>
                </label>
              </div>
              <div class="form-group mb-4">
                <label class="input-hero" for="Category">
                  <Multiselect v-model="this.statustest" :options="this.TestStatus" mode="tags" :searchable="true"
                    :classes="{
                      tag: 'multiselect-tag text-wrap',
                    }" :close-on-select="false">
                  </Multiselect>
                  <span class="input-name input-group-kj">Choose Status
                    <span class="text-danger"></span></span>
                </label>
              </div> -->
              <!-- <div class="form-group mb-4">
                <label class="input-hero" for="Category">
                  <input type="text" class="input_area select_ca " name="daterange" v-model="this.date" />
                  <span class="input-name">Choose Date <span class="text-danger">*</span></span>
                    <span class="toggle-password">
                        <img src="../../public/dashboard-assets/images/M2/date-icon.png" class="date-icon-kj"
                    @click="opendatePicker" />
                      </span>
                </label> -->

              <!--  <div class="form-group mb-4">
                    <label class="input-hero" for="PartnerCompany" @click="clickcalendarData">
                      <input class="input_area" type="text" required="" aria-autocomplete="list"
                        v-model="this.dateData" />
                      <span class="input-name">Date & Time <span class="text-danger">*</span></span>
                      <span class="toggle-password" @click="toggle = !toggle">
                        <i class="fa-solid fa-calendar-days"></i>
                      </span>
                    </label>
                  </div> -->

              <!-- </div>

              <div class="text-end mt-3">
                <button type="button" class="btn btn-primary btn-download"
                  :class="{ active_inc_dec_button: !DownloadDisabledButton() }" :disabled="!DownloadDisabledButton()"
                  @click="ConsolidatedSheet()">
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="modal fade" id="DemoModal" tabindex="-1" aria-labelledby="DeactivatePartnerModalLabel"
        aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable border-radius-kj-20">
          <div class="modal-content border-radius-kj-20">
            <div class="modal-header">   
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"
                id="choosedatetimebtn"></button>
            </div>
            <div class="modal-body">
              <span class="center-elements" >
        <h3 style="font-weight: 600;">Exciting News! <i class="fa-solid fa-rocket fa-beat" style="color: #ffc801;"></i></h3>
    </span>
    <p class="center-elements" style="margin-top: 1%;">Revamped Candidate Bulk Download: Explore the Enhanced Workflow in Our Latest Video! </p>

    <span class="center-elements"  style="margin-top: 3%;">
        <img src="../../public/assets/Images/platform-demo.gif" alt="" style="width: 100%;" />
    </span>
    
    
</div>


          </div>
        </div>
      </div>
      <!--update-->
      <!-- to be optimized(Medusind bulk data) -->
      <div class="modal fade" id="MedusindBulkPopUp" tabindex="-1" aria-labelledby="DeactivatePartnerModalLabel"
        aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable border-radius-kj-20 model-sm">
          <div class="modal-content border-radius-kj-20">
            <div class="modal-header token-model-header-color">
              <h5 class="modal-title text-centerm-auto" id="IncreaseTokenModalLabel">
                <span class="font-size-20-kj"> Medusind Bulk Data</span>
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"
                @click="resetForm('MedusindData')"></button>
            </div>
            <div class="modal-body p-4">
              <div class="form-group mb-4">
                <label class="input-hero" for="Category">
                  <input type="text" class="input_area select_ca" name="daterange" v-model="this.date" />
                  <span class="input-name">Choose Date <span class="text-danger">*</span></span>
                  <img src="../../public/dashboard-assets/images/M2/date-icon.png" class="date-icon-kj"
                    @click="opendatePicker" />
                </label>
              </div>

              <div class="text-end mt-3">
                <button type="button" class="btn btn-primary btn-download"
                  :class="{ active_inc_dec_button: !DownloadDisabledButton() }" :disabled="!DownloadDisabledButton()"
                  @click="MedusindBulkCandidatesDownload()">
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- scope ends here -->

      <!--update-->
      <paginate v-model="currentPage" :page-count="Page_count" :margin-pages="1" :page-range="1"
        :click-handler="clickCallback" :prev-text="'< Prev'" :next-text="'Next >'" :container-class="'pagination'"
        :page-class="'page-item'" v-if="this.showcandidateList == true && this.Remainingdays >= 0">
      </paginate>
      <div id="layer" v-if="Remainingdays<0"></div>
    </div>

    <div class="text-center" v-if="!this.showcandidateList">
            <img src="../../public/dashboard-assets/images/M2/empty_record.png" style="width: 20%; padding-top: 7%; " />
            <h3 class="ms-5 mt-2">No Record Found</h3>
          </div>
  </div>
</template>

<script>
// import Multiselect from "@vueform/multiselect";
import "vue-datepicker-ui/lib/vuedatepickerui.css";
import { superadmin } from "../utils/helper";
import { getUserPermissionData } from "../utils/helper";
import { mapState, mapActions, mapMutations } from "vuex";
import moment from "moment";
import Paginate from "vuejs-paginate-next";
import { Tooltip } from "bootstrap/dist/js/bootstrap.esm.min.js";
import { calculateRemainingDays } from '../platformFeatures/dateUtils';


const Status = Object.freeze({
  0: "Pending",
  1: "InProgress",
  2: "Review",
  3: "Pass",
  4: "Fail",
});

export default {
  name: "ActiveTestViewCandidates",
  components: {
    paginate: Paginate,
    // Multiselect
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      clientId: (state) => state.auth.user.clientId,
    }),
  },
  data() {
    return {
      selectedCandidateId: "",
      uniqueCandidateId: "",
      grp: [],
      users: [],
      statustest: [],
      isShowing: false,
      candidateList: [],
      candidateStatus: [],
      candidateRegion: [],
      report: [],
      calendarList: [],
      downloadinfo: [],
      List: "",
      offset: 1,
      limit: 8,
      search: "",
      regionId: "",
      status: "",
      fromDate: "",
      toDate: "",
      currentPage: 1,
      Page_count: 1,
      totalCandidates: 0,
      TestStatus: Status,

      //filter
      applyfilter: 0,
      filterTab: 1,
      searchfilter: "",
      filters: {
        status: [],
        region: [],
        name: "",
      },
      region: [],
      regionCategories: [],
      resendinviteList: [],
      searchDisable: false,
      //search
      input: "",
      result: [],
      name: [],
      firstName: "",
      lastName: "",
      email: "",
      mobileNo: "",
      statusinfo: "",
      companyId: "",
      behavioural: [],
      reportType: "",
      selected: [],
      selectAll: false,
      dateData: "",
      date: "",
      groupList: [],
      cal: [],
      showcandidateList: false,
      data: [],
      emailVerify: [],
      to: "",
      from: "",
      opencals: false,
      companyName: "",
      StatusList: [
        {
          key: 0,
          value: "Pending",
        },
        {
          key: 1,
          value: "InProgress",
        },
        {
          key: 2,
          value: "Review",
        },
        {
          key: 3,
          value: "Pass",
        },
        {
          key: 4,
          value: "Fail",
        },
      ],
      StatusDataList: [
        {
          key: 0,
          value: "Pending",
        },
        {
          key: 1,
          value: "InProgress",
        },
        {
          key: 2,
          value: "Review",
        },
        {
          key: 3,
          value: "Pass",
        },
        {
          key: 4,
          value: "Fail",
        },
      ],
      testclientid: "",
      baseurl: window.app_base_url,
      groups: [],
      screenWidth: 0,
      url: "",
      routetestId: window.atob(this.$route.params.testId),
      routeclientId: window.atob(this.$route.params.clientId),
      routeisParent: window.atob(this.$route.params.isParent),
      routesearch: window.atob(this.$route.query.search),
      routeoffset: window.atob(this.$route.query.offset),
      routecurrent: window.atob(this.$route.query.current),
      show:false,
      Remainingdays:0
    };
  },
  async created() {
    window.addEventListener("resize", this.myEventHandler);
    this.screenWidth = window.outerWidth;

    await this.viewCandinateList();

    var testdetail = await this.activetestdetail({
      testId: this.routetestId,
    });
    this.companyId = testdetail.object.data?.testId;
    this.companyName = testdetail.object.data?.testName;
    
    await this.getTestReport();
    var categories = await this.regionfilter({
      clientId: this.clientId,
      offset: 1,
      limit: -1,
    });
    if (categories.status) {
      this.regionCategories = categories.object.data.regions;
      this.candidateRegion = categories.object.data.regions;
    }

    if(this.$route.query.email != '' && this.$route.query.email != undefined){
      const encryptedEmail = this.$route.query.email;
      const decryptedEmail = atob(encryptedEmail);
      this.input=decryptedEmail;
      await this.filteredList();
    }

    if(this.billing !=null){
      this.Remainingdays = calculateRemainingDays(this.billing.object.data.subscription.expiryDate);
    }
  },
  async mounted() {
  //   if (this.$route.query.activefilter) {
  //   if(localStorage.getItem('ActiveTestViewCandidatesfilters') != null)
  //   {
  //     this.filters = JSON.parse(localStorage.getItem('ActiveTestViewCandidatesfilters'));
  //     this.applyfilter =
  //         this.filters.region.length +
  //         this.filters.status.length ;
  //   }
  // }
  // else{
  //   this.clearFilters(false);
   
  // }
  
    this.screenWidth = window.outerWidth;
    
    window.$('input[name="daterange"]').daterangepicker(
      {
        maxDate: new Date(),
        locale: {
          cancelLabel: "Clear",
        },
        opens: "center",
        autoUpdateInput: false
      },
      async (start, end) => {
        this.fromDate = start.format("YYYY-MM-DD 00:00:00");
        this.toDate = end.format("YYYY-MM-DD 23:59:59");
      }
    );
    window
      .$('input[name="daterange"]')
      .on("cancel.daterangepicker", async (ev, picker) => {
        picker.startDate._d = new Date();
        picker.endDate._d = new Date();
        var list = await this.viewCandinate({
          testId: this.routetestId,
          OffSet: 1,
          Limit: this.limit,
        });
        this.showcandidateList=true;
        this.fromDate = "";
        this.toDate = "";
        this.candidateList = list.object.data.testCandidates;
        this.totalCandidates = list.object.data.filteredCandidates;
        this.Page_count = list
          ? Math.ceil(list.object.data.filteredCandidates / this.limit) 
          : 0;
        window.$('input[name="daterange"]').val("");
      });
    window
      .$('input[name="daterange"]')
      .on("apply.daterangepicker", async (ev, picker) => {
        var start = moment(String(picker.startDate._d)).format(
          "YYYY-MM-DD 00:00:00"
        );
          // es6 jquery 
        var end = moment(String(picker.endDate._d)).format(
          "YYYY-MM-DD 23:59:59"
        );
        // var start = moment(String(picker.startDate._d)).format("DD/MM/YYYY");
        // var end = moment(String(picker.endDate._d)).format("DD/MM/YYYY");

        if (
          Math.ceil(
            Math.abs(new Date(start) - new Date(end)) / (1000 * 60 * 60 * 24)
          ) <= 31
//           Math.ceil(
//     Math.abs(moment(start, "DD/MM/YYYY").toDate() - moment(end, "DD/MM/YYYY").toDate()) / (1000 * 60 * 60 * 24)
// ) <= 31
        ) {
          this.date = start + " - " + end;
          if (this.opencals == false) {
            var list = await this.viewCandinate({
              testId: this.routetestId,
              OffSet: 1,
              Limit: this.limit,
              fromDate: this.fromDate,
              toDate: this.toDate,
              isParent: localStorage.getItem("isParent")
            });
            if(list.status){
              this.showcandidateList = true;
              this.candidateList = list.object.data.testCandidates;
            this.totalCandidates = list.object.data.filteredCandidates;
            this.Page_count = list
              ? Math.ceil(list.object.data.filteredCandidates / this.limit)
              : 0;
            this.currentPage = 1;
            }else {
        // this.candidateList = [];
        this.showcandidateList = false;
        this.candidateList=[];
      }
          }
        } else {
          this.fromDate=0;
           this.toDate=0;
          return this.ShowWarningMessage("Please select range within 31 days.");
        }
      });

    await this.groupLists();
    await this.fromEmail();

    this.candidateStatus = this.candidateList;

    // var x = await this.GetTeams({
    //   clientId: this.user.clientId,
    // });
    // var team = x.object.data.regions;
    // team.forEach((element) => {
    //   this.region.push(element.regionName);
    // });

    Array.from(
      document.querySelectorAll('img[data-bs-toggle="tooltip"]')
    ).forEach((tooltipNode) => new Tooltip(tooltipNode));
  },

  methods: {
    getUserPermissionData,
    ...mapActions([
      "viewCandinate",
      "ShowWarningMessage",
      "ShowErrorMessage",
      "getRegionCategories",
      "getRegionCategoriesNewData",
      "GetTeams",
      "resendInvite",
      "resendInviteEmailCandidate",
      "getviewReport",
      "viewReportcalendar",
      "downloadsheet",
      "UserRegionData",
      "viewReport",
      "invitefromemail",
      "AllActiveTest",
      "adminregionList",
      "regionfilter",
      /*update*/
      "activetestdetail",
      "MedusindBulkData",
      "deleteCandidate",
      "getLastCandidateInviteMode",
      "sendSmsInvitation",
      "IsSmsInvitePreference"

      /*update*/
    ]),
    changeFormat: function (args) {
      this.dateFormat = args.value;
      this.separate = args.value === "yyyy/MM/dd HH:mm" ? "to" : "-";
    },

    ...mapMutations(["setLoading"]),
    dateConvert: (value) => {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    superadmin,

    timeConverter(val) {
      const today = new Date(val);
      let h = today.getHours();
      let m = today.getMinutes();
      let s = today.getSeconds();
      var strTime = h + ":" + m + ":" + s + " ";
      return strTime;
    },
    timeConvert(val) {
      const today = new Date(val);
      let h = today.getHours();
      let m = today.getMinutes();
      var meridian = h >= 12 ? "PM" : "AM";
      h = h % 12;
      h = h ? h : 12;
      m = m < 10 ? "0" + m : m;
      var strTime = h + ":" + m + " " + meridian;
      return strTime;
    },
    encode(item) {
      return window.btoa(item);
    },
    myEventHandler(e) {
      this.screenWidth = window.outerWidth;
      e.preventDefault();
    },
    clickCallback(pageNum) {
      this.offset = (pageNum - 1) * this.limit + 1;
      this.viewCandinateList();
    },

    clearbtn() {
      this.dateData = "";
      this.selectedCandidateId = ""
      document.getElementById("choosedatetimebtn").click();
    },
    showReport() {
      window.$("#View-report").modal("show");
      window.$('#choose-Date-time').modal('hide');
    },
    closechoosedate() {
      this.calendarList = [];
      document.getElementById("reportclose").click();
      this.calendarList=[];
    },
    viewbuttondisabled() {
      if (this.dateData != "") {
        return true;
      } else {
        return false;
      }
    },

    filterDisabledButton() {
      if (this.applyfilter == 0) {
        return true;
      } else {
        return false;
      }
    },

    viewreportDownloadDisabledButton() {
      if (this.selected != "" && this.dateData != "") {
        return true;
      } else {
        return false;
      }
    },

    DownloadDisabledButton() {
      if (this.date != "") {
        return true;
      } else {
        return false;
      }
    },

    async openViewReportModal(firstName, lastName, email, mobileNo) {
      this.firstName = firstName;
      this.lastName = lastName;
      this.email = email;
      this.mobileNo = mobileNo
    },

    downlodedViewReports(name, testid, TokenId, PsySecId) {
      var url = "";
      switch (name) {
        case "Invigilation":
          url = `${this.baseurl}/ManageCandidates/GetWebVideoProctoringResult?TestId=${testid}&TokenId=${TokenId}&isPrint=2`;
          this.url = url;
          break;
        case "DestopProctoring":
          url = `${this.baseurl}/ManageCandidates/GetProctoringResult?TestId=${testid}&TokenId=${TokenId}&isPrint=2`;
          this.url = url;
          break;
        case "Psychometry":
          url = `${this.baseurl}/ManageCandidates/GetPsychometryDetailedResult?TestId=${testid}&TokenId=${TokenId}&PsySecId=${PsySecId}&isPrint=2`;
          this.url = url;
          break;
        case "Language":
          url = `${this.baseurl}/ManageCandidates/GetLanguageResult?TestId=${testid}&TokenId=${TokenId}&isPrint=2`;
          this.url = url;
          break;
        case "AnswerSheet":
          url = `${this.baseurl}/ManageCandidates/GetAnswerSheet?TestId=${testid}&TokenId=${TokenId}&isPrint=2`;
          this.url = url;
          break;
        case "ChatBot":
          url = `${this.baseurl}/ManageCandidates/GetChatBotResult?TestId=${testid}&TokenId=${TokenId}&isPrint=2`;
          this.url = url;
          break;
        case "CandidateDetail":
          url = `${this.baseurl}/ManageCandidates/ViewCandidateDetails?TestId=${testid}&TokenId=${TokenId}&isPrint=2`;
          this.url = url;
          break;
        //Added by Pmaps Team
        case "Overall Report":
          url = `${this.baseurl}/ManageCandidates/GetAptitudeResult?TestId=${testid}&TokenId=${TokenId}&ClientId=${this.user.clientId}&UserId=${this.user.userId}&isPrint=2`;
          this.url = url;
          break; //Added by Pmaps Team
        default:
          name;
      }
      // window.$('#iframe').modal('show');
      window.open(url);
    },

    async viewCandinateList() {
      var model = {
        testId: this.routetestId,
        OffSet: this.offset,
        Limit: this.limit,
        Search: this.input,
        // Search: this.filters.name,
        regionId: this.filters.region.toString(),
        status: this.filters.status.toString(),
        fromDate: this.fromDate,
        toDate: this.toDate,
        isParent: localStorage.getItem("isParent"), //update
      };
      var x = [];
      var viewList = await this.viewCandinate(model);

      if (viewList.status) {
        this.showcandidateList = true;
        this.candidateList = viewList.object.data.testCandidates;
        this.candidateList.forEach((element) => {
          x.push(element.testId);
        });
        this.totalCandidates = viewList.object.data.filteredCandidates;

        this.Page_count = viewList
          ? Math.ceil(viewList.object.data.filteredCandidates / this.limit)
          : 0;
      } else {
        this.candidateList = [];
        this.showcandidateList = false;
      }

      this.candidateList.forEach(async (element) => {
        var list = await this.viewReportcalendar({
          testId: this.routetestId,
          candidateEmail: encodeURIComponent(element.emailAddress),
        });
        if (list?.status) {
          element.attemptcount = list.object.data.length;
        }
      });
    },

    async getTestReport() {
      var datas = await this.getviewReport({
        testId: this.routetestId,
      });
      this.report = datas.object.data.reports;
      this.behavioural = datas.object.data.behavioural;
      this.reportType = datas.object.data.reportType;
    },

    selectedCandidate(candidate) {
      this.activeCandidate = candidate;
    },

    async clickcalendarData() {
      this.calendarList = [];
      window.$('#choose-Date-time').modal('show');
      window.$('#View-report').modal('hide');
      var list = await this.viewReportcalendar({
        testId: this.routetestId,
        candidateEmail: encodeURIComponent(this.email),
      });
      this.calendarList = list.object.data.sort((a, b) => moment(b.examEndTime, 'YYYYMMDD HH:mm:ss').diff(moment(a.examEndTime, 'YYYYMMDD HH:mm:ss')));
    },

    async DemoModal(){
      window.$('#DemoModal').modal('show');

    },
    //updated by Pmaps team
    async ResendInvite(val) {
      var lastInviteMode = await this.getLastCandidateInviteMode({
        candidateId: val
      });

      var isSmsSettingEnabled = await this.IsSmsInvitePreference({
        clientId: this.routeclientId
      });

      let isSmsMode = true;
      let contactDetailsList = [];

      if (lastInviteMode.status) {
        var isSMSModeChosen = lastInviteMode.object.data;
        isSmsMode = isSMSModeChosen === false ? 1 : 2;
        if(isSmsMode === 2 && isSmsSettingEnabled === undefined) {
          return this.ShowErrorMessage("Sms invite is disabled. Kindly contact PMaps.");
        }
        contactDetailsList = isSMSModeChosen === false ? this.email : this.mobileNo;
      }

      var datas = await this.resendInvite({
        testId: this.routetestId,
        fromEmail: JSON.parse(localStorage.getItem("authUserData")).email,
        contactDetails: [contactDetailsList],
        candidateType: "Invite",
        inviteMode: isSmsMode
      });

      if (datas.status) {
        let candidates = [];
        var duplicateCandidates = datas.object.data.dupicateList.map(
          (x) => x.candidateId
        );
        if(duplicateCandidates.length) {
          candidates.push(duplicateCandidates[0]);
        } else {
          var newCandidates = datas.object.data.newList.map(
            (x) => x.candidateId
          );
          candidates.push(newCandidates[0]);
        }
        if (isSmsMode === 1) {
          await this.resendInviteEmailCandidate({
            testId: this.routetestId,
            senderEmail: JSON.parse(localStorage.getItem("authUserData")).email,
            candidates: candidates,
          });
        }
        else if (isSmsMode == 2) {
          await this.sendSmsInvitation({
            testId: this.routetestId,
            senderEmail: JSON.parse(localStorage.getItem("authUserData")).email,
            candidates: candidates
          });
        }
      }
    },
    //updated by Pmaps team

    async ConsolidatedSheet() {
      if(this.fromDate!=0){
      var regionid = "";
      this.grp.forEach((element) => {
        this.groups.forEach((elm) => {
          if (elm.regionName == element) {
            regionid = elm.regionId;
          }
        });
      });
      var downloads = await this.downloadsheet({
        testId: this.routetestId,
        RegionId: regionid,
        UserId: this.user.userId,
        Status: this.statustest,
        formDate: this.fromDate,
        toDate: this.toDate,
      });
      this.downloadinfo = downloads.object.data.url;
      window.open(this.downloadinfo);
      // window.$("#ConsolidatedSheet").modal("hide");
      // document.getElementById("close").click();
      // document.getElementById("closeModal").click();
      /*update*/
      this.resetForm("ConsolidatedSheet");
      /*update*/
    }
    else{
      return this.ShowWarningMessage("Please Add Start and End Date in Filter for Downloading Consolidated Sheet");

    }
    },
    /*update*/
    async MedusindBulkCandidatesDownload() {
      var downloads = await this.MedusindBulkData({
        fromDate: this.fromDate,
        toDate: this.toDate,
        userId: this.user.userId,
        testId: this.routetestId,
        clientId: this.user.clientId,
      });
      this.downloadinfo = downloads.object.data.url;
      window.open(this.downloadinfo);
      window.$("#MedusindBulkPopUp").close();
      document.getElementById("closeModal").click();
    },

    resetForm(mode) {
      /*update*/
      this.statustest = [];
      this.grp = [];
      this.date = "";
      this.users = [];

      /*update*/
      if (mode === "MedusindData") {
        document.getElementById("closeModal").click();
        window.$("#MedusindBulkPopUp").close();
        // window.$("#MedusindBulkPopUp").modal("hide");
        // window.$("#MedusindModal").modal("show");
      } 
      else if (
        mode === "MedusindModal"
      ){
        //window.$("#MedusindBulkPopUp").close();
        document.getElementById("closeModal").click();
      }
      else if (
        mode === "ConsolidatedSheet" &&
        this.routeclientId === "1025" 
      ) {
        document.getElementById("closeModal").click();
        window.$("#ConsolidatedSheet").close();
       // window.$("#MedusindBulkPopUp").close();
        
        // window.$("#ConsolidatedSheet").modal("hide");
        // window.$("#MedusindBulkPopUp").close();
        // document.getElementById("closeModal").click();
        // //window.$("#MedusindModal").modal("show");
      }

       //
      /*update*/
    },

    async groupLists() {
      this.groupList = [];
      var group;
      if (this.clientId == 1001) {
        group = await this.adminregionList({
          userId: this.routeclientId,
        });
        this.groups = group.object?.data.regions;
      } else {
        group = await this.UserRegionData({
          userId: this.user.userId,
        });
        this.groups = group.object.data;
      }
      this.groups?.forEach((element) => {
        this.groupList.push(element.regionName);
      });

      this.groupList = Object.freeze(
        JSON.parse(JSON.stringify(this.groupList))
      );
    },
    async fromEmail() {
      var fromemail = await this.invitefromemail({
        userId: this.user.userId,
      });
      this.fromuser = fromemail.object.data;
    },

    //filter

    filterclose() {
      document.getElementById("filterDropdown").click();
    },

    Filterresult() {
      if (this.searchfilter != "") {
        if (this.filterTab == 1) {
          var x = [];
          this.StatusList.forEach((el) => {
            if (
              el.value.toLowerCase().startsWith(this.searchfilter.toLowerCase())
            ) {
              x.push(el);
            }
          });
          this.StatusDataList = x;
        } else {
          this.regionCategories = this.candidateRegion.filter((element) =>
            element.regionName
              .toLowerCase()
              .includes(this.searchfilter.toLowerCase())
          );
        }
      } else {
        this.StatusDataList = this.StatusList;
        this.regionCategories = this.candidateRegion;
      }
    },

    async addFilter(value, e) {
      if (e.target.checked) {
        if (this.filterTab == 1) {
          this.filters.status.push(value);
        } else {
          this.filters.region.push(value);
        }
        this.applyfilter =
          this.filters.status.length + this.filters.region.length;
      } else {
        if (this.filterTab == 1) {
          this.filters.status.splice(
            Object.freeze(
              JSON.parse(JSON.stringify(this.filters.status))
            ).indexOf(value),
            1
          );
        } else {
          this.filters.region.splice(
            Object.freeze(
              JSON.parse(JSON.stringify(this.filters.region))
            ).indexOf(value),
            1
          );
        }
        this.applyfilter =
          this.filters.status.length + this.filters.region.length;
        if (this.applyfilter == 0) {
          await this.viewCandinateList();
        }
      }
    },

    async applyFilters() {
      this.offset = 1;
      await this.viewCandinateList();
      localStorage.setItem("ActiveTestViewCandidatesfilters", JSON.stringify(this.filters));
      // var ActiveTestViewCandidatesfilters = localStorage.getItem("ActiveTestViewCandidatesfilters");
      // console.log(ActiveTestViewCandidatesfilters);
      document.getElementById("filterDropdown").click();
    },

    async clearFilters(value) {
      this.filters.status = [];
      this.filters.region = [];
      this.applyfilter = 0;
      this.searchfilter = "";
      this.showcandidateList = true;
      this.Filterresult();
      await this.viewCandinateList();
      if(value) document.getElementById("filterDropdown").click();
      localStorage.removeItem('ActiveTestViewCandidatesfilters')
    },

    //search

    opensearch() {
      document.querySelector(".Searching_input-box").classList.add("open");
    },

    async closesearch() {
      document.querySelector(".Searching_input-box").classList.remove("open");
      this.result = [];
      this.input = "";
      this.filters.name = "";
      await this.viewCandinateList();
    },

    async filteredList() {
      // this.input = this.input.trim();
      this.searchDisable = true;
      if (this.input != "") {
        this.name = [];
        this.result = [];
        var list = await this.viewCandinate({
          testId: this.routetestId,
          OffSet: 1,
          Limit: this.limit,
          Search: encodeURIComponent(this.input),
          isParent: localStorage.getItem("isParent")
        });
        this.searchDisable = false;
        if (list.status) {
          var x = list.object?.data.testCandidates;
          this.candidateList = list.object.data.testCandidates;
          this.candidateList.forEach(async (element) => {
            var list = await this.viewReportcalendar({
              testId: this.routetestId,
              candidateEmail: encodeURIComponent(element.emailAddress),
            });
            if (list?.status) {
              element.attemptcount = list.object.data.length;
            }
          });

          x.forEach((element) => {
            if (this.name.indexOf(element.firstName) < 0) {
              this.name.push(element.firstName);
            }
          });
          this.result = this.name?.filter((element) =>
            element?.toLowerCase().startsWith(this.input?.toLowerCase())
          );
          this.result = Object.freeze(JSON.parse(JSON.stringify(this.result)));
        } else {
          this.candidateList = [];
          this.showcandidateList = false;
        }
      } else {
        this.result = [];
        this.filters.name = "";
        await this.viewCandinateList();
      }
      if (this.input.trim() != "") {
        list.Search = this.input;

      }
    },

    // async searchregionname(val) {
    //   this.input = val.trim();
    //   this.offset = 1;
    //   this.search = val;
    //   this.result = [];
    //   this.currentPage = 1;
    //   await this.viewCandinateList();
    // },
    stringTOHtml(item, idx) {
      setTimeout(() => {
        if (this.input != "") {
          let re = new RegExp(this.input.toLowerCase(), "g");
          var html = document.getElementById(idx);
          var ex = item
            .toLowerCase()
            .replace(
              re,
              `<span style="color: #7b68ee !important">${this.input}</span>`
            );
          if (html) html.innerHTML = ex;
        }
      }, 100);
    },
    opencal() {
      document.getElementById("calendar_icon").click();
    },
    opendatePicker() {
      this.opencals = true;
      document.getElementById("calendar_icon").click();
    },
    selectDate(event1, event2, tokenId, candidateid, candidateUniqueId) {
      this.dateData = event1 + " - " + event2;
      this.cal = tokenId;
      this.selectedCandidateId = candidateid;
      this.uniqueCandidateId = candidateUniqueId;
    },

    async removeCandidate(candidateId) {
      var datas = await this.deleteCandidate(candidateId);
      if (datas.status) {
        this.offset = 1;
        await this.viewCandinateList();
      }
    },
    openmobilesearch() {
      this.show = true;
      this.result = [];
      this.input = "";
      document
        .querySelector(".Searching_input_box_mobile_view")
        .classList.add("open");
    },

    async closemobilesearch() {
      this.show = false;
      document.querySelector(".Searching_input_box_mobile_view").classList.remove("open");
      this.result = [];
      this.input = "";
      this.filters.name = "";
      await this.viewCandinateList();
    },
  },
};
</script>

<style scoped>
.page-item.disabled .page-link {
  background-color: #fff !important;
  border-color: #fff !important;
}

.pagination .page-item .page-link {
  width: 30px;
  height: 32px;
  background: transparent;
  display: flex;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  margin: 5px;
  color: #000 !important;
  border: none;
  cursor: pointer;
}

.pagination {
  justify-content: flex-end;
}

.pagination .page-item.active .page-link {
  font-weight: 600;
  background: #ffc801;
}

.pagination .page-item:last-child .page-link {
  background-color: transparent;
  border: none;
  width: 100% !important;
}

.pagination .page-item:first-child .page-link {
  background-color: transparent;
  border: none;
  width: 100% !important;
}

.pagination .page-item.disabled .page-link {
  color: rgb(130, 130, 130) !important;
  cursor: no-drop !important;
}

.active_inc_dec_button {
  background-color: #e8e4ff !important;
  color: gray !important;
  padding: 4px 15px;
  margin-bottom: 5px;
}

.btn_view_Report {
  color: #7b68ee;
  cursor: pointer;
}

.table thead th {
  text-transform: capitalize;
  color: #000;
}

@media (max-width: 480px) {
  .font-size-MV {
    font-size: 13px;
  }
}

.table thead th {
  text-transform: capitalize;
  color: #000;
  font-weight: 700;
  font-size: 15px;
}

.font-weight {
  font-weight: 700;
}

.active_form_button2 {
  background-color: #fff !important;
  color: #7b68ee !important;
}

.btn-active-TestView {
  padding: 4px 15px;
  margin-bottom: 5px;
}

.notialert-kj-view {
  border-radius: 50%;
  padding: 0.5rem;
  border: 1px solid #7c68ee;
  background-color: #f1f0ff;
  color: #7c68ee;
  top: 0;
  position: absolute;
  width: 20px;
  right: -20px;
  font-size: 11px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cal-alert {
  border-radius: 50%;
  padding: 0.4rem;
  /* border: 1px solid #7c68ee; */
  background-color: #ffdd00;
  left: 90%;
  /* color: #7c68ee; */
  top: 10%;
  position: absolute;
}
.center-elements{
  display: flex; 
  justify-content: center;
}
/* #showData{
  position: relative;
  z-index: 1;
} */
#layer{
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: -2%;
  left: 1%;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.disable-text-selection {
  user-select: none;
 }
</style>
