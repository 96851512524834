<template>
    <div class="page-content">
      <!-- mobile view Searching -->
      <Tour :page="componentName"/>
      <div class="Searching_input_box_mobile_view mb-3 position-relative">
        <div class="w-100" v-if="this.show">
          <input type="text" v-model="input" :disabled="searchDisable" @keyup.enter="searchregionname(input)"
            placeholder="Search..." />
          <img src="../../../public/dashboard-assets/images/M2/Close_icon.png" @click="closemobilesearch" alt=""
            class="close-icon" />
        </div>
      </div>
      <!-- header -->
      <div class="d-flex justify-content-between align-items-center flex-wrap mb-2">
        <div class="my-2">
          <h4 class="mb-2 mb-md-0" id="test-token-management-page">Token Management</h4>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <!-- Search  web view-->
          <div class="top_heading_CreatePartner_btn position-relative z-index-999">
            <div class="Group_top_three_dot">
              <div class="Searching_input-box">
                <input type="text" v-model="input" :disabled="searchDisable" @keyup.enter="searchregionname(input)"
                  placeholder="Search..." />
                <span class="icon Group_top_three_dot_dropdown" @click="opensearch">
                  <img src="../../../public/dashboard-assets/images/M2/Icon-Set.png" alt="" class="Searching_search_icon" />
                </span>
                <img src="../../../public/dashboard-assets/images/M2/Close_icon.png" @click="closesearch" alt=""
                  class="close-icon" />
              </div>
            </div>
          </div>
  
          <!-- Mobile View Searching Button -->
          <div class="top_heading_CreatePartner_btn position-relative Searching_button_mobileView">
            <div class="Group_top_three_dot">
              <span class="icon Group_top_three_dot_dropdown" :style="{ display: this.show == true ? 'none' : 'block' }"
                @click="openmobilesearch">
                <img src="../../../public/dashboard-assets/images/M2/Icon-Set.png" alt="" class="Searching_search_icon" />
              </span>
            </div>
          </div>
  
          <!-- Filter -->
          <div class=" top_heading_CreatePartner_btn top_heading_CreatePartner_btn-kj d-block
          mx-2" id="closefiltr">
            <div class="Group_top_three_dot">
              <div class="dropdown">
                <button class="btn p-0 Group_top_three_dot_dropdown position-relative" type="button" id="filterview"
                  data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" aria-haspopup="true"
                  style="line-height: 0.3" @click="filterData">
                  <img src="../../../public/dashboard-assets/images/M2/Filter_icon.png" />
  
                  <span class="Noti-alert-kj translate-middle" v-if="this.applyFilter > 0">
                    {{ this.applyFilter }}<span class="visually-hidden"></span>
                  </span>
                </button>
  
                <ul class="dropdown-menu dropdown-menu-kj" aria-labelledby="dropdownMenuButton1">
                  <div class="modal-header">
                    <h4 class="modal-title text-centerm-auto" id="CreateGroupModalLabel">
                      Filter
                    </h4>
                    <button type="button" class="btn-close" @click="crossfilter"></button>
                  </div>
  
                  <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item w-100" role="presentation">
                      <button class="nav-link active" id="pills-profile-tab" data-bs-toggle="pill"
                        data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                        aria-selected="false">
                        Partner
                        {{
                          this.filter.clientName.length > 0
                          ? "(" + this.filter.clientName.length + ")"
                          : ""
                        }}
                      </button>
                    </li>
                  </ul>
                  <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active p-0" id="pills-profile" role="tabpanel"
                      aria-labelledby="pills-profile-tab">
                      <div class="search-bar">
                        <div class="search-form">
                          <input type="text" placeholder="Search" name="" class="form-control bg-white"
                            v-model="searchfilter" @keyup="Filterresult($event)" /><button class="btn">
                            <i class="fas fa-search"></i>
                          </button>
                        </div>
                      </div>
                      <div class="ul-li-checkbox-kj">
                        <ul>
                          <li v-for="item in this.partnersClientName" :key="item">
                            <div>
                              <input type="checkbox" name="" id="" :value="item.clientId"
                                @change="addFilter(item.clientId, $event)" :checked="this.filter.clientName.includes(item.clientId)
                                  " />
                            </div>
  
                            <span>{{ item.company }}</span>
                          </li>
                        </ul>
                        <div class="text-end">
                          <button type="button" class="btn-Apply-kj-color" @click="clearFilter" v-if="this.applyFilter > 0">
                            Clear Filter
                          </button>
                          <button type="button" class="modail_submit_button"
                            :class="{ active_form_button: this.applyFilter > 0 }" :disabled="this.applyFilter == 0"
                            @click="applyFilters">
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </ul>
                <!-- </div> -->
              </div>
            </div>
          </div>
  
          <!-- changes -->
        </div>
      </div>
      <!-- end -->
      <div class="row">
        <div class="col-xl-5 col-lg-8 partners_top_menu_section_hp">
          <div class="row">
            <div class="col-6">
              <router-link :to="{ name: 'OverviewTokenManagementTour' }" class="w-100 btn">
                Overview
              </router-link>
            </div>
            <div class="col-6">
              <router-link :to="{ name: 'TestTokenManagementTour' }"
                class="w-100 btn active btn-outline-primary btn-icon-text">
                View Test <span>({{ totalItemsVIewtest }})</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
  
      <!-- empty section -->
      <section class="Partner_empty_main_section_hp mt-4" v-if="viewData.length == 0">
        <div class="row">
          <div class="Partner_empty_img_hp m-auto text-center">
            <img src="../../../public/dashboard-assets/images/M2/empty_record.png" alt="" />
            <h2>No Record Found</h2>
          </div>
        </div>
      </section>
  
      <section class="Partner_empty_main_section_kj mt-3" v-else>
        <div class="row">
          <div class="col-md-6 col-lg-4 col-xl-3 mb-3 stretch-card" v-for="item in viewData" :key="item">
            <div class="card p-0" id="test-token-management">
              <!-- <router-link :to="{ name: '' }"> -->
              <div class="card-body p-3">
                <div class="Partner_management_top-kj mb-2">
                  <div class="d-block">
                    <p class="mb-2"><span class="text-muted me-1" style="background: none;font-size: 12px;padding: 0;">
                        TestId: </span> <span class="test-dark fw-bold"
                        style="background: none;font-size: 12px;padding: 0;color:#000">{{ item.testId }}</span></p>
                    <h5 class="text-dark">
                      {{ item.testName }}
                    </h5>
                  </div>
                </div>
                <div class="my-3">
                  <h6 class="text-muted">Partner</h6>
                  <h4 class="text-dark" v-if="item.clientName.length > 40">
                    {{
                      item.clientName.substring(
                        0,
                        item.clientName.lastIndexOf(item.clientName.substring(40))
                      ) + "..."
                    }}
                  </h4>
                  <h4 class="text-dark" v-else>
                    {{ item.clientName }}
                  </h4>
                </div>
  
                <div class="Partner_management_top-kj">
  
                  <div class="Partner_management_contant-2-kj">
                    <div class="mb-3">
                      <span class="text-dark"> Tokens Remaining</span>
                    </div>
                    <h6>{{ item.allocatedToken === 0 ? 'NA' : item.remainingToken }}</h6>
                  </div>
                  <div class="Partner_management_contant-kj">
                    <div class="mb-3">
                      <span class="text-dark"> Tokens Allocated</span>
                    </div>
                    <h6>{{ item.allocatedToken === 0 ? 'NA' : item.allocatedToken }}</h6>
                  </div>
                </div>
              </div>
              <!-- </router-link> -->
              <div class="card-footer card-footer-bg-kj" data-bs-toggle="modal" data-bs-target="#AllocateTokens" @click="getCompanyName(item.testName, item.remainingToken, item.testId)
                " v-if="superadmin() && item.requestCount == 0">
                Allocate Tokens
              </div>
              <div class="card-footer card-footer-bg-kj" data-bs-toggle="modal" type="button"
                data-bs-target="#RequestedTokenModal" v-if="superadmin() && item.requestCount > 0" @click="ViewReqList(item.company, item.remainingToken, item.testId)
                  ">
                View {{ item.requestCount }} Request
              </div>
            </div>
          </div>
        </div>
  
        <paginate v-model="currentPage" :page-count="Page_count" :margin-pages="1" :page-range="1"
          :click-handler="clickCallback" :prev-text="'< Prev'" :next-text="'Next >'" :container-class="'pagination'"
          :page-class="'page-item'" v-if="viewData != 0">
        </paginate>
      </section>
  
      <!-- Requests Token Modal Popup -->
      <div class="modal fade CreatePartnerModal_hp RequestTokenModal_secction" id="RequestedTokenModal" tabindex="-1"
        aria-labelledby="RequestedTokenModal" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title text-centerm-auto" id="RequestedTokenModal">
                Allocate Tokens to {{ modalData.company }}
              </h4>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close" id="closerequest"
                @click="clearbtn()"></button>
            </div>
            <div class="modal-body">
              <div class="d-flex justify-content-between align-items-center">
                <h5>{{ reqList?.length }}-Pending Requests</h5>
  
                <p class="request-color-muted font-size-12" :class="{ error: this.redtext }">
                  Balance Tokens:{{
                    this.parentCompany.remainingToken - this.remainingToken
                  }}
                </p>
              </div>
              <div class="d-flex justify-content-between align-items-center mt-3 RequestTokenModal_item_box"
                v-for="(item, index) in reqList" :key="item">
                <div class="RequestTokenModal_item_box_company">
                  <img src="../../../public/dashboard-assets/images/M2/UsersDP2.png" alt="" />
                  <div>
                    <h5>{{ item.firstName }}&nbsp;{{ item.lastName }}</h5>
                    <p class="request-color-token">
                      Requested {{ item.tokenAmount }} Tokens
                    </p>
  
                    <p class="request-color-token-date">
                      {{ dateConvert(item.requestedDate) }}&nbsp;{{
                        timeConverter(item.requestedDate)
                      }}
                    </p>
                  </div>
                </div>
                <!-----<div class="RequestTokenModal_item_box_button">--->
                <div class="d-flex">
                  <button class="modail_submit_button RequestTokenModal_item_box_CheckButton px-5 py-1 mx-1" id="right"
                    :hidden="!this.approvedReq.includes(item.requestId)">
                    <i class="fa-solid fa-check"></i>
                  </button>
                  <button class="modail_submit_button px-3 py-1 mx-1 text-muted float-start border-secondary"
                    data-bs-toggle="modal" data-bs-target="#RejectTokenModal" id="remove"
                    :hidden="this.approvedReq.includes(item.requestId)" @click="rejectBtn(
                          item.firstName,
                          item.lastName,
                          item.tokenAmount,
                          item.requestId,
                          item.testId
                        )
                        ">
                    Reject
                  </button>
                  <button class="modail_submit_button px-3 py-1 mx-1" id="aprove" @click="approverequest(
                        item.requestId,
                        'Approved',
                        index,
                        item.tokenAmount,
                        item.testId
                      )
                      " :hidden="this.approvedReq.includes(item.requestId)" :disabled="this.parentCompany.remainingToken < item.tokenAmount
          ">
                    Approve
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <!-- model Request Token -->
      <div class="modal fade" id="RequestTokenModal" tabindex="-1" aria-labelledby="IncreaseTokenModalLabel"
        data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable model-sm">
          <div class="modal-content border-radius-kj-20">
            <div class="modal-header token-model-header-color">
              <h5 class="modal-title text-centerm-auto" id="IncreaseTokenModalLabel">
                Request Tokens
              </h5>
              <button id="RequestTokensclose" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"
                @click="clearbtn('RequestTokenModal')"></button>
            </div>
            <div class="modal-body">
              <h5 class="my-4">How many tokens do you need?</h5>
              <form class="row my-3" method="">
                <div class="tokenPrice token-model-kj d-flex">
                  <div class="value-button-min" id="decrease" :disabled="decDisableButton()"
                    :class="{ active_inc_dec_button: decDisableButton() }" @click="decreaseValue()" value="Decrease Value">
                    <i class="fa-solid fa-minus"></i>
                  </div>
                  <input type="number" class="w-75 input-number-token-kj" id="number" placeholder="0" :class="{
                    input_error_border:
                      reqtoken > 5000 && this.clientId != 1001,
                  }" oninput="validity.valid||(value='');" v-model="reqtoken" />
                  <div class="value-button-max" id="increase" :disabled="inrDisableButton()"
                    :class="{ active_inc_dec_button: inrDisableButton() }" @click="increaseValue()" value="Increase Value">
                    <i class="fa-solid fa-plus"></i>
                  </div>
                </div>
                <p v-if="this.reqtoken > 5000 && this.clientId != 1001" style="color: red">
                  You cannot request more than 5000 tokens
                </p>
                <h5 class="my-3">
                  Balance Tokens: {{ regionAllocationList.remainingToken }}
                </h5>
                <div class="text-end mt-2">
                  <button type="button" class="btn btn-token-color-kj" :disabled="!isRequestedButtonDisable()" :class="{
                    actived_form_button:
                      (reqtoken > 0 && reqtoken < 5001) ||
                      (reqtoken >= 5001 && this.clientId == 1001),
                  }" @click="RequestTokens">
                    Request
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- AllocateTokens -->
      <div class="modal fade" id="AllocateTokens" tabindex="-1" aria-labelledby="IncreaseTokenModalLabel" aria-hidden="true"
        data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable model-sm">
          <div class="modal-content border-radius-kj-20">
            <div class="modal-header token-model-header-color">
              <h4 class="modal-title text-centerm-auto" id="IncreaseTokenModalLabel">
                Allocate Tokens
              </h4>
              <button type="button" id="close" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"
                @click="clearbtn('AllocateTokens')"></button>
            </div>
            <div class="modal-body">
              <h5 class="my-4">
                How many tokens do you want to allocate to
                <strong class="text-dark"> {{ clientTestName }}? </strong>
              </h5>
              <form class="row my-3" method="">
                <div class="tokenPrice token-model-kj d-flex">
                  <div class="value-button-min" id="decrease" :disabled="decDisableAllocatedButton()" :class="{
                    active_inc_dec_button: decDisableAllocatedButton(),
                  }" @click="decreaseallocatevalue()" value="Decrease Value">
                    <i class="fa-solid fa-minus"></i>
                  </div>
                  <input type="number" class="w-75 input-number-token-kj" placeholder="0" id="number"
                    oninput="validity.valid||(value='');" v-model="allocatedTokenAmount" />
                  <div class="value-button-max" id="increase" @click="increaseallocateValue()" value="Increase Value">
                    <i class="fa-solid fa-plus"></i>
                  </div>
                </div>
                <h5 class="my-3 request-color-token">
                  Balance Tokens: {{ balanceToken }}
                </h5>
                <div class="text-end mt-2">
                  <button type="button" class="btn btn-token-color-kj" :disabled="!requestbuttondisable()"
                    :class="{ actived_form_button: requestbuttondisable() }"
                    @click="postAmountAllocation(viewTestId, clientTestName)">
                    Allocate
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- RejectTokenModal -->
      <div class="modal fade" id="RejectTokenModal" tabindex="-1" aria-labelledby="RejectTokenModalLabel" aria-hidden="true"
        data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-body p-4">
              <div class="">
                <h3 class="mb-3">Reject Tokens?</h3>
                <p class="text-muted">
                  Are you sure you want to reject
                  <strong class="text-dark"> {{ this.amt }}</strong> tokens
                  requested from {{ this.firstname }}&nbsp;{{ this.lastname }}.
                </p>
              </div>
              <div class="Deactivate_buttons row mt-3 justify-content-center">
                <button class="col-5 Deactivate_Cancel_buttons" data-bs-dismiss="modal" aria-label="btn-close"
                  id="rejectcancel" @click="closeModal()">
                  Cancel
                </button>
                <button type="button" class="col-5 btn Deactivate_Deactivate_buttons"
                  @click="rejectToken(this.reqId, 'Rejected', this.amt)">
                  Reject
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Archive Test -->
      <div class="modal fade" id="DeleteUserModal" tabindex="-1" aria-labelledby="DeleteUserModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-body p-4">
              <div class="">
                <h3 class="mb-3">Archive Test?</h3>
                <p class="text-muted">
                  Are you sure you want to Archive
                  <strong class="text-dark"> PDX Recruiter Test 2020. </strong>
                </p>
              </div>
              <div class="Deactivate_buttons row mt-5 justify-content-center">
                <button class="col-5 Deactivate_Cancel_buttons" data-bs-dismiss="modal" aria-label="btn-close">
                  Cancel
                </button>
                <button type="button" class="col-5 btn Deactivate_Deactivate_buttons">
                  Archive
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions, mapMutations } from "vuex";
  import Paginate from "vuejs-paginate-next";
  import { superadmin } from "../../utils/helper";
  import moment from "moment";
  import testTokenManagementObject from '../../assets/TourObjects/testTokenManagementTourObject.json';
  import Tour from '../TourDemo.vue';
  export default {
    name: "TokenmanagementViewTest",
    data() {
      return {
        show: false,
        viewData: [],
        totalItemsVIewtest: 0,
        page: 1,
        limit: 8,
        search: "",
        partners: "",
        Page_count: 1,
        reqtoken: null,
        result: [],
        tokenList: [],
        allocatedTokenAmount: null,
        listData: "",
        offset: 8,
        groupData: [],
        regionAllocationList: [],
        //filter
        filter: { clientName: [] },
        applyFilter: 0,
        searchfilter: "",
        dataView: [],
        //filter
  
        //search
        results: [],
        input: "",
        filters: {
          company: "",
        },
        company: [],
        currentPage: 1,
        //search
        searchDisable: false,
        clientTestName: "",
        balanceToken: 0,
        viewTestId: "",
        Testname: "",
        partnersClientName: [],
        partner: [],
        ReqTestId: null,
        modalData: "",
        reqList: [],
        parentCompany: "",
        redtext: false,
        remainingToken: 0,
        approvedReq: [],
        firstname: "",
        lastname: "",
        amt: "",
        clientid: "",
        reqId: "",
        isActive: true,
        componentName: ''
      };
    },
  
    components: {
      paginate: Paginate,
      Tour
    },
    created() {
      this.componentName = this.$route.name;
    },
    async mounted() {
      await this.getviewData();
    },
  
    computed: {
      ...mapState({
        authenticated: (state) => state.auth.authenticate,
        user: (state) => state.auth.user,
        clientId: (state) => state.auth.user.clientId,
        PartnerId: (state) => state.auth.user.PartnerId,
      }),
    },
    methods: {
      superadmin,
      ...mapActions([
        "viewtestList",
        "RequestToken",
        "GetTAllocation",
        "GetTokenAllocation",
        "allocateToken",
        "viewGroupsList",
        "IncrOrDecTokens",
        "piechartPartner",
        "AllPartnerList",
        "requestedTokens",
        "rejectReq",
        "requestedTokens",
        "testRequest",
        "rejectRequestTest",
      ]),
  
      ...mapMutations(["setLoading"]),
      dateConvert: (value) => {
        return moment(String(value)).format("DD MMM YYYY");
      },
      //time-converter
      timeConverter(val) {
        const today = new Date(val);
        let h = today.getHours();
        let m = today.getMinutes();
        var meridian = h >= 12 ? "PM" : "AM";
        h = h % 12;
        h = h ? h : 12;
        m = m < 10 ? "0" + m : m;
        var strTime = h + ":" + m + " " + meridian;
        return strTime;
      },
      async approverequest(reqId, status, index, tokenamount, id) {
        var rejectrequest = await this.rejectRequestTest({
          testId: id,
          requestId: reqId,
          status: status,
          tokenAmount: tokenamount,
          company: this.modalData.company,
        });
        this.isActive = false;
        if (rejectrequest.status) {
          this.approvedReq.push(reqId);
  
          setTimeout(async () => {
            var request = await this.testRequest({
              testId: id,
            });
            this.reqList = request?.object?.data;
            if (this.reqList.length == 0) {
              document.getElementById("closerequest").click();
            } else {
              this.reqList.forEach((element) => {
                if (element.tokenAmount > this.parentCompany.remainingToken) {
                  this.redtext = true;
                } else {
                  this.redtext = false;
                }
              });
            }
          }, 3000);
        } else {
          this.parentCompany.remainingToken =
            this.parentCompany.remainingToken - tokenamount;
        }
  
        this.remainingToken = this.reqList[index].tokenAmount;
        await this.getgroupList();
        await this.getviewData();
        await this.getviewTestAllocation();
      },
      async rejectBtn(firstName, lastName, amt, reqid, clientId) {
        (this.firstname = firstName),
          (this.lastname = lastName),
          (this.amt = amt),
          (this.reqId = reqid),
          (this.clientid = clientId);
      },
      async rejectToken(reqId, status, tokenamount) {
        await this.rejectRequestTest({
          testId: this.clientid,
          requestId: reqId,
          status: status,
          tokenAmount: tokenamount,
          company: this.modalData.company,
        });
        this.closeModal();
        document.getElementById('rejectcancel').click();
  
        await this.getviewData();
      },
      openRequestToken(id) {
        window.$("#RequestTokenModal").modal("show");
        this.ReqTestId = id;
      },
      async ViewReqList(companyName, balanceToken, id) {
        this.modalData = {
          company: companyName,
          remainingToken: balanceToken,
          clientId: id,
        };
        var request = await this.testRequest({
          testId: id,
        });
        if (request != undefined) {
          this.reqList = request.object.data;
          this.checkBalance();
          this.reqList.forEach((element) => {
            if (element.tokenAmount > this.parentCompany.remainingToken) {
              this.redtext = true;
            } else {
              this.redtext = false;
            }
          });
        }
  
        // this.getData();
      },
  
      checkBalance(amt) {
        Array.prototype.sum = function (prop) {
          var total = 0;
          for (var i = 0, len = this.length; i < len; i++) {
            total += this[i][prop];
          }
          return total;
        };
        var total = this.reqList.sum("tokenAmount");
        if (total > amt) return true;
        else return false;
      },
  
      // Searching box
      opensearch() {
        document.querySelector(".Searching_input-box").classList.add("open");
      },
      async closesearch() {
        document.querySelector(".Searching_input-box").classList.remove("open");
        this.results = [];
        this.input = "";
        this.filters.company = "";
        await this.getviewData();
      },
      async closemobilesearch() {
        this.show = false;
        document
          .querySelector(".Searching_input_box_mobile_view")
          .classList.remove("open");
        this.results = [];
        this.filters.company = "";
        this.input = "";
        await this.getviewData();
      },
      openmobilesearch() {
        document
          .querySelector(".Searching_input_box_mobile_view")
          .classList.add("open");
        this.results = [];
        this.clientId;
        this.input = "";
        this.show = true;
      },
  
      async filteredList() {
        if (this.input != "") {
          this.company = [];
          this.results = [];
          var list = await this.viewtestList({
            clientId: this.clientId,
            Page: this.page,
            Limit: this.limit,
            Search: this.input,
          });
          var x = list.object.data.items;
          x.forEach((element) => {
            if (this.company.indexOf(element.testName) <= 0) {
              this.company.push(element.testName);
            }
          });
          this.results = this.company.filter((element) =>
            element.toLowerCase().startsWith(this.input.toLowerCase())
          );
          this.results = Object.freeze(JSON.parse(JSON.stringify(this.results)));
        } else {
          this.results = [];
          this.filters.company = "";
          await this.getviewData();
        }
      },
      stringTOHtml(item, idx) {
        setTimeout(() => {
          if (this.input != "") {
            let re = new RegExp(this.input.toLowerCase(), "g");
            var html = document.getElementById(idx);
            var ex = item
              .toLowerCase()
              .replace(
                re,
                `<span style="color: #7b68ee !important">${this.input}</span>`
              );
            if (html) html.innerHTML = ex;
          }
        }, 100);
      },
      async searchregionname(val) {
        this.input = val.trim();
        this.page = 1;
        this.filters.company = this.input;
        this.results = [];
        this.currentPage = 1;
        await this.getviewData();
      },
      //*********search end*********
  
      //filter
      async addFilter(value, e) {
        if (e.target.checked) {
          this.filter.clientName.push(value);
        } else {
          this.filter.clientName.splice(
            Object.freeze(
              JSON.parse(JSON.stringify(this.filter.clientName))
            ).indexOf(value),
            1
          );
        }
        this.applyFilter = this.filter.clientName.length;
        if (this.applyFilter == 0) {
          await this.getgroupList();
          await this.getviewData();
          await this.getviewTestAllocation();
  
          var partners = await this.AllPartnerList({
            clientId: this.clientId,
            offset: 1,
            limit: 100,
          });
          this.partnersClientName = partners.object.data.partnerData;
        }
      },
      async clearFilter() {
  
        this.filter.clientName = [];
        this.applyFilter = 0;
        this.searchfilter = "";
        await this.getviewData();
        document.getElementById("filterview").click();
      },
      async applyFilters() {
        this.page = 1;
        this.currentPage = 1;
        await this.getviewData();
        document.getElementById("filterview").click();
      },
      crossfilter() {
        document.getElementById("filterview").click();
      },
      Filterresult(evt) {
        this.partnersClientName = this.partner;
        if (evt.target.value != "") {
          this.partnersClientName = this.partnersClientName.filter((element) =>
            element.company.toLowerCase().includes(evt.target.value.trim().toLowerCase())
          );
        } else {
          this.partnersClientName = this.partner;
        }
      },
      //filter
  
      clickCallback(pageNum) {
        this.page = pageNum;
        this.getviewData();
      },
  
      async getCompanyName(testName, balanceToken, id) {
        this.clientTestName = testName;
        this.balanceToken = balanceToken;
        this.viewTestId = id;
      },
  
      async getviewData() {
        this.searchDisable = true;
        var detail = testTokenManagementObject;
        this.searchDisable = false;
        var a = [];
        if (detail != undefined) {
          this.viewData = detail.object.data.items;
          //filter
          this.dataView = detail.object.data.items;
          //filter
          this.viewData.forEach((element) => {
            a.push(element.clientId);
          });
  
          this.totalItemsVIewtest = detail.object.data.totalItems;
          this.Page_count = detail.object.data.totalPages;
        }
        if (this.input.trim() != "") {
          detail.Search = this.input;
        }
      },
  
      async filterData() {
        var detail = await this.piechartPartner({
          clientId: this.clientId,
        });
  
        if (detail != undefined) {
          //filter
          this.dataView = detail.object.data.items;
          //filter
        }
      },
  
      async RequestTokens() {
        this.result = await this.RequestToken({
          testId: this.ReqTestId,
          amount: this.reqtoken,
        });
        window.$("#RequestTokenModal").modal("hide");
        this.reqtoken = null;
        this.getviewTestAllocation();
      },
  
      clearbtn(modelId) {
        if (modelId == "RequestTokenModal") {
          this.reqtoken = null;
        } else {
          this.allocatedTokenAmount = null;
        }
      },
      inrDisableButton() {
        return this.reqtoken > 5000;
      },
  
      decDisableButton() {
        if (
          this.reqtoken == null ||
          this.reqtoken == 0 ||
          this.reqtoken == "" ||
          this.reqtoken < 0
        ) {
          return true;
        } else {
          return false;
        }
      },
      decDisableAllocatedButton() {
        if (
          this.allocatedTokenAmount == null ||
          this.allocatedTokenAmount == 0 ||
          this.allocatedTokenAmount == "" ||
          this.allocatedTokenAmount < 0
        ) {
          return true;
        } else {
          return false;
        }
      },
  
      isRequestedButtonDisable() {
        if (this.reqtoken > 0 && this.clientId == 1001) {
          return true;
        } else {
          if (this.reqtoken > 0 && this.reqtoken < 5001) {
            return true;
          } else if (this.reqtoken > 5000) {
            return false;
          }
        }
      },
      requestbuttondisable() {
        return this.allocatedTokenAmount > 0;
      },
      async decreaseValue() {
        if (
          this.reqtoken == null ||
          this.reqtoken == 0 ||
          this.reqtoken == "" ||
          this.reqtoken < 0
        ) {
          this.reqtoken = null;
        } else {
          this.reqtoken = this.reqtoken - 1;
        }
      },
      async increaseValue() {
        if (this.reqtoken == null || this.reqtoken == "" || this.reqtoken == 0) {
          this.reqtoken = 1;
        } else {
          if (parseInt(this.reqtoken) <= 5000) {
            this.reqtoken = parseInt(this.reqtoken) + 1;
          }
        }
      },
      async decreaseallocatevalue() {
        if (
          this.allocatedTokenAmount == null ||
          this.allocatedTokenAmount == 0 ||
          this.allocatedTokenAmount == "" ||
          this.allocatedTokenAmount < 0
        ) {
          this.allocatedTokenAmount = null;
        } else {
          this.allocatedTokenAmount = this.allocatedTokenAmount - 1;
        }
      },
      async increaseallocateValue() {
        if (
          this.allocatedTokenAmount == null ||
          this.allocatedTokenAmount == "" ||
          this.allocatedTokenAmount == 0
        ) {
          this.allocatedTokenAmount = 1;
        } else {
          this.allocatedTokenAmount = this.allocatedTokenAmount + 1;
        }
      },
  
      async postAmountAllocation(id, testname) {
        await this.allocateToken({
          testId: id,
          amount: this.allocatedTokenAmount,
          testName: testname,
        });
        document.getElementById("close").click();
        window.$("#AllocateTokens").modal("hide");
        this.allocatedTokenAmount = null;
        this.getviewData();
        this.getviewTestAllocation();
      },
  
      async getgroupList() {
        var listData = await this.viewGroupsList({
          clientId: this.clientId,
          page: this.page,
          limit: this.offset,
          search: this.search,
          partners: this.partners,
        });
  
        if (listData != undefined) {
          this.groupData = listData.object.data.items;
        }
      },
      async getviewTestAllocation() {
        var list = await this.GetTAllocation({
          clientId: this.clientId,
        });
        if (list != undefined) {
          this.regionAllocationList = list.object.data;
        }
      },
      closeModal() {
        document.getElementById('closerequest').click();
      }
    },
  };
  </script>
  <style>
  .active_form_button {
    background-color: #7b68ee !important;
    color: #fff !important;
  }
  
  .active_inc_dec_button {
    background-color: #e8e4ff !important;
    color: grey !important;
  }
  
  .page-item.disabled .page-link {
    background-color: #fff !important;
    border-color: #fff !important;
  }
  
  .input_error_border:focus-visible {
    border: 1px solid red !important;
    outline: none !important;
  }
  
  .input_error_border {
    border: 1px solid red !important;
  }
  
  .pagination .page-item .page-link {
    width: 30px;
    height: 32px;
    background: transparent;
    display: flex;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    margin: 5px;
    color: #000 !important;
    border: none;
    cursor: pointer;
  }
  
  .pagination {
    justify-content: flex-end;
  }
  
  .pagination .page-item.active .page-link {
    font-weight: 600;
    background: #ffc801;
  }
  
  .pagination .page-item:last-child .page-link {
    background-color: transparent;
    border: none;
    width: 100% !important;
  }
  
  .pagination .page-item:first-child .page-link {
    background-color: transparent;
    border: none;
    width: 100% !important;
  }
  
  .pagination .page-item.disabled .page-link {
    color: rgb(130, 130, 130) !important;
    cursor: no-drop !important;
  }
  
  
  input[type=number] {
    -moz-appearance: textfield;
  }</style>
  