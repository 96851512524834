<template>
  <div class="page-content">
    <!-- blue color used #4b2eee -->
    <!-- mobile view Searching -->
    <Tour v-if="this.user.redirectUrl != null && this.tourStatus" :page="componentName"
      @tour-started="handleTourStarted" @tour-ended="handleTourEnded" />
    <TestLibraryTour v-if="this.componentTour" :page="componentName" @tour-close="CloseComponentTour" />
    <div class="Searching_input_box_mobile_view mb-3 position-relative">
      <div class="w-100" v-if="this.show">
        <input type="text" v-model="searchInput" placeholder="Search..."
          @keyup.enter="searchTestDetails(searchInput)" />
        <img src="../../public/dashboard-assets/images/M2/Close_icon.png" @click="closemobilesearch" alt=""
          class="close-icon" />
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center flex-wrap mb-2">
      <div class="my-2">
        <h3 class="mb-2 mb-md-0">Test Library</h3>
        <!-- <p style="font-weight: 600; font-size: small; color: #7D7D7D;" class="mt-1 mx-1">
          Explore Test Library !
        </p> -->
      </div>
      <!-- Header Icons Start -->
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex justify-content-between align-items-center">
          <!-- Search  web view-->
          <div class="top_heading_CreatePartner_btn position-relative z-index-999 mx-2 tour-web-search" :class="this.componentTour == true ? 'disable-text-selection' : '' ">
            <div class="Group_top_three_dot">
              <div class="Searching_input-box">
                <input type="text" v-model="searchInput" placeholder="Search..."
                  @keyup.enter="searchTestDetails(searchInput)" />
                <span class="icon Group_top_three_dot_dropdown" @click="opensearch">
                  <img src="../../public/dashboard-assets/images/M2/Icon-Set.png" alt=""
                    class="Searching_search_icon" />
                </span>
                <img src="../../public/dashboard-assets/images/M2/Close_icon.png" @click="closesearch" alt=""
                  class="close-icon" />
              </div>
            </div>
          </div>

          <!-- Mobile View Searching Button -->
          <div class="
            top_heading_CreatePartner_btn
            position-relative
            Searching_button_mobileView mx-2 tour-mobile-search">
            <div class="Group_top_three_dot">
              <span class="icon Group_top_three_dot_dropdown" @click="openmobilesearch">
                <img src="../../public/dashboard-assets/images/M2/Icon-Set.png" alt="" class="Searching_search_icon" />
              </span>
            </div>
          </div>
        </div>

        <div class="top_heading_CreatePartner_btn top_heading_CreatePartner_btn-kj d-block mx-2 tour-filter" :class="this.componentTour == true ? 'disable-text-selection' : '' ">
          <div class="Group_top_three_dot">
            <div class="dropdown">
              <button class="btn p-0 Group_top_three_dot_dropdown position-relative" type="button" id="filterDropdown"
                data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" aria-haspopup="true"
                style="line-height: 0.3">
                <img src="../../public/dashboard-assets/images/M2/Filter_icon.png" />

                <span class="Noti-alert-kj translate-middle" v-if="this.applyfilter > 0">
                  {{ this.applyfilter }} <span class="visually-hidden"></span>
                </span>
              </button>

              <ul class="dropdown-menu dropdown-menu-kj" aria-labelledby="filterDropdown">
                <div class="modal-header p-3">
                  <h4 class="modal-title text-centerm-auto" id="CreateGroupModal1">
                    Filter
                  </h4>
                  <button type="button" class="btn-close" @click="filterclose"></button>
                </div>

                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                  <li class="nav-item w-50" role="presentation">
                    <button class="nav-link text-muted text-center active" id="pills-home-tab" data-bs-toggle="pill"
                      data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                      aria-selected="true" @click="
                        this.filterTab = 1;
                      this.searchfilter = '';
                      this.Filterresult();
                      ">
                      Job Level
                      {{
                        this.filters.jobLevel.length > 0
                          ? "(" + this.filters.jobLevel.length + ")"
                          : ""
                      }}
                    </button>
                  </li>
                  <li class="nav-item w-50" role="presentation">
                    <button class="nav-link text-muted text-center" id="pills-profile-tab" data-bs-toggle="pill"
                      data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                      aria-selected="false" @click="
                        this.filterTab = 2;
                      this.searchfilter = '';
                      this.Filterresult();
                      ">
                      Job Family
                      {{
                        this.filters.jobFamily.length > 0
                          ? "(" + this.filters.jobFamily.length + ")"
                          : ""
                      }}
                    </button>
                  </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div class="tab-pane fade show active p-0" role="tabpanel" aria-labelledby="pills-home-tab">
                    <div class="search-bar">
                      <form class="search-form">
                        <input type="text" placeholder="Search" name="" v-model="searchfilter" @keyup="Filterresult"
                          class="form-control bg-white" /><button class="btn">
                          <i class="fas fa-search"></i>
                        </button>
                      </form>
                      <div class="ul-li-checkbox-kj">
                        <ul v-if="this.filterTab == 1">
                          <li v-for="item in JobLevelResponse" :key="item">
                            <input type="checkbox" :value="item.id" @change="addFilter(item.id, $event)" :checked="this.filters.jobLevel.includes(
                              item.id
                            )" />
                            <span> {{ item.name }} </span>
                          </li>
                        </ul>

                        <ul v-else>
                          <li v-for="item in JobFamilyResponse" :key="item">
                            <input type="checkbox" :value="item.id" @change="addFilter(item.id, $event)" :checked="this.filters.jobFamily.includes(
                              item.id
                            )" />
                            <span> {{ item.name }} </span>
                          </li>
                        </ul>
                        <div class="align-items-center">
                          <button type="button" class="btn-Apply-kj-color" v-if="this.applyfilter > 0"
                            @click="clearFilters(true)">
                            Clear Filter
                          </button>
                          <button type="button" class="modail_submit_button text-end" :class="{
                            active_form_button: this.applyfilter > 0,
                          }" :disabled="this.applyfilter == 0" @click="applyFilters()">
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <div class="top_heading_CreatePartner_btn top_heading_CreatePartner_btn-kj d-block mx-2 tour-check-bookmark-details" :class="this.componentTour == true ? 'disable-text-selection' : '' "> 
          <div class="Group_top_three_dot">
            <div class="icon Group_top_three_dot_dropdown"
              style=" display: flex !important; justify-content: center !important; align-items: center !important;">
              <router-link :to="{ name: 'TestLibraryBookmark' }" class="p-0 my-2 mx-1" type="button">
                <i class="fa-solid fa-bookmark fa-lg" style="color: #7D7D7D; margin: auto !important;"></i>
              </router-link>
            </div>
          </div>
        </div>



      </div>
    </div>
    <!-- Header Icons End -->

    <!-- Search By Filter Starts -->

    <div class="my-2">
      <!-- <div class="row">
        <div class="col-12 col-lg-1 mt-1" style="font-size:100% ;">Search by:</div>
        <div class="col-12 col-lg-11">
          <div class="d-flex flex-wrap">
            <div class="demo-preview mt-1" v-for="item in CategoryList" :key="item.id">
              <div :id="item.id" class="badge filter-badge" @click="addCompetencyFilters(item.id)" style="cursor: pointer;">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <img src="../../public/dashboard-assets/images/M7/Actions.png" width="25" class=" cursor-pointer ms-2"
                    alt="" data-bs-toggle="tooltip" data-bs-placement="top"
                    title="Includes the definitions, scores and descriptions of the candidate on all cognitive competencies. " /> -->


      <!-- Search By Filter Ends -->
      <!-- Card Details Starts -->
      <div class="my-4">
        <section class="Partner_empty_main_section_kj mt-3">
          <div class="row">
            <div class="col-md-6 col-lg-6 col-xl-3 mb-4 stretch-card" v-for="(item, index) in CardDetails"
              :key="item.id">
              <div class="card p-0 tour-test-card" :class="screenRatioClass" v-on:mouseover="hoverIndex = index"
                v-on:mouseout="hoverIndex = null" :style="{ backgroundColor: hoverIndex === index ? '#E4DFFF' : '' }"
                id="screen-ratio" >
                <div class="card-body p-0" id="card-tour">
                  <!-- data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling"
                  aria-controls="offcanvasScrolling" -->
                  <!-- <div class="test-library-card-banner" :style="{ backgroundColor: item.cardColor }"></div> -->
                  <div class="float-end mx-3 my-2">
                    <span @click="cardUrl(item.websiteUrl)" v-if="
                      getUserPermissionData({
                        parent: 'Test Library Management',
                        child: 'Test Library',
                        permission: 'Can_Share_Test_Library',
                      })
                    " class="tour-share-web-icon" :class="this.componentTour == true ? 'disable-text-selection' : '' ">
                      <i class="fa-solid fa-share-nodes mx-1" data-bs-toggle="modal" data-bs-target="#SharelinkModel"
                        style="cursor: pointer;color: #2d2d2d;"></i>
                    </span>
                    <span v-if="item.bookmarkId !== null" @click="UpdateBookmark(item)" class="tour-test-bookmark" :class="this.componentTour == true ? 'disable-text-selection' : '' ">
                      <i class="fa-solid fa-bookmark mx-1" style="cursor: pointer; color: #2d2d2d; "></i>
                    </span>
                    <span v-else @click="UpdateBookmark(item)" class="tour-test-bookmark" :class="this.componentTour == true ? 'disable-text-selection' : '' ">
                      <i class="fa-regular fa-bookmark mx-1" style="cursor: pointer; color: #2d2d2d;"></i>
                    </span>
                    <span class="Group_top_three_dot action-dots" v-if="superadmin() || this.user.isEnterprise == true" >
                      <span class="dropdown">
                        <button class="btn p-0" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                          aria-haspopup="true" aria-expanded="false">
                          <i class="fa-solid fa-ellipsis mx-1" style="cursor: pointer; color: #2d2d2d;"></i>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <li>
                            <button type="button" class="btn w-100 text-start" @click="notifyRm(item.testId)">
                              Request Access
                            </button>
                          </li>
                        </ul>
                      </span>
                    </span>

                  </div>
                  <div class="mt-5 mx-3 ">
                    <div class="test-name tour-test-name ">
                      {{ item.testName }}
                    </div>

                    <div class="row mt-2 mb-2">
                      <div class="col-6 test-time-questions tour-test-time py-2">
                        <i class="fa-solid fa-stopwatch fa-lg " style="color: #B4B4B4;"></i>
                        <span class="px-2 ">{{
                          dateConvert(item.testTime) }}</span>
                      </div>
                      <div class="col-6 test-time-questions tour-job-level py-2">
                        <i class="fa-solid fa-chart-simple fa-lg" style="color: #B4B4B4;"></i>
                        <span class="px-2 ">{{
                          item.jobLevel === null ? 'NA' : item.jobLevel }}</span>
                      </div>
                    </div>
                    <div class="my-3 mt-2">
                      <div class="d-flex flex-wrap tour-competencies py-2">
                        <div v-for="(tags, index) in item.tagDetails" :key="tags.testLibraryCategoryId">
                          <div class="demo-preview" style="cursor: default;">
                            <div class="badge badge-outlined mb-2">
                              {{ activeTags(tags, index) }}
                            </div>
                          </div>
                          <div data-bs-toggle="tooltip" v-if="index == this.hideTagsCount" data-bs-placement="top"
                            :title="tooltipTags(item.tagDetails)" class="test-tags-counter">
                            +{{ item.tagDetails.length - this.hideTagsCount }}
                          </div>
                        </div>
                      </div>
                      <!-- <div class="mt-4" style="font-weight: 500; font-size: 13px;">
                        {{ item.shortDescription }}
                      </div> -->
                      <div class="mt-2 tour-description test-description py-2">
                        {{ item.shortDescription.length > this.descriptionCharCount ? item.shortDescription.slice(0,
                          this.descriptionCharCount) + '...' :
                          item.shortDescription }}
                      </div>
                    </div>
                    <div style="text-align: center;" class="row mb-2">
                      <div class="col-6 tour-check-details py-2" :class="this.componentTour == true ? 'disable-text-selection' : '' ">
                        <button data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling"
                          aria-controls="offcanvasScrolling" @click="GetOffcanvasData(item.testId)"
                          class="test-primary-button ">
                          Details
                        </button>
                      </div>
                      <div class="col-6 tour-request-access py-2" :class="this.componentTour == true ? 'disable-text-selection' : '' ">
                        <button v-if="superadmin() || this.user.isEnterprise == true" @click="notifyRm(item.testId)"
                          class="test-secondary-button" >
                          Request Access
                        </button>
                        <button v-if="!superadmin() && this.user.isEnterprise == false"
                          @click="deployTestCard(item.testId)" class="test-secondary-button">
                          Add Test
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <!-- Card Details Ends -->

      <!-- OffCanvas Details Starts -->
      <div class="offcanvas offcanvas-end role_item_view_detail_sidebar" data-bs-scroll="false"
        data-bs-backdrop="static" tabindex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
        <div class="offcanvas-header">
          <h5 id="offcanvasScrolling" style="color: white; font-size:larger; width: 90% !important;">
            {{ OffcanvasDetails.testName }}
          </h5>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"
            @click="closeOffcanvas"></button>
        </div>
        <div class="offcanvas-body">
          <div class="my-2" style="font-weight: 600; font-size: 15px;">
            {{ OffcanvasDetails.jobLevel === null ? 'NA' : OffcanvasDetails.jobLevel }}
          </div>
          <!-- style="display: flex; justify-content: center;" -->
          <div style="font-weight: 600;  font-size: larger; display: inline-block; color: #4b2eee;"
            v-for="family in OffcanvasDetails.jobFamily" :key="family">
            {{ family }} &nbsp; &nbsp;
          </div>
          <div class="my-4" style="font-weight: 600; font-size: 15px;">
            Test Description
            <div class="my-1" style="width: 95%; text-align: justify; font-weight: 500;">
              {{ OffcanvasDetails.longDescription }}

            </div>
            <div class="my-4">
              <div class="details_card">
                <div class="row align-items-center justify-content-center my-1">
                  <div class="col-12 d-flex flex-column align-items-center col-lg-4 col-md-3 mt-3 mb-3">



                    <!-- <img src="../../public/assets/Images/TestLibrary/Time.svg" alt="" class="my-1" width=30
                       :class="colorClassMapping[OffcanvasDetails.headerColor]" /> -->
                    <img src="../../public/assets/Images/TestLibrary/Time.svg" alt="" class="my-1" width=30 />
                    <label class="my-1" style="font-size: large; color: #4b2eee;">Time</label>
                    <label class="my-1">{{ dateConvert(OffcanvasDetails.time) ? dateConvert(OffcanvasDetails.time) :
                      'NA' }}</label>

                  </div>
                  <div class="col-12 d-flex flex-column align-items-center col-lg-4 col-md-3 mt-3 mb-3">
                    <img src="../../public/assets/Images/TestLibrary/Question.svg" alt="" class="my-1" />
                    <label class="my-1" style=" font-size: large; color: #4b2eee;">Questions</label>
                    <label class="my-1">{{ OffcanvasDetails.questions ? OffcanvasDetails.questions : 'NA' }}</label>

                  </div>
                  <div class="col-12 d-flex flex-column align-items-center col-lg-4 col-md-3 mt-3 mb-3">
                    <img src="../../public/assets/Images/TestLibrary/Reliability.svg" alt="" class="my-1" />
                    <label class="my-1" style="font-size: large; color: #4b2eee;">Reliability</label>

                    <label class="my-1">{{ OffcanvasDetails.reliability ? OffcanvasDetails.reliability : 'NA' }}</label>
                  </div>
                </div>
              </div>

              <div class="my-4">
                <div class="row gap-10 justify-content-center">
                  <!-- <div class="col-12 col-sm-12 col-md-6 col-lg-6" v-if="user.plan?.planCode == 'FP-001' && superadmin()">
                    <button class="btn btn-primary" type="button"
                      style="width:100% !important ; border-radius: 20px !important; "
                      :style="{ backgroundColor: OffcanvasDetails.headerColor }"> 
                      <a @click="confirmTrialTestOption()" style="color: white !important;"> -->
                  <!-- <a :href="'https://poc-app.pmapstest.in/Test/RegisterForTest?Token='+this.testToken+'~'+this.regionToken' " target="_blank" style="color: white !important;"> -->

                  <!-- Take a Trial Test</a></button>
                  </div> -->

                  <!--    <a :href="'https://twitter.com/intent/tweet?url=' + this.SharedUrl
                    " target="_blank"
                  -->

                  <div class="col-12 col-sm-12 col-md-6 col-lg-6" v-if="superadmin() || this.user.isEnterprise == true">
                    <button class="btn btn-primary" type="button"
                      style="width:100% !important ; border-radius: 10px !important; background-color: #4b2eee;"
                      @click="notifyRm(OffcanvasDetails.testId)">Request Access</button>
                  </div>

                  <div class="col-12 col-sm-12 col-md-6 col-lg-6"
                    v-if="!superadmin() && this.user.isEnterprise == false">
                    <button class="btn btn-primary" type="button"
                      style="width:100% !important ; border-radius: 10px !important; background-color: #4b2eee;"
                      @click="deployTest" data-bs-dismiss="offcanvas">Add Test</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="OffcanvasDetails.competencies">
            <p class="my-4" style="font-weight: 600; font-size: 15px;">Competencies</p>
            <div v-for="item in OffcanvasDetails.competencies" :key="item.id">
              <div class="mt-4">
                <!-- : '#F4F2FF' -->
                <div class="details_card">
                  <div class="row gap-10 align-items-center justify-content-center">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 d-flex flex-column align-items-center">
                      <img src="../../public/assets/Images/TestLibrary/Behavioural.svg" class="pink" alt=""
                        v-if="item.id == 2">
                      <img src="../../public/assets/Images/TestLibrary/Cognitive.svg" alt="" v-if="item.id == 1">
                      <img src="../../public/assets/Images/TestLibrary/Skills.svg" alt="" v-if="item.id == 3">
                      <label class="my-3 competency-color" style="font-weight: 600;">{{ item.name }}</label>
                      <!-- 
  statusFlag: $('#statusFlag').val() == 'true' ? true : $('#statusFlag').val() == '' ? 'empty' : false
 -->
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 d-flex flex-column align-items-center">

                      <div class="row">
                        <div class="col-12 d-inline-flex pb-3" v-for="detail in item.subCompetencies" :key="detail.id">

                          <img src="../../public/assets/Images/TestLibrary/Behavioural_option.svg" alt=""
                            style="width:20px" v-if="item.id == 2" />
                          <label class="mx-2 competency-color" v-if="item.id == 2">{{ detail.name }}</label>

                          <img src="../../public/assets/Images/TestLibrary/Cognitive_option.svg" alt=""
                            style="width:20px" v-if="item.id == 1" />
                          <label class="mx-2 competency-color" v-if="item.id == 1">{{ detail.name }}</label>

                          <img src="../../public/assets/Images/TestLibrary/Skills_option.svg" alt="" style="width:20px"
                            v-if="item.id == 3" />
                          <label class="mx-2 competency-color" v-if="item.id == 3">{{ detail.name }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <p style="font-weight: 600; font-size: 15px; margin-top: 10%;">Features</p>
          <div class="col-12 d-inline-flex pb-2 " style=" margin-top: 5%;" v-for="item in OffcanvasDetails.featureList"
            :key="item">
            <img src="../../public/assets/Images/TestLibrary/Tick.svg" alt="" v-if="item.isEnabled != false" />
            <label style="font-weight: 600; font-size: 18px;" class="mx-5" v-if="item.isEnabled != false">{{ item.name
              }}</label>
          </div>
          <hr style="margin-top: 10%;">
          <p style="font-weight: 600; font-size: 15px;">Rate this content</p>


          <div class="row">
            <i v-for="rate in rates" :key="rate.id" class="ratingIcons"
              :class="{ [rate.class]: true, selected: rate.isSelected }" @click="selectRate(rate.id)"></i>
          </div>


          <div class="row" style="margin-top: 5%; margin-bottom: 5%">
            <div class="col-12 col-lg-7 col-md-6 col-sm-12">
              <input type="text" v-model="testFeedback" placeholder="Enter review here"
                style=" background-color: #DFDFDF; border-color: #DFDFDF; width: 100%; height: 35px;" />
            </div>
            <div class="col-12 col-lg-5 col-md-6 col-sm-12">
              <button class="btn btn-primary" type="button" style="background-color: #4b2eee;" @click="submitFeedback">
                Send Feedback</button>
            </div>
          </div>

        </div>

      </div>
      <!-- OffCanvas Details Ends -->



      <div class="modal fade" id="SharelinkModel" tabindex="-1" aria-labelledby="IncreaseTokenModalLabel"
        aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable model-sm">
          <div class="modal-content border-radius-kj-20">
            <div class="modal-header token-model-header-color">
              <h5 class="modal-title text-centerm-auto" id="IncreaseTokenModalLabel">
                <span class="font-size-20-kj"> Share Link</span>
                <p class="Active-model-p-kj">
                  Please choose a platform you would like to share the test.
                </p>
              </h5>

              <button type="button" class="btn-close" data-bs-dismiss="modal" @click="resetShareLink"
                aria-label="btn-close"></button>
            </div>
            <div class="modal-body">
              <!-- <div class="form-group my-4">
                <label class="input-hero" for="Group">
                  <input type="text" class="input_area select_ca" :value="this.SharedUrl" />
                  <span class="input-name">Link <span class="text-danger">*</span></span></label>
              </div> -->
              <!--  v-if="SharedUrl != ''" -->
              <div class="Share-via-kj">
                <h6>Share via</h6>
                <div class="social-media-kj">
                  <a :href="'https://www.facebook.com/sharer/sharer.php?u=' +
                    + encodeURIComponent('Check out PMaps Website: ' + SharedUrl)
                    " target="_blank" class="social-icon-kj">
                    <img src="../../public/dashboard-assets/images/M2/facebook.png" alt="" class="w-60" />
                    <!-- <i class="fab fa-facebook-f"></i> -->
                  </a>
                  <a :href="'https://twitter.com/intent/tweet?url=' + + encodeURIComponent('Check out PMaps Website: ' + SharedUrl)
                    " target="_blank" class="social-icon-kj">
                    <!-- <i class="fab fa-twitter"></i> -->
                    <img src="../../public/dashboard-assets/images/M2/twitter.png" alt="" class="60" />
                  </a>
                  <!-- <a :href="'https://api.whatsapp.com/send?text=' + this.SharedUrl
                    " target="_blank" class="social-icon-kj">
                    <img src="../../public/dashboard-assets/images/M2/whtasup.png" alt="" class="60" />
                  </a> -->
                  <a :href="'https://api.whatsapp.com/send?text=' + encodeURIComponent('Check out PMaps Website: ' + SharedUrl)"
                    target="_blank" class="social-icon-kj">
                    <img src="../../public/dashboard-assets/images/M2/whtasup.png" alt="" class="60" />
                  </a>

                  <!-- <a :href="'https://www.linkedin.com/sharing/share-offsite/?url=' +
                     + encodeURIComponent('Check out PMaps Website: ' + SharedUrl) " target="_blank" class="social-icon-kj">
                    <img src="../../public/dashboard-assets/images/M2/link.png" alt="" class="60" />
                  </a> -->
                  <button @click="copytoClipboard(this.SharedUrl)" data-bs-dismiss="modal"
                    class="social-icon-kj social-icomn-active">
                    <img src="../../public/dashboard-assets/images/M2/linkshare.png" alt="" class="60" />
                    <!-- <i class="fa-solid fa-paperclip"></i> -->
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="ConfirmTrialTest" tabindex="-1" aria-labelledby="IncreaseTokenModalLabel"
        aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable model-sm">
          <div class="modal-content border-radius-kj-20">
            <div class="modal-header token-model-header-color">
              <h5 class="modal-title text-centerm-auto" id="TrialTest">
                <span class="font-size-20-kj"> Trial Test</span>
              </h5>

              <button type="button" class="btn-close" data-bs-dismiss="modal" @click="resetShareLink"
                aria-label="btn-close"></button>
            </div>
            <div class="modal-body">
              <div class="Share-via-kj mt-2">
                <h5>Are you sure you want to take the trial test ?</h5>
                <div class="social-media-kj mt-4">
                  <button type="button" style="margin-left: 8%" class="btn-primary btn-Apply-kj"
                    @click="trialTest()">Submit</button>
                  <button type="button" style="margin-left: 12%" class="btn Assign_RM_buttons"
                    @click="closeTrialTestModal">Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!-- 
      <paginate v-model="currentPage" :page-count="Page_count" :margin-pages="1" :page-range="1"
        :click-handler="clickCallback" :prev-text="'< Prev'" :next-text="'Next >'" :container-class="'pagination'"
        :page-class="'page-item'"></paginate> -->


    </div>
    <div class="text-center" v-if="CardDetails == false">
      <img src="../../public/assets/Images/Technical-Issue.png" style="width: 20%" class="my-5" />
      <h3 class="ms-5 mt-2">No Records Found</h3>
    </div>
    <!-- Show Tour Modal -->
    <div class="modal fade" id="showTourModal" tabindex="-1" aria-labelledby="IncreaseTokenModalLabel"
      aria-hidden="true" data-bs-backdrop="static">
      <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable model-sm ">
        <div class="modal-content border-radius-kj-20 ">
          <div class="modal-body">
            <div class="float-end" style="color: #333333;">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"></button>
            </div>
            <div class="row my-4 mx-1">
              <div class="col-12 col-sm-6 col-lg-6 col-md-6 col-xl-6">
                <div style="font-weight: 600; font-size: 26px;">Ready to see how we can make your hiring process a
                  breeze?</div>
                <div style="color: #333333;" class="mt-2">
                  Explore our interactive tour and discover how to easily manage Test invites, Candidate Reports, and
                  many more!

                  Our platform is designed with you in mind, making everything smooth and straightforward.

                  Let's make your hiring process easier together!
                </div>
                <div class="mt-5">
                  <div class="row">
                    <!-- <div class="col-12 col-sm-6 col-lg-6 col-md-6 col-xl-6 mb-3"><button
                        class="tour-primary-button">Watch Video</button></div> -->
                    <div class="col-12 col-sm-6 col-lg-6 col-md-6 col-xl-6 mb-3"><button class="tour-secondary-button"
                        @click="StartComponentTour()">Start Tour</button></div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-lg-6 col-md-6 col-xl-6" style="align-content:center">
                <img src="../../public/dashboard-assets/images/M2/TourImages/ActiveTestStart.svg" alt=""
                  style="width:100%;" />
              </div>
            </div>
            <!-- <div class="form-group my-4 ">
                <label class="input-hero" for="Group">
                  <select name="" class="input_area select_ca" v-model="SelectGroup" @change="onChange()" id="">
                    <option value="" selected="" disabled="">
                      Choose Group<span class="text-danger">*</span>
                    </option>
                    <option v-for="item in groupshowList" :key="item" :value="item.regionId">
                      {{ item.regionName }}
                    </option>
                  </select><span class="input-name">Group <span class="text-danger">*</span></span></label>
              </div> -->


          </div>
        </div>
      </div>
    </div>

    <!-- Tour Complete Modal  -->
    <div class="modal fade" id="TourCompleteModal" tabindex="-1" aria-labelledby="IncreaseTokenModalLabel"
      aria-hidden="true" data-bs-backdrop="static">
      <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable model-sm ">
        <div class="modal-content border-radius-kj-20 ">
          <div class="modal-body">
            <div class="my-6" style="text-align: center;">
              <h1 style="font-weight: 600; color: #333333;">Nice Work !</h1>
              <h1 style="font-weight: 600; color: #333333;">Your Test Library Summary is done</h1>
              <div style="color: #111111; font-size: 18px;" class="mt-2">Time to hire best candidates through
                assessments </div>
              <div class="mt-5">
                <button type="button" class="tour-primary-button" data-bs-dismiss="modal" aria-label="btn-close"
                  style="max-width:200px">Tour Completed</button>
                <div class="mt-4" @click="this.OpenTourModal();" style="color: #7b68ee; cursor: pointer;"><i
                    class="fa-solid fa-arrow-rotate-left"></i><span class="mx-2">Restart Tour</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { superadmin } from "../utils/helper";
import { getUserPermissionData } from "../utils/helper";
import { Tooltip } from "bootstrap/dist/js/bootstrap.esm.min.js";
import { calculateRemainingDays } from '../platformFeatures/dateUtils';
// import { AccessPlanFeatures } from '../platformFeatures/featureUtils';
import Tour from '../components/TourDemo.vue';
import { onUnmounted } from 'vue';
import eventBus from './TourComponents/eventBus.js'
import TestLibraryTour from './TourComponents/TestLibraryTour.vue'
// import Paginate from "vuejs-paginate-next";

// import { Offcanvas } from 'bootstrap';
import moment from "moment";

export default {
  name: "Test-Library",

  components: {
    Tour,
    TestLibraryTour
  },
  data() {
    return {
      CardDetails: [],
      TestTags: [],
      JobLevelResponse: [],
      JobFamilyResponse: [],
      CategoryList: [],
      OffcanvasDetails: [],
      hover: false,
      hoverIndex: null,
      iconClass: 'fa-regular fa-bookmark fa-lg mx-2',
      isBookmark: false,
      isActive: false,
      bookmarkSelect: 'fa-solid fa-bookmark fa-lg mx-2',
      bookmarkDeselect: 'fa-regular fa-bookmark fa-lg mx-2',
      SharedUrl: '',
      applyfilter: 0,
      searchInput: '',
      searchfilter: '',
      jobLevelFilter: [],
      jobFamilyFilter: [],
      competencyList: [],
      tagLable: '',
      filterTab: 1,
      filters: {
        jobLevel: [],
        jobFamily: []
      },
      rates: [
        { id: 1, class: 'Rate1', isSelected: false },
        { id: 2, class: 'Rate2', isSelected: false },
        { id: 3, class: 'Rate3', isSelected: false },
        { id: 4, class: 'Rate4', isSelected: false },
        { id: 5, class: 'Rate5', isSelected: false },
      ],
      SelectGroup: '',
      Page: 1,
      Limit: 8,
      enterKeyPress: false,
      filterEnabled: false,
      competencyFilterEnabled: false,
      show: false,

      testFeedback: '',
      filterCleared: false,
      // colorClassMapping: {
      //   '#FF90C0': 'primary-Pink',
      //   '#4b2eee': 'primary-Purple',
      //   '#FDCA11': 'primary-Yellow',
      //   '#26C7FF': 'primary-Blue',
      //   '': 'primary-Purple',
      // minColor: '#4b2eee', // Purple
      // maxColor: '#FF90C0'  // Pink
      // },
      // secondaryColorClassMapping: {
      //   '#FF90C0': 'secondary-Pink',
      //   '#4b2eee': 'secondary-Purple',
      //   '#FDCA11': 'secondary-Yellow',
      //   '#26C7FF': 'secondary-Blue',
      //   '': 'secondary-Purple',

      // },
      billing: JSON.parse(localStorage.getItem('billingData')),
      Remainingdays: 0,
      componentName: '',
      tourStatus: false,
      screenRatioClass: '',
      componentTour:false
    };
  },
  async created() {
    var scale = window.devicePixelRatio;

    if (scale == 1.5) {
      this.screenRatioClass = 'small-screen-ratio'
      this.descriptionCharCount = 100;
    }
    else {
      this.screenRatioClass = 'regular-screen-ratio';
      this.descriptionCharCount = 120;
    }
    this.componentName = this.$route.name;
    this.tourStatus = JSON.parse(localStorage.getItem("isNewUser"));
    // await AccessPlanFeatures(this.user.extId,48,'Test Library Access');
    if (this.billing != null) {
      this.Remainingdays = calculateRemainingDays(this.billing.object.data.subscription.expiryDate);
    }
    var jobLevelCategory = await this.fetchAllJobLevels();
    this.JobLevelResponse = jobLevelCategory.object.data;

    this.jobLevelFilter = jobLevelCategory.object.data;
    var jobFamilyCategory = await this.GetTestLibraryCardCategories(2);

    this.JobFamilyResponse = jobFamilyCategory.object.data;
    this.jobFamilyFilter = jobFamilyCategory.object.data;
    await this.GetCardData({
      Page: 1, // this.Page
      Limit: this.Limit // this.Limit
    });

    // var competencyList = await this.GetTestLibraryCardCategories(1);
    // this.CategoryList = competencyList.object.data;


  },

  async mounted() {
    eventBus.emit('data-from-child-component', true);
    eventBus.on('data-from-header-component', data => {
      this.receivedData = data;
      this.OpenTourModal();
    });
    await this.getNextTestLibraries();
    Array.from(
      document.querySelectorAll('img[data-bs-toggle="tooltip"]')
    ).forEach((tooltipNode) => new Tooltip(tooltipNode));
  },
  beforeUnmount() {
    eventBus.off('data-from-header-component');
    eventBus.emit('data-from-child-component', false);
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticate,
      user: (state) => state.auth.user,
      clientId: (state) => state.auth.user.clientId,
    }),

  },

  methods: {
    getUserPermissionData,
    ...mapActions(["GetTestLibraryCardDetails", "ShowErrorMessage", "GetTestLibraryCardCategories", "CreateTestLibraryBookmark", "DeleteTestLibraryBookmark", "GetTestLibraryOffcanvasDetails", "fetchAllJobLevels", "rateTest", "submitTestReview", "ShowSucessMessage", "sendTestLibraryEmailToRm", 'testLibraryTrailTest', 'postDeployTest']),
    ...mapMutations(["setLoading"]),
    superadmin,
    dateConvert: function (value) {
      return moment(value, "HH:mm:ss").format((moment(value, "HH:mm:ss").hour() === 0 ? "m [min]" : "h [hr] m [min]"));
    },

    cardUrl(url) {
      this.SharedUrl = url;
    },
    async copytoClipboard(mytext) {
      try {
        await navigator.clipboard.writeText(mytext);
        window.$("#SharelinkModel").modal("hide");
        return this.ShowSucessMessage("Link copied");
      } catch ($e) {
        // console.log($e);
      }
    },
    confirmTrialTestOption() {
      window.$("#ConfirmTrialTest").modal("show");
    },
    closeTrialTestModal() {
      window.$("#ConfirmTrialTest").modal("hide");
    },

    async searchTestDetails(inputName) {
      this.CardDetails = [];
      this.enterKeyPress = true;
      await this.GetCardData({
        Page: 1,
        Limit: this.Limit,
        Search: inputName,
        JobLevel: this.filters.jobLevel.length > 0 ? this.filters.jobLevel.toString() : null,
        JobFamily: this.filters.jobFamily.length > 0 ? this.filters.jobFamily.toString() : null,
        CompetencyTags: this.competencyList.length > 0 ? this.competencyList.toString() : null
      });
    },
    // Searching box
    opensearch() {
      document.querySelector(".Searching_input-box").classList.add("open");
    },
    async OpenTourModal() {
      window.$("#TourCompleteModal").modal("hide");
      window.$("#showTourModal").modal("show");
    },
    async StartComponentTour() {
      window.$("#showTourModal").modal("hide");
      this.componentTour = true;
    },
    async CloseComponentTour() {
      window.$("#TourCompleteModal").modal("show");
      window.scrollTo(0, 0);
      this.componentTour = false;
    },
    async closesearch() {
      document.querySelector(".Searching_input-box").classList.remove("open");
      this.searchInput = '';
      this.CardDetails = [];
      this.Page = 1;
      await this.GetCardData({
        Page: this.Page,
        Limit: this.Limit,
        JobFamily: this.filters.jobFamily.length > 0 ? this.filters.jobFamily.toString() : null,
        JobLevel: this.filters.jobLevel.length > 0 ? this.filters.jobLevel.toString() : null,
        CompetencyTags: this.competencyList.length > 0 ? this.competencyList.toString() : null
      });
    },
    openmobilesearch() {
      this.show = true;
      document
        .querySelector(".Searching_input_box_mobile_view")
        .classList.add("open");
    },
    async closemobilesearch() {
      this.show = false;
      document
        .querySelector(".Searching_input_box_mobile_view")
        .classList.remove("open");
      this.searchInput = '';
      this.CardDetails = [];
      this.Page = 1;
      await this.GetCardData({
        Page: this.Page,
        Limit: this.Limit,
        Search: '',
        JobFamily: this.filters.jobFamily.length > 0 ? this.filters.jobFamily.toString() : null,
        JobLevel: this.filters.jobLevel.length > 0 ? this.filters.jobLevel.toString() : null,
        CompetencyTags: this.competencyList.length > 0 ? this.competencyList.toString() : null
      });
    },

    encode(item) {
      return window.btoa(item);
    },

    async deployTest() {
      if (this.Remainingdays >= 0) {
        var data = await this.postDeployTest({
          testid: this.OffcanvasDetails.testId
        });
        var testStatus = data.status;
        if (testStatus) {
          this.$router.push({
            name: 'ActiveTests',

            // ActiveTestInviteCandidates
            // params: {
            //   id: this.encode(data.object.data.testId),
            //   clientId: this.encode(data.object.data.clientId)
            // },
            // query: {
            //   search: this.encode(''),
            //   offset: this.encode(''),
            //   current: this.encode(''),
            // },
          });
        }


      }
      else {
        this.ShowErrorMessage('Your plan has been expired');
      }
    },
    async deployTestCard(Id) {
      if (this.Remainingdays >= 0) {
        var data = await this.postDeployTest({
          testid: Id
        });
        var testStatus = data.status;
        if (testStatus) {
          this.$router.push({
            name: 'ActiveTests',
            // params: {
            //   id: this.encode(data.object.data.testId),
            //   clientId: this.encode(data.object.data.clientId)
            // },
            // query: {
            //   search: this.encode(''),
            //   offset: this.encode(''),
            //   current: this.encode(''),
            // },
          });
        }
      }
      else {
        this.ShowErrorMessage('Your plan has been expired');
      }
    },
    async GetOffcanvasData(id) {
      var offcanvasData = await this.GetTestLibraryOffcanvasDetails({
        testId: id
      });
      // this.CategoryList = category.object.data;
      this.OffcanvasDetails = offcanvasData.object.data;

    },

    async GetCardData(payload) {
      var cardDetails = await this.GetTestLibraryCardDetails(payload);
      if (cardDetails.object.data.items.length > 0) {
        if (this.CardDetails.length === 0) {
          this.CardDetails = cardDetails.object.data.items;
        } else {
          cardDetails.object.data.items.forEach((obj) => {
            this.CardDetails.push(obj);
          });
        }
        this.count = 0;
      }
      this.TestTags = this.CardDetails.tagDetails;
    },



    async UpdateBookmark(item) {
      if (item.isBookmarked == true) {
        var removeBookmarkData = await this.DeleteTestLibraryBookmark({
          bookmarkId: item.bookmarkId
        });

        if (removeBookmarkData.status) {
          item.isBookmarked = false;
          item.bookmarkId = null;

          // document.getElementById("bookmark-" + item.testId.toString()).classList.remove("fa-solid");
          // document.getElementById("bookmark-" + item.testId.toString()).classList.add("fa-regular");
        }
      }
      else {

        var bookmarkedData = await this.CreateTestLibraryBookmark({
          testId: item.testId,
          testLibraryId: item.id,
          userId: this.user.userId
        });

        if (bookmarkedData.status) {
          item.bookmarkId = bookmarkedData.object.data.id;
          item.isBookmarked = true;
          // document.getElementById("bookmark-" + item.testId.toString()).classList.remove("fa-regular");
          // document.getElementById("bookmark-" + item.testId.toString()).classList.add("fa-solid");
          // document.getElementById("bookmark-" + item.testId).classList.remove("bookmarkDeselect");
          // document.getElementById("bookmark-" + item.testId).classList.add("bookmarkSelect");
        }
      }

    },
    // clickCallback: function (pageNum) {
    //   this.Page = (pageNum - 1) * this.Limit + 1;
    //   this.GetCardData(pageNum);
    // },
    Filterresult() {
      if (this.searchfilter != "") {
        if (this.filterTab == 1) {
          this.JobLevelResponse = this.jobLevelFilter.filter(
            (element) =>
              element.name
                .toLowerCase()
                .includes(this.searchfilter.toLowerCase())
          );
        } else {
          this.JobFamilyResponse = this.jobFamilyFilter.filter((element) =>
            element.name
              .toLowerCase()
              .includes(this.searchfilter.toLowerCase())
          );
        }
      } else {
        this.JobLevelResponse = this.jobLevelFilter;
        this.JobFamilyResponse = this.jobFamilyFilter;
      }
    },

    async addFilter(value, e) {

      if (e.target.checked) {
        if (this.filterTab == 1) {
          this.filters.jobLevel.push(value);
        } else {
          this.filters.jobFamily.push(value);
        }

        this.applyfilter =
          this.filters.jobLevel.length + this.filters.jobFamily.length;
      } else {
        if (this.filterTab == 1) {
          this.filters.jobLevel.splice(
            Object.freeze(
              JSON.parse(JSON.stringify(this.filters.jobLevel))
            ).indexOf(value),
            1
          );
        } else {
          this.filters.jobFamily.splice(
            Object.freeze(
              JSON.parse(JSON.stringify(this.filters.jobFamily))
            ).indexOf(value),
            1
          );
        }
        this.applyfilter =
          this.filters.jobLevel.length + this.filters.jobFamily.length;
        if (this.applyfilter == 0) {
          this.CardDetails = [];
          await this.GetCardData({
            Page: 1,
            Limit: this.Limit
          });
        }
      }
    },

    async applyFilters() {
      this.offset = 1;
      this.currentPage = 1;
      this.CardDetails = [];
      this.filterEnabled = true;

      await this.GetCardData({
        Page: 1,
        Limit: this.Limit,
        Search: this.searchInput,
        JobLevel: this.filters.jobLevel.length > 0 ? this.filters.jobLevel.toString() : null,
        JobFamily: this.filters.jobFamily.length > 0 ? this.filters.jobFamily.toString() : null
      });
      localStorage.setItem("TestBookmarkFilters", JSON.stringify(this.filters));
      // var Bookmarkfilters = localStorage.getItem("TestBookmarkFilters");
      document.getElementById("filterDropdown").click();
    },

    async clearFilters(value) {
      this.CardDetails = [];
      this.filters.jobLevel = [];
      this.filters.jobFamily = [];
      this.applyfilter = 0;
      this.searchfilter = "";
      this.filterCleared = true;
      this.Filterresult();
      if (value) document.getElementById("filterDropdown").click();

      await this.GetCardData({
        Page: 1,
        Limit: this.Limit
      });
      localStorage.removeItem('TestBookmarkFilters')
    },

    filterclose() {
      document.getElementById("filterDropdown").click();
    },

    async addCompetencyFilters(competencyId) {
      if (!this.competencyList.includes(competencyId)) {
        this.competencyList.push(competencyId);
        document.getElementById(competencyId.toString()).style.backgroundColor = "#4b2eee";
        document.getElementById(competencyId.toString()).style.color = "#ffffff";
        document.getElementById(competencyId.toString()).style.borderColor = "#ffffff";
      } else {
        this.competencyList = this.competencyList.filter(function (item) {
          return item !== competencyId
        });
        document.getElementById(competencyId.toString()).style.backgroundColor = "#ffffff";
        document.getElementById(competencyId.toString()).style.color = "#000000";
        document.getElementById(competencyId.toString()).style.borderColor = "#000000";
      }

      this.CardDetails = [];
      this.competencyFilterEnabled = true;
      await this.GetCardData({
        Page: 1,
        Limit: this.Limit,
        Search: this.searchInput,
        CompetencyTags: this.competencyList.length > 0 ? this.competencyList.toString() : null,
        JobLevel: this.filters.jobLevel.length > 0 ? this.filters.jobLevel.toString() : null,
        JobFamily: this.filters.jobFamily.length > 0 ? this.filters.jobFamily.toString() : null
      });
    },

    // async getNextTestLibraries() {
    //   this.count = 0;
    //   window.onscroll = () => {
    //     if ((document.documentElement.scrollTop + window.innerHeight) >= (document.documentElement.offsetHeight - 40)) {
    //       if (this.enterKeyPress) {
    //         this.enterKeyPress = false;
    //         this.Page = 1;
    //       }

    //       if (this.filterEnabled) {
    //         this.filterEnabled = false;
    //         this.Page = 1;
    //       }

    //       if (this.competencyFilterEnabled) {
    //         this.competencyFilterEnabled = false;
    //         this.Page = 1;
    //       }

    //       if (this.filterCleared) {
    //         this.filterCleared = false;
    //         this.Page = 1;
    //       }

    //       this.count++;
    //       if (!(this.count > 1)) {
    //         this.Page += 1;
    //         this.GetCardData({
    //           Page: this.Page,
    //           Limit: this.Limit,
    //           Search: this.searchInput,
    //           CompetencyTags: this.competencyList.length > 0 ? this.competencyList.toString() : null,
    //           JobLevel: this.filters.jobLevel.length > 0 ? this.filters.jobLevel.toString() : null,
    //           JobFamily: this.filters.jobFamily.length > 0 ? this.filters.jobFamily.toString() : null
    //         });
    //       }
    //     }
    //   }
    // },




    async getNextTestLibraries() {
      this.count = 0;

      const scrollHandler = () => {
        if ((document.documentElement.scrollTop + window.innerHeight) >= (document.documentElement.offsetHeight - 40)) {
          if (this.enterKeyPress) {
            this.enterKeyPress = false;
            this.Page = 1;
          }

          if (this.filterEnabled) {
            this.filterEnabled = false;
            this.Page = 1;
          }

          if (this.competencyFilterEnabled) {
            this.competencyFilterEnabled = false;
            this.Page = 1;
          }

          if (this.filterCleared) {
            this.filterCleared = false;
            this.Page = 1;
          }

          this.count++;
          if (!(this.count > 1)) {
            this.Page += 1;
            this.GetCardData({
              Page: this.Page,
              Limit: this.Limit,
              Search: this.searchInput,
              CompetencyTags: this.competencyList.length > 0 ? this.competencyList.toString() : null,
              JobLevel: this.filters.jobLevel.length > 0 ? this.filters.jobLevel.toString() : null,
              JobFamily: this.filters.jobFamily.length > 0 ? this.filters.jobFamily.toString() : null
            });
          }
        }
      };

      window.addEventListener('scroll', scrollHandler);

      onUnmounted(() => {
        window.removeEventListener('scroll', scrollHandler);
      });
    },


    handleTourStarted() {
      this.tourStatus = 'active';
    },
    handleTourEnded() {
      this.tourStatus = 'inactive';
    },

    async selectRate(rateId) {
      await this.rateTest({
        UserId: this.user.userId,
        TestId: this.OffcanvasDetails.testId,
        TestLibraryId: this.OffcanvasDetails.testLibraryId,
        Rating: rateId
      })
    },

    async submitFeedback() {
      await this.submitTestReview({
        UserId: this.user.userId,
        TestId: this.OffcanvasDetails.testId,
        TestLibraryId: this.OffcanvasDetails.testLibraryId,
        Review: this.testFeedback
      });
      this.testFeedback = '';
    },

    async notifyRm(testId) {
      await this.sendTestLibraryEmailToRm({
        testId: testId
      });
    },

    async trialTest() {
      window.$("#ConfirmTrialTest").modal("hide");

      var data = await this.testLibraryTrailTest({
        testId: this.OffcanvasDetails.testId,
        userClientId: this.user.clientId,
        amount: 1
      });
      var testUrl = data.object.data;

      var a = document.createElement('a');
      a.href = testUrl;
      a.target = '_blank';
      a.rel = 'noopener';
      a.dispatchEvent(new MouseEvent('click'));
    },
    closeOffcanvas() {
      this.$nextTick(() => {
        const offcanvasBody = document.querySelector('.offcanvas-body');
        if (offcanvasBody) {
          offcanvasBody.scrollTop = 0;
        }
      });
    },
    // activeTags(tags, index) {
    //   if (index === 0 || index === 1) return tags.testLibraryCategoryName;
    // },
    activeTags(tags, index) {
      if (index == 0) {
        var tag1 = tags.testLibraryCategoryName.length;
        this.remainingChar = 27 - tag1;
        this.hideTagsCount = 1;
        return tags.testLibraryCategoryName;
      }
      if (index == 1) {
        var tag2 = tags.testLibraryCategoryName.length;
        var remaining = this.remainingChar - tag2;
        this.remainingChar = 0;
        if (remaining > 0) {
          this.hideTagsCount = 2;
          return tags.testLibraryCategoryName;
        }
      }

    },


    tooltipTags(tagDetails) {
      const tagsFromIndex2 = tagDetails.slice(this.hideTagsCount).map(tag => tag.testLibraryCategoryName).join(', ');
      return tagsFromIndex2;
    }
  },
};
</script>

<style scoped>
.test-name {
  font-size: 16px;
  font-weight: 600;
  /* height: 35px; */
}

.small-screen-ratio .test-name {
  font-size: small;
  font-weight: 600;
}

.test-time-questions {
  font-size: 13px;
  font-weight: 500;
}

.small-screen-ratio .test-time-questions {
  font-size: 11px;
  font-weight: 500;
}

.test-primary-button {
  background-color: #4b2eee;
  font-size: small;
  border-radius: 4px;
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
  border: none;
  width: 100%;
}

.small-screen-ratio .test-primary-button {
  font-size: 9.5px;
}

.test-secondary-button {
  background-color: #FFFFFF;
  font-size: small;
  font-weight: 600;
  border-radius: 4px;
  color: #4b2eee;
  border: 1px solid;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.small-screen-ratio .test-secondary-button {
  font-size: 9.5px;
}

.Searching_input-box input {
  border: none;
  outline: none;
  border: none;
  height: 100%;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 400;
  color: #333;
  background: none;
  border-radius: 108px;
  width: 84% !important;
}

.close-icon {
  right: 6px !important;
  margin-bottom: 7% !important;
  position: absolute;
  font-size: 30px;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  color: #000;
  border-radius: 10px;
  background: #fff;
  z-index: 9;
  padding: 5px 10px;
  opacity: 0;
  pointer-events: none;
  transform: translateY(-50%);
}

.offcanvas-header {
  box-shadow: 0 0 15px 0;
}

.competency-color {
  color: #4F4F4F;
}

.col-sm-12 {
  margin-top: 3% !important;
}



img {
  position: relative;
}

.offcanvas-header {
  height: 10% !important;
}

.my-6 {
  margin-top: 4.5rem !important;
  margin-bottom: 1.5rem !important;
}

.stretch-card {
  display: block;
  align-items: stretch;
  justify-content: stretch;
  min-width: 25% !important;
  max-width: 100% !important;

  /* width: 25% !important; */
  height: 2% !important;
}

.stretch-tags {
  display: block;
  align-items: stretch;
  justify-content: stretch;
  width: 418px !important;
  height: 5% !important;
}

.card :hover {
  border-radius: 20px;
}

.stretch-card>.card {
  width: 100% !important;
  height: 100%;
  min-width: 100%;
  border-radius: 20px;
  border: none !important;
  /* box-shadow: 0px 8px 5px 1px #ababab4d; */
  box-shadow: 0px 1px 5px 1px #0909094d;

}

.stretch-card .card {
  margin-right: 35px !important;
}


.details_card {
  width: 100%;
  height: 100%;
  background-color: #F4F2FF;
  border-radius: 20px;
}

/* .competency-options {
  margin-left: -3.5%;
  display: block;
  align-items: stretch;
  justify-content: stretch;
  width: 107.5% !important;
  height: 5% !important;
} */

/* 
.competency-selected :hover {
  background-color: #eeebff !important;
  border-left: 12px solid #4b2eee !important;
} */

/* .test-competencies-card {
  top: 0;
  left: 0;
  background-color: #FDCA11;
}

.test-competencies-card .test-competencies-banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 8px;
  height: 100%;
  background-color: #0dff00;
} */

.offcanvas-testname .test-library-card-label {
  left: 240px;
  top: 40px;
  transform: translateY(-50%);
}

.offcanvas-header {
  background-color: #4b2eee !important;
}

.test-library-card-banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 7%;
  height: 100%;
  background-color: #000000;
  border-radius: 20px 0 0 20px !important;
}

.card-body {
  min-height: 340px !important;
}

.small-screen-ratio .card-body {
  min-height: 320px !important;

}

.test-library-card-line {
  position: absolute;
  top: 105px;
  left: 40px;
  width: 310px;
  height: 1px;
  background-color: grey;
}

.test-library-card-label {
  position: relative;
  background: whitesmoke;
  width: 45px;
  height: 45px;
  border-radius: 40px;
  text-align: center;
  padding: 25%;
  box-shadow: 0px 0px 25px 5px #ababab4d;
}

.offcanvas-button {
  position: absolute;
  border-radius: 40px;
  background: linear-gradient(103.01deg, #669BF4 0%, #7870F1 100%);
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  font-family: DM SANS;
}




.demo-preview .badge {
  margin-right: 10px;
}

/* .col-6 {
  width: 50%; 

}

@media (max-width: 576px) {
  .col-6 {
    width: 100%; 
  }
} */



.badge {
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  padding: 6px 8px;
  border: 1px solid transparent;
  min-width: 10px;
  line-height: 1;
  color: #2d2d2d;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #f1f4f9;
  /* border-radius: 99999px */
}

.small-screen-ratio .badge {
  font-size: 10px;
}

.small-screen-ratio .test-tags-counter {
  font-size: 14px;
  font-weight: 600;
}

.test-description {
  font-weight: 500;
  font-size: 12px;
}

.small-screen-ratio .test-description {
  font-weight: 500;
  font-size: 10px;
}

/* .badge.filter-badge {
  font-size: 13px !important;
   min-width: 130px;
  min-height: 25px; 
  font-weight: 500 !important;
  background-color: #f1f4f9;
  border-color: #000000;
  border-width: 1px;
  color: black;
} */


/* .badge.badge-outlined {
  background-color: #f1f4f9;
  border-width: 2px;
  color: black;
} */




.ratingIcons {
  width: 40px;
  height: 40px;
  display: block;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 5%;
  margin-left: 6%
}

.Rate1 {
  background-image: url(../../public/assets/Images/TestLibrary/Rate1.svg);
}

.Rate2 {
  background-image: url(../../public/assets/Images/TestLibrary/Rate2.svg);
}

.Rate3 {
  background-image: url(../../public/assets/Images/TestLibrary/Rate3.svg);
}

.Rate4 {
  background-image: url(../../public/assets/Images/TestLibrary/Rate4.svg);
}

.Rate5 {
  background-image: url(../../public/assets/Images/TestLibrary/Rate5.svg);
}

.Rate1:hover,
.Rate1.selected {
  width: 45px;
  height: 45px;
  background-image: url(../../public/assets/Images/TestLibrary/Rate1.1.svg);
}

.Rate2:hover,
.Rate2.selected {
  width: 45px;
  height: 45px;
  background-image: url(../../public/assets/Images/TestLibrary/Rate2.1.svg);
}

.Rate3:hover,
.Rate3.selected {
  width: 45px;
  height: 45px;
  background-image: url(../../public/assets/Images/TestLibrary/Rate3.1.svg);
}

.Rate4:hover,
.Rate4.selected {
  width: 45px;
  height: 45px;
  background-image: url(../../public/assets/Images/TestLibrary/Rate4.1.svg);
}

.Rate5:hover,
.Rate5.selected {
  width: 45px;
  height: 45px;
  background-image: url(../../public/assets/Images/TestLibrary/Rate5.1.svg);
}

.primary-Pink {
  filter: invert(74%) sepia(70%) saturate(739%) hue-rotate(289deg) brightness(102%) contrast(103%);
}

.primary-Purple {
  filter: invert(38%) sepia(95%) saturate(1769%) hue-rotate(228deg) brightness(98%) contrast(91%);
}

.primary-Yellow {
  filter: invert(92%) sepia(11%) saturate(6934%) hue-rotate(347deg) brightness(100%) contrast(99%);
}

.primary-Blue {
  filter: invert(55%) sepia(90%) saturate(568%) hue-rotate(167deg) brightness(102%) contrast(108%);
}

.secondary-Pink {
  filter: invert(97%) sepia(29%) saturate(5510%) hue-rotate(282deg) brightness(102%) contrast(101%);
}

.secondary-Purple {
  filter: invert(81%) sepia(24%) saturate(7484%) hue-rotate(202deg) brightness(106%) contrast(107%);
}

.secondary-Yellow {
  filter: invert(98%) sepia(94%) saturate(1079%) hue-rotate(312deg) brightness(106%) contrast(108%);
}

.secondary-Blue {
  filter: invert(74%) sepia(75%) saturate(282%) hue-rotate(172deg) brightness(104%) contrast(103%);
}



::placeholder {
  /* color: red; */
  font-size: 15px;
  padding-left: 10px;


}
.disable-text-selection {
  pointer-events: none;
}
</style>