<template>
    <div class="page-content">
        <h3>Current plan</h3>
        <div class="card mt-3">
            <div class="card-header">
                <div style="display: flex; justify-content: space-between">
                    <div>
                        <h2 style="font-weight: 600;">
                            {{ this.billing.object.data.plan.planName }}</h2>
                    </div>
                    <div class="float-end">
                        <span>
                            <router-link :to="{ name: 'BillingPlan' }" class="btn btn-primary">
                                <span v-if="this.PlanStatus == false && this.FreePlan != 'Free Trial'">
                                    Switch Plan
                                </span>
                                <span v-else>Choose a plan</span>
                                <!-- {{ this.PlanStatus ? 'Activate Account' : 'Switch Plan' }} -->
                            </router-link>
                        </span>

                        <!-- <a href="#" class="btn btn-primary">Upgrade Plan</a> -->
                    </div>
                </div>

            </div>
            <div class="card-body">
                <!-- <h5 class="card-title">Special title treatment</h5> -->
                <span class="card-text" v-if="this.Remainingdays>=0">
                    {{ this.Remainingdays }}
                    Days Left</span>
                    <span v-else style="color: crimson;"> Your plan has been expired</span>

<!-- 
                <span class="float-end mx-2" style="font-size: small; color: rgb(56, 56, 255); cursor: pointer;"
                    @click="ValidationModal()" v-if="this.PlanStatus == false && this.FreePlan != 'Free Trial'">
                    Cancel Subscription
                </span>
                <span class="float-end mx-2" style="font-size: small; color: rgb(56, 56, 255); cursor: pointer;"
                  v-if="this.PlanStatus == true && this.FreePlan != 'Free Trial'" @click="reactiveSubscription()">
                    Reactivate Subscription
                </span> -->
                <span v-if="this.Remainingdays >= 0">
                        <span class="float-end mx-2" style="font-size: small; color: rgb(56, 56, 255); cursor: pointer;"
                @click="this.PlanStatus ? reactiveSubscription() : ValidationModal()" 
                v-if="this.FreePlan != 'Free Trial'">
                {{ this.PlanStatus ? 'Reactivate Subscription' : 'Cancel Subscription' }}
            </span>
        </span>

            </div>
        </div>

        <div class="modal fade" id="ValidationModal" tabindex="-1" aria-labelledby="DeactivatePartnerModalLabel"
            aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog  modal-dialog-centered modal-dialog-scrollable border-radius-kj-20">
                <div class="modal-content border-radius-kj-20">
                    <div class="modal-header">
                        <h4 style="font-weight: 600;">Deactivating your {{ this.billing.object.data.plan.planName }} </h4>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"></button>
                    </div>
                    <div class="modal-body">
                        Do you really want to cancel your existing subscription?
                        <span class="float-end mt-5">
                            <button type="button" class="btn btn-primary secondary " data-bs-dismiss="modal">Cancel</button>
                            <button type="button" class=" mx-2 btn btn-primary float-end"
                                @click="CancelSubscription()">Continue to Cancel Plan</button>

                            <!-- Exciting News! <i class="fa-solid fa-rocket fa-beat" style="color: #ffc801;"></i></h3> -->
                        </span>





                    </div>


                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { calculateRemainingDays } from '../platformFeatures/dateUtils';
import { getEncryptedData } from '../platformFeatures/featureUtils';

export default {
    name: "BillingOverview",
    data() {
        return {
            billing: JSON.parse(localStorage.getItem('billingData')),
            PlanStatus: false,
            FreePlan: ''
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.auth.user,
        }),
    },
    async created() {

        this.dataFrom = getEncryptedData(this.user.extId);
   
        this.Remainingdays = calculateRemainingDays(this.billing.object.data.subscription.expiryDate);
        this.PlanStatus = this.billing.object.data.subscription.isCancelled;
        this.FreePlan = this.billing.object.data.plan.planName;
    },

    methods: {
        ...mapMutations(["setLoading"]),
        ...mapActions(['putSubscriptionCancel', 'RefreshBillingDetails', 'postReactivateSubscription']),

        async ValidationModal() {
            window.$('#ValidationModal').modal('show');

        },
        CheckStatus(dueDate) {
            let currentDate = new Date();
            let due_date = new Date(dueDate);
            let diff = due_date.getTime() - currentDate.getTime();
            let days = Math.ceil(diff / (1000 * 60 * 60 * 24));
            this.Remainingdays = Math.abs(days);
            return this.Remainingdays;
        },

        async CancelSubscription() {
            await this.putSubscriptionCancel({
                subscriptionId: this.billing.object.data.subscription.subScriptionId
            });
            await this.RefreshBillingDetails();
            location.reload();
        },


        async reactiveSubscription() {
            await this.postReactivateSubscription({
                subscriptionId: this.billing.object.data.subscription.subScriptionId
            });
            await this.RefreshBillingDetails();
            location.reload();
        },
    },
};
</script>
  
<style scoped>
.secondary {
    background-color: rgb(211, 211, 211);
    color: rgb(30, 30, 30);
}
</style>
  