<template>
    <div class="Overview_dashboard_map_section my-4">
        <div class="card p-4">
            <div class="card-body p-0" id="pdfStackColumn">
                <h3 style="font-weight: 600;" class="mb-4">Overall Result Analysis
                </h3>
                <div class="mb-5">
                    <div class="mb-2" v-if="this.highestPassPercent != 0 || this.lowestPassPercent != 0"> Key Insights:
                    </div>
                    <div class="mb-2" v-if="this.highestPassPercent != 0">
                        <i class="fa-solid fa-check fa-lg mx-2" style="color: #7C68EE;"></i> High Pass Rate in <span
                            style="font-weight: 600;">{{ this.highestRegionName }} </span>: <span
                            style="font-weight: 600;"> {{ this.highestPassPercent }} %</span>
                    </div>
                    <div class="mb-2" v-if="this.highestPassPercent == 0 || regionLength">
                        <i class="fa-solid fa-triangle-exclamation fa-lg mx-2" style="color: #7C68EE;"></i> Improvement
                        Needed in <span style="font-weight: 600;">{{ this.lowestRegionName }} </span>: <span
                            style="font-weight: 600;">{{ this.lowestPassPercent }} % Pass Rate</span>
                    </div>
                </div>
                <div class="row">
                    <div :class="barChartClass">
                        <div class="stacked-column-chart" ref="chartContainer"></div>
                    </div>
                    <div :class="descriptorClass" class="mt-5">
                        <ul>
                            <li class="mb-3">
                                <span style="font-weight:600;">Overall Performance:</span> In the given period, out of
                                <span style="font-weight: 600;"> {{ totalCandidatesCount }} </span> candidates appearing
                                for this assessment, overall <span style="font-weight: 600;">{{ totalPassPercents }}%
                                </span> (<span style="font-weight: 600;">{{ totalPassCounts }} </span> candidates) have
                                cleared the assessment.
                            </li>
                            <li class="mb-3" v-if="highestPassCandidate != 0">
                                <span style="font-weight:600;">Top Performing Region:</span> Out of all the regions,
                                <span style="font-weight: 600;">{{ highestRegionName }}</span> (<span
                                    style="font-weight: 600;">{{ highestTotalCount }}</span> candidates) has seen the
                                maximum throughput with a high passing count of <span style="font-weight: 600;">{{
                                    highestPassCandidate }} </span> candidates for the selected time period.
                            </li>
                            <li class="mb-3" v-if="regionLength">
                                <span style="font-weight:600;">Region with Challenges:</span> Reduced throughput is
                                observed in <span style="font-weight: 600;">{{ lowestRegionName }}</span> ( <span
                                    style="font-weight: 600;">{{ lowestTotalCount }}</span> candidates) where only <span
                                    style="font-weight: 600;">{{ lowestPassCandidate }}</span> candidates have passed
                                the assessment.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Highcharts from 'highcharts';
import { Tooltip } from "bootstrap/dist/js/bootstrap.esm.min.js";


export default {
    name: 'StackedColumnChartProctoring',
    props: {
        assessmentData: Array
    },
    data() {
        return {
            testData: [],
            categories: [],
            totalCandidatesCount: 0,
            totalPassCounts: 0,
            totalPassPercents: 0,
            highestPassPercent: 0,
            highestRegionName: '',
            lowestPassPercent: 0,
            lowestRegionName: '',
            highestPassCandidate: 0,
            lowestPassCandidate: 0,
            highestTotalCount: 0,
            lowestTotalCount: 0,
            regionLength: false
        };
    },
    computed: {
        barChartClass() {
            return this.categories.length < 3 ? 'col-12 col-xl-5 col-md-5 col-lg-5 col-sm-12' : 'col-12';
        },
        descriptorClass() {
            return this.categories.length < 3 ? 'col-12 col-lg-7 col-md-7 col-sm-12' : 'col-12 mt-5';
        }
    },
    async created() {
        this.testData = this.assessmentData[0];
        this.categories = this.testData.regionWise.map(region => region.regionName);
    },
    async mounted() {
        if (this.assessmentData.length > 0) {
            const regions = this.assessmentData[0].regionWise;
            this.regionLength = regions.length > 1;
            // Description of Graphs Starts //
            const totalCount = regions.map(region => region.totalCandidates);
            const totalPassCount = regions.map(region =>
                region.overAllResult.passedCandidates
            );
            this.totalCandidatesCount = totalCount.reduce((a, b) => a + b, 0);
            this.totalPassCounts = totalPassCount.reduce((a, b) => a + b, 0);

            this.totalPassPercents = Math.round(this.totalPassCounts / this.totalCandidatesCount * 100);

            let highestRegion = regions[0];
            let lowestRegion = regions[0];

            for (let i = 1; i < regions.length; i++) {
                if (regions[i].overAllResult.passPercent > highestRegion.overAllResult.passPercent) {
                    highestRegion = regions[i];
                }
                if (regions[i].overAllResult.passPercent < lowestRegion.overAllResult.passPercent) {
                    lowestRegion = regions[i];
                }
            }

            this.highestPassPercent = highestRegion.overAllResult.passPercent;
            this.highestPassCandidate = highestRegion.overAllResult.passedCandidates;
            this.highestTotalCount = highestRegion.totalCandidates;
            this.highestRegionName = highestRegion.regionName;

            if (regions.length > 1) {
                this.lowestPassPercent = lowestRegion.overAllResult.passPercent;
                this.lowestPassCandidate = lowestRegion.overAllResult.passedCandidates;
                this.lowestRegionName = lowestRegion.regionName;
                this.lowestTotalCount = lowestRegion.totalCandidates;
            }

            // Description of Graphs Ends //

            const categories = regions.map(region => region.regionName);
            const series = [
                {
                    name: 'Passed Candidates',
                    data: regions.map(region => ({
                        y: region.overAllResult.passedCandidates,
                        passPercent: region.overAllResult.passPercent
                    })),
                    stack: 'overall',
                    color: '#7C68EE'
                },
                {
                    name: 'Failed Candidates',
                    data: regions.map(region => ({
                        y: region.overAllResult.failedCandidates,
                        failPercent: region.overAllResult.failPercent
                    })),
                    stack: 'overall',
                    color: '#FF90C0'

                }
            ];

            Highcharts.chart(this.$refs.chartContainer, {
                chart: {
                    type: 'column',
                },
                title: {
                    text: 'Overall Fitment Analysis',
                    style: {
                        fontWeight: "600",
                        display: 'none',
                    }
                },
                xAxis: {
                    categories: categories,
                    title: {
                        text: 'Regions',
                        style: {
                            fontWeight: "600",
                        }
                    },
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'No. of candidates',
                        style: {
                            fontWeight: "600",
                        }
                    },
                    stackLabels: {
                        enabled: true,
                        style: {
                            fontWeight: 'bold',
                            color: (
                                Highcharts.defaultOptions.title.style &&
                                Highcharts.defaultOptions.title.style.color
                            ) || 'gray'
                        }
                    }
                },
                legend: {
                    align: 'center',
                    verticalAlign: 'bottom',
                    backgroundColor: '#FFFFFF',
                    shadow: false
                },
                credits: {
                    enabled: false
                },
                tooltip: {
                    headerFormat: '<b>{point.x}</b><br/>',
                    pointFormatter: function () {
                        if (this.series.name === 'Passed Candidates') {
                            return this.series.name + ': ' + this.y + '<br/>Pass Percent: ' + this.passPercent + '%<br/>Total: ' + this.stackTotal;
                        } else if (this.series.name === 'Failed Candidates') {
                            return this.series.name + ': ' + this.y + '<br/>Fail Percent: ' + this.failPercent + '%<br/>Total: ' + this.stackTotal;
                        }
                        return '';
                    }
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: true
                        }
                    }
                },
                series: series
            });

        }


        Array.from(
            document.querySelectorAll('img[data-bs-toggle="tooltip"]')
        ).forEach((tooltipNode) => new Tooltip(tooltipNode));

    }

};
</script>

<style scoped>
.stacked-column-chart {
    width: 100%;
    height: 400px;
}
</style>