import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import store from "./store";
import VueAxios from "vue-axios";
import VueCookies from "vue-cookies";
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import Vue3Toastify, { toast } from 'vue3-toastify'; // Importing toast directly from Vue3Toastify

require("./assets/js/main");
import features from './platformFeatures/features';

// Register modules in the Vuex store
store.registerModule('features', features);

createApp(App)
  .use(store)
  .use(router)
  .use(VueCookies)
  .use(VueAxios, axios)
  .use(Vue3Toastify, {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 3000,
    limit: 1,
    icon: false,
    hideProgressBar: true,
  })
  .mount("#app");
