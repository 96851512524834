<template>
  <div class="container">
    <div class="verify-container-body">
      <img src="../../public/assets/Images/PaymentVerification.gif" alt="" width=400 />
      <h3 class="mt-4">Please wait for a while we are setting up your Account</h3>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { encryptData } from '@/platformFeatures/security'

export default {
  name: "VerifyPaymentStatus",
  data() {
    return {
      subscriptionId: this.$route.query.subscription,
      referenceId: this.$route.query.reference,
      planId: this.$route.query.plan,
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },

  async mounted() {
    var updatedPlanData = await this.postVerifyUpgradePlan({
      subscriptionId: this.subscriptionId,
      referenceId: this.referenceId,
      type: "PlanChange"
    });
    if (updatedPlanData.status) {
  if (this.user.isEnterprise == false) {
    try {
      var billingInfo = await this.GetBillingDetails({
        clientId: this.user.clientId
      });
      localStorage.setItem("billingData", JSON.stringify(billingInfo));
      // const planCode = billingInfo.object.data.plan.planCode;
      const secretKey = this.user.extId;
      const plandata = updatedPlanData.object.data;
      localStorage.setItem("featuresData", plandata);
      const encryptedData = encryptData(plandata, secretKey);
      localStorage.setItem("plan", updatedPlanData.object.data.planCode);
      localStorage.setItem("featuresData", encryptedData);
      this.$router.push({ name: "DashboardGroup" });
    } catch (error) {
      this.$router.push({ name: "BillingPlan" });
    }
  }
}
   // if (updatedPlanData.status) {
    //   if (this.user.isEnterprise == false) {
    //     var billingInfo = await this.GetBillingDetails({
    //       clientId: this.user.clientId
    //     })
    //     localStorage.setItem("billingData", JSON.stringify(billingInfo));
    //     // const planCode = billingInfo.object.data.plan.planCode;
    //     const secretKey = this.user.extId;
    //     const plandata = updatedPlanData.object.data;
    //     localStorage.setItem("featuresData", plandata);

    //     const encryptedData = encryptData(plandata, secretKey);
    //     localStorage.setItem("plan", updatedPlanData.object.data.planCode);
    //     localStorage.setItem("featuresData", encryptedData);
    //     this.$router.push({ name: "DashboardGroup" });
    //   }
    // }
    // this.intervalId = setInterval(async () => {
    //   await this.RefreshBillingDetails();

    //   const updatedBillingInfo = JSON.parse(localStorage.getItem("billingData"));
    //   const updatedPlan = updatedBillingInfo.object.data.plan.planCode;

    //   if (localStorage.getItem("plan") !== updatedPlan) {
    //     clearInterval(this.intervalId);
    //     localStorage.setItem("plan", updatedPlan);

    //     this.$router.push({
    //       name: 'DashboardGroup',
    //     });
    //   }
    // }, 2500);

  },
  methods: {
    ...mapMutations(["setLoading"]),
    ...mapActions(["postVerifyUpgradePlan", "GetBillingDetails"]),
  },
};
</script>
<style scoped lang="css">
@import url("/assets/css/style.css");
@import url("/assets/css/responsive.css");

.container {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  width: 100% !important;
  height: 100% !important;
}

.verify-container-body {
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 50px;
}
</style>