<template>
    <!-- style="filter: blur(8px);" -->
    <div class="page-content">
        <div v-if="isFirstVisit === 'true'">
            <Tour :page="componentName" />
        </div>
        <!-- header -->
        <!--<div class="d-flex justify-content-between align-items-center flex-wrap mb-2">
      <div class="my-2">
        <h3 class="mb-2 mb-md-0">Integration</h3>
      </div>
    </div>-->
        <!-- End -->

        <div class="row">
            <div class="col-lg-4 col-sm-12">
                <ul class="nav nav-pills mb-3 partners_top_menu_section_hp p-2 mt-4" role="tablist">
                    <!-- <li class="nav-item col-6" role="presentation">
                        <div class="nav-link btn px-3 active" id="API-tab" data-bs-toggle="pill" data-bs-target="#API"
                            role="tab" aria-controls="API" aria-selected="true" @click="changeTab('API')">
                            API
                        </div>
                    </li> -->
                    <li class="nav-item col-6" role="presentation">
                        <div class="nav-link btn px-3 active" id="Career-tab" data-bs-toggle="pill"
                            data-bs-target="#Career" role="tab" aria-controls="Career" aria-selected="false"
                            @click="changeTab('Widget')">
                            Widget
                        </div>
                    </li>
                    <li class="nav-item col-6" role="presentation">
                        <div class="nav-link btn px-3" id="HowtoUse-tab" data-bs-toggle="pill"
                            data-bs-target="#HowToUse" role="tab" aria-controls="HowToUse" aria-selected="true"
                            @click="changeTab('Documentation')">
                            How to use
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <!-- <div class="row" :style="[this.activeTab != 'API' ? { display: 'none' } : {}]">
            <div class="col-md-12">
                <div class="card p-0">
                    <div>
                        <div class="m-3 flex-box">
                            <div class="col-11 p-1"> Enable API access </div>
                            <div class="col-1 flex-box">
                                <div class="form-group">
                                    <input type="checkbox" disabled hidden="" id="IntegrationStatus"
                                        class="switchInput" />
                                    <label class="switch" for="IntegrationStatus"></label>
                                </div>
                            </div>
                        </div>
                        <hr class="m-3">
                        <div class="m-3 col-12">
                            <div class="alert alert-warning col-11" role="alert">
                                Note: Your access token is associated with your user credentials and should NEVER be
                                shared with anyone.
                            </div>
                            <div class="pt-3 p-1">
                                <label class="col-3">Endpoint URL</label>
                                <label class="col-9"> {{ this.baseUrl }} </label>
                            </div>
                            <div class="pt-3 p-1 flex-box">
                                <label class="col-3">Authorization Key</label>
                                <div class="col-9 flex-box p-1">
                                    <label class="input-hero col-5" style="padding-right:3px;">
                                        <input type="text" class="form-control" disabled v-model="AuthorizationKey"
                                            placeholder="Authorization Key">
                                    </label>
                                    <button class="btn btn-primary mb-2" type="button"
                                        :style="[this.AuthorizationKey != '' ? { display: 'none' } : {}]"
                                        @click="GenerateAuthKey">
                                        Generate Authorization Key
                                    </button>
                                    <button class="btn btn-primary mb-2" type="button"
                                        :style="[this.AuthorizationKey == '' ? { display: 'none' } : {}]"
                                        @click="copytoClipboard(this.AuthorizationKey)">
                                        Copy
                                    </button>

                                </div>
                            </div>
                            <div class="pt-5 p-1 flex-box">
                                <label> You can refer to our <a href="#">docs</a> for details about the APIs.</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="row" :style="[this.activeTab != 'Widget' ? { display: 'none' } : {}]">
            <div class="col-md-12">
                <div class="card p-0">
                    <div class="m-3">
                        Easily identify good talent directly from your company's career page
                    </div>
                    <hr class="m-3">
                    <div class="p-0 col-12" id="career-desc">
                        <div class="table-responsive">
                            <table class="table ActiveTestTable">
                                <thead>
                                    <tr>
                                        <th class="text-center"> Customize Button </th>
                                        <th class="text-center"> Configure Assessment </th>
                                        <th class="text-center bold"> Preview </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td rowspan="2" class="col-4">
                                            <div class="col-10 form-group mb-4">
                                                <label for="WidgetStyle" class="form-label">Button Style </label><span
                                                    class="text-danger">*</span>
                                                <select class="form-select input_area select_ca">
                                                    <!-- <option disabled>Select button style</option> -->
                                                    <option selected>Style 1</option>
                                                </select>
                                            </div>
                                            <div class="col-10 form-group mb-4">
                                                <label for="WidgetButtonText" class="form-label">Button
                                                    Text</label><span class="text-danger">*</span>
                                                <input class="form-control" v-model="WidgetButtonText" type="text"
                                                    required="" />
                                            </div>
                                            <div class="col-10 form-group mb-4">
                                                <label for="WidgetColor" class="form-label">Button Color</label><span
                                                    class="text-danger">*</span>
                                                <input type="color" class="form-control form-control-color"
                                                    v-model="WidgetColor" title="Choose your color">
                                            </div>
                                        </td>
                                        <td rowspan="2" class="col-4" style="vertical-align: top !important;">
                                            <div class="col-10 form-group mb-4">
                                                <label for="WidgetStyle" class="form-label">Select Assessment
                                                </label><span class="text-danger">*</span>
                                                <select class="form-select input_area select_ca"
                                                    v-model="WidgetButtonAssessment">
                                                    <option disabled>Select Assessment</option>
                                                    <option v-for="Assessment in Assessments" :key="Assessment.token"
                                                        :value="Assessment.token">
                                                        {{ Assessment.testName }}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-10 form-group mb-4">
                                                <label for="WidgetStyle" class="form-label">Select Group
                                                </label><span class="text-danger">*</span>
                                                <select class="form-select input_area select_ca"
                                                    v-model="WidgetButtonGroup">
                                                    <option disabled>Select Group</option>
                                                    <option v-for="group in groups" :key="group.regionGuid"
                                                        :value="group.regionGuid">
                                                        {{ group.regionName }}
                                                    </option>
                                                </select>
                                            </div>
                                        </td>
                                        <td class="col-4 text-center" style="background-color: #dfdfdf;"
                                            onfocus="changeColor()">
                                            <div class="form-group mb-4">
                                                <button class="btn btn-primary" type="button"
                                                    :style="[{ pointerEvents: 'none' }, { backgroundColor: this.WidgetColor }]">
                                                    {{ this.WidgetButtonText == '' ? "Apply Now" : this.WidgetButtonText
                                                    }}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">
                                            <button class="btn btn-link" @click="ShowWidgetCode">Show Code</button>
                                            <button class="btn btn-link" @click="CopyWidgetCode">Copy Code</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="row" :style="[this.activeTab != 'Documentation' ? { display: 'none' } : {}]">
            <div class="col-md-12">
                <div class="card p-0">
                    <div class="m-3">
                        <h4 class="m-3" id='how-to-use'><b> What is Career Widget? </b></h4>
                        <div class="m-3">
                            <p>A widget is a small piece of code which can be installed and executed within a web page.
                                In our assessment platform, you can use our Career Widget to create a "Apply Now" button
                                of your assessments. This "Apply Now" button can then be embedded in your website's
                                career page. The purpose of this widget is to assess the prospective candidates visiting
                                the career page.</p>
                        </div>
                    </div>
                    <hr class="mx-4 mt-0">
                    <div class="m-3">
                        <h4 class="mx-3"><b> How to generate Widget? </b></h4>
                        <div class="m-3">
                            <p>
                                You can create multiple "Apply Now" buttons for different assessments within your
                                account. If you are creating multiple buttons for your website, kindly make sure that
                                you are embedding the buttons correctly based on the job role.
                                When the button is clicked, the candidate will be redirected to the assessment page,
                                where they can enter their details and take the assessment. You can check the results of
                                the candidates in the candidates page of the assessment.
                            </p>
                            <ul class="mt-4">
                                <li class="mt-4">After navigating to the Career Widget module, you will see 3 sections,
                                    for customization, configuring and generating the code.<br>
                                    <img class="mt-3 w-100" src="../../../public/assets/Images/widget_career.png" />
                                </li>
                                <li class="mt-4">
                                    The Customize section is where you can customize the look and feel of the button.
                                    The widget can be customized in a lot of ways.
                                    <ol class="mt-2">
                                        <li class="mt-2">The style of the button can be changed.</li>
                                        <li class="mt-2">You can change the text to be displayed.</li>
                                        <li class="mt-2">The color can also be updated to match the theme of your
                                            website.</li>
                                    </ol>
                                    <br>
                                    <img class="mt-3" style="height: 50vh;"
                                        src="../../../public/assets/Images/widget_s1.png" />
                                </li>
                                <li class="mt-4">
                                    In Congigure section you can select the assessment which you want to embed within
                                    your website. You also need to select a 'Group' of your choice to easily filter the
                                    candidates.<br> You can create a new 'Group' from <b>Management > Group</b><br>
                                    <img class="mt-3" style="height: 30vh;"
                                        src="../../../public/assets/Images/widget_s2.png" />
                                </li>
                                <li class="mt-4">
                                    Once you have customized your widget, you can view the code by clicking 'Show Code'
                                    and copy the entire code by clicking 'Copy Code' and paste it in your website to
                                    embed the widget.<br>
                                    <img class="mt-3" style="height: 50vh;"
                                        src="../../../public/assets/Images/widget_s3.png" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" tabindex="-1" id="widgetModal" aria-labelledby="ShowWidgetModal" aria-hidden="true"
            data-bs-backdrop="static">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                <div class="modal-content border-radius-kj-20">
                    <div class="modal-header token-model-header-color">
                        <h5 class="modal-title text-centerm-auto">
                            <span class="font-size-20-kj"> Widget Code</span>
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"></button>
                    </div>
                    <div class="modal-body">
                        <pre>{{ this.WidgetCode }}</pre>
                        <button type="button" class="btn btn-primary float-end my-3" data-bs-dismiss="modal" @click="CopyWidgetCode">Copy</button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import Tour from "../TourDemo.vue";

export default {
    name: "Integration",
    components: {
        Tour
    },
    computed: {
        ...mapState({
            user: (state) => state.auth.user,
            clientId: (state) => state.auth.user.clientId
        })
    },
    data() {
        return {
            baseUrl: window.api_base_url,
            activeTab: "Widget",
            AuthorizationKey: "",
            WidgetButtonGroup: "",
            WidgetButtonAssessment: "",
            WidgetButtonText: "Apply Now",
            WidgetColor: "#7b68ee",
            Assessments: [],
            groups: [],
            hrefLink: "",
            WidgetCode: "",
            componentName: "",
            isFirstVisit: 'false'
        }
    },
    created() {
        this.componentName = this.$route.name;
        this.isFirstVisit = this.$cookies.get('firstVisit') === null ? 'true' : 'false';
        this.$cookies.set('firstVisit', this.isFirstVisit);
    },
    async mounted() {
        var model = {
            clientId: this.clientId,
            limit: 999,
            sortCol: "testid",
            offset: 0,
            sortdir: "desc",
        };
        var list = await this.AllActiveTest(model);
        if (list.status) {
            this.Assessments = list.object.data.testSummary;
            this.WidgetButtonAssessment = this.Assessments[0].token;
        }

        var result = await this.UserRegionData({
            userId: this.user.userId,
        });
        var group = result.object.data;
        group.forEach((element) => {
            if (element.isActive) {
                this.groups.push(element);
            }
        });
        this.WidgetButtonGroup = this.groups[0].regionGuid;

    },
    methods: {
        //getUserPermissionData,
        ...mapActions([
            "getIntegrationAuthKey",
            "AllActiveTest",
            "ShowSucessMessage",
            "UserRegionData"
        ]),
        ...mapMutations(["setLoading"]),
        //superadmin,

        changeTab(value) {
            if (value == "API") {
                this.activeTab = "API";
            }
            else if (value == "Widget") {
                this.activeTab = "Widget";
            }
            else if (value == "Documentation") {
                this.activeTab = "Documentation";
            }
        },

        PrepareWidgetCode() {
            this.hrefLink = window.app_base_url + "/Test/RegisterForTest?Token=" + this.WidgetButtonAssessment + "~" + this.WidgetButtonGroup;
            this.WidgetCode =
                `<div class='pmaps-career-widget'>
    <link href='https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css' rel='stylesheet' integrity='sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH' crossorigin='anonymous'> 
    <a class='btn btn-primary' style='background-color:` + this.WidgetColor + `;' href='` + this.hrefLink + `' target='_blank'>` + this.WidgetButtonText + `</a>
</div>`;
        },

        ShowWidgetCode() {
            this.PrepareWidgetCode();
            window.$('#widgetModal').modal('show');
        },

        CopyWidgetCode() {
            this.PrepareWidgetCode();
            this.copytoClipboard(this.WidgetCode);
        },

        async GenerateAuthKey() {
            var authToken = await this.getIntegrationAuthKey();

            if (authToken.status) {
                this.AuthorizationKey = authToken.object?.data.token;
            }
        },

        async copytoClipboard(mytext) {
            try {
                await navigator.clipboard.writeText(mytext);
                return this.ShowSucessMessage("Copied to clipboard");
            } catch ($e) {
                // console.log($e);
            }
        },
    }
};
</script>
