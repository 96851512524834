<template>
    <div>
        <h3 style="font-weight: 600;" class="mt-5">Overall Region Wise Usage : {{ testData.testName }}</h3>
        <div class="row">
            <div class="col-5">
                <div class="bar-chart mt-5" ref="chartContainer"></div>
            </div>
            <div class="col-7 mt-5 table-responsive">
                <table class="table table-hover table-bordered assessment-usage-table">
                    <thead style="color: #7B68EE">
                        <tr>
                            <th>Regions</th>
                            <th>Psychometry Fit(%)</th>
                            <th>Aptitude Fit(%)</th>
                            <th>Overall Fit(%)</th>
                            <th>Total Assessments</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="regionObj in testData.regionWise" :key="regionObj">
                            <td> {{ regionObj.regionName }} </td>
                            <td>
                                {{ regionObj.psyResult.passPercent === null ? 'NA' : regionObj.psyResult.passPercent + '%' }}
                            </td>
                            <td> {{ regionObj.aptiResult.passPercent === null ? 'NA' : regionObj.aptiResult.passPercent + '%' }} </td>
                            <td>
                                {{ regionObj.overAllResult.passPercent === null ? 'NA' : regionObj.overAllResult.passPercent + '%' }}
                            </td>
                            <td> {{ regionObj.totalCandidates }} </td>
                        </tr>
                        <tr>
                            <td><b>Total</b></td>
                            <td><b> {{ testData.psyPassPercent === null ? 'NA' : testData.psyPassPercent + '%' }} </b>
                            </td>
                            <td><b> {{ testData.aptiPassPercent === null ? 'NA' : testData.aptiPassPercent + '%' }} </b>
                            </td>
                            <td><b> {{ testData.overallPassPercent === null ? 'NA' : testData.overallPassPercent + '%' }} </b></td>
                            <td><b> {{ testData.assessmentUsed }} </b></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="mt-4">
            <ul v-for="item in testData.descriptiveSummary" :key="item">
                <li> {{ item }} </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Highcharts from 'highcharts';

export default {
    name: 'TestWiseRegionConsumption',
    data() {
        return {
            chartColors: ['#7C68EE', '#F8B200', '#4B2EEE', '#FFC801', '#5E45EB', '#F5C101', '#A293F4', '#FEDE69', '#CEC6F8', '#FFECA6', '#EDEAFF', '#FF9900', '#6A8BF3', '#669BFA', '#9FCDFF', '#CDE5FF', '#1E3D58']
        };
    },
    props: {
        testData: Object
    },
    mounted() {
        const chartContainer = this.$refs.chartContainer;
        const categories = this.testData.regionWise.map(region => region.regionName);
        const values = this.testData.regionWise.map(region => region.totalCandidates);

        Highcharts.chart(chartContainer, {
            chart: {
                type: 'column',
            },
            title: {
                text: 'Region wise consumption',
                style: {
                    fontWeight: "600",
                    text: null,
                }
            },
            exporting: {
                enabled:false
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            xAxis: {
                title: {
                    text: 'Regions',
                    styl: {
                        fontFamily: 'DM Sans, sans-serif',
                        color: '#333'
                    }
                },
                categories: categories,
                labels: {
                    style: {
                        fontFamily: 'DM Sans, sans-serif',
                        color: '#333' // Set label color
                    }
                }
            },
            yAxis: {
                title: {
                    text: 'No of candidates',
                    style: {
                        fontFamily: 'DM Sans, sans-serif',
                        color: '#333' // Set title color
                    }
                },
                labels: {
                    style: {
                        fontFamily: 'DM Sans, sans-serif',
                        color: '#333' // Set label color
                    }
                }
            },
            series: [{
                name: 'Candidates(N)',
                data: values,
                colorByPoint: true,
                colors: this.chartColors
            }]
        });
    }
}
</script>

<style scoped>
.bar-chart {
    width: 100%;
    max-width: 1200px;
    height: 400px;
    margin: 0 auto;
}

.assessment-usage-table th {
    background-color: #F2F0FF;
}
</style>