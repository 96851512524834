<template>
    <div class="Overview_dashboard_map_section my-4">
        <div class="card p-4">
            <div class="card-body p-0" id="pdfScoreAnalysisBenchmark">
                <h3 style="font-weight: 600;" class="mb-5">Score Analysis (with Benchmark)
                        <!-- <img src="../../../../public/dashboard-assets/images/M7/Actions.png" width="25"
                            class="cursor-pointer ms-2" alt="" data-bs-toggle="tooltip" data-bs-placement="top" title=" Analyzing the frequency of assessment usage over time provides valuable insights into the number of
                                    candidates assessed during defined intervals." /> -->
                    </h3>
                <!-- <div class="table-header mt-2 mb-4">
                    <h2 class="table-title">Score Analysis (with Benchmark)
                    </h2>
                </div> -->
                <div class="mt-5">
                    <div class="row" style="align-items: center;">
                        <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6">
                            <div class="col-12">
                                <div ref="cognitiveChart" class="chart-container w-100"></div>
                            </div>
                            <div class="col-12 mt-5">
                                <div ref="behaviouralChart" class="chart-container w-100"></div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6">
                            <ul v-for="list in this.descriptors" :key="list">
                                <li>{{ list }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Highcharts from 'highcharts';
import { Tooltip } from "bootstrap/dist/js/bootstrap.esm.min.js";

export default {
    name: 'ScoreAnalysisGraphs',
    data() {
        return {
            testData: [],
            descriptors: []
        }
    },
    props: {
        assessmentData: Array
    },
    async mounted() {

        this.testData = this.assessmentData.testList[0];
        const traitName = this.testData.psyResults.map(data => data.traitName);
        const BehaviouraMinScore = this.testData.psyResults.map(data => data.avgFailScore);
        const BehaviouraMaxScore = this.testData.psyResults.map(data => data.avgPassScore);
        const CognitiveMinScore = this.testData.results.map(data => data.avgFailScore);
        const CognitiveMaxScore = this.testData.results.map(data => data.avgPassScore);
        const CognitiveBenchmark = this.testData.results.map(data => data.benchmark);
        const CognitiveSectionName = this.testData.results.map(data => data.sectionName);
        this.TestName = this.testData.testName.toUpperCase();
        this.descriptors = this.testData.descriptors;

        if (BehaviouraMaxScore.length > 0 || BehaviouraMinScore.length > 0) {
            var BehaviouralChartOptions = {
                chart: {
                    type: 'column'
                },
                title: {
                    text: 'Behavioural Competency'
                },
                xAxis: {
                    categories: traitName,
                    title: {
                        text: 'Sections',
                        style: {
                        fontWeight: "600"
                    }
                    },
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'Average % Score',
                        style: {
                        fontWeight: "600"
                    }
                    }
                },
                credits: {
                    enabled: false
                },
                series: [{
                    name: 'Pass',
                    data: BehaviouraMaxScore,
                    color: '#7C68EE'
                }, {
                    name: 'Fail',
                    data: BehaviouraMinScore,
                    color: '#FF90C0'
                }]
            };
        }

        if (CognitiveMinScore.length > 0 || CognitiveMaxScore.length > 0) {
            var CongitiveChartOptions = {
                chart: {
                    type: 'column'
                },
                title: {
                    text: 'Test Sections'
                },
                xAxis: {
                    categories: CognitiveSectionName,
                    title: {
                        text: 'Sections',
                        style: {
                        fontWeight: "600"
                    }
                    },
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'Average % Score',
                        style: {
                        fontWeight: "600"
                    }
                    }
                },
                credits: {
                    enabled: false
                },
                series: [{
                    name: 'Pass',
                    data: CognitiveMaxScore,
                    color: '#7C68EE'

                }, {
                    name: 'Fail',
                    data: CognitiveMinScore,
                    color: '#FF90C0'

                }, {
                    name: 'Benchmark',
                    type: 'line',
                    data: CognitiveBenchmark,
                    marker: {
                        enabled: true,
                        symbol: 'circle'
                    },
                    color: 'green'
                }]
            };
        }
        if (BehaviouraMaxScore.length > 0 || BehaviouraMinScore.length > 0) {
            Highcharts.chart(this.$refs.behaviouralChart, BehaviouralChartOptions);
        }
        if (CognitiveMinScore.length > 0 || CognitiveMaxScore.length > 0) {

            Highcharts.chart(this.$refs.cognitiveChart, CongitiveChartOptions);
        }

        Array.from(
            document.querySelectorAll('img[data-bs-toggle="tooltip"]')
        ).forEach((tooltipNode) => new Tooltip(tooltipNode));

    },

};
</script>

<style scoped>
#lineChartContainer {
    margin: 0 auto;
}

.chart-container {
    max-width: 100%;
    width: 100vh;
    text-align: center;
}

.chart-container>div {
    display: inline-block;
}

.table-header {
    text-align: center;
    margin-bottom: 10px;
}

.table-title {
    font-size: 18px;
    font-weight: bold;
}
</style>