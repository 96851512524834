<template>
    <div></div>
  </template>
  <script>
  import Shepherd from "shepherd.js";
  import router from "@/router"
  // import { setNewUser } from '../utils/auth'
  import { mapActions ,mapState} from "vuex";

  export default {
    name: "App",
    computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
    data() {
      return {
        dashboardGroupTour: null,
        dynamicTestLibraryTour: null,
        careerPageTour: null,
        redirectToActiveTests: false,
        // activeTestTour: null,
        dashboardGroupSubscriptionSteps: [
          {
            // title: "Dashboard Overview",
            text: 
            "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Profile <div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>Explore your profile! Start with Profile Settings. Dive into your account settings here.</div>" ,
            attachTo: {
              element: "#profile-button",
              on: "bottom"
            },
            buttons: [
              {
                action: this.tourCancel,
                classes: "shepherd-button-third",
                text: "Skip"
              },
              {
                action: this.tourGoNext,
                text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
              }
            ]
          },
          // <i class="fa-solid fa-circle-chevron-right"></i>
          // {
          //   title: "Dashboard Overview",
          //   text: "User can access their profile details, privacy policy and can logout from here",
          //   attachTo: {
          //     element: "#user-profile-actions",
          //     on: "left"
          //   },
          //   buttons: [
          //     {
          //       action: this.tourCancel,
          //       text: "Skip"
          //     },
          //     {
          //       action: this.tourGoBack,
          //       classes: "shepherd-button-secondary",
          //       text: "Back"
          //     },
          //     {
          //       action: this.tourGoNext,
          //       text: "Next"
          //     }
          //   ]
          // },
          {
            // title: "Dashboard Overview",
            text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Switch account<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>This is your Switch Account. Access partner accounts and view your current plan.</div>",
            // text: "<div style='margin-top:2%; color:#7C68EE; font-weight:600; font-size:14px'>1/</div><div style='margin-top:4%;font-weight:600;font-size:24px'>Switch account element</div><div style='margin-top:5%; margin-bottom:7%;font-size:18px'>Shows companies the user is associated with</div>",
            attachTo: {
              element: "#user-company-details",
              on: "bottom"
            },
            buttons: [
              {
                action: this.tourCancel,
                classes: "shepherd-button-third",
                text: "Skip"
              },
              {
                action: this.tourGoBack,
                // classes: "shepherd-button-secondary",
                text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
              },
              {
                action: this.tourGoNext,
                text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
              }
            ]
          },
          {
            // title: "Dashboard Overview",
            text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Dashboard<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>This is your Dashboard. See your activity at a glance. Check your Groups, Users, and Test Tokens here.</div>",
            attachTo: {
              element: "#sidebar-dashboard",
              on: "right"
            },
         
            buttons: [
              {
                action: this.tourCancel,
                classes: "shepherd-button-third",
                text: "Skip"
              },
              {
                action: this.tourGoBack,
                // classes: "shepherd-button-secondary",
                text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
              },
              {
                action: this.tourGoNext,
                text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
              }
            ]
          },
          {
            // title: "Dashboard Overview" Library,
            text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Test Library<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>Your Library is stocked with job role tests and you get 5 for free to start evaluating skills right away.</div>",
            attachTo: {
              element: "#sidebar-library",
              on: "right"
            },
            
            buttons: [
              {
                action: this.tourCancel,
                classes: "shepherd-button-third",
                text: "Skip"
              },
              {
                action: this.tourGoBack,
                text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
              },
              {
                action: this.tourGoNext,
                text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
              }
            ]
          },
          {
            text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Tests<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>When you're ready, take or assign a test in the 'Test' section. It’s easy to keep track of ongoing and past tests.</div>",
            attachTo: {
              element: ".sidebar-test-management",
              on: "right"
            },
            beforeShowPromise: function() {
            const dropdownParent = document.querySelector('.sidebar-test-management .collapse');
            if (dropdownParent) {
                dropdownParent.classList.add('show'); 
            }
            const nextDropdownParent = document.querySelector('.sidebar-team-management-item .collapse.show');
                if(nextDropdownParent){
                  nextDropdownParent.classList.remove('show');
                  nextDropdownParent.classList.add('hide'); 
                }
            return new Promise(function(resolve) {
                setTimeout(resolve, 100); 
            });
        },
            buttons: [
              {
                action: this.tourCancel,
                classes: "shepherd-button-third",
                text: "Skip"
              },
              {
                action: this.tourGoBack,
                text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
              },
              {
                action: this.tourGoNext,
                text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
              }
            ]
          },
          {
            text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Management<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>In Management, you have the tools to manage your team’s users and roles efficiently.</div>",
            attachTo: {
              element: ".sidebar-team-management-item",
              on: "right"
            },
            beforeShowPromise: function() {
              const BackDropdownParent = document.querySelector('.sidebar-test-management .collapse.show');
                if(BackDropdownParent){
                  BackDropdownParent.classList.remove('show');
                  BackDropdownParent.classList.add('hide'); 
                }
              const dropdownParent = document.querySelector('.sidebar-team-management-item .collapse');
                if (dropdownParent) {
                  dropdownParent.classList.add('show'); 
                }
                return new Promise(function(resolve) {
                  setTimeout(resolve, 100); 
                });
            },
            buttons: [
              {
                action: this.tourCancel,
                classes: "shepherd-button-third",
                text: "Skip"
              },
              {
                action: this.tourGoBack,
                text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
              },
              {
                action: this.tourGoNext,
                text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
              }
            ]
          },
          {
            text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Settings<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>In settings, you can access the Career Widget, Billing, and Plans Details.</div>",
            attachTo: {
              element: ".sidebar-settings",
              on: "right"
            },
            beforeShowPromise: function() {
              const BackDropdownParent = document.querySelector('.sidebar-team-management-item .collapse.show');
                if(BackDropdownParent){
                  BackDropdownParent.classList.remove('show');
                  BackDropdownParent.classList.add('hide'); 
                }
              const dropdownParent = document.querySelector('.sidebar-settings .collapse');
                if (dropdownParent) {
                dropdownParent.classList.add('show'); 
                }
                return new Promise(function(resolve) {
                setTimeout(resolve, 100); 
            });
           },
            buttons: [
              {
                action: this.tourCancel,
                classes: "shepherd-button-third",
                text: "Skip"
              },
              {
                action: this.tourGoBack,
                text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
              },
              {
                action: this.goToNextTour,
                text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
              }
            ]
          }
        ],
        dashboardGroupEnterpriseSteps: [
          {
            // title: "Dashboard Overview",
            text: 
            "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Profile <div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>Explore your profile! Start with Profile Settings. Dive into your account settings here.</div>" ,
            attachTo: {
              element: "#profile-button",
              on: "bottom"
            },
            buttons: [
              {
                action: this.tourCancel,
                classes: "shepherd-button-third",
                text: "Skip"
              },
              {
                action: this.tourGoNext,
                text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
              }
            ]
          },
          // <i class="fa-solid fa-circle-chevron-right"></i>
          // {
          //   title: "Dashboard Overview",
          //   text: "User can access their profile details, privacy policy and can logout from here",
          //   attachTo: {
          //     element: "#user-profile-actions",
          //     on: "left"
          //   },
          //   buttons: [
          //     {
          //       action: this.tourCancel,
          //       text: "Skip"
          //     },
          //     {
          //       action: this.tourGoBack,
          //       classes: "shepherd-button-secondary",
          //       text: "Back"
          //     },
          //     {
          //       action: this.tourGoNext,
          //       text: "Next"
          //     }
          //   ]
          // },
          {
            // title: "Dashboard Overview",
            text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Switch account<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>This is your Switch Account. Access partner accounts and view your current plan.</div>",
            // text: "<div style='margin-top:2%; color:#7C68EE; font-weight:600; font-size:14px'>1/</div><div style='margin-top:4%;font-weight:600;font-size:24px'>Switch account element</div><div style='margin-top:5%; margin-bottom:7%;font-size:18px'>Shows companies the user is associated with</div>",
            attachTo: {
              element: "#user-company-details",
              on: "bottom"
            },
            buttons: [
              {
                action: this.tourCancel,
                classes: "shepherd-button-third",
                text: "Skip"
              },
              {
                action: this.tourGoBack,
                // classes: "shepherd-button-secondary",
                text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
              },
              {
                action: this.tourGoNext,
                text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
              }
            ]
          },
          {
            // title: "Dashboard Overview",
            text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Dashboard<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>This is your Dashboard. See your activity at a glance. Check your Groups, Users, and Test Tokens here.</div>",
            attachTo: {
              element: "#sidebar-dashboard",
              on: "right"
            },
         
            buttons: [
              {
                action: this.tourCancel,
                classes: "shepherd-button-third",
                text: "Skip"
              },
              {
                action: this.tourGoBack,
                // classes: "shepherd-button-secondary",
                text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
              },
              {
                action: this.tourGoNext,
                text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
              }
            ]
          },
          {
            // title: "Dashboard Overview" Library,
            text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Test Library<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>Your library is stocked with job role tests, allowing you to start evaluating skills right away.</div>",
            attachTo: {
              element: "#sidebar-library",
              on: "right"
            },
            
            buttons: [
              {
                action: this.tourCancel,
                classes: "shepherd-button-third",
                text: "Skip"
              },
              {
                action: this.tourGoBack,
                text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
              },
              {
                action: this.tourGoNext,
                text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
              }
            ]
          },
          {
            text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Tests<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>When you're ready, take or assign a test in the 'Test' section. It’s easy to keep track of ongoing and past tests.</div>",
            attachTo: {
              element: ".sidebar-test-management",
              on: "right"
            },
            beforeShowPromise: function() {
            const dropdownParent = document.querySelector('.sidebar-test-management .collapse');
            if (dropdownParent) {
                dropdownParent.classList.add('show'); 
            }
            const nextDropdownParent = document.querySelector('.sidebar-team-management-item .collapse.show');
                if(nextDropdownParent){
                  nextDropdownParent.classList.remove('show');
                  nextDropdownParent.classList.add('hide'); 
                }
            return new Promise(function(resolve) {
                setTimeout(resolve, 100); 
            });
        },
            buttons: [
              {
                action: this.tourCancel,
                classes: "shepherd-button-third",
                text: "Skip"
              },
              {
                action: this.tourGoBack,
                text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
              },
              {
                action: this.tourGoNext,
                text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
              }
            ]
          },
          {
            text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Management<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>In Management, you have the tools to manage your team’s users and roles efficiently.</div>",
            attachTo: {
              element: ".sidebar-team-management-item",
              on: "right"
            },
            beforeShowPromise: function() {
              const BackDropdownParent = document.querySelector('.sidebar-test-management .collapse.show');
                if(BackDropdownParent){
                  BackDropdownParent.classList.remove('show');
                  BackDropdownParent.classList.add('hide'); 
                }
              const dropdownParent = document.querySelector('.sidebar-team-management-item .collapse');
                if (dropdownParent) {
                  dropdownParent.classList.add('show'); 
                }
                return new Promise(function(resolve) {
                  setTimeout(resolve, 100); 
                });
            },
            buttons: [
              {
                action: this.tourCancel,
                classes: "shepherd-button-third",
                text: "Skip"
              },
              {
                action: this.tourGoBack,
                text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
              },
              {
                action: this.tourGoNext,
                text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
              }
            ]
          },
          {
            text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Settings<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>In settings, you can access the Career section where you can integrate with your platform.</div>",
            attachTo: {
              element: ".sidebar-settings",
              on: "right"
            },
            beforeShowPromise: function() {
              const BackDropdownParent = document.querySelector('.sidebar-team-management-item .collapse.show');
                if(BackDropdownParent){
                  BackDropdownParent.classList.remove('show');
                  BackDropdownParent.classList.add('hide'); 
                }
              const dropdownParent = document.querySelector('.sidebar-settings .collapse');
                if (dropdownParent) {
                dropdownParent.classList.add('show'); 
                }
                return new Promise(function(resolve) {
                setTimeout(resolve, 100); 
            });
           },
            buttons: [
              {
                action: this.tourCancel,
                classes: "shepherd-button-third",
                text: "Skip"
              },
              {
                action: this.tourGoBack,
                text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
              },
              {
                action: this.goToNextTour,
                text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
              }
            ]
          }
        ],
        dashboardGroupSubscriptionResponsiveSteps: [
          {
            // title: "Dashboard Overview",
            text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Profile <div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'></div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Explore your profile! Start with Profile Settings. Dive into your account settings here.</div>",
            attachTo: {
              element: "#profile-button",
              on: "bottom"
            },
            buttons: [
              {
                action: this.tourCancel,
                classes: "shepherd-button-third",
                text: "Skip"
              },
              {
                action: this.tourGoNext,
                text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
              }
            ]
          },
         
          {
            // title: "Dashboard Overview",
            text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Switch account<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>This is your Switch Account. Access partner accounts and view your current plan.</div>",
            // text: "<div style='margin-top:2%; color:#7C68EE; font-weight:600; font-size:14px'>1/</div><div style='margin-top:4%;font-weight:600;font-size:24px'>Switch account element</div><div style='margin-top:5%; margin-bottom:7%;font-size:18px'>Shows companies the user is associated with</div>",
            attachTo: {
              element: "#user-company-details",
              on: "bottom"
            },
            beforeShowPromise: function () {
              const mobileSidebar = document.querySelector('.main-wrapper');
              if (mobileSidebar) {
                mobileSidebar.classList.remove('sidebar-open');
              }
              return new Promise(function (resolve) {
                setTimeout(resolve, 100);
              });
            },

            buttons: [
              {
                action: this.tourCancel,
                classes: "shepherd-button-third",
                text: "Skip"
              },
              {
                action: this.tourGoBack,
                // classes: "shepherd-button-secondary",
                text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
              },
              {
                action: this.tourGoNext,
                text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
              }
            ]
          },
          {
            // title: "Dashboard Overview",
            text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Dashboard<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>This is your Dashboard. See your activity at a glance. Check your Groups, Users, and Test Tokens here.</div>",
            attachTo: {
              element: "#sidebar-dashboard",
              on: "bottom"
            },
            beforeShowPromise: function () {
              const dropdownParent = document.querySelector('.main-wrapper');
              if (dropdownParent) {
                dropdownParent.classList.add('sidebar-open');
              }
              // const nextDropdownParent = document.querySelector('.sidebar-team-management-item .collapse.show');
              //     if(nextDropdownParent){
              //       nextDropdownParent.classList.remove('show');
              //       nextDropdownParent.classList.add('hide'); 
              //     }
              return new Promise(function (resolve) {
                setTimeout(resolve, 100);
              });
            },
            buttons: [
              {
                action: this.tourCancel,
                classes: "shepherd-button-third",
                text: "Skip"
              },
              {
                action: this.tourGoBack,
                // classes: "shepherd-button-secondary",
                text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
              },
              {
                action: this.tourGoNext,
                text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
              }
            ]
          },
          {
            // title: "Dashboard Overview" Library,
            text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Test Library<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Your Library is stocked with job role tests and you get 5 for free to start evaluating skills right away.</div>",
            attachTo: {
              element: "#sidebar-library",
              on: "bottom"
            },
            buttons: [
              {
                action: this.tourCancel,
                classes: "shepherd-button-third",
                text: "Skip"
              },
              {
                action: this.tourGoBack,
                text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
              },
              {
                action: this.tourGoNext,
                text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
              }
            ]
          },
          {
            text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Tests<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>When you're ready, take or assign a test in the 'Test' section. It’s easy to keep track of ongoing and past tests.</div>",
            attachTo: {
              element: ".sidebar-test-management",
              on: "bottom"
            },
            beforeShowPromise: function () {
              const dropdownParent = document.querySelector('.sidebar-test-management .collapse');
              if (dropdownParent) {
                dropdownParent.classList.add('show');
              }
              const nextDropdownParent = document.querySelector('.sidebar-team-management-item .collapse.show');
              if (nextDropdownParent) {
                nextDropdownParent.classList.remove('show');
                nextDropdownParent.classList.add('hide');
              }
              return new Promise(function (resolve) {
                setTimeout(resolve, 100);
              });
            },
            buttons: [
              {
                action: this.tourCancel,
                classes: "shepherd-button-third",
                text: "Skip"
              },
              {
                action: this.tourGoBack,
                text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
              },
              {
                action: this.tourGoNext,
                text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
              }
            ]
          },
          {
            text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Management<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>In Management, you have the tools to manage your team’s users and roles efficiently.</div>",
            attachTo: {
              element: ".sidebar-team-management-item",
              on: "bottom"
            },
            beforeShowPromise: function () {
              const BackDropdownParent = document.querySelector('.sidebar-test-management .collapse.show');
              if (BackDropdownParent) {
                BackDropdownParent.classList.remove('show');
                BackDropdownParent.classList.add('hide');
              }
              const dropdownParent = document.querySelector('.sidebar-team-management-item .collapse');
              if (dropdownParent) {
                dropdownParent.classList.add('show');
              }
              return new Promise(function (resolve) {
                setTimeout(resolve, 100);
              });
            },
            buttons: [
              {
                action: this.tourCancel,
                classes: "shepherd-button-third",
                text: "Skip"
              },
              {
                action: this.tourGoBack,
                text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
              },
              {
                action: this.goToNextTour,
                text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
              }
            ]
          },
          {
            text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Settings<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>In settings, you can access the Career Widget, Billing, and Plans Details.</div>",
            attachTo: {
              element: ".sidebar-settings",
              on: "right"
            },
            beforeShowPromise: function() {
              const BackDropdownParent = document.querySelector('.sidebar-team-management-item .collapse.show');
                if(BackDropdownParent){
                  BackDropdownParent.classList.remove('show');
                  BackDropdownParent.classList.add('hide'); 
                }
              const dropdownParent = document.querySelector('.sidebar-settings .collapse');
                if (dropdownParent) {
                dropdownParent.classList.add('show'); 
                }
                return new Promise(function(resolve) {
                setTimeout(resolve, 100); 
            });
           },
            buttons: [
              {
                action: this.tourCancel,
                classes: "shepherd-button-third",
                text: "Skip"
              },
              {
                action: this.tourGoBack,
                text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
              },
              {
                action: this.goToNextTour,
                text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
              }
            ]
          }
        ],
        dashboardGroupEnterpriseResponsiveSteps: [
          {
            // title: "Dashboard Overview",
            text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Profile <div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'></div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Explore your profile! Start with Profile Settings. Dive into your account settings here.</div>",
            attachTo: {
              element: "#profile-button",
              on: "bottom"
            },
            buttons: [
              {
                action: this.tourCancel,
                classes: "shepherd-button-third",
                text: "Skip"
              },
              {
                action: this.tourGoNext,
                text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
              }
            ]
          },   
          {
            text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Switch account<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>This is your Switch Account. Access partner accounts and view your current plan.</div>",
            attachTo: {
              element: "#user-company-details",
              on: "bottom"
            },
            beforeShowPromise: function () {
              const mobileSidebar = document.querySelector('.main-wrapper');
              if (mobileSidebar) {
                mobileSidebar.classList.remove('sidebar-open');
              }
              return new Promise(function (resolve) {
                setTimeout(resolve, 100);
              });
            },

            buttons: [
              {
                action: this.tourCancel,
                classes: "shepherd-button-third",
                text: "Skip"
              },
              {
                action: this.tourGoBack,
                // classes: "shepherd-button-secondary",
                text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
              },
              {
                action: this.tourGoNext,
                text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
              }
            ]
          },
          {
            // title: "Dashboard Overview",
            text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Dashboard<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>This is your Dashboard. See your activity at a glance. Check your Groups, Users, and Test Tokens here.</div>",
            attachTo: {
              element: "#sidebar-dashboard",
              on: "bottom"
            },
            beforeShowPromise: function () {
              const dropdownParent = document.querySelector('.main-wrapper');
              if (dropdownParent) {
                dropdownParent.classList.add('sidebar-open');
              }
              // const nextDropdownParent = document.querySelector('.sidebar-team-management-item .collapse.show');
              //     if(nextDropdownParent){
              //       nextDropdownParent.classList.remove('show');
              //       nextDropdownParent.classList.add('hide'); 
              //     }
              return new Promise(function (resolve) {
                setTimeout(resolve, 100);
              });
            },
            buttons: [
              {
                action: this.tourCancel,
                classes: "shepherd-button-third",
                text: "Skip"
              },
              {
                action: this.tourGoBack,
                // classes: "shepherd-button-secondary",
                text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
              },
              {
                action: this.tourGoNext,
                text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
              }
            ]
          },
          {
            // title: "Dashboard Overview" Library,
            text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Test Library<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Your library is stocked with job role tests, allowing you to start evaluating skills right away.</div>",
            attachTo: {
              element: "#sidebar-library",
              on: "bottom"
            },
            buttons: [
              {
                action: this.tourCancel,
                classes: "shepherd-button-third",
                text: "Skip"
              },
              {
                action: this.tourGoBack,
                text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
              },
              {
                action: this.tourGoNext,
                text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
              }
            ]
          },
          {
            text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Tests<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>When you're ready, take or assign a test in the 'Test' section. It’s easy to keep track of ongoing and past tests.</div>",
            attachTo: {
              element: ".sidebar-test-management",
              on: "bottom"
            },
            beforeShowPromise: function () {
              const dropdownParent = document.querySelector('.sidebar-test-management .collapse');
              if (dropdownParent) {
                dropdownParent.classList.add('show');
              }
              const nextDropdownParent = document.querySelector('.sidebar-team-management-item .collapse.show');
              if (nextDropdownParent) {
                nextDropdownParent.classList.remove('show');
                nextDropdownParent.classList.add('hide');
              }
              return new Promise(function (resolve) {
                setTimeout(resolve, 100);
              });
            },
            buttons: [
              {
                action: this.tourCancel,
                classes: "shepherd-button-third",
                text: "Skip"
              },
              {
                action: this.tourGoBack,
                text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
              },
              {
                action: this.tourGoNext,
                text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
              }
            ]
          },
          {
            text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Management<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>In Management, you have the tools to manage your team’s users and roles efficiently.</div>",
            attachTo: {
              element: ".sidebar-team-management-item",
              on: "bottom"
            },
            beforeShowPromise: function () {
              const BackDropdownParent = document.querySelector('.sidebar-test-management .collapse.show');
              if (BackDropdownParent) {
                BackDropdownParent.classList.remove('show');
                BackDropdownParent.classList.add('hide');
              }
              const dropdownParent = document.querySelector('.sidebar-team-management-item .collapse');
              if (dropdownParent) {
                dropdownParent.classList.add('show');
              }
              return new Promise(function (resolve) {
                setTimeout(resolve, 100);
              });
            },
            buttons: [
              {
                action: this.tourCancel,
                classes: "shepherd-button-third",
                text: "Skip"
              },
              {
                action: this.tourGoBack,
                text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
              },
              {
                action: this.goToNextTour,
                text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
              }
            ]
          },
          {
            text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Settings<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>In settings, you can access the Career section where you can integrate with your platform.</div>",
            attachTo: {
              element: ".sidebar-settings",
              on: "right"
            },
            beforeShowPromise: function() {
              const BackDropdownParent = document.querySelector('.sidebar-team-management-item .collapse.show');
                if(BackDropdownParent){
                  BackDropdownParent.classList.remove('show');
                  BackDropdownParent.classList.add('hide'); 
                }
              const dropdownParent = document.querySelector('.sidebar-settings .collapse');
                if (dropdownParent) {
                dropdownParent.classList.add('show'); 
                }
                return new Promise(function(resolve) {
                setTimeout(resolve, 100); 
            });
           },
            buttons: [
              {
                action: this.tourCancel,
                classes: "shepherd-button-third",
                text: "Skip"
              },
              {
                action: this.tourGoBack,
                text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
              },
              {
                action: this.goToNextTour,
                text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
              }
            ]
          }
        ],
        dynamicTestLibraryTourSteps: [
          {
            text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Test Library<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'></div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Explore our Test Library to find the perfect test for optimal hiring based on your needs!</div>",
            attachTo: {
              element: "#card-tour",
              on: "bottom"
            },
            buttons: [        
              {
                action: this.tourComplete,
                text: "Finish <i class='fa-solid fa-thumbs-up fa-lg mx-2'></i>"
              }
            ]
          }
        ],
        dynamicTestLibraryTourResponsiveSteps: [
          {
            text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Test Library<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'></div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Explore our Test Library to find the perfect test for optimal hiring based on your needs!</div>",
            attachTo: {
              element: "#card-tour",
              on: "bottom"
            },
                    beforeShowPromise: function() {
                const mobileSidebar = document.querySelector('.main-wrapper');
                if (mobileSidebar) {
                mobileSidebar.classList.remove('sidebar-open'); 
              }
            return new Promise(function(resolve) {
                setTimeout(resolve, 100); 
            });
        },
            buttons: [        
              {
                action: this.tourComplete,
                text: "Finish <i class='fa-solid fa-thumbs-up fa-lg mx-2'></i>"
              }
            ]
          }
        ],
        activeTestTourSubscriptionSteps:[
          {
            text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Test Card<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>You've successfully added this test from the library! Easily check candidate scores and access reports.</div>",
            attachTo: {
              element: "#tour-test-card",
              on: "right"
            },
            beforeShowPromise: function() {
            const dropdownParent = document.querySelector('.sidebar-test-management .collapse');
            if (dropdownParent) {
                dropdownParent.classList.add('show'); 
            }
            const nextDropdownParent = document.querySelector('.sidebar-team-management-item .collapse.show');
                if(nextDropdownParent){
                  nextDropdownParent.classList.remove('show');
                  nextDropdownParent.classList.add('hide'); 
                }
             
            return new Promise(function(resolve) {
                setTimeout(resolve, 100); 
            });
        },
            buttons: [
              {
                action: this.tourCancel,
                classes: "shepherd-button-third",
                text: "Skip"
              },
              // {
              //   action: this.tourGoBack,
              //   text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
              // },
              {
                action: this.tourGoNext,
                text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
              }
            ]
          },
          {
            text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Open Invite Link<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>Click the share button to access the open test link for this assessment!</div>",
            attachTo: {
              element: "#tour-share-url",
              on: "bottom"
            },
            buttons: [
              {
                action: this.tourCancel,
                classes: "shepherd-button-third",
                text: "Skip"
              },
              {
                action: this.tourGoBack,
                text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
              },
              {
                action: this.OpenModalNext,
                text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
              }
            ]
          },
          {
            text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Invite Link<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Select your region, grab the invite link, and dive into the test!</div>",
            attachTo: {
              element: ".tour-share-tour-modal",
              on: "right"
            },
        
            buttons: [
            
              {
                action: this.CloseModalBack,
                text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
              },
              {
                action: this.tourComplete,
                text: "Finish <i class='fa-solid fa-thumbs-up fa-lg mx-2'></i>"
              }
            ]
          },
        ],
        activeTestTourEnterpriseSteps:[
          {
            text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Test Card<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>Check test details, candidate scores, and reports easily for a thorough evaluation of their performance.</div>",
            attachTo: {
              element: "#tour-test-card",
              on: "right"
            },
            beforeShowPromise: function() {
            const dropdownParent = document.querySelector('.sidebar-test-management .collapse');
            if (dropdownParent) {
                dropdownParent.classList.add('show'); 
            }
            const nextDropdownParent = document.querySelector('.sidebar-team-management-item .collapse.show');
                if(nextDropdownParent){
                  nextDropdownParent.classList.remove('show');
                  nextDropdownParent.classList.add('hide'); 
                }
             
            return new Promise(function(resolve) {
                setTimeout(resolve, 100); 
            });
        },
            buttons: [
              {
                action: this.tourCancel,
                classes: "shepherd-button-third",
                text: "Skip"
              },
              // {
              //   action: this.tourGoBack,
              //   text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
              // },
              {
                action: this.tourGoNext,
                text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
              }
            ]
          },
          {
            text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Open Invite Link<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>Click the share button to access the open test link for this assessment!</div>",
            attachTo: {
              element: "#tour-share-url",
              on: "bottom"
            },
            buttons: [
              {
                action: this.tourCancel,
                classes: "shepherd-button-third",
                text: "Skip"
              },
              {
                action: this.tourGoBack,
                text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
              },
              {
                action: this.OpenModalNext,
                text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
              }
            ]
          },
          {
            text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Invite Link<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Select your region, grab the invite link, and dive into the test!</div>",
            attachTo: {
              element: ".tour-share-tour-modal",
              on: "right"
            },
        
            buttons: [
            
              {
                action: this.CloseModalBack,
                text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
              },
              {
                action: this.tourComplete,
                text: "Finish <i class='fa-solid fa-thumbs-up fa-lg mx-2'></i>"
              }
            ]
          },
        ],
        activeTestTourSubscriptionResponsiveSteps:[
          {
            text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Test Card<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>You've successfully added this test from the library! Easily check candidate scores and access reports.</div>",
            attachTo: {
              element: "#tour-test-card",
              on: "bottom"
            },
            beforeShowPromise: function() {
            const dropdownParent = document.querySelector('.sidebar-test-management .collapse');
            if (dropdownParent) {
                dropdownParent.classList.add('show'); 
            }
            const nextDropdownParent = document.querySelector('.sidebar-team-management-item .collapse.show');
                if(nextDropdownParent){
                  nextDropdownParent.classList.remove('show');
                  nextDropdownParent.classList.add('hide'); 
                }
                const mobileSidebar = document.querySelector('.main-wrapper');
                if (mobileSidebar) {
                mobileSidebar.classList.remove('sidebar-open'); 
              }
            return new Promise(function(resolve) {
                setTimeout(resolve, 100); 
            });
        },
            buttons: [
              {
                action: this.tourCancel,
                classes: "shepherd-button-third",
                text: "Skip"
              },
              // {
              //   action: this.tourGoBack,
              //   text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
              // },
              {
                action: this.tourGoNext,
                text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
              }
            ]
          },
          {
            text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Open Invite Link<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Click the share button to access the open test link for this assessment!</div>",
            attachTo: {
              element: "#tour-share-url",
              on: "bottom"
            },
            buttons: [
              {
                action: this.tourCancel,
                classes: "shepherd-button-third",
                text: "Skip"
              },
              {
                action: this.tourGoBack,
                text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
              },
              {
                action: this.OpenModalNext,
                text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
              }
            ]
          },
          {
            text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Invite Link<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Select your region, grab the invite link, and dive into the test!</div>",
            attachTo: {
              element: ".tour-share-tour-modal",
              on: "bottom"
            },
        
            buttons: [
            
              {
                action: this.CloseModalBack,
                text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
              },
              {
                action: this.tourComplete,
                text: "Finish <i class='fa-solid fa-thumbs-up fa-lg mx-2'></i>"
              }
            ]
          },
        ],
        activeTestTourEnterpriseResponsiveSteps:[
          {
            text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Test Card<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Check test details, candidate scores, and reports easily for a thorough evaluation of their performance.</div>",
            attachTo: {
              element: "#tour-test-card",
              on: "bottom"
            },
            beforeShowPromise: function() {
            const dropdownParent = document.querySelector('.sidebar-test-management .collapse');
            if (dropdownParent) {
                dropdownParent.classList.add('show'); 
            }
            const nextDropdownParent = document.querySelector('.sidebar-team-management-item .collapse.show');
                if(nextDropdownParent){
                  nextDropdownParent.classList.remove('show');
                  nextDropdownParent.classList.add('hide'); 
                }
                const mobileSidebar = document.querySelector('.main-wrapper');
                if (mobileSidebar) {
                mobileSidebar.classList.remove('sidebar-open'); 
              }
            return new Promise(function(resolve) {
                setTimeout(resolve, 100); 
            });
        },
            buttons: [
              {
                action: this.tourCancel,
                classes: "shepherd-button-third",
                text: "Skip"
              },
              // {
              //   action: this.tourGoBack,
              //   text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
              // },
              {
                action: this.tourGoNext,
                text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
              }
            ]
          },
          {
            text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Open Invite Link<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Click the share button to access the open test link for this assessment!</div>",
            attachTo: {
              element: "#tour-share-url",
              on: "bottom"
            },
            buttons: [
              {
                action: this.tourCancel,
                classes: "shepherd-button-third",
                text: "Skip"
              },
              {
                action: this.tourGoBack,
                text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
              },
              {
                action: this.OpenModalNext,
                text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
              }
            ]
          },
          {
            text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Invite Link<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Select your region, grab the invite link, and dive into the test!</div>",
            attachTo: {
              element: ".tour-share-tour-modal",
              on: "bottom"
            },
        
            buttons: [
            
              {
                action: this.CloseModalBack,
                text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
              },
              {
                action: this.tourComplete,
                text: "Finish <i class='fa-solid fa-thumbs-up fa-lg mx-2'></i>"
              }
            ]
          },
        ],
        careerTourSteps: [
          {
            text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Career Page<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>Use this widget on your career page to engage and screen applicants</div>",
            attachTo: {
              element: "#career-desc",
              on: "bottom"
            },
            buttons: [
              // {
              //   action: this.tourCancel,
              //   classes: "shepherd-button-third",
              //   text: "Skip"
              // },
              {
                action: this.tourGoNext,
                text: "Finish <i class='fa-solid fa-thumbs-up fa-lg mx-2'></i>"
              }
            ]
          },
          // {
          //   text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Demo content 1<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>Demo content 2</div>",
          //   attachTo: {
          //     element: "#how-to-use",
          //     on: "bottom"
          //   },
          //   buttons: [
          //     {
          //       action: this.tourCancel,
          //       classes: "shepherd-button-third",
          //       text: "Skip"
          //     },
          //     {
          //       action: this.tourGoNext,
          //       text: "Finish <i class='fa-solid fa-thumbs-up fa-lg mx-2'></i>"
          //     }
          //   ]
          // },
        ]
        // activeTestTourSubscriptionSteps: [
        //   {
        //     title: "Active test",
        //     text: "This page shows active test page",
        //     attachTo: {
        //       element: "#tour-test-card",
        //       on: "right"
        //     },
        //     buttons: [
        //       {
        //         action: this.tourCancel,
        //         text: "Skip"
        //       },
        //       {
        //         action: this.tourGoNext,
        //         text: "Next"
        //       }
        //     ]
        //   },
        //   {
        //     title: "Dashboard Overview",
        //     text: "User can access their profile details here. Click next to know more.",
        //     attachTo: {
        //       element: "#profile-button",
        //       on: "bottom"
        //     },
        //     buttons: [
        //       {
        //         action: this.tourCancel,
        //         text: "Skip"
        //       },
        //       {
        //         action: this.tourGoNext,
        //         text: "Next"
        //       }
        //     ]
        //   },
        //   {
        //     title: "Dashboard Overview",
        //     text: "User can access their profile details, privacy policy and can logout from here",
        //     attachTo: {
        //       element: "#user-profile-actions",
        //       on: "left"
        //     },
        //     buttons: [
        //       {
        //         action: this.tourCancel,
        //         text: "Skip"
        //       },
        //       {
        //         action: this.tourGoBack,
        //         classes: "shepherd-button-secondary",
        //         text: "Back"
        //       },
        //       {
        //         action: this.tourGoNext,
        //         text: "Next"
        //       }
        //     ]
        //   },
        //   {
        //     title: "Dashboard Overview",
        //     text: "User can access their company details here",
        //     attachTo: {
        //       element: "#user-company-details",
        //       on: "left"
        //     },
        //     buttons: [
        //       {
        //         action: this.tourCancel,
        //         text: "Skip"
        //       },
        //       {
        //         action: this.tourGoBack,
        //         classes: "shepherd-button-secondary",
        //         text: "Back"
        //       },
        //       {
        //         action: this.tourGoNext,
        //         text: "Next"
        //       }
        //     ]
        //   },
        //   {
        //     title: "Dashboard Overview",
        //     text: "This dashboard button displays the details on the dashboard page",
        //     attachTo: {
        //       element: "#sidebar-dashboard",
        //       on: "right"
        //     },
        //     buttons: [
        //       {
        //         action: this.tourCancel,
        //         text: "Skip"
        //       },
        //       {
        //         action: this.tourGoBack,
        //         classes: "shepherd-button-secondary",
        //         text: "Back"
        //       },
        //       {
        //         action: this.tourGoNext,
        //         text: "Next"
        //       }
        //     ]
        //   },
        //   {
        //     title: "Dashboard Overview",
        //     text: "This library button displays the test library details on the dashboard page",
        //     attachTo: {
        //       element: "#sidebar-library",
        //       on: "right"
        //     },
        //     buttons: [
        //       {
        //         action: this.tourCancel,
        //         text: "Skip"
        //       },
        //       {
        //         action: this.tourGoBack,
        //         classes: "shepherd-button-secondary",
        //         text: "Back"
        //       },
        //       {
        //         action: this.tourGoNext,
        //         text: "Next"
        //       }
        //     ]
        //   },
        //   {
        //     title: "Dashboard Overview",
        //     text: "The test module displays active tests, token management features",
        //     attachTo: {
        //       element: "#sidebar-test-management",
        //       on: "right"
        //     },
        //     buttons: [
        //       {
        //         action: this.tourCancel,
        //         text: "Skip"
        //       },
        //       {
        //         action: this.tourGoBack,
        //         classes: "shepherd-button-secondary",
        //         text: "Back"
        //       },
        //       {
        //         action: this.tourGoNext,
        //         text: "Next"
        //       }
        //     ]
        //   },
        //   {
        //     title: "Dashboard Overview",
        //     text: "The management module displays groups, users, roles features",
        //     attachTo: {
        //       element: "#sidebar-team-management",
        //       on: "right"
        //     },
        //     buttons: [
        //       {
        //         action: this.tourCancel,
        //         text: "Skip"
        //       },
        //       {
        //         action: this.tourGoBack,
        //         classes: "shepherd-button-secondary",
        //         text: "Back"
        //       },
        //       {
        //         action: this.tourGoNext,
        //         text: "Next"
        //       }
        //     ]
        //   },
        //   {
        //     title: "Active Test",
        //     text: "User can share test from here",
        //     attachTo: {
        //       element: "#tour-share-url",
        //       on: "bottom"
        //     },
        //     buttons: [
        //       {
        //         action: this.tourCancel,
        //         text: "Skip"
        //       },
        //       {
        //         action: this.tourGoBack,
        //         classes: "shepherd-button-secondary",
        //         text: "Back"
        //       },
        //       {
        //         action: this.tourComplete,
        //         text: "Complete"
        //       }
        //     ]
        //   }
        // ]
      };
    },
    props: {
      page: String,
      tourStatus: String,
      shareModalStatus:Boolean
    },
    mounted() {
      const windowWidth = window.innerWidth;

      if (this.page === 'DashboardGroup') {
        localStorage.setItem("isNewUser", true);
        this.dashboardGroupTour = new Shepherd.Tour({
          useModalOverlay: true,
          tourName: "DashboardGroup",
          defaultStepOptions: {
            scrollTo: {
              behavior: "smooth",
              block: "center"
            }
          }
        });
        if (windowWidth < 999) {
          if(this.user.isEnterprise==false){
            this.dashboardGroupSubscriptionResponsiveSteps.forEach(step => {
            this.dashboardGroupTour.addStep(step);
          });
          }
          else{
            this.dashboardGroupEnterpriseResponsiveSteps.forEach(step => {
            this.dashboardGroupTour.addStep(step);
          });
          }
        }
        else {
          if(this.user.isEnterprise==false){
            this.dashboardGroupSubscriptionSteps.forEach(step => {
            this.dashboardGroupTour.addStep(step);
          });
          }
          else{
            this.dashboardGroupEnterpriseSteps.forEach(step => {
            this.dashboardGroupTour.addStep(step);
          });
          }
        }
        this.dashboardGroupTour.start();
      } else if (this.page === 'TestLibrary') {
        // this.$emit('tour-started');
        this.dynamicTestLibraryTour = new Shepherd.Tour({
          useModalOverlay: true,
          tourName: "DynamicTestLibraryTour",
          defaultStepOptions: {
            scrollTo: {
              behavior: "smooth",
              block: "center"
            }
          }
        });
        if (windowWidth < 999) {
          this.dynamicTestLibraryTourResponsiveSteps.forEach(step => {
            this.dynamicTestLibraryTour.addStep(step);
          });
        }
        else {
          this.dynamicTestLibraryTourSteps.forEach(step => {
            this.dynamicTestLibraryTour.addStep(step);
          });
        }
        // this.dynamicTestLibraryTourSteps.forEach(step => {
        //   this.dynamicTestLibraryTour.addStep(step);
        // });

        this.dynamicTestLibraryTour.start();
      } else if (this.page === 'ActiveTests') {
        this.activeTestTour = new Shepherd.Tour({
          useModalOverlay: true,
          tourName: "ActiveTestTour",
          defaultStepOptions: {
            scrollTo: {
              behavior: "smooth",
              block: "center"
            }
          }
        });

        if (windowWidth < 999) {
          if(this.user.isEnterprise==false){
            this.activeTestTourSubscriptionResponsiveSteps.forEach(step => {
            this.activeTestTour.addStep(step);
          });
          }
          else{
            this.activeTestTourEnterpriseResponsiveSteps.forEach(step => {
            this.activeTestTour.addStep(step);
          });
          }

        }
        else {
          if(this.user.isEnterprise==false){
            this.activeTestTourSubscriptionSteps.forEach(step => {
            this.activeTestTour.addStep(step);
          });
          }
          else{
            this.activeTestTourEnterpriseSteps.forEach(step => {
            this.activeTestTour.addStep(step);
          });
          }
   
        }
        this.activeTestTour.start();
      } else if(this.page === 'Integration') {
        this.careerPageTour = new Shepherd.Tour({
          useModalOverlay: true,
          tourName: "CareerPageTour",
          defaultStepOptions: {
            scrollTo: {
              behavior: "smooth",
              block: "center"
            }
          }
        });

        if (windowWidth < 999) {
          this.careerTourSteps.forEach(step => {
            this.careerPageTour.addStep(step);
          });
        }
        else {
          this.careerTourSteps.forEach(step => {
            this.careerPageTour.addStep(step);
          });
        }
        this.careerPageTour.start();
      }
    },
    methods: {
      ...mapActions(["AllActiveTest"]),
      tourGoNext() {
        if(this.page === 'DashboardGroup') {
          this.dashboardGroupTour.next();
        } else if(this.page === 'TestLibrary') {
          this.dynamicTestLibraryTour.next();
        } else if(this.page === 'ActiveTests') {
          this.activeTestTour.next();
        } else if(this.page === 'Integration') {
          this.careerPageTour.next();
        }
      },
      tourGoBack() {
        if(this.page === 'DashboardGroup') {
          this.dashboardGroupTour.back();
        } else if(this.page === 'TestLibrary') {
          this.dynamicTestLibraryTour.back();
        } else if(this.page === 'ActiveTests') {
          this.activeTestTour.back();
        } else if(this.page === 'Integration') {
          this.careerPageTour.back();
        }
      },
      tourCancel() {
        if(this.page === 'DashboardGroup') {
          this.$emit('tour-close');
          localStorage.setItem("isNewUser", false);
          this.dashboardGroupTour.cancel();
        } else if(this.page === 'TestLibrary') {
          this.$emit('tour-close');
          localStorage.setItem("isNewUser", false);
          this.dynamicTestLibraryTour.cancel();
        } else if(this.page === 'ActiveTests') {
          this.$emit('tour-close');
          localStorage.setItem("isNewUser", false);
          this.activeTestTour.cancel();
        } else if(this.page === 'Integration') {
          this.careerPageTour.cancel();
        }
      },
      
      tourComplete() {
        if(this.page === 'DashboardGroup') {
          this.dashboardGroupTour.complete();
        } else if(this.page === 'TestLibrary') {
          localStorage.setItem("isNewUser", false);
          this.dynamicTestLibraryTour.complete();
          // setNewUser(false);
        } else if(this.page === 'ActiveTests') {
          localStorage.setItem("isNewUser", false);
          this.$emit('share-modal-close');
          this.$emit('tour-close');
          this.activeTestTour.complete();
          // setNewUser(false);
        } else if(this.page === 'Integration') {
          this.careerPageTour.complete();
        }
      },
      goToNextTour() {
        if(this.page === 'DashboardGroup') {
          this.dashboardGroupTour.complete();
          if(this.user.redirectUrl!=null){
            router.push(this.user.redirectUrl);
          }
          else{
            router.push('/test-library');
          }
          // if(this.user.redirectUrl=='active-tests')
          // router.push(this.user.redirectUrl);
          // if(this.redirectToActiveTests === false) {
          //   router.push('/test-library');
          // } else {
          //   router.push('/active-tests');
          // }
        }
      },
      OpenModalNext() {
          this.$emit('share-modal-open');
          this.activeTestTour.next();
      },
      CloseModalBack(){
          this.$emit('share-modal-close');
          this.activeTestTour.back();
      },
      goToBackTour() {
        if(this.page === 'TestLibrary') {
          this.dynamicTestLibraryTour.complete();
          router.push('/dashboard-group');
        }
      }
    }
  };
  </script>