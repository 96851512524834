<template>
  <!-- style="filter: blur(8px);" -->
  <div class="page-content" style="padding-bottom:10px !important;">
    <!-- <button @click="generateExcel">Generate Excel</button> -->
    <div v-if="List.length > 0">
      <Tour v-if="this.user.redirectUrl != null && this.tourStatus" :page="componentName" :shareModalStatus="ShareModal"
        @tour-close="CloseTour" @share-modal-open="OpenShareModal" @share-modal-close="CloseShareModal" />
      <ActiveTestTour v-if="this.componentTour" :page="componentName" :shareModalStatus="ShareModal"
        @tour-close="CloseComponentTour" @share-modal-open="OpenShareModal" @share-modal-close="CloseShareModal"
        @dropdown-open="CardDropdownOpen" />
    </div>
    <div class="Searching_input_box_mobile_view mb-3 position-relative">
      <div class="w-100" v-if="this.show">
        <input type="text" v-model="input" :disabled="searchDisable" @keyup.enter="searchregionname(input)"
          placeholder="Search..." />
        <!--update @keyup="filteredList"-->
        <!-- <div class="search_detail_list" v-if="this.result > 0">
          <div
            v-for="(item, index) in this.result"
            :key="index"
            class="search_detail_list_item"
            @click="searchusername(item)"
            style="cursor: pointer"
          >
            <span :id="index">
              {{ stringTOHtml(item, index) }}
            </span>
          </div>
        </div> -->
        <!--update-->
        <img src="../../public/dashboard-assets/images/M2/Close_icon.png" @click="closemobilesearch" alt=""
          class="close-icon" />
        <!-- </span> -->
      </div>
    </div>

    <!-- header -->
    <div class="d-flex justify-content-between align-items-center flex-wrap mb-2">
      <div class="my-2">
        <h3 class="mb-2 mb-md-0">Active Test</h3>
        <!-- <button @click="NewshowData">Success</button> -->
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <!-- Search -->
        <!-- Search  web view-->
        <div class="card is-loading mx-2" v-if="this.CardLoader == true"
          style="border-radius: 999px; width: 40px; height: 40px;">
        </div>
        <div class="top_heading_CreatePartner_btn position-relative z-index-999" v-else>
          <div class="Group_top_three_dot">
            <div class="Searching_input-box">
              <input type="text" v-model="input" :disabled="searchDisable" @keyup.enter="searchregionname(input)"
                placeholder="Search..." />

              <!--update  @keyup="filteredList"-->
              <!-- <div class="search_detail_list" v-if="this.result.length > 0">
                    <div v-for="(item, index) in this.result" :key="index" class="search_detail_list_item"
                      @click="searchregionname(item)" style="cursor: pointer">
                      <span :id="index" style="cursor: pointer">
                        {{ stringTOHtml(item, index) }}
                      </span>
                    </div>
                  </div> -->
              <!--update-->

              <span class="icon Group_top_three_dot_dropdown" @click="opensearch" id="tour-search-web-icon">
                <img src="../../public/dashboard-assets/images/M2/Icon-Set.png" alt="" class="Searching_search_icon" />
              </span>
              <img src="../../public/dashboard-assets/images/M2/Close_icon.png" @click="closesearch" alt=""
                class="close-icon" />
            </div>
          </div>
        </div>

        <!-- Mobile View Searching Button -->
        <div class="top_heading_CreatePartner_btn position-relative Searching_button_mobileView"
          v-if="List.length != 0">
          <div class="Group_top_three_dot" id="search-mobile-icon">
            <span class="icon Group_top_three_dot_dropdown" :style="{ display: this.show == true ? 'none' : 'block' }"
              @click="openmobilesearch">
              <img src="../../public/dashboard-assets/images/M2/Icon-Set.png" alt="" class="Searching_search_icon" />
            </span>
          </div>
        </div>
        <!-- Filter -->
        <div class="card is-loading mx-2" v-if="this.CardLoader == true"
          style="border-radius: 999px; width: 40px; height: 40px;">
        </div>
        <div class="top_heading_CreatePartner_btn top_heading_CreatePartner_btn-kj d-block mx-2" v-else>
          <div class="Group_top_three_dot" id="tour-filter-web-icon">
            <div class="dropdown">
              <button class="btn p-0 Group_top_three_dot_dropdown position-relative" type="button"
                id="dropdownMenuButton1" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false"
                aria-haspopup="true" style="line-height: 0.3">
                <img src="../../public/dashboard-assets/images/M2/Filter_icon.png" />

                <span class="Noti-alert-kj translate-middle" v-if="this.applyfilter > 0">
                  {{ this.applyfilter }} <span class="visually-hidden"></span>
                </span>
              </button>

              <ul class="dropdown-menu dropdown-menu-kj dropdown-menu-kj-w" aria-labelledby="dropdownMenuButton1">
                <div class="modal-header">
                  <h4 class="modal-title text-centerm-auto" id="CreateGroupModalLabel">
                    Filter
                  </h4>
                  <button @click="filterclose" type="button" class="btn-close" data-bs-toggle="dropdown"></button>
                </div>

                <ul class="nav nav-pills mb-3 nav-fill" id="pills-tab" role="tablist">

                  <li class="nav-item" role="presentation">
                    <button class="nav-link text-muted text-center active" id="pills-profile-tab" data-bs-toggle="pill"
                      @click="
                        this.filterTab = 2;
                      this.searchfilter = '';
                      this.Filterresult();
                      " data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                      aria-selected="false">
                      Language
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link text-muted text-center" id="pills-profile-tab" data-bs-toggle="pill" @click="
                      this.filterTab = 3;
                    this.searchfilter = '';
                    this.Filterresult();
                    " data-bs-target="#pills-profile1" type="button" role="tab" aria-controls="pills-profile"
                      aria-selected="false">
                      Invigilation
                    </button>
                  </li>

                  <li class="nav-item" role="presentation" v-if="getUserPermissionData({
                    parent: 'Partner Management',
                    child: 'Partner Account', permission: 'Can_View_Partner', //update
                  }) && this.isParent === 'true'">
                    <button class=" nav-link text-muted text-center " id="pills-home-tab" @click="
                      this.filterTab = 1;
                    this.searchfilter = '';
                    this.Filterresult();
                    " data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab"
                      aria-controls="pills-home" aria-selected="true">

                      Partner
                    </button>
                  </li>

                  <!-- <li 
                    class="nav-item" 
                    role="presentation" 
                    v-if="getUserPermissionData({
                      parent: 'Partner Management',
                      child: 'Partner Account', permission: 'Can_View_Partner', //update
                    })  && this.isParent === 'true'  ">
                    <button class=" nav-link text-muted text-center " id="pills-home-tab" @click="
                        this.filterTab = 1;
                        this.searchfilter = '';
                        this.Filterresult();
                      " 
                      data-bs-toggle="pill" data-bs-target="#pills-home" 
                      type="button" role="tab"
                      aria-controls="pills-home" aria-selected="true"
                    
                      >

                      Partner
                    </button>
                  </li> -->

                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div class="tab-pane fade   p-0" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    <div class="search-bar">
                      <form class="search-form">
                        <input type="text" v-model="searchfilter" @keyup="Filterresult" placeholder="Search"
                          class="form-control bg-white" /><button class="btn">
                          <i class="fas fa-search"></i>
                        </button>
                      </form>
                      <div class="ul-li-checkbox-kj">
                        <ul>
                          <li v-for="item in partners" :key="item">
                            <input type="checkbox" :value="item" name="" @change="addFilter(item.clientId, $event)"
                              :checked="this.filters.partners.includes(item.clientId)
                                " />
                            <span>{{ item.company }} </span>
                          </li>
                        </ul>
                        <div class="text-end">
                          <button type="button" class="btn-Apply-kj-color" v-if="this.applyfilter > 0"
                            @click="clearFilters()">
                            Clear Filter
                          </button>

                          <button type="button" class="modail_submit_button text-end" :class="{
                            active_form_button: this.applyfilter > 0,
                          }" :disabled="applyfilter == 0" @click="applyFilters()">
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade active show p-0" id="pills-profile" role="tabpanel"
                    aria-labelledby="pills-profile-tab">
                    <div class="search-bar">
                      <form class="search-form">
                        <input type="text" v-model="searchfilter" @keyup="Filterresult" placeholder="Search"
                          class="form-control bg-white" /><button class="btn">
                          <i class="fas fa-search"></i>
                        </button>
                      </form>

                      <!-- <form class="search-form">
                          <input type="text" placeholder="Search" name="" class="form-control bg-white" /><button
                            class="btn">
                            <i class="fas fa-search"></i>
                          </button>
                        </form> -->
                    </div>
                    <div class="ul-li-checkbox-kj">
                      <ul>
                        <li v-for="lang in LanguageList" :key="lang">
                          <input type="checkbox" :value="lang.key" name="" @change="addFilter(lang.key, $event)"
                            :checked="this.filters.language.includes(lang.key)" />
                          <span>{{ lang.value }} </span>
                        </li>
                      </ul>
                      <div class="text-end">
                        <button type="button" class="btn-Apply-kj-color" v-if="this.applyfilter > 0"
                          @click="clearFilters()">
                          Clear Filter
                        </button>

                        <button type="button" class="modail_submit_button text-end"
                          :class="{ active_form_button: this.applyfilter > 0 }" :disabled="applyfilter == 0"
                          @click="applyFilters()">
                          Apply
                        </button>
                      </div>
                      <!-- <div class="text-end">
                          <button type="button" class="btn-Apply-kj-color">
                            Clear Filter
                          </button>
                          <button type="button" class="btn-primary btn-Apply-kj">
                            Apply
                          </button>
                        </div> -->
                    </div>
                  </div>
                  <div class="tab-pane fade p-0" id="pills-profile1" role="tabpanel"
                    aria-labelledby="pills-profile-tab">
                    <div class="search-bar">
                      <!-- <form class="search-form">
                          <input type="text" placeholder="Search" name="" class="form-control bg-white" /><button
                            class="btn">
                            <i class="fas fa-search"></i>
                          </button>
                        </form> -->

                      <form class="search-form">
                        <input type="text" v-model="searchfilter" @keyup="Filterresult" placeholder="Search"
                          class="form-control bg-white" /><button class="btn">
                          <i class="fas fa-search"></i>
                        </button>
                      </form>
                    </div>
                    <div class="ul-li-checkbox-kj">
                      <ul>
                        <li v-for="invi in InvigilationList" :key="invi">
                          <input type="checkbox" :value="invi.key" name="" @change="addFilter(invi.key, $event)"
                            :checked="this.filters.invigilation.includes(invi.key)
                              " />
                          <span>{{ invi.value }}</span>
                        </li>
                        <!-- <li>
                            <input type="checkbox" name="" id="" />
                            <span>Video Invigilation </span>
                          </li> -->
                        <!-- <li>
                            <input type="checkbox" name="" id="" />
                            <span>Web Invigilation </span>
                          </li> -->
                      </ul>
                      <div class="text-end">
                        <button type="button" class="btn-Apply-kj-color" v-if="this.applyfilter > 0"
                          @click="clearFilters()">
                          Clear Filter
                        </button>

                        <button type="button" class="modail_submit_button text-end"
                          :class="{ active_form_button: this.applyfilter > 0 }" :disabled="applyfilter == 0"
                          @click="applyFilters()">
                          Apply
                        </button>

                      </div>
                      <!-- <div class="text-end">
                          <button type="button" class="btn-Apply-kj-color">
                            Clear Filter
                          </button>
                          <button type="button" class="btn-primary btn-Apply-kj">
                            Apply
                          </button>
                        </div> -->
                    </div>
                  </div>
                </div>
              </ul>
              <!-- </div> -->
            </div>
          </div>
        </div>
        <div class="card is-loading mx-2" v-if="this.CardLoader == true"
          style="border-radius: 999px; width: 40px; height: 40px;">
        </div>
        <div class="top_heading_CreatePartner_btn top_heading_CreatePartner_btn-kj d-block mx-1" v-else>
          <!-- 
                v-if="
            getUserPermissionData({
              partner: 'Candidate Management',
              child: 'Candidates',
              permission: 'Can_View_Overall_Cand_Consolidated_Sheet',
            })
          "
           -->
          <div class="Group_top_three_dot" id="tour-consolidated-web-icon">
            <div class="dropdown">
              <button class="btn p-0 Group_top_three_dot_dropdown position-relative" type="button"
                @click="showCandidateConsolidateModal()">
                <i class="fa-solid fa-file-excel fa-lg" style="color: #6571ff;"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="modal fade" id="CandidateConsolidateModal" tabindex="-1"
          aria-labelledby="DeactivatePartnerModalLabel" aria-hidden="true" data-bs-backdrop="static"
          data-bs-keyboard="false">
          <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable border-radius-kj-20 model-sm">
            <div class="modal-content border-radius-kj-20">
              <div class="modal-header token-model-header-color">
                <h5 class="modal-title text-centerm-auto" id="IncreaseTokenModalLabel">
                  <span class="font-size-20-kj">Overall Consolidated Data For All Tests</span>
                </h5>
                <button type="button" id="close" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"
                  @click="resetModal()"></button>
              </div>
              <div class="modal-body ">
                <div class="row mt-4">
                  <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div class="form-check mb-4  " style="padding-left: 0px;">
                      <label class="input-hero">
                        <input type="date" class="form-control" id="start-time" v-model="this.StartConsolidatedate"
                          :max="maxDateTime" />
                        <span class="input-name">
                          Start Date
                          <span class="text-danger">*</span></span>
                      </label>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div class="form-check mb-4" style="padding-left: 0px;">
                      <label class="input-hero">
                        <input type="date" class="form-control" id="end-time" v-model="this.EndConsolidatedate"
                          :max="maxDateTime" />
                        <span class="input-name">
                          End Date
                          <span class="text-danger">*</span></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="text-end mt-3">
                  <button type="button" class="btn btn-primary btn-download" @click="generateExcel()"
                    :disabled="!this.StartConsolidatedate || !this.EndConsolidatedate">
                    Download
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Create Group -->
        <div class="top_heading_CreatePartner_btn mx-2" v-if="getUserPermissionData({
          partner: 'Test Management',
          child: 'Test Account',
          permission: 'Can_Create_Test',
        })
        ">
          <button type="button" class="btn btn-primary btn-icon-text" data-bs-toggle="modal"
            data-bs-target="#CreateGroupModal">
            <i class="fa-solid fa-plus"></i> Create Test
          </button>
        </div>

        <!-- Moblie View icon Create Group -->
        <div class="mobile_Create_Partner_button managementteam-btn-kj" v-if="getUserPermissionData({
          partner: 'Test Management',
          child: 'Test Account',
          permission: 'Can_Create_Test',
        })
        ">
          <button type="button" class="btn" data-bs-toggle="modal" data-bs-target="#CreateGroupModal">
            <i class="fa-solid fa-plus"></i>
          </button>
        </div>
      </div>
    </div>
    <!-- End -->

    <div class="modal fade" id="AssignTestModal" tabindex="-1" aria-labelledby="AssignTestModal" aria-hidden="true"
      data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable border-radius-kj-20">
        <div class="modal-content border-radius-kj-20">
          <div class="modal-header token-model-header-color">
            <h5 class="modal-title text-centerm-auto" id="AssignTestModalLabel">
              <span class="font-size-20-kj">Assign Test to Partner</span>
            </h5>
            <button type="button" id="close" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"
              @click="resetModal()"></button>
          </div>
          <div class="modal-body" style="min-height: 100px; height: 100%;">
            <div class="row mt-3">
              <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                <div class="mb-4">
                  <p class="text-muted font-size-12">Test Name</p>
                  <input type="text" readonly class="form-control-plaintext" v-model="this.assignTestName">
                </div>
                <div class="form-group mb-4">
                  <label class="input-hero">
                    <Multiselect v-model="selectedclients" placeholder="Choose Partner" mode="tags"
                      :close-on-select="false" :options="clientsList" :searchable="true" :group-select="false" required>
                    </Multiselect>
                    <span class="input-name input-group-kj">Select Partner <span class="text-danger">*</span></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="text-end mt-3">
              <button type="button" class="btn btn-primary btn-download" @click="AssignToClient()">
                Assign Test
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Data Show -->
    <section class="Partner_empty_main_section_kj mt-3">
      <div class="row">
        <div v-if="cardLoading" class="row">
          <!-- <img src="@/assets/Images/VerifySuccessful.png" alt="" id="loading" width="100" /> -->
          <div v-for="n in 8" :key="n" class="col-md-6 col-lg-6 col-xl-3 mb-3 stretch-card">
            <div class="card is-loading"></div>
          </div>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-3 mb-3 stretch-card" v-for="Activelist in List" :key="Activelist" v-else>
          <div class="card p-0" id="tour-test-card"
            :class="this.tourStatus == true || this.componentTour == true ? 'disable-text-selection' : ''">
            <div class="card-body p-0" :class="Activelist.isActive === false && this.admin
              ? 'card-body-grey'
              : ''
              ">
              <div class="d-flex" style="position: absolute; right: 15px; top: 15px;">
                <!-- Added by Pmaps Team -->
                <!-- <a data-bs-toggle="modal" data-bs-target="#SharelinkModel" class="text-dark mx-2" @click="
                  (this.testIdforurl = Activelist.testId),
                  (this.SharedUrl = ''),
                  this.GetAllgroups(Activelist.clientId)
                  " v-if="shareTest(Activelist.clientId)">
                  <i style="cursor: pointer" class="fa-solid fa-share-nodes"></i>
                </a> -->
                <span v-if="Activelist.protocorSettings && this.Remainingdays >= 0">
                  <i class="fa-solid fa-eye fa-xs" style="color: #1565ef"></i></span>
                <span v-if="Activelist.isDeployed == false && this.admin && this.Remainingdays >= 0">
                  <a class="fa-solid fa-triangle-exclamation px-2" style="color: #ffc801">
                  </a>
                </span>
                <span style="cursor: pointer" id="tour-share-url" v-else-if="
                  shareTest(Activelist.clientId) && Activelist.isPublic == true && this.Remainingdays >= 0 && Activelist.isActive == true && Activelist.isDeployed == true
                ">
                  <!-- v-if="Activelist.isActive == true && Activelist.isDeployed == true" -->
                  <a data-bs-toggle="modal" data-bs-target="#SharelinkModel" class="text-dark mx-2" @click="
                    (this.testIdforurl = Activelist.testId),
                    (this.SharedUrl = ''),
                    this.GetAllgroups(Activelist.clientId)
                    ">
                    <i style="cursor: pointer" class="fa-solid fa-share-nodes"></i>
                  </a>
                </span>
                <div class="dropdown tour-card-dot-dropdown" v-if="this.Remainingdays >= 0">
                  <button class="btn px-2 py-1 mx-2" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false"
                    style="background: #FFF; box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25); border-radius: 25px;">
                    <i class="fa-solid fa-ellipsis"></i>
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <li v-if="showList(Activelist.isParent, Activelist.clientId) &&
                      getUserPermissionData({
                        parent: 'Candidate Management',
                        child: 'Candidates',
                        permission: 'Can_Invite_Candidate',
                      })
                    ">
                      <!-- <span v-if="Activelist.isActive == true && Activelist.isDeployed == true">
                        <div class="dropdown-item d-flex align-items-center" @click="
                          invite(
                            Activelist.testName,
                            Activelist.testId,
                            Activelist.clientId
                          )
                        " v-if="shareTest(Activelist.clientId)">
                            Invite Candidates
                        </div>
                      </span> -->
                      <span v-if="Activelist.isActive == true && Activelist.isDeployed == true">
                        <router-link :to="{
                          name: 'InviteCandidatePage',
                          query: {
                            id: encode(Activelist.testId),
                            clientId: encode(Activelist.clientId),
                            testName: encode(Activelist.testName),
                            search: encode(this.input),
                            offset: encode(this.offset),
                            current: encode(this.currentPage),
                            page: encode('active-test')
                          },
                        }" v-if="shareTest(Activelist.clientId)">
                          <div class="dropdown-item d-flex align-items-center">
                            Invite Candidates
                            <span style="color:red" class="mx-1">[New]</span>
                          </div>
                        </router-link>
                      </span>
                    </li>


                    <li v-if="showList(Activelist.isParent, Activelist.clientId) &&
                      getUserPermissionData({
                        parent: 'Candidate Management',
                        child: 'Candidates',
                        permission: 'Can_Invite_Candidate',
                      })
                    ">
                      <span v-if="Activelist.isActive == true && Activelist.isDeployed == true">
                        <div class="dropdown-item d-flex align-items-center cursor-pointer" @click="
                          invite(
                            Activelist.testName,
                            Activelist.testId,
                            Activelist.clientId
                          )
                          " v-if="shareTest(Activelist.clientId)">
                          Invite Candidates

                        </div>
                      </span>
                    </li>
                    <li>
                      <router-link :to="{
                        name: 'ActiveTestInviteCandidates',
                        params: {
                          id: encode(Activelist.testId),
                          clientId: encode(Activelist.clientId),
                        },
                        query: {
                          search: encode(this.input),
                          offset: encode(this.offset),
                          current: encode(this.currentPage),
                        },
                      }">
                      <!-- <router-link :to="{
                        name: 'ActiveTestEditInstance',
                        query: {
                          id: encode(Activelist.testId),
                          search: encode(this.input),
                          offset: encode(this.offset),
                          current: encode(this.currentPage),
                        },
                      }"> -->
                        <div class="dropdown-item d-flex align-items-center">
                          Test Instance
                        </div>
                      </router-link>
                    </li>
                    <!-- <li v-if="(this.enablePartner && (Activelist.clientId == this.clientId)) || superadmin()">  -->
                    <li v-if="(this.enablePartner && (Activelist.clientId == this.clientId))">
                      <div class="dropdown-item d-flex align-items-center cursor-pointer"
                        @click="showAssignTestModal(Activelist.testName, Activelist.testId, Activelist.clientId)">
                        Assign to Partner
                      </div>
                    </li>
                    <!-- <li v-if="getUserPermissionData({parent:'Candidate Management', child:'Candidates', permission:'Can_Access_Candidates'})">
                                                  <router-link :to="{
                                                  name: 'ActiveTestViewCandidates',
                                                  params: { id: Activelist.testId , search:this.input},
                                              }">
                                                      <div class="dropdown-item d-flex align-items-center">
                                                          View Candidates
                                                      </div>
                                                  </router-link>
                                              </li> -->
                    <!-- <li v-if="this.admin">
                                                  <div class="dropdown-item d-flex align-items-center">
                                                      Edit Test
                                                  </div>
                                              </li> -->
                    <li v-if="Activelist.isDeployed == false && this.admin">
                      <div class="dropdown-item d-flex align-items-center cursor-pointer"
                        @click="checkTestSlab(Activelist.testId, Activelist.clientId)">
                        Deploy
                      </div>
                    </li>
                    <li v-if="Activelist.isDeployed == true && this.admin">
                      <div @click="Deployundeploy(Activelist.testId, 'undeploy')"
                        class="dropdown-item d-flex align-items-center cursor-pointer">
                        Undeploy
                      </div>
                    </li>
                    <li v-if="Activelist.isPublic == false && this.admin">
                      <div @click="privatePublic(Activelist.testId, 'public')"
                        class="dropdown-item d-flex align-items-center">
                        Public
                      </div>
                    </li>
                    <li v-if="Activelist.isPublic == true && this.admin">
                      <div @click="privatePublic(Activelist.testId, 'private')"
                        class="dropdown-item d-flex align-items-center cursor-pointer">
                        Private
                      </div>
                    </li>
                    <li v-if="Activelist.isActive == false && this.admin">
                      <div @click="ActivateDeactivate(Activelist.testId, 'active')"
                        class="dropdown-item d-flex align-items-center cursor-pointer">
                        Activate
                      </div>
                    </li>
                    <li v-if="Activelist.isActive == true && this.admin">
                      <div @click="
                        ActivateDeactivate(Activelist.testId, 'deactivate')
                        " class="dropdown-item d-flex align-items-center cursor-pointer">
                        Deactivate
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <router-link :to="{
                name: 'ActiveTestViewCandidatesNew',
                params: {
                  testId: encode(Activelist.testId),
                  clientId: encode(Activelist.clientId),
                  isParent: encode(isParent), //update
                },
                query: {
                  search: encode(this.input),
                  offset: encode(this.offset),
                  current: encode(this.currentPage),
                  // filterList: JSON.stringify(encode(this.filters))
                },
              }">
                <div class="p-3">
                  <div class="Partner_management_top-kj mb-2">
                    <div class="d-flex font-size-14">
                      <span id="tour-card-id">
                        <span class="text-muted me-1">Test Id: </span>
                        <span class="test-dark fw-bold">{{ Activelist.testId }}</span></span>
                    </div>


                  </div>
                  <!-- <h6 class="text-dark" v-if="Activelist.testName.length > 15">{{
                                  Activelist.testName.substring(0,
                                      Activelist.testName.lastIndexOf(Activelist.testName.substring(40))) + "..."
                          }}</h6> -->
                  <h6 class="text-dark mt-4" id="tour-card-name">{{ Activelist.testName }}</h6>


                  <div class="my-3" id="tour-card-partner">
                    <h6 class="text-muted">Partner</h6>
                    <!-- <h4 class="text-dark" v-if="Activelist.company.length > 15">{{
          Activelist.company.substring(0,
              Activelist.company.lastIndexOf(Activelist.csompany.substring(26))) + "..."
  }}</h4> -->
                    <h6 class="text-dark">{{ Activelist.company }}</h6>
                  </div>
                  <div id="tour-card-graph">
                    <div class="px-2">
                      <!-- <img src="../../public/dashboard-assets/images/M2/ActiveTestchat.png" alt="" class="w-100" /> -->
                      <div class="row">
                        <div class="col-6 p-0 d-flex justify-content-between align-items-center">
                          <div class="m-auto" style="position: relative" v-if="
                            this.showgraphornot(
                              Activelist.completed,
                              Activelist.progress,
                              Activelist.scheduled
                            )
                          ">
                            <DoughnutChart :chartData="this.setup(Activelist)" :width="500" :height="300"
                              max-width="100%" max-height="100%" style="
                              max-width: 100%;
                              max-height: 100%;
                              display: block;
                              height: 105.6px;
                              width: 107.2px;
                            " />
                            <div class="active_test_cart_number">
                              {{ Activelist.scheduled }} <br />
                              Candidates
                            </div>
                          </div>
                          <div class="m-auto" style="position: relative" v-else>
                            <DoughnutChart :chartData="this.setup()" :options="this.options" :width="500" :height="300"
                              max-width="100%" max-height="100%" style="
                              max-width: 100%;
                              max-height: 100%;
                              display: block;
                              height: 105.6px;
                              width: 107.2px;
                            " />
                            <div class="active_test_cart_number">
                              0 <br />
                              Candidates
                            </div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="row">
                            <div class="col-1 p-0">
                              <i class="fa-solid fa-circle circle-icon"></i>
                            </div>
                            <div class="col-11 p-0">
                              <div class="d-flex justify-content-between align-items-center mt-1">
                                <div class="d-flex align-items-center">
                                  <span class="text-active-kj"> Completed</span>
                                </div>
                                <span class="text-active-kj">{{
                                  Activelist.completed
                                }}</span>
                              </div>
                              <div class="progress mt-1">
                                <div class="bg-success-kj" role="progressbar" style="width: 50%" aria-valuenow="50"
                                  aria-valuemin="0" aria-valuemax="100">
                                  {{ Activelist.pass }}
                                </div>
                                <div class="bg-danger-kj" role="progressbar" style="width: 50%" aria-valuenow="50"
                                  aria-valuemin="0" aria-valuemax="100">
                                  {{ Activelist.fail }}
                                </div>
                              </div>
                              <div class="d-flex m-0">
                                <span class="progress-label-vales w-50">Pass</span>
                                <span class="progress-label-vales w-50">Fail</span>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-1 p-0">
                              <i class="fa-solid fa-circle circle-icon-progress"></i>
                            </div>
                            <div class="col-11 p-0">
                              <div class="d-flex justify-content-between align-items-center mt-1">
                                <div class="d-flex align-items-center">
                                  <span class="text-active-kj"> In-progress</span>
                                </div>
                                <span class="text-active-kj">{{
                                  Activelist.progress
                                }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-1 p-0">
                              <i class="fa-solid fa-circle circle-icon-Pending"></i>
                            </div>
                            <div class="col-11 p-0">
                              <div class="d-flex justify-content-between align-items-center mt-1">
                                <div class="d-flex align-items-center">
                                  <span class="text-active-kj"> Pending</span>
                                </div>
                                <span class="text-active-kj">{{
                                  Activelist.notAttempted
                                }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5" v-if="List.length != 0">
        <!-- <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6 d-flex align-middle" style="bottom:10px;">
          <div class="select-container">
            Show
            <select v-model="this.limit" class="me-1 custom-select" @change="fetchItems">
              <option value="8">8 / Page</option>
              <option value="16">16 / Page</option>
              <option value="32">32 / Page</option>
            </select>
          </div>
        </div> -->
        <!-- <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6"> -->
        <paginate v-model="currentPage" :page-count="Page_count" :margin-pages="1" :page-range="1"
          :click-handler="clickCallback" :prev-text="'< Prev'" :next-text="'Next >'" :container-class="'pagination'"
          :page-class="'page-item'" id="tour-active-test-pagination">
        </paginate>
        <!-- </div> -->
      </div>

      <!-- share Via model -->
      <div class=" modal fade" id="SharelinkModel" tabindex="-1" aria-labelledby="IncreaseTokenModalLabel"
        aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable model-sm ">
          <div class="modal-content border-radius-kj-20 ">
            <div class="modal-header token-model-header-color">
              <h5 class="modal-title text-centerm-auto" id="IncreaseTokenModalLabel">
                <span class="font-size-20-kj"> Share Link</span>
                <p class="Active-model-p-kj ">
                  Please choose a group you would like to share the test.
                </p>
              </h5>

              <button type="button" class="btn-close" data-bs-dismiss="modal" @click="resetShareLink"
                aria-label="btn-close"
                :class="this.tourStatus == true || this.componentTour == true ? 'disable-text-selection' : ''"></button>
            </div>
            <div class="modal-body tour-share-tour-modal"
              :class="this.tourStatus == true || this.componentTour == true ? 'disable-text-selection' : ''">
              <div class="form-group my-4 ">
                <label class="input-hero" for="Group">
                  <select name="" class="input_area select_ca" v-model="SelectGroup" @change="onChange()" id="">
                    <option value="" selected="" disabled="">
                      Choose Group<span class="text-danger">*</span>
                    </option>
                    <option v-for="item in groupshowList" :key="item" :value="item.regionId">
                      {{ item.regionName }}
                    </option>
                  </select><span class="input-name">Group <span class="text-danger">*</span></span></label>
              </div>

              <div class="Share-via-kj" v-if="SharedUrl != ''">
                <h6>Share via</h6>
                <div class="social-media-kj">
                  <a :href="'https://www.facebook.com/sharer/sharer.php?u=' +
                    this.SharedUrl
                    " target="_blank" class="social-icon-kj">
                    <img src="../../public/dashboard-assets/images/M2/facebook.png" alt="" class="w-60" />
                    <!-- <i class="fab fa-facebook-f"></i> -->
                  </a>
                  <a :href="'https://twitter.com/intent/tweet?url=' + this.SharedUrl
                    " target="_blank" class="social-icon-kj">
                    <!-- <i class="fab fa-twitter"></i> -->
                    <img src="../../public/dashboard-assets/images/M2/twitter.png" alt="" class="60" />
                  </a>
                  <a :href="'https://api.whatsapp.com/send?text=' + this.SharedUrl
                    " target="_blank" class="social-icon-kj">
                    <img src="../../public/dashboard-assets/images/M2/whtasup.png" alt="" class="60" />
                    <!-- <i class="fa-brands fa-whatsapp"></i> -->
                  </a>
                  <!-- <a
                    :href="
                      'https://www.linkedin.com/sharing/share-offsite/?url=' +
                      this.SharedUrl
                    "
                    target="_blank"
                    class="social-icon-kj"
                  >
                    <img
                      src="../../public/dashboard-assets/images/M2/link.png"
                      alt=""
                      class="60"
                    />
                  </a> -->
                  <button :disabled="this.SelectGroup == ''" @click="copytoClipboard(this.SharedUrl)"
                    data-bs-dismiss="modal" class="social-icon-kj social-icomn-active" :class="{
                      active_form_button2: this.SelectGroup == null,
                    }">
                    <img src="../../public/dashboard-assets/images/M2/linkshare.png" alt="" class="60" />
                    <!-- <i class="fa-solid fa-paperclip"></i> -->
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Add TestSlab-->
      <div class="modal fade" id="AddTestSlabModal" tabindex="-1" aria-labelledby="TestSlabModalLabel"
        aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable model-sm">
          <div class="modal-content border-radius-kj-20">
            <div class="modal-header token-model-header-color">
              <h5 class="modal-title text-centerm-auto">Add Test Slab</h5>
              <button type="button" class="btn-close" id="close" data-bs-dismiss="modal"
                aria-label="btn-close"></button>
            </div>
            <div class="modal-body mt-4">
              <div class="row" id="CreateForm">
                <div class="form-group mb-4">
                  <label class="input-hero" for="Category">
                    <select name="" v-model="this.clientInvoiceDetails.invoiceType"
                      class="form-select input_area select_ca" disabled>
                      <option selected>Choose Transaction Type</option>
                      <option :value=1>Pay as go</option>
                      <option :value=2>Recurring</option>
                      <option :value=3>PO</option>
                      <option :value=4>Subscription</option>
                      <!-- <option :value=5>Pay-As-Go +  PO</option>
                    <option :value=6>Pay-By-Parent</option> -->
                      <option :value=7>Demo Test</option>
                    </select>
                    <span class="input-name">Client Invoice Type <span class="text-danger">*</span></span>
                  </label>
                </div>
                <div class="form-group mb-4">
                  <label class="input-hero" for="Category">
                    <select name="" v-model="this.invoiceType" @change="updateCost()"
                      class="form-select input_area select_ca" id="" required>
                      <option selected disabled>Choose Transaction Type</option>
                      <option :value=1>Pay as go</option>
                      <option :value=2>Recurring</option>
                      <option :value=3>PO</option>
                      <option :value=4>Subscription</option>
                      <!-- <option :value=5>Pay-As-Go +  PO</option>
                    <option :value=6>Pay-By-Parent</option> -->
                      <option :value=7>Demo Test</option>
                    </select>
                    <span class="input-name">Transaction Type <span class="text-danger">*</span></span>
                  </label>
                  <span v-if="this.clientInvoiceDetails.invoiceType != this.invoiceType" style="color: red;">
                    Note: Try to keep the transaction type same as the client invoice type to avoid any issue in the
                    invoicing.
                  </span>
                </div>
                <div class="form-group mb-4">
                  <label class="input-hero" for="FromCount">
                    <input class="input_area" v-model="testSlabFromCount" maxlength="5" type="number"
                      required="" /><span class="input-name">From
                      Count <span class="text-danger">*</span></span>
                  </label>
                </div>
                <div class="form-group mb-4">
                  <label class="input-hero" for="ToCount">
                    <input class="input_area" v-model="testSlabToCount" maxlength="5" type="number" required="" />
                    <span class="input-name">To Count
                      <span class="text-danger">*</span></span>
                  </label>
                </div>
                <div class="form-group mb-4">
                  <label class="input-hero" for="Cost">
                    <input class="input_area" v-model="testSlabCost" maxlength="5" type="number" required="" />
                    <span class="input-name">Cost
                      <span class="text-danger">*</span></span>
                  </label>
                </div>
                <div class="">
                  <button class="modail_submit_button mx-2"
                    @click="addTestSlabList(this.testSlabFromCount, this.testSlabToCount, this.testSlabCost)">
                    Add
                  </button>
                </div>
              </div><br />

              <div class="card p-0" id="testslabtable" style="border-radius: 12px; width: 100%;">
                <div class="table-responsive">
                  <table class="table text-center">
                    <thead style="background-color: #F1F0FF !important;">
                      <tr>
                        <th>Sr No</th>
                        <th>From Count</th>
                        <th>To Count</th>
                        <th>Cost</th>
                        <!-- <th>Edit</th>
                        <th>Delete</th> -->
                      </tr>
                    </thead>
                    <tbody v-for="(details, index) in testSlabData" :key="index + 1">
                      <tr>
                        <td>{{ details.Id }}</td>
                        <td>{{ details.FromCount }}</td>
                        <td>{{ details.ToCount }}</td>
                        <td>{{ details.Cost }}</td>
                        <!-- <td @click="editTestSlab(details.Id, details.FromCount, details.ToCount, details.Cost)"><i class="fa-solid fa-pen-to-square fa-lg mx-1"></i></td> 
                       <td @click="removeTestSlabList(index)"><i class="fa-solid fa-trash"></i></td> -->
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <button class="modail_submit_button mt-3 mx-2" @click="addTestSlab()">
                Save
              </button>
              <button class="modail_submit_button mt-3" @click="clearTestSlabList()">
                Clear
              </button>
            </div>

          </div>

        </div>
      </div>

      <!-- Show Tour Modal -->
      <div class="modal fade" id="showTourModal" tabindex="-1" aria-labelledby="IncreaseTokenModalLabel"
        aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable model-sm ">
          <div class="modal-content border-radius-kj-20 ">
            <div class="modal-body">
              <div class="float-end" style="color: #333333;">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"></button>
              </div>
              <div class="row my-4 mx-1">
                <div class="col-12 col-sm-6 col-lg-6 col-md-6 col-xl-6">
                  <div style="font-weight: 600; font-size: 26px;">Ready to see how we can make your hiring process a
                    breeze?</div>
                  <div style="color: #333333;" class="mt-2">
                    Explore our interactive tour and discover how to easily manage Test invites, Candidate Reports, and
                    many more!

                    Our platform is designed with you in mind, making everything smooth and straightforward.

                    Let's make your hiring process easier together!
                  </div>
                  <div class="mt-5">
                    <div class="row">
                      <!-- <div class="col-12 col-sm-6 col-lg-6 col-md-6 col-xl-6 mb-3"><button class="tour-primary-button" >Watch Video</button></div> -->
                      <div class="col-12 col-sm-6 col-lg-6 col-md-6 col-xl-6 mb-3"><button class="tour-secondary-button"
                          @click="StartComponentTour()">Start Tour</button></div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-6 col-md-6 col-xl-6" style="align-content:center">
                  <img src="../../public/dashboard-assets/images/M2/TourImages/ActiveTestStart.svg" alt=""
                    style="width:100%;" />
                </div>
              </div>
              <!-- <div class="form-group my-4 ">
                <label class="input-hero" for="Group">
                  <select name="" class="input_area select_ca" v-model="SelectGroup" @change="onChange()" id="">
                    <option value="" selected="" disabled="">
                      Choose Group<span class="text-danger">*</span>
                    </option>
                    <option v-for="item in groupshowList" :key="item" :value="item.regionId">
                      {{ item.regionName }}
                    </option>
                  </select><span class="input-name">Group <span class="text-danger">*</span></span></label>
              </div> -->


            </div>
          </div>
        </div>
      </div>

      <!-- Tour Complete Modal  -->
      <div class="modal fade" id="TourCompleteModal" tabindex="-1" aria-labelledby="IncreaseTokenModalLabel"
        aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable model-sm ">
          <div class="modal-content border-radius-kj-20 ">
            <div class="modal-body">
              <div class="my-6" style="text-align: center;">
                <h1 style="font-weight: 600; color: #333333;">Nice Work !</h1>
                <h2 style="font-weight: 600; color: #333333;" class="mt-2">Your active test summary is done</h2>
                <div style="color: #111111; font-size: 18px;" class="mt-2">Time to hire best candidates through
                  assessments </div>
                <div class="mt-5">
                  <button type="button" class="tour-primary-button" data-bs-dismiss="modal" aria-label="btn-close"
                    style="max-width:200px">Tour Completed</button>
                  <div class="mt-4" @click="this.OpenTourModal();" style="color: #7b68ee; cursor: pointer;"><i
                      class="fa-solid fa-arrow-rotate-left"></i><span class="mx-2">Restart Tour</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  Invite Candidates model -->
      <inviteCandidate v-if="this.openInvite == true" :testname="this.testName" :testid="this.testId"
        :testclientid="this.testclientid" @clicked="closeinvite" />


      <!-- <InviteCandidatesNew
        v-if="this.openInvite == true"
        :testname="this.testName"
        :testid="this.testId"
        :testclientid="this.testclientid"
        @clicked="closeinvite"
      /> -->


      <!-- Archive Test? -->
      <div class="modal fade" id="DeactivatePartnerModal" tabindex="-1" aria-labelledby="DeactivatePartnerModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" v-for="Activelist in List"
          :key="Activelist">
          <div class="modal-content">
            <div class="modal-body p-4">
              <div class="">
                <h3 class="mb-3">Archive Test?</h3>
                <p class="text-muted">
                  Are you sure you want to Archive
                  <strong> {{ Activelist.company }}</strong>
                </p>
              </div>
              <div class="Deactivate_buttons row mt-5 justify-content-center">
                <button class="col-5 Deactivate_Cancel_buttons" data-bs-dismiss="modal">
                  Cancel
                </button>
                <button type="button" class="col-5 btn Deactivate_Deactivate_buttons">
                  Archive
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- empty section -->
    <!-- <section class="Partner_empty_main_section_hp" v-if="List.length == 0"> -->
    <section class="Partner_empty_main_section_hp" v-if="this.ListStatus == false">
      <div class="row">
        <div class="Partner_empty_img_hp m-auto text-center">
          <img src="../../public/dashboard-assets/images/M2/Partner_blank.png" alt="" />
        </div>
        <div class="Partner_empty_content_hp m-auto text-center">
          <div class="mb-4">
            <!-- <h2>No Record Found</h2> -->
            <h4 class="mt-2" style="line-height: 35px;">
              <b>Don’t</b> forget to add the test to your <b>Active Tests!</b><br>You’re missing active tests, check
              out the <b>Test Library</b> for great options!
            </h4>
            <router-link :to="'/test-library'" style="text-decoration: none !important;">
              <h5 class="my-4 secondary-button" style="font-weight: 600; border-radius: 30px; padding: 1rem;">
                View Test Library
                <i class="fa-solid fa-arrow-right mx-2"></i>
              </h5>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { superadmin } from "../utils/helper";
import Paginate from "vuejs-paginate-next";
import { getUserPermissionData } from "../utils/helper";
// import { SFacebook, STwitter ,SWhatsApp,SLinkedIn } from 'vue-socials'
import { mapState, mapActions, mapMutations } from "vuex";
import inviteCandidate from "./inviteCandidate.vue";
// import InviteCandidatesNew from "./InviteCandidatesNew.vue";
import { DoughnutChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
import { calculateRemainingDays } from '../platformFeatures/dateUtils';
// import { AccessPlanFeatures } from '../platformFeatures/featureUtils';
// import { XlsxDownload, XlsxRead } from 'vue3-xlsx';
import * as XLSX from 'xlsx';
import Multiselect from "@vueform/multiselect";
import moment from 'moment';
import Tour from '../components/TourDemo.vue';
import store from "../store";
import eventBus from './TourComponents/eventBus.js'
import ActiveTestTour from './TourComponents/ActiveTestTour.vue'
// import { getNewUser } from '../utils/auth'

Chart.register(...registerables);

export default {
  name: "Active-Tests",
  components: {
    paginate: Paginate,
    Multiselect,
    inviteCandidate,
    // InviteCandidatesNew,
    DoughnutChart,
    Tour,
    ActiveTestTour
    // XlsxRead,
    // XlsxDownload
    // SFacebook,STwitter,SWhatsApp,SLinkedIn
  },
  data() {
    return {
      // facebookShareOptions: {
      //               url: this.SharedUrl,
      //               // quote: this.SharedUrl,
      //               // hashtag: '#Github',
      //           },
      //           twitterShareOptions: {
      //               url: this.SharedUrl,
      //               title: 'Say hi to Vite! A brand new, extremely fast development setup for Vue.',

      //               // text: this.SharedUrl,
      //               // hashtags: ['hash', 'tag'],
      //               via: 'Pmaps',
      //           },
      //           shareOptions: {
      //     // number: '1(999)999-99-99',
      //     text: this.SharedUrl,
      //   },
      //   shareOptions1: {

      //     url: this.SharedUrl
      //   },
      //   useNativeBehavior: false,
      clientsList: [],
      assignTestName: "",
      assignTestId: 0,
      assignClientId: 0,
      selectedclients: [],
      enablePartner: false,
      isShowing: false,
      openInvite: false,
      componentName: false,
      tourStatus: JSON.parse(localStorage.getItem("isNewUser")),
      ShareModal: false,
      Id: null,
      testIdforurl: "",
      SharedUrl: "",
      filterTab: 1,
      results: [],
      groupshowList: [],
      listing: [],
      SelectGroup: "",
      Page_count: 1,
      ListStatus: true,
      CardLoader: true,
      limit: 8,
      offset: 1,
      result: [],
      List: [],
      input: "",
      show: false,
      currentPage: 1,
      testId: [],
      partners: [],
      partnersFilter: [],
      applyfilter: 0,
      searchDisable: false,
      searchActivationList: [],
      testclientid: "",
      searchfilter: "",
      filters: {
        partners: [],
        language: [],
        invigilation: [],
        testsearchname: "",
      },
      isPublic: true,
      isParent: localStorage.getItem("isParent"), //update
      LanguageList: [
        {
          key: 1,
          value: "English",
        },
        {
          key: 2,
          value: "Gujarati",
        },
        {
          key: 3,
          value: "Marathi",
        },
        {
          key: 4,
          value: "Tamil",
        },
        {
          key: 5,
          value: "Kannada",
        },
        {
          key: 6,
          value: "French",
        },
      ],
      LanguageListFilter: [
        {
          key: 1,
          value: "English",
        },
        {
          key: 2,
          value: "Gujarati",
        },
        {
          key: 3,
          value: "Marathi",
        },
        {
          key: 4,
          value: "Tamil",
        },
        {
          key: 5,
          value: "Kannada",
        },
        {
          key: 6,
          value: "French",
        },
      ],

      InvigilationList: [
        {
          key: 1,
          value: "Desktop Invigilation",
        },
        {
          key: 2,
          value: "Web Invigilation",
        },
      ],

      InvigilationListFilter: [
        {
          key: 1,
          value: "Desktop Invigilation",
        },
        {
          key: 2,
          value: "Web Invigilation",
        },
      ],
      options: {
        plugins: {
          tooltip: {
            enabled: false,
          },
        },
      },
      admin: false,
      routesearch: this.$route.query.id
        ? window.atob(this.$route.query.id)
        : '',
      routeoffset: this.$route.query.offset
        ? window.atob(this.$route.query.offset)
        : 0,
      routecurrent: this.$route.query.current
        ? window.atob(this.$route.query.current)
        : 0,
      Remainingdays: 0,
      billing: JSON.parse(localStorage.getItem('billingData')),
      excelData: [],
      StartConsolidatedate: "",
      EndConsolidatedate: "",
      maxDateTime: moment().format('YYYY-MM-DD'),
      tourTestId: 0,
      tourClientId: 0,
      testSlabFromCount: null,
      testSlabToCount: null,
      testSlabCost: null,
      selectedTestId: 0,
      testSlabData: [],
      testSlabIndex: 0,
      componentTour: false,
      receivedData: false,
      isOpen: false,
      tempDataStatus: false,
      invoiceType: 0,
      clientInvoiceDetails: ""
    };
  },
  async created() {
    store.state.loader.cardLoading = true;
    this.componentName = this.$route.name;
    this.enablePartner = this.user.enablePartner;
    await this.ActiivetestList();

    if (this.billing != null) {
      this.Remainingdays = calculateRemainingDays(this.billing.object.data.subscription.expiryDate);
      //  AccessPlanFeatures(this.user.extId, 49, 'Active Test Access');
    }
    if (this.isParent == "true") {
      await this.PartnerList();
    }
    this.admin = superadmin();
    store.state.loader.cardLoading = false;
    //await this.ActiivetestList();
    // this.handleTourStarted();
  },
  async mounted() {
    eventBus.emit('data-from-child-component', true);
    eventBus.on('data-from-header-component', data => {
      this.receivedData = data;
      this.OpenTourModal();
    });
    if (getUserPermissionData({
      parent: 'Partner Management',
      child: 'Partner Account', permission: 'Can_View_Partner'
    }) /* && this.isParent === 'true' */) {
      this.filterTab = 1
    } else {
      this.filterTab = 2
    }
    // console.log(JSON.parse(window.atob(this.$route.query.filterList)));

    if (this.$route.query.filter) {
      if (localStorage.getItem('ActiveTestfilters') != null) {
        this.filters = JSON.parse(localStorage.getItem('ActiveTestfilters'));
        this.applyfilter =
          this.filters.partners.length +
          this.filters.language.length +
          this.filters.invigilation.length;
      }
    }
    // else{
    //   this.clearFilters();
    // }
    var TeamList = await this.GetTeams({
      clientId: this.user.clientId,
    });
    {
      this.listing = TeamList.object.data.regions;
    }
    // if (this.isParent == "true") {
    //   await this.PartnerList();
    // }

    if (this.routeoffset && this.routecurrent) {
      this.offset = parseInt(this.routeoffset);
      this.currentPage = parseInt(this.routecurrent);

      if (this.routesearch != '') {
        this.input = this.routesearch;
        this.opensearch();
      }
      await this.ActiivetestList();
    }
  },
  beforeUnmount() {
    eventBus.off('data-from-header-component');
    eventBus.emit('data-from-child-component', false);
    // this.clearFilters();
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticate,
      user: (state) => state.auth.user,
      clientId: (state) => state.auth.user.clientId,
      cardLoading: (state) => state.loader.cardLoading,
    }),
  },

  methods: {
    getUserPermissionData,
    ...mapActions([
      "AllActiveTest",
      "getrole",
      "deleteroles",
      "Deploy",
      "Undeploy",
      "Private",
      "Public",
      "GetTeams",
      "Activate",
      "ShowSucessMessage",
      "Deactivate",
      "AllPartnerList",
      "InviteCandidates",
      "UserRegionData",
      "GetUrlforshare",
      "adminregionList",
      "postCandidateDetails",
      "ShowWarningMessage",
      "GetTestSlabData",
      "PostTestSlabData",
      "editTestSlab",
      "clearSlabList",
      "AssignTestToClients",
      "GetInvoiceClientDetails"
    ]),
    ...mapMutations(["setLoading"]),
    superadmin,


    // handleTourStarted() {
    //   this.tourStatus = 'active'; 
    // },
    // handleTourEnded() {
    //   this.tourStatus = 'inactive'; 
    // },

    async OpenShareModal() {
      window.$("#SharelinkModel").modal("show");
    },
    async CloseShareModal() {
      // this.tourStatus = false;
      window.$("#SharelinkModel").modal("hide");
    },
    CardDropdownOpen() {
      this.isOpen = true;
    },
    async OpenTourModal() {
      window.$("#TourCompleteModal").modal("hide");
      window.$("#showTourModal").modal("show");
    },
    async StartComponentTour() {
      window.$("#showTourModal").modal("hide");

      if (this.List.length <= 0) {
        this.List = [{ "testId": 6059, "testName": "Customer Service Assessment Test", "token": "1478991E-2C8D-4EA5-ADC9-813BBA755AD6", "parentId": 5438, "clientId": 1771, "company": "PMaps Assessment", "scheduled": 30, "completed": 20, "progress": 10, "notAttempted": 0, "isPublic": true, "isDeleted": false, "isActive": true, "isDeployed": true, "isArchive": null, "protocorSettings": false, "pass": 0, "fail": 0 }];
        this.tempDataStatus = true;
      }
      this.componentTour = true;
    },
    async CloseComponentTour() {
      if (this.tempDataStatus == true) {
        this.List = [];
        this.tempDataStatus = false;
      }
      window.$("#TourCompleteModal").modal("show");
      window.scrollTo(0, 0);
      this.componentTour = false;
    },
    CloseTour() {
      this.tourStatus = false;
    },
    async generateExcel() {
      const start = moment(this.StartConsolidatedate).format('YYYY-MM-DD');
      const end = this.EndConsolidatedate = moment(this.EndConsolidatedate).format('YYYY-MM-DD');

      if (start > end) {
        return this.ShowWarningMessage("Start date is cant be greater than end date");
      }
      if (moment(end).diff(moment(start), 'days') >= 31) {
        return this.ShowWarningMessage("The difference between start and end dates cannot be more than 31 days");
      }
      else {
        var endDate = moment(end).add(1, 'days').format('YYYY-MM-DD');
        var value = await this.postCandidateDetails({
          clientId: this.user.clientId,
          fromDate: start,
          toDate: endDate
        });

        var newData = value.object?.data;

        newData.forEach((data) => {
          this.excelData.push({
            TestCandidateId: data.testCandidateId,
            TestId: data.testId || "NA",
            TestName: data.testName || "NA",
            ClientName: data.clientName || "NA",
            CandidateName: data.candidateName || "NA",
            EmailAddress: data.emailAddress || "NA",
            MobileNo: data.mobileNo || "NA",
            TestStartTime: data.testStartTime || "NA",
            TestEndTime: data.testEndTime || "NA",
            RegionName: data.regionName || "NA",
            Percentage: data.percentage || "NA",
            Result: data.result || "NA",
            WeightagePercentage: data.weightagePercentage || "NA",
            WeightageResult: data.weightageResult || "NA",
            AptitudeFitment: data.aptitudeFitment || "NA",
            PsychometryFitment: data.psychometryFitment || "NA",
            'CompletionScore(%)': data.completionScore || "NA",
            ProctoringScore: data.proctoringScore || "NA",
            ProctoringFitment: data.proctoringFitment || "NA"
          });
        });
        if (this.excelData.length != 0) {
          const workbook = XLSX.utils.book_new();
          const worksheet = XLSX.utils.json_to_sheet(this.excelData);
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
          XLSX.writeFile(workbook, 'CandidateConsolidateSheet.xlsx');
          this.resetModal();
          window.$("#CandidateConsolidateModal").modal("hide");
        }
      }
    },
    // Consolidated Sheet dont delete 
    // NewshowData(){
    //   const sheetsData = this.Newsdata;
    //   const workbook = XLSX.utils.book_new();
    //   for (const sheetName in sheetsData) {
    //     if (sheetsData.hasOwnProperty.call(sheetsData, sheetName)) {
    //       const sheetData = sheetsData[sheetName];
    //       const excelData = sheetData.map(data => {
    //         return {
    //           ...data
    //         };
    //       });
    //       const worksheet = XLSX.utils.json_to_sheet(excelData);

    //       XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    //     }
    //   }
    //   XLSX.writeFile(workbook, 'MultipleSheets.xlsx');

    //   // this.resetModal();
    //   // window.$("#CandidateConsolidateModal").modal("hide");

    // },
    showCandidateConsolidateModal() {
      window.$('#CandidateConsolidateModal').modal('show');
    },
    updateCost() {
      if (this.invoiceType == 7) {
        this.testSlabCost = 0;
      }
    },
    showAssignTestModal(name, testId, clientId) {
      this.assignTestName = name;
      this.assignTestId = testId;
      this.assignClientId = clientId;
      window.$('#AssignTestModal').modal('show');
    },

    async AssignToClient() {

      await this.AssignTestToClients({
        testId: this.assignTestId,
        clientId: this.assignClientId,
        partnerIds: this.selectedclients,
      });
      this.selectedclients = [];
      window.$('#AssignTestModal').modal('hide');
    },

    resetModal() {
      this.excelData = [];
      this.StartConsolidatedate = "";
      this.EndConsolidatedate = "";
      this.selectedclients = [];
    },
    clickCallback: function (pageNum) {
      this.offset = (pageNum - 1) * this.limit + 1;
      this.ActiivetestList();
    },
    encode(item) {
      return window.btoa(item);
    },
    showgraphornot(complete, progress, scheduled) {
      var x = 0;
      x = complete + progress + scheduled;
      if (x > 0) {
        return true;
      } else {
        return false;
      }
    },
    setup(item) {
      if (item != null) {
        var testData = {
          labels: [],
          datasets: [
            {
              data: [item.completed, item.progress, item.notAttempted],
              backgroundColor: ["#F8B200", "#FEDE69", "#FFECA6"],
            },
          ],
        };
        return testData;
      } else {
        var Datatest = {
          labels: [],
          datasets: [
            {
              data: [100],
              backgroundColor: ["#FFAFD2"],
            },
          ],
        };
        return Datatest;
      }
    },
    invite(name, id, clientId) {
      this.testName = name;
      this.testId = id;
      this.testclientid = clientId;
      this.openInvite = true;
    },
    closeinvite(val) {
      this.openInvite = val;
    },
    showList(val, client) {
      if (!superadmin()) {
        if (val) {
          if (this.clientId != client) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    },

    shareTest(Testclient) {
      var parent = localStorage.getItem("isParent");
      /*update*/
      if (!superadmin() && parent == "true" && this.clientId != Testclient) {
        return false;
      } else {
        return true;
      }

      // else {
      //   if (this.clientId != Testclient) {
      //     return false
      //   }
      //   else {
      //     return true;
      //   }
      // }
    },
    async copytoClipboard(mytext) {
      try {
        await navigator.clipboard.writeText(mytext);
        this.SelectGroup = "";
        window.$("#SharelinkModel").modal("hide");
        return this.ShowSucessMessage("Link copied");
      } catch ($e) {
        // console.log($e);
      }
    },

    async ActiivetestList() {
      var model = {
        clientId: this.clientId,
        limit: this.limit,
        sortCol: "testid",
        offset: this.offset,
        sortdir: "desc",
      };
      if (this.filters.partners.length > 0) {
        model.partner = this.filters.partners.toString();
        //model.offset = (this.currentPage ) * this.limit;

      }
      if (this.filters.language.length > 0) {
        model.language = this.filters.language.toString();
        //model.offset = (this.currentPage ) * this.limit;
      }
      if (this.filters.invigilation.length > 0) {
        model.invigilation = this.filters.invigilation.toString();
        //model.offset = (this.currentPage ) * this.limit;
      }

      if (this.input != null) {
        if (this.input.trim() != "") {
          model.Search = this.input;
          this.searchDisable = true;
        }
      }

      var list = await this.AllActiveTest(model);
      if (list.status) {
        this.ListStatus = true;
        this.searchDisable = false;
        this.List = list.object.data.testSummary;
        if (this.List.length <= 0) {
          this.ListStatus = false;
        }
        this.Page_count = list
          ? Math.ceil(list.object.data.filterCount / this.limit)
          : 0;
        this.CardLoader = false;

        // if(this.user.redirectUrl != null){
        // const TourData = this.List[0];
        // if (TourData) {
        //   this.tourTestId = TourData.testId;
        //   this.tourClientId = TourData.clientId;
        // }
        // }
      }
      else {
        this.ListStatus = false;
      }

    },

    async applyFilters() {
      this.currentPage = 1;
      this.offset = 1;
      localStorage.setItem("ActiveTestfilters", JSON.stringify(this.filters));
      // var ActiveTestfilters = localStorage.getItem("ActiveTestfilters");
      document.getElementById("dropdownMenuButton1").click();
      await this.ActiivetestList();
    },

    async clearFilters() {
      this.currentPage = 1;
      this.offset = 1;
      this.filters.language = [];
      this.filters.invigilation = [];
      this.filters.partners = [];
      this.applyfilter = 0;
      this.searchfilter = "";
      this.Filterresult();
      this.ActiivetestList();
      document.getElementById("dropdownMenuButton1").click();
      localStorage.removeItem('ActiveTestfilters')
    },

    async addFilter(value, e) {
      if (e.target.checked) {
        if (this.filterTab == 1) {
          this.filters.partners.push(value);
        }
        if (this.filterTab == 2) {
          this.filters.language.push(value);
        }
        if (this.filterTab == 3) {
          this.filters.invigilation.push(value);
        }
        this.applyfilter =
          this.filters.partners.length +
          this.filters.language.length +
          this.filters.invigilation.length;
      } else {
        if (this.filterTab == 1) {
          this.filters.partners.splice(
            Object.freeze(
              JSON.parse(JSON.stringify(this.filters.partners))
            ).indexOf(value),
            1
          );
        }

        if (this.filterTab == 2) {
          this.filters.language.splice(
            Object.freeze(
              JSON.parse(JSON.stringify(this.filters.language))
            ).indexOf(value),
            1
          );
        }

        if (this.filterTab == 3) {
          this.filters.invigilation.splice(
            Object.freeze(
              JSON.parse(JSON.stringify(this.filters.invigilation))
            ).indexOf(value),
            1
          );
        }
        if (this.applyfilter == 1) {
          this.applyFilters();
        }
        this.applyfilter =
          this.filters.partners.length +
          this.filters.language.length +
          this.filters.invigilation.length;
      }
    },

    Filterresult() {
      if (this.searchfilter != "") {
        if (this.filterTab == 1) {
          this.partners = this.partnersFilter.filter((element) =>
            element.company
              .toLowerCase()
              .includes(this.searchfilter.toLowerCase())
          );
        }

        if (this.filterTab == 2) {
          this.LanguageList = this.LanguageListFilter.filter((element) =>
            element.value
              .toLowerCase()
              .includes(this.searchfilter.toLowerCase())
          );
        }
        if (this.filterTab == 3) {
          this.InvigilationList = this.InvigilationListFilter.filter(
            (element) =>
              element.value
                .toLowerCase()
                .includes(this.searchfilter.toLowerCase())
          );
        }
      } else {
        this.partners = this.partnersFilter;
        this.LanguageList = this.LanguageListFilter;
        this.InvigilationList = this.InvigilationListFilter;
      }
    },

    async checkTestSlab(id, clientId) {
      if (clientId == 1001 || clientId == 2278 || clientId == 1063 || clientId == 1372 || clientId == 1266 || clientId == 1182 || clientId == 1219) {
        await this.Deploy({
          testId: id,
        });
        await this.ActiivetestList();
      }
      else {
        var data = await this.GetTestSlabData({
          testId: id
        });
        if (data.object.data.length > 0) {
          await this.Deploy({
            testId: id,
          });
          await this.ActiivetestList();
        }
        else {
          this.selectedTestId = id;
          var clientInvoiceDetails = await this.GetInvoiceClientDetails({ clientId: clientId });
          this.clientInvoiceDetails = clientInvoiceDetails.object.data;
          this.invoiceType = this.clientInvoiceDetails.invoiceType;
          window.$("#AddTestSlabModal").modal("show");
        }
      }

    },

    addTestSlabList(from, to, cost) {

      if (from == null || from == "") {
        return this.ShowWarningMessage(
          "Please enter From Count."
        );
      }

      if (to == null || to == "") {
        return this.ShowWarningMessage(
          "Please enter To Count."
        );
      }

      if (this.invoiceType != 7 && (cost == null || cost == "")) {
        return this.ShowWarningMessage(
          "Please enter the Cost."
        );
      }

      if (from > to) {
        return this.ShowWarningMessage(
          "From count should be less than To count"
        );
      }

      if (from == to) {
        return this.ShowWarningMessage(
          "From count and To count can not be same"
        );
      }

      var count = this.testSlabData.length;
      var error = "";
      if (this.Id == null || this.Id == "") {
        if (count > 0) {
          this.testSlabData.forEach(element => {

            if (element.FromCount == to || element.ToCount == to) {
              error = "To Count already exists";
            }
            if (element.FromCount == from || element.ToCount == from) {
              error = "From Count already exists";
            }

            if (element.FromCount < from && element.ToCount > from) {
              error = "From Count exists in given slabs";
            }
            if (element.FromCount < to && element.ToCount > to) {
              error = "To Count exists in given slabs";
            }
          }

          )
        }
        else {
          if (from > 1) {
            return this.ShowWarningMessage("Slab should start with from Count as 1");
          }
        }
      }

      if (error != "") {
        return this.ShowWarningMessage(error);
      }
      else {
        if (this.Id != null) {
          this.testSlabData[this.Id].FromCount = from;
          this.testSlabData[this.Id].ToCount = to;
          this.testSlabData[this.Id].Cost = cost;
          this.Id = null;
          this.testSlabFromCount = null;
          this.testSlabToCount = null;
          this.testSlabCost = null;
        }
        else {
          var id = ++this.testSlabIndex;
          this.testSlabData.push({ Id: id, FromCount: from, ToCount: to, Cost: cost });
          this.testSlabFromCount = null;
          this.testSlabToCount = null;
          this.testSlabCost = null;
        }
      }
    },

    clearTestSlabList() {
      this.testSlabData = [];
      this.testSlabFromCount = null;
      this.testSlabToCount = null;
      this.testSlabCost = null;
      this.testSlabIndex = null;
    },

    // removeTestSlabList(index){
    //   this.testSlabData.splice(index , 1);
    //   --this.testSlabIndex; 
    //   // if(this.testSlabData==[]){
    //   //   this.testSlabIndex=0;
    //   // }
    // },

    // editTestSlab(id, fromcount, tocount, cost)
    // {
    //   this.Id = id-1;
    //   this.testSlabFromCount = fromcount;
    //   this.testSlabToCount = tocount;
    //   this.testSlabCost = cost;
    // },

    async addTestSlab() {
      if (this.testSlabData == null || this.testSlabData == "") {
        return this.ShowWarningMessage(
          "Please enter Test Slabs"
        );
      }

      // if(this.clientInvoiceDetails.invoiceType != this.invoiceType && this.invoiceType != 7){
      //   return this.ShowWarningMessage(
      //     "Selected transaction type is different from the client's type, " + this.clientInvoiceDetails.invoiceTypeName + "."
      //   );
      // }

      var data = await this.PostTestSlabData({
        testId: this.selectedTestId,
        invoiceType: this.invoiceType,
        slabs: this.testSlabData
      });
      if (data.status) {
        await this.Deploy({
          testId: this.selectedTestId,
        });
        await this.ActiivetestList();
      }
      window.$("#AddTestSlabModal").modal("hide");
      this.selectedTestId = 0;
      this.testSlabData = [];
      this.Id = null;
    },
    async Deployundeploy(val, type) {
      if (type == "deploy") {
        await this.Deploy({
          testId: val,
        });
        await this.ActiivetestList();
      }
      if (type == "undeploy") {
        await this.Undeploy({
          testId: val,
        });
        await this.ActiivetestList();
      }
    },
    async privatePublic(val, type) {
      if (type == "public") {
        await this.Public({
          testId: val,
        });
        await this.ActiivetestList();
      }
      if (type == "private") {
        await this.Private({
          testId: val,
        });
        await this.ActiivetestList();
      }
    },

    async ActivateDeactivate(val, type) {
      if (type == "active") {
        await this.Activate({
          testId: val,
        });
        await this.ActiivetestList();
      }
      if (type == "deactivate") {
        await this.Deactivate({
          testId: val,
        });
        await this.ActiivetestList();
      }
    },

    filterclose() {
      document.getElementById("dropdownMenuButton1").click();
    },

    async PartnerList() {
      var partners = await this.AllPartnerList({
        clientId: this.clientId,
        offset: 0,
        limit: -1,
        Search: "",
      });
      this.partners = partners?.object?.data?.partnerData;
      this.partnersFilter = partners?.object?.data?.partnerData;

      this.clientsList = partners?.object?.data?.partnerData.map((i) => ({
        value: i.clientId,
        label: i.clientId + ' - ' + i.company,
      }));
    },

    async resetShareLink() {
      // this.SelectGroup = [];
      this.SelectGroup = "";
    },

    async searchregionname(val) {
      this.input = val.trim();
      this.offset = 0;
      this.Search = val;
      this.result = [];
      this.currentPage = 1;
      await this.ActiivetestList();
    },
    stringTOHtml(item, idx) {
      setTimeout(() => {
        if (this.input != "") {
          let re = new RegExp(this.input.toLowerCase(), "g");
          var html = document.getElementById(idx);
          var ex = item
            .toLowerCase()
            .replace(
              re,
              `<span style="color:#7b68ee !important">${this.input}</span>`
            );
          if (html) html.innerHTML = ex;
        }
      }, 100);
    },
    // Searching box
    opensearch() {
      document.querySelector(".Searching_input-box").classList.add("open");
    },
    async closesearch() {
      document.querySelector(".Searching_input-box").classList.remove("open");
      this.result = [];
      this.Search = "";
      if (this.input !== "") {
        this.input = "";
        await this.ActiivetestList();
      }
    },
    async closemobilesearch() {
      this.show = false;
      document
        .querySelector(".Searching_input_box_mobile_view")
        .classList.remove("open");
      this.result = [];
      this.Search = "";
      if (this.input !== "") {
        this.input = "";
        await this.ActiivetestList();
      }
    },

    openmobilesearch() {
      this.show = true;
      this.result = [];
      this.input = "";
      document
        .querySelector(".Searching_input_box_mobile_view")
        .classList.add("open");
    },
    async filteredList() {
      if (this.input != "") {
        this.searchActivationList = [];
        var roles = await this.AllActiveTest({
          Search: this.input,
          clientId: this.clientId,
          limit: 100,
          sortCol: "testid",
          offset: 0,
          sortdir: "desc",
        });
        var x = roles.object.data.testSummary;
        x.forEach((element) => {
          if (this.searchActivationList.indexOf(element.testName) < 0) {
            this.searchActivationList.push(element.testName);
          }
        });
        this.result = this.searchActivationList.filter((element) =>
          element.toLowerCase().startsWith(this.input.toLowerCase())
        );
        this.result = Object.freeze(JSON.parse(JSON.stringify(this.result)));
      } else {
        this.result = [];
        this.ActiivetestList();
      }
    },

    async GetAllgroups(id) {
      this.groupshowList = []; //update
      var result;
      if (this.clientId == 1001) {
        result = await this.adminregionList({
          userId: id,
        });
        var res = result.object.data.regions;
        res.forEach((element) => {
          if (element.isActive) {
            this.groupshowList.push(element);
          }
        });
      } else {
        result = await this.UserRegionData({
          userId: this.user.userId,
        });
        var rs = result.object.data;
        rs.forEach((element) => {
          if (element.isActive) {
            this.groupshowList.push(element);
          }
        });
      }
    },

    async fetchItems() {
      store.state.loader.cardLoading = true;
      this.offset = 0;
      this.currentPage = 1;
      await this.ActiivetestList();
      store.state.loader.cardLoading = false;
    },

    async onChange() {
      if (this.SelectGroup != "") {
        var result = await this.GetUrlforshare({
          testId: this.testIdforurl,
          regionId: this.SelectGroup,
        });
        if (result.status) {
          this.SharedUrl = result.object.data.token;
        } else {
          window.$("#SharelinkModel").modal("hide");
          this.SelectGroup = "";
        }
      }
    },
    onClose() { },
    onOpen() {
      this.SelectGroup = "";
    },
    onBlock() { },
    onFocus() { },
  },
};
</script>

<style scoped>
@import "../../node_modules/share-button-links/main.css";

.card {
  /* margin: 10px; */
  width: 260px;
  height: 290px;
  /* background: #8a8a8a; */
  /* border-radius: 5px; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}

/* .cards .content {
  padding: 20px 0px;
} */

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.card.is-loading {

  background: #E8E4FF;
  background: linear-gradient(110deg, #ece8fa 8%, #f5f5f5 18%, #ece8fa 33%);
  /* border-radius: 5px; */
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;

}

.social-icomn-active {
  color: #64686f !important;
  border-color: #ded8ff !important;
  background: #ded8ff !important;
}

.active_form_button2 {
  background-color: #fff !important;
  color: #7b68ee !important;
}

.disable-text-selection {
  pointer-events: none;
}

.text-active-kj {
  font-size: 12px;
  font-weight: 600;
  color: #292e34;
}

.table-responsive {
  overflow-y: auto;
  display: block;
  min-height: 150px !important;
  max-height: 250px !important;

  height: 100%;
}

.table-responsive thead th {
  position: sticky;
  top: 0;
  background-color: #f2f0ff;
  z-index: 2;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

.circle-icon {
  font-size: 9px;
  color: #f8b200;
}

.circle-icon-progress {
  font-size: 9px;
  color: #fede69;
}

.circle-icon-Pending {
  font-size: 9px;
  color: #ffeca68a;
}

.bg-danger-kj {
  background-color: #ff549e !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  padding-left: 10px;
  white-space: nowrap;
  background-color: #6571ff;
  transition: width 0.6s ease;
}

.bg-success-kj {
  background-color: #5e45eb !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  padding-left: 10px;
  white-space: nowrap;
  background-color: #6571ff;
  transition: width 0.6s ease;
}

.progress-label-vales {
  color: #666666;
  font-size: 10px;
  font-weight: 600;
  padding-left: 13px;
}

.dropdown-menu-kj-w {
  min-width: 290px !important;
}

.w-60 {
  width: 60%;
}

.page-item.disabled .page-link {
  background-color: #fff !important;
  border-color: #fff !important;
}

.pagination .page-item .page-link {
  width: 30px;
  height: 32px;
  background: transparent;
  display: flex;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  margin: 5px;
  color: #000 !important;
  border: none;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

/* .pagination {
  justify-content: flex-end;
} */

.pagination .page-item.active .page-link {
  font-weight: 600;
  background: #ffc801;
}

.pagination .page-item:last-child .page-link {
  background-color: transparent;
  border: none;
  width: 100% !important;
}

.pagination .page-item:first-child .page-link {
  background-color: transparent;
  border: none;
  width: 100% !important;
}

.pagination .page-item.disabled .page-link {
  color: rgb(130, 130, 130) !important;
  cursor: no-drop !important;
  background: #7fffd400 !important;
}

@media (max-width: 480px) {
  .active_test_cart_number {
    font-size: 7px;
  }
}

@media (max-width:400px) {
  .table-responsive tbody td {
    font-size: small;
  }
}

.font-size-12 {
  font-size: 12px !important;
}

/* Added by Pmaps Team */

.i-icon-color {
  color: black;
}

.secondary-button {
  font-size: medium !important;
  font-weight: 600;
  border-radius: 4px;
  color: #7b68ee;
  border: 2px solid #7b68ee !important;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  padding: 0.375rem 0.75rem;
  cursor: pointer;
}

.select-container {
  /* position: relative; */
  width: 100%;
}

.custom-select {
  width: 100%;
  max-width: 100px;
  border-radius: 15px;
  /* border: 1px solid #333333; */
  border: none;
  box-shadow: 0 0 10px #e6e6eb;
  /* padding-left: 5%; */
  height: 100%;
  /* min-height: 25px; */
  max-height: 45px;
  /* appearance: none; */
  padding-left: 15px;
  /* padding-right: 15px; */
  padding-top: 10px;
  padding-bottom: 10px;
}

/* .tour-primary-button {
  background-color: #7b68ee;
  font-size: 16px;
  border-radius: 4px;
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
  border: none;
  width: 100%;
}

.tour-secondary-button {
  background-color: #FFFFFF;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
  color: #7b68ee;
  border: 1px solid;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
} */
/* Added by Pmaps Team */
</style>