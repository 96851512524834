<template>
  <div class="Overview_dashboard_map_section my-4">
    <div class="card p-4">
      <div class="card-body p-0" id="score-analysis-chart">
        <h3 style="font-weight: 600;" class="mb-5">Score Analysis
        </h3>
        <div>
          <div>
            <div class="mb-3" v-if="this.highestScore != 0 || this.lowestScore != 0"> Key Insights: </div>
            <div class="mb-3" v-if="this.highestScore != 0"> <i class="fa-solid fa-check  mx-2"
                style="color: #7C68EE;"></i> High Scores in <span style="font-weight: 600;"> {{ highestRegion }} </span>
              <span style="font-weight: 600;"> ({{ highestSection }} Section)</span>: <span style="font-weight: 600;">{{
                highestScore
                }}</span>
            </div>
            <div class="mb-5"> <i class="fa-solid fa-triangle-exclamation  mx-2"
                style="color: #7C68EE;"></i> Improvement Needed in <span style="font-weight: 600;"> {{ lowestRegion }}
              </span>
              <span style="font-weight: 600; margin-left: 5px;">({{ lowestSection }} Section)</span>: <span
                style="font-weight: 600;"> {{ lowestScore
                }}</span>
            </div>

            <div class="heatmap-chart" ref="chartContainerSectionLangCompetencies"></div>

            <div class="mt-3">
              <ul>
                <li v-if="this.highestScore != 0">
                  <span style="font-weight: 600;"> Top Performing Section: </span>The <span style="font-weight: 600;">
                    {{ highestSection }}</span> section in <span style="font-weight: 600;"> {{ highestRegion }}</span>
                  had the highest average score of <span style="font-weight: 600;"> {{ highestScore }}</span>. Leverage
                  successful training methods from this section to improve other areas.
                </li>
                <li>
                  <span style="font-weight: 600;"> Sections Needing Improvement: </span>The <span
                    style="font-weight: 600;">{{ lowestSection }}</span> section in <span style="font-weight: 600;"> {{
                    lowestRegion }}</span> had the lowest average score of <span style="font-weight: 600;">{{
                    lowestScore }}</span>. Conduct a detailed analysis to identify specific challenges and develop
                  targeted interventions to address them.
                </li>
              </ul>
            </div>
          </div>

          <div v-if="this.assessmentData.psyCompetency.length > 0" class="mt-7">
            <div class="mb-3" v-if="this.psyHighestScore != 0 || this.psyLowestScore != 0"> Key Insights: </div>
            <div class="mb-3" v-if="this.psyHighestScore != 0"> <i class="fa-solid fa-check mx-2"
                style="color: #7C68EE;"></i> High Scores in <span style="font-weight: 600;"> {{ psyHighestRegion }}
              </span> <span style="font-weight: 600;">({{ psyHighestSection }} Section)</span> : <span
                style="font-weight: 600;">
                {{
                  psyHighestScore
                }}
              </span> </div>
            <div class="mb-5"> <i class="fa-solid fa-triangle-exclamation mx-2"
                style="color: #7C68EE;"></i> Improvement Needed in <span style="font-weight: 600;"> {{ psyLowestRegion}}
              </span>
              <span style="font-weight: 600; margin-left: 5px;"> ({{ psyLowestSection }} Section)</span> : <span
                style="font-weight: 600;"> {{
                  psyLowestScore
                }}</span>
            </div>

            <div class="heatmap-chart mt-5" ref="chartContainerPsyCompetencies"></div>

            <div class="mt-3">
              <ul>
                <li  v-if="this.psyHighestScore != 0">

                  <span style="font-weight: 600;"> Top Performing Section: </span>The <span style="font-weight: 600;">
                    {{ psyHighestSection }}</span> section in <span style="font-weight: 600;"> {{ psyHighestRegion
                    }}</span>
                  had the highest average score of <span style="font-weight: 600;">{{ psyHighestScore }}</span>.
                  Leverage
                  successful training methods from this section to improve other areas.
                </li>
                <li>
                  <span style="font-weight: 600;"> Sections Needing Improvement: </span>The <span
                    style="font-weight: 600;">{{ psyLowestSection }}</span> section in <span style="font-weight: 600;">
                    {{
                    psyLowestRegion }}</span> had the lowest average score of <span style="font-weight: 600;">{{
                    psyLowestScore }}</span>. Conduct a detailed analysis to identify specific challenges and develop
                  targeted interventions to address them.
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import { Tooltip } from "bootstrap/dist/js/bootstrap.esm.min.js";

HighchartsHeatmap(Highcharts);

export default {
  name: 'HeatmapChart',
  props: {
    assessmentData: Array
  },
  data() {
    return {
      overallSectionScore: [],
      overallLangScore: [],
      competencies: [],
      hasPsyCompetency: false,
      hasOtherCompetency: false,
      graphShow: false,
      countData: 0,
      highestScore: 0,
      highestSection: '',
      highestRegion: '',
      lowestScore: '',
      lowestSection: '',
      lowestRegion: '',
      psyHighestScore:  0,
      psyHighestSection: '',
      psyHighestRegion:  '',
      psyLowestScore:    0,
      psyLowestSection: '',
      psyLowestRegion:  '',
    };
  },
  watch: {
    assessmentData() {
      this.SectionLanguageCompetencyData();
      this.psyCompetencyData();
    }
  },
  methods: {
    SectionLanguageCompetencyData() {
      const data = this.assessmentData;
      var graphTitle = '';
      this.hasOtherCompetency = data.sectionCompetency.length > 0 || data.languageCompetency.length > 0;

      if (data.languageCompetency.length < 0) {
        graphTitle = 'Test Sections';
      }
      else {
        graphTitle = 'Test & Language Sections';
      }
      let xAxisCategories = new Set();
      let yAxisCategories = new Set();
      let seriesData = [];
      if (this.hasOtherCompetency) {
        this.processCompetency = (competencies) => {
          competencies.forEach(section => {
            section.regions.forEach(region => {
              region.competencyScores.forEach(score => {
                xAxisCategories.add(score.competencyName);
                yAxisCategories.add(region.regionName);
                seriesData.push([score.competencyName, region.regionName, score.overallScore]);
              });
            });
          });
        };
      }


      const competencies = [data.sectionCompetency, data.languageCompetency];
      competencies.forEach(competency => {
        if (competency.length > 0) {
          this.processCompetency(competency);
        }
      });

      xAxisCategories = Array.from(xAxisCategories);
      yAxisCategories = Array.from(yAxisCategories);

      let highScore = -Infinity;
      let highSection = "";
      let highRegion = "";
      let lowScore = Infinity;
      let lowSection = "";
      let lowRegion = "";

      for (let i = 0; i < seriesData.length; i++) {
        let section = seriesData[i][0];
        let region = seriesData[i][1];
        let score = seriesData[i][2];

        if (score > highScore) {
          highScore = score;
          highSection = section;
          highRegion = region;
        }

        if (score < lowScore) {
          lowScore = score;
          lowSection = section;
          lowRegion = region;
        }
      }

      this.highestScore = highScore;
      this.highestSection = highSection;
      this.highestRegion = highRegion;
      this.lowestScore = lowScore;
      this.lowestSection = lowSection;
      this.lowestRegion = lowRegion;

      seriesData = seriesData.map(item => [
        xAxisCategories.indexOf(item[0]),
        yAxisCategories.indexOf(item[1]),
        item[2]
      ]);



      if (this.hasOtherCompetency) {
        Highcharts.chart(this.$refs.chartContainerSectionLangCompetencies, {
          chart: {
            type: 'heatmap',
            spacing: [0, 0, 0, 0]
          },
          title: {
            text: graphTitle,
            style: {
              fontWeight: "600",
            }
          },
          xAxis: {
            categories: xAxisCategories,
            title: {
              text: 'Sections',
              style: {
                fontWeight: "600",
                fontSize: '16px',
                color: '#333333'
              },
            },
            labels: {
              style: {
                fontSize: '14px',
                color: '#333333'
              }
            }
          },
          yAxis: {
            categories: yAxisCategories,
            title: {
              text: 'Regions',
              style: {
                fontWeight: "600",
                fontSize: '16px',
                color: '#333333'
              },
            },
            labels: {
              style: {
                fontSize: '14px',
                color: '#333333'
              }
            },
          },
          colorAxis: {
            min: 0,
            minColor: '#FF90C0', // Purple
            maxColor: '#4b2eee'  // Pink
          },
          legend: {
            align: 'right',
            layout: 'vertical',
            margin: 0,
            verticalAlign: 'top',
            y: 25,
            symbolHeight: 320
          },
          credits: {
            enabled: false
          },
          series: [{
            borderWidth: 1,
            data: seriesData,
            dataLabels: {
              enabled: true,
              color: '#FFFFFF',
              style: {
                fontSize: '14px'
              }
            }
          }],
          tooltip: {
            formatter: function () {
              return '<b>' + this.series.xAxis.categories[this.point.x] + '</b> scored <br><b>' +
                this.point.value + '</b> in <br><b>' +
                this.series.yAxis.categories[this.point.y] + '</b>';
            }
          }
        });
      }
    },

    psyCompetencyData() {
      const data = this.assessmentData;
      this.hasPsyCompetency = data.psyCompetency.length > 0;
      let psyXAxisCategories = new Set();
      let psyYAxisCategories = new Set();
      let psySeriesData = [];

      this.processPsyCompetency = (competencies) => {
        competencies.forEach(section => {
          section.regions.forEach(region => {
            region.competencyScores.forEach(score => {
              psyXAxisCategories.add(score.competencyName);
              psyYAxisCategories.add(region.regionName);
              psySeriesData.push([score.competencyName, region.regionName, score.overallScore]);
            });
          });
        });
      };

      this.processPsyCompetency(data.psyCompetency);

      psyXAxisCategories = Array.from(psyXAxisCategories);
      psyYAxisCategories = Array.from(psyYAxisCategories);

      let psyHighScore = -Infinity;
      let psyHighSection = "";
      let psyHighRegion = "";
      let psyLowScore = Infinity;
      let psyLowSection = "";
      let psyLowRegion = "";

      for (let i = 0; i < psySeriesData.length; i++) {
        let section = psySeriesData[i][0];
        let region = psySeriesData[i][1];
        let score = psySeriesData[i][2];

        if (score > psyHighScore) {
          psyHighScore = score;
          psyHighSection = section;
          psyHighRegion = region;
        }

        if (score < psyLowScore) {
          psyLowScore = score;
          psyLowSection = section;
          psyLowRegion = region;
        }
      }

      this.psyHighestScore   = psyHighScore;
      this.psyHighestSection = psyHighSection;
      this.psyHighestRegion  = psyHighRegion;
      this.psyLowestScore    = psyLowScore;
      this.psyLowestSection  = psyLowSection;
      this.psyLowestRegion   = psyLowRegion;

      psySeriesData = psySeriesData.map(item => [
        psyXAxisCategories.indexOf(item[0]),
        psyYAxisCategories.indexOf(item[1]),
        item[2]
      ]);

      if (this.hasPsyCompetency) {
        Highcharts.chart(this.$refs.chartContainerPsyCompetencies, {
          chart: {
            type: 'heatmap',
            spacing: [0, 0, 0, 0]
          },
          title: {
            text: 'Behavioural Competency',
            style: {
              fontWeight: "600",
            }
          },
          xAxis: {
            categories: psyXAxisCategories,
            title: {
              text: 'Sections',
              style: {
                fontWeight: "600",
                fontSize: '16px',
                color: '#333333'
              },
            },
            labels: {
              style: {
                fontSize: '14px',
                color: '#333333'
              }
            }
          },
          yAxis: {
            categories: psyYAxisCategories,
            title: {
              text: 'Regions',
              style: {
                fontWeight: "600",
                fontSize: '16px',
                color: '#333333'
              },
            },
            labels: {
              style: {
                fontSize: '14px',
                color: '#333333'
              }
            }
          },
          colorAxis: {
            min: 0,
            minColor: '#FF90C0', // Purple
            maxColor: '#4b2eee'  // Pink
          },
          legend: {
            align: 'right',
            layout: 'vertical',
            margin: 0,
            verticalAlign: 'top',
            y: 25,
            symbolHeight: 320
          },
          credits: {
            enabled: false
          },
          series: [{
            borderWidth: 1,
            data: psySeriesData,
            dataLabels: {
              enabled: true,
              color: '#FFFFFF',
              style: {
                fontSize: '14px'
              }
            }
          }],
          tooltip: {
            formatter: function () {
              return '<b>' + this.series.xAxis.categories[this.point.x] + '</b> scored <br><b>' +
                this.point.value + '</b> in <br><b>' +
                this.series.yAxis.categories[this.point.y] + '</b>';
            }
          }
        });
      }
      Array.from(
        document.querySelectorAll('img[data-bs-toggle="tooltip"]')
      ).forEach((tooltipNode) => new Tooltip(tooltipNode));
    }
  },
  async mounted() {
    this.graphShow = false;
    await this.SectionLanguageCompetencyData();

    if (this.assessmentData.psyCompetency.length > 0) {
      await this.psyCompetencyData();
    }
    this.graphShow = true;
  },
};
</script>

<style scoped>
.heatmap-chart {
  width: 100%;
  height: 400px;
}
</style>
