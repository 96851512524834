<template>
    <div class="page-content">
      <!-- mobile view Searching -->
      <div class="Searching_input_box_mobile_view mb-3 position-relative" v-if="
        this.grouplist.length != 0 ||
        this.activegroupCount > 0 ||
        this.deactivegroupCount > 0 ||
        this.applyfilter.length != 0 ||
        input != ''
      ">
        <div class="w-100" v-if="this.show">
          <input type="text" v-model="input" @keyup.enter="searchregionname(input)" placeholder="Search..." />
          <!--update @keyup="filteredList"-->
          <!-- <div class="search_detail_list" v-if="this.result.length > 0">
            <div v-for="(item, index) in this.result" :key="index" class="search_detail_list_item"
              @click="searchregionname(item)">
              <span :id="index">
                {{ stringTOHtml(item, index) }}
              </span>
            </div>
          </div> -->
          <!--update-->
          <img src="../../public/dashboard-assets/images/M2/Close_icon.png" @click="closemobilesearch" alt=""
            class="close-icon" />
        </div>
      </div>
  
      <div class="d-flex justify-content-between align-items-center flex-wrap mb-2">
        <div class="my-2 d-flex align-items-center">
          <router-link :to="{
                name: 'ManagementPartners',
                query: {
                    offset: this.$route.params.offset,
                    current: this.$route.params.current,
                    search: this.$route.params.search
                  },

              }" class="p-0" type="button">
                <img src="../../public/dashboard-assets/images/M2/BackButton.png" />
              </router-link>
        <div class="my-2">
          
          <h4 class="mb-2 mb-md-0"> Manage Partner Groups</h4>
        </div>
        </div>
        
        
      
        <div class="d-flex justify-content-between align-items-center">
          <div class="top_heading_CreatePartner_btn position-relative z-index-999" v-if="
            this.grouplist.length != 0 ||
            this.activegroupCount > 0 ||
            this.deactivegroupCount > 0 ||
            this.applyfilter.length != 0 ||
            input != ''
          ">
            <div class="Group_top_three_dot">
              <div class="Searching_input-box">
                <input type="text" v-model="input" @keyup.enter="searchregionname(input)" placeholder="Search..." />
                
                <span class="icon Group_top_three_dot_dropdown" @click="opensearch">
                  <img src="../../public/dashboard-assets/images/M2/Icon-Set.png" alt="" class="Searching_search_icon" />
                </span>
                <img src="../../public/dashboard-assets/images/M2/Close_icon.png" @click="closesearch" alt=""
                  class="close-icon" />
              </div>
            </div>
          </div>
  
          <!-- Mobile View Searching Button -->
          <div class="
              top_heading_CreatePartner_btn
              position-relative
              Searching_button_mobileView
            " v-if="
              this.grouplist.length != 0 ||
              this.activegroupCount > 0 ||
              this.deactivegroupCount > 0 ||
              this.applyfilter.length != 0 ||
              this.input != ''
            ">
            <div class="Group_top_three_dot">
              <span class="icon Group_top_three_dot_dropdown" :style="{ display: this.show == true ? 'none' : 'block' }"
                @click="openmobilesearch">
                <img src="../../public/dashboard-assets/images/M2/Icon-Set.png" alt="" class="Searching_search_icon" />
              </span>
            </div>
          </div>
  
          <!-- Filter -->
          <div class="
              top_heading_CreatePartner_btn top_heading_CreatePartner_btn-kj
              d-block
              mx-2
            " v-if="
              this.grouplist.length != 0 ||
              this.activegroupCount > 0 ||
              this.deactivegroupCount > 0 ||
              this.applyfilter.length != 0 ||
              input != ''
            ">
            <div class="Group_top_three_dot">
              <div class="dropdown">
                <button class="btn p-0 Group_top_three_dot_dropdown position-relative" type="button" id="filterDropdown"
                  data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" aria-haspopup="true"
                  style="line-height: 0.3">
                  <img src="../../public/dashboard-assets/images/M2/Filter_icon.png" />
  
                  <span class="Noti-alert-kj translate-middle" v-if="this.applyfilter > 0">
                    {{ this.applyfilter }} <span class="visually-hidden"></span>
                  </span>
                </button>
  
                <ul class="dropdown-menu dropdown-menu-kj" aria-labelledby="filterDropdown">
                  <div class="modal-header p-3">
                    <h4 class="modal-title text-centerm-auto" id="CreateGroupModal1">
                      Filter
                    </h4>
                    <button type="button" class="btn-close" @click="filterclose"></button>
                  </div>
  
                  <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item w-50" role="presentation" v-if="getUserPermissionData({parent:'Groups Management', child:'Groups Account', permission:'Can_Access_Region'})">
                      <button class="nav-link text-muted text-center active" id="pills-home-tab" data-bs-toggle="pill"
                        data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                        aria-selected="true" @click="
    this.filterTab = 1;
  this.searchfilter = '';
  this.Filterresult();
                        ">
                        Category{{
                          this.filters.category.length > 0
                            ? "(" + this.filters.category.length + ")"
                            : ""
                        }}
                      </button>
                    </li>
                    <li class="nav-item w-50" role="presentation" v-if="getUserPermissionData({parent:'Groups Management', child:'Groups Account', permission:'Can_Access_Region'})">
                      <button class="nav-link text-muted text-center" id="pills-profile-tab" data-bs-toggle="pill"
                        data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                        aria-selected="false" @click="
    this.filterTab = 2;
  this.searchfilter = '';
  this.Filterresult();
                        ">
                        Location
                        {{
                          this.filters.location.length > 0
                            ? "(" + this.filters.location.length + ")"
                            : ""
                        }}
                      </button>
                    </li>
                  </ul>
                  <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active p-0" role="tabpanel" aria-labelledby="pills-home-tab">
                      <div class="search-bar">
                        <form class="search-form">
                          <input type="text" placeholder="Search" name="" v-model="searchfilter" @keyup="Filterresult"
                            class="form-control bg-white" /><button class="btn">
                            <i class="fas fa-search"></i>
                          </button>
                        </form>
                        <div class="ul-li-checkbox-kj">
                          <ul v-if="this.filterTab == 1">
                            <li v-for="item in regionCategories" :key="item">
                              <input type="checkbox" :value="item.regionCategoryId"
                                @change="addFilter(item.regionCategoryId, $event)" :checked="
                                  this.filters.category.includes(
                                    item.regionCategoryId
                                  )
                                " />
                              <span>{{ item.categoryName }} </span>
                            </li>
                          </ul>
  
                          <ul v-else>
                            <li v-for="item in grouplocation" :key="item">
                              <input type="checkbox" :value="item.countryCode" :checked="
                                this.filters.location.includes(item.countryCode)
                              " @change="addFilter(item.countryCode, $event)" />
                              <span>
                                {{ item.locationName }}
                              </span>
                            </li>
                          </ul>
                          <div class="align-items-center">
                            <button type="button" class="btn-Apply-kj-color" v-if="this.applyfilter > 0"
                              @click="clearFilters()">
                              Clear Filter
                            </button>
                            <button type="button" class="modail_submit_button text-end" :class="{
                              active_form_button: this.applyfilter > 0,
                            }" :disabled="this.applyfilter == 0" @click="applyFilters()">
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
          <!-- Assign Group -->
          
          <!--update-->
          <!-- <div class="top_heading_CreatePartner_btn mx-2" v-if="this.grouplist.length != 0 && getUserPermissionData({parent:'Groups Management', child:'Groups Member', permission:'Can_Add_Region_Member'})">
            <button type="button" class="btn Assign_Group_buttons" data-bs-toggle="modal" data-bs-target="#AssignGroup"
              @click="GetGroupUserList()">
              Assign Group 
            </button>
          </div> -->
  
          <!-- Create Group -->
          <div class="top_heading_CreatePartner_btn mx-2" v-if="this.grouplist.length != 0 && getUserPermissionData({parent:'Groups Management', child:'Groups Account', permission:'Can_Create_Region'})">
            <button type="button" class="btn btn-primary btn-icon-text" data-bs-toggle="modal"
              data-bs-target="#CreateGroupModal">
              <i class="fa-solid fa-plus"></i> Create Group
            </button>
          </div>
          <!-- three_dot -->
          <div class="top_heading_CreatePartner_btn d-block" v-if="getUserPermissionData({parent:'Groups Management', child:'Groups Account', permission: 'Can_Create_Region'})">
            <div class="Group_top_three_dot">
              <div class="dropdown">
                <button class="btn p-0 Group_top_three_dot_dropdown" type="button" id="dropdownMenuButton"
                  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="fa-solid fa-ellipsis-vertical"></i>
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <li>
                    <button type="button" class="btn w-100 text-start" data-bs-toggle="modal"
                      data-bs-target="#BulkUploadModal">
                      <i class="fa-solid fa-arrow-up-from-bracket"></i>&nbsp;&nbsp; Bulk upload
                    </button>
                  </li>
                  <li>
                    <button type="button" class="btn w-100 text-start" @click="DownloadTemplate()">
                      <i class="fa-solid fa-download"></i>&nbsp;&nbsp; Download
                      Template
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- Moblie View icon Create Group -->
          <div class="mobile_Create_Partner_button managementteam-btn-kj" v-if="getUserPermissionData({parent:'Groups Management', child:'Groups Account', permission: 'Can_Create_Region'})">
            <button type="button" class="btn" data-bs-toggle="modal" data-bs-target="#CreateGroupModal">
              <i class="fa-solid fa-plus"></i>
            </button>
          </div>
          <!-- Moblie View icon Create Group / Assign Group-->
  
          <!--update-->
          <div class="mobile_Create_Partner_button-kj" v-if="getUserPermissionData({parent:'Groups Management', child:'Groups Member', permission:'Can_Add_Region_Member'})">
            <button type="button" class="btn" data-bs-toggle="modal" data-bs-target="#AssignGroup">
              <img src="../../public/dashboard-assets/images/M2/Icon-dashboard.png" alt="" />
            </button>
          </div>
          <!--update-->
        </div>
      </div>
    <!-- demo -->
      
    <section class="my-4 token_secand_header_section mt-3">
          <div class="col-md-12">
          <div class="card p-0">
            <div class="card-body px-3 py-3">
              <div class="d-flex justify-content-between align-items-center">
                 
                
                <div
                  class="d-flex token_secand_header_company align-items-center"
                >
                <div class="PartnerTeamMembersProfile-area-kj">
                    <div class="PartnerTeamMembersProfile-kj name-logo-1" v-if="this.profileImage == null || this.profileImage == ''">
                      {{ this.companyName?.charAt(0) }}
                    </div>
                    <div class="" v-if="this.profileImage != null && this.profileImage != ''">
                     <img :src="this.profileImage" class="profile"/>
                    </div>
                  </div>

                  <div class="PartnerTeamMembersProfile-area-kj">
                    <h3>{{ this.companyName }}</h3>
                    <p class="partnerheading">ClientId:{{ this.currentclientId }}</p>
                  </div>

                  <!-- <div class="parnter_iten_content">
                  <div class="partner_item_img" v-if="this.profileImage != null && this.profileImage != ''">
                    <img :src="this.profileImage" />
                  </div>
                  <div class="partner_item_img" v-if="this.profileImage == null || this.profileImage == ''">
                    <div class="partner_FirstWord">
                      {{ this.companyName?.charAt(0) }}
                    </div>
                  </div>
                  <h4 class="my-2 text-dark" style="overflow-wrap: break-word;">{{ this.companyName }}</h4>
                 
                </div> -->




                </div>
              </div>
            </div>
          </div>
          </div>
        </section>
      <section v-if="this.activegroupCount > 0 || this.deactivegroupCount > 0">
        <ul class="
            nav nav-pills
            mb-3
            partners_top_menu_section_hp
            p-2
            mt-4
            user_tabing_nav_section
          " id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link btn px-3 active" id="Group_Activated_hp-tab" data-bs-toggle="pill"
              data-bs-target="#Group_Activated_hp" type="button" role="tab" aria-controls="Group_Activated_hp"
              aria-selected="true" @click="this.tabStatus = 'active'">
              Activated ({{ this.activegroupCount }})
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link btn px-3" id="Group_Deactivated_hp-tab" data-bs-toggle="pill"
              data-bs-target="#Group_Deactivated_hp" type="button" role="tab" aria-controls="Group_Deactivated_hp"
              aria-selected="false" @click="this.tabStatus = 'inactive'">
              Deactivated ({{ this.deactivegroupCount }})
            </button>
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="Group_Activated_hp" role="tabpanel"
            aria-labelledby="Group_Activated_hp-tab">
            <section class="Partner_empty_main_section_kj mt-3">
              <div class="row">
                <div class="col-md-6 col-lg-4 col-xl-3 mb-3 stretch-card" v-for="(item, index) in this.grouplist"
                  :key="index">
                  <div class="card p-0">
                    <div class="card-body p-3">
                      <div class="Partner_management_top-kj mb-3">
                        <h5 class="text-dark" style="cursor: pointer" @click="
                          this.$router.push(
                            `/Add-Member-list/${item.regionId}/${item.isActive}`
                          )
                        ">
                          {{ item.regionName }}
                        </h5>
                        <div class="dropdown" v-if="item.isActive">
                          <button class="btn p-0 text-muted" type="button" id="dropdownMenuButton"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fa-solid fa-ellipsis"></i>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
  
                            <!--update-->
                            <li v-if="superadmin()/*getUserPermissionData({parent:'Groups Management', child:'Groups Member', permission:'Can_Add_Region_Member'})*/">
                              <div class="dropdown-item d-flex align-items-center" @click="
                                addmemberpopup(item.regionId, item.regionName)
                              ">
                                Add Member
                              </div>
                            </li>
                            <!--update-->
  
                            <li v-if="getUserPermissionData({parent:'Groups Management', child:'Groups Account', permission:'Can_Update_Region'})">
                              <div class="dropdown-item d-flex align-items-center" data-bs-toggle="modal"
                                data-bs-target="#CreateGroupModal" @click="editTeam(index)">
                                Edit Group
                              </div>
                            </li>
                            <li v-if="getUserPermissionData({parent:'Groups Management', child:'Groups Account', permission:'Can_Deactivate_Region'})">
                              <div class="dropdown-item d-flex align-items-center" data-bs-toggle="modal"
                                data-bs-target="#DeactivatePartnerModal" @click="
                                  deactivegroup(item.regionId, item.regionName)
                                ">
                                Deactivate Group
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <router-link :to="{
                        name: 'AddMemberlist',
                        params: { id: item.regionId, status: item.isActive },
                        query:{ offset:this.offset, current:this.currentPage }
                      }">
                        <div class="Partner_management_top-kj" @click="disableClick">
                          <div class="Partner_management_contant-kj" >
                            <div class="mb-3">
                              <i class="fa-solid fa-users"></i><span class="text-dark"> Members</span>
                            </div>
                            <p>{{ item.userCount }}</p>
                          </div>
                          <div class="Partner_management_contant-2-kj">
                            <div class="mb-3">
                              <i class="fa-solid fa-location-dot"></i><span class="text-dark"> Location</span>
                            </div>
                            <p>{{ item.locationName }}</p>
                          </div>
                        </div>
                      </router-link>
                    </div>
                    <div v-if="!item.isActive && getUserPermissionData({parent:'Groups Management', child:'Groups Account', permission:'Can_Activate_Region'})" class="card-footer card-footer-bg-kj" data-bs-toggle="modal"
                      data-bs-target="#ReactivatePartnerModal" @click="activategroup(item.regionId, item.regionName)">
                      Reactivate
                    </div>
                  </div>
                </div>
              </div>
              <paginate v-model="currentPage" v-if="this.grouplist.length > 0" :page-count="Page_count" :margin-pages="1"
                :page-range="1" :click-handler="clickCallback" :prev-text="'< Prev'" :next-text="'Next >'"
                :container-class="'pagination'" :page-class="'page-item'">
              </paginate>
            </section>
          </div>
        </div>
      </section>
      <!-- empty section -->
      <section class="Partner_empty_main_section_hp mt-4" v-if="this.grouplist.length == 0">
        <div class="row">
          <div class="Partner_empty_img_hp m-auto text-center">
            <img src="../../public/dashboard-assets/images/M2/Partner_blank.png" alt="" />
          </div>
          <div class="Partner_empty_content_hp m-auto text-center">
            <div class="mb-4">
              <h2>Hey, you still alone?</h2>
              <p class="text-muted mt-2">
                This is the best time to create groups and start working
              </p>
            </div>
            <button type="button" class="btn btn-primary btn-icon-text" data-bs-toggle="modal"
              data-bs-target="#CreateGroupModal" v-if="this.tabStatus=='active'">
              <i class="fa-solid fa-plus"></i> Create Group
            </button>
          </div>
        </div>
      </section>
  
      <!-- Add member -->
      <Addnewgroupmember v-if="this.addnew == true" :gid="this.groupid" :gname="this.groupname" @clicked="showpop" />
  
      <!-- Create Group -->
      <div class="modal fade CreatePartnerModal_hp" id="CreateGroupModal" tabindex="-1"
        aria-labelledby="CreateGroupModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title text-centerm-auto" id="CreateGroupModalLabel">
                {{ this.team.teamId == 0 ? "Create Group" : "Edit Group" }}
              </h4>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"
                @click="Resetgroupform"></button>
            </div>
            <div class="modal-body">
              <div class="row" id="CreateGroupForm">
                <div class="form-group mb-4">
                  <label class="input-hero" for="PartnerCompany">
                    <input class="input_area" type="text" v-model="this.team.grpName" required=""
                      :readonly="this.team.teamId != 0" :style="[
                        this.team.teamId != 0
                          ? { color: '#9c9c9e!important' }
                          : {},
                      ]" /><span class="input-name" :style="[
    this.team.teamId != 0
      ? { color: '#9c9c9e!important' }
      : {},
  ]">Group Name <span class="text-danger">*</span></span>
                  </label>
                </div>
  
                <div class="form-group mb-4">
                  <label class="input-hero" for="Category">
                    <select v-model="this.team.RegionCategoryId" class="form-select input_area select_ca">
                      <option value='' selected disabled>Choose Category</option>
                      <option v-for="item in regioncategories" :key="item" :value="item.id">
                        {{ item.name }}
                      </option>
                    </select>
                    <span class="input-name">Category<span class="text-danger">*</span></span>
                  </label>
                </div>
  <!-- Mayuresh -->
                <div class="form-group mb-4">
                  <label class="input-hero" for="ChooseLoaction">
                    <div class="
                        form-select
                        dropdown
                        county_code_flag
                        input-group input_area
                      ">
                      <div class="dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                        aria-expanded="false" :disabled="this.team.RegionCategoryId == 0">
                        <img width="30px" height="20px" alt="" class="flagsImg" v-if="flagurl" v-bind:src="flagurl" />
                        <input class="flagInput" type="text" v-model="this.team.locationName"
                          @input="searchFlag($event)" />
                      </div>
  
                      <ul class="dropdown-menu county_code_flag_dropdown_Option"
                        style="width: 97%; z-index: 999 !important" aria-labelledby="dropdownMenuButton1">
                        <li v-for="item in this.groupLocation" :key="item" :value="item.name"
                          class="dropdown-item" href="#" @click="changeflag(item.name, item.iso2)">
                          <a>
                            <img class="flagsImg" v-bind:src="[GetFlagCode(item.iso2)]" width="30px" height="20px"
                              alt="" />
                            {{ item.name }}
                          </a>
                        </li>
                      </ul>
                    </div>
                    <span class="input-name">Location <span class="text-danger">*</span></span>
                  </label>
                </div>
  
                <div class="" data-bs-dismiss="modal">
                  <button @click="saveTeam" :disabled="isDisabled()" v-if="this.team.teamId == 0"
                    :class="{ active_form_button: !isDisabled() }" class="modail_submit_button">
                    Create
                  </button>
                  <button @click="saveTeam" :disabled="isDisabled()" v-else :class="{ active_form_button: !isDisabled() } "
                    class="modail_submit_button">
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <!-- BulkUploadModal -->
      <div class="modal fade" id="BulkUploadModal" tabindex="-1" aria-labelledby="BulkUploadModalLabel" aria-hidden="true"
      data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content border-file-uploading">
          <div class="modal-body">
            <div class="text-end border-bottom-0">
              <button id="closePopup" :hidden="showProgress" type="button" class="btn-close" data-bs-dismiss="modal"
                aria-label="btn-close"></button>
            </div>

            <div class="file-upload-kj">
              <div class="d-flex align-items-center mb-3">
                <h4 class="text-file-name" v-if="showProgress" :style="[
                  progValue == '100%' ? { color: 'black!important' } : {},
                ]">
                  {{ selectedFile?.name }}
                </h4>
              
                <span v-if="showcheck && selectedFile?.name">
                  <i class="fa-solid fa-circle-check progress-check mx-2" ></i>

                </span>
                <span v-if="!showcheck && selectedFile?.name">
                  <i class="fa-solid fa-circle-xmark progress-close mx-2" ></i>

                </span>
              </div>
              <div class="progress" v-if="showProgress">
                <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                  :style="{ width: progValue }"></div>
              </div>
              <div class="image-upload-wrap">
                <input :hidden="showProgress" id="bulkFileUpload" class="file-upload-input" type="file"
                  @change="handleFileUpload($event)"
                  accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                <div class="text-center" v-if="!showProgress">
                  <img src="../../public/dashboard-assets/images/M2/Upload-File-icon.png" alt="" />
                  <h4>Upload File</h4>
                  <h3>Supported formats: XLSX</h3>
                </div>
              </div>
            </div>
            <div class="d-flex m-auto float-end mt-5 my-2" v-if="showProgress">
              <div class="mx-2">
                <button v-if="progValue == '100%'" type="button" class="btn btn-upload-border-kj" @click="cancel()">
                  Cancel
                </button>
              </div>
              <div>
                <button type="button" :disabled="progValue != '100%'" class="btn" :class="[
                  progValue == '100%'
                    ? 'btn-primary btn-upload-kj'
                    : 'customUploadBtn',
                ]" @click="bulkUpload()">
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
      <!-- AssignGroup -->
      <div class="modal fade CreatePartnerModal_hp" id="AssignGroup" tabindex="-1" aria-labelledby="CreateGroupModalLabel"
        aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title text-centerm-auto" id="CreateGroupModalLabel">
                Assign Group
              </h4>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close" id="closeassigngroup"
                @click="ResetForm"></button>
            </div>
            <div class="modal-body">
              <div class="row mt-4" id="CreateForm">
                <!-- <div class="form-group mb-5">
                  <label class="input-hero" for="PartnerCompany">
                    <Multiselect class="input_area" v-model="selectedteams" mode="tags" :search="true"
                    :searchable="true"
  
                      placeholder="Add Group Names" :close-on-select="false" :options="options" :classes="{
                        tag: 'multiselect-tag text-wrap',
                      }">
                      <template v-slot:tag="{ option, handleTagRemove, disabled }">
                        <div class="multiselect-tag is-user" :class="{
                          'is-disabled': disabled,
                        }">
                          {{ option.label }}
                          <span v-if="!disabled" class="multiselect-tag-remove"
                            @mousedown.prevent="handleTagRemove(option, $event)">
                            <span class="multiselect-tag-remove-icon"></span>
                          </span>
                        </div>
                      </template>
                      <template v-slot:option="{ option }">
                        {{ option.label }}
                      </template>
                    </Multiselect>
  
                    <span class="input-name">Group Names <span class="text-danger">*</span>
                    </span>
                  </label>
                </div> -->
  
                <div class="form-group mb-4">
                  <label class="input-hero" for="PartnerCompany">
                    <Multiselect v-model="selectedteams" placeholder="Choose Groups" mode="tags" :close-on-select="false"
                      :options="options" :searchable="true" :group-select="true">
                    </Multiselect>
                    <span class="input-name input-group-kj">Group <span class="text-danger">*</span></span>
                  </label>
                </div>
                <div class="form-group mb-4">
                  <label class="input-hero" for="PartnerCompany">
                    <Multiselect v-model="selecteduser" placeholder="Add Usernames" mode="tags" :close-on-select="false"
                      :options="users" :searchable="true" :group-select="true">
                    </Multiselect>
                    <span class="input-name input-group-kj">Usernames <span class="text-danger">*</span></span>
                  </label>
                </div>
  
                <!-- <div class="form-group mb-5">
                  <label class="input-hero" for="PartnerCompany">
                    <Multiselect class="input_area" v-model="selecteduser" mode="tags" :search="true"
                    :searchable="true"
  
                      placeholder="Add Usernames" :close-on-select="false" :options="users" :classes="{
                        tag: 'multiselect-tag text-wrap',
                      }">
                      <template v-slot:tag="{ option, handleTagRemove, disabled }">
                        <div class="multiselect-tag is-user" :class="{
                          'is-disabled': disabled,
                        }">
                          {{ option.label }}
                          <span v-if="!disabled" class="multiselect-tag-remove"
                            @mousedown.prevent="handleTagRemove(option, $event)">
                            <span class="multiselect-tag-remove-icon"></span>
                          </span>
                        </div>
                      </template>
                      <template v-slot:option="{ option }">
                        {{ option.label }}
                      </template>
                    </Multiselect>
                    <span class="input-name">Usernames <span class="text-danger">*</span>
                    </span>
                  </label>
                </div> -->
                <div class="">
                  <button :disabled="
                    this.selecteduser.length == 0 ||
                    this.selectedteams.length == 0
                  " @click="AssignGrouptoUser" class="modail_submit_button" :class="{
    active_form_button:
      this.selecteduser.length > 0 &&
      this.selectedteams.length > 0,
  }">
                    Assign
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <!-- ReactivatePartnerModal -->
      <div class="modal fade Reactivate_Reactivate_Modal" id="ReactivatePartnerModal" tabindex="-1"
        aria-labelledby="ReactivatePartnerModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-body p-4">
              <div class="">
                <h3 class="mb-3">Reactivate Group?</h3>
                <p class="text-muted">
                  Are you sure you want to Reactivate
                  <strong style="color: #000000">{{ activategroupname }}</strong>
                  group.
                </p>
              </div>
              <div class="Deactivate_buttons row mt-5 justify-content-center">
                <button class="col-5 Deactivate_Cancel_buttons" data-bs-dismiss="modal">
                  Cancel
                </button>
                <button type="button" class="col-5 btn Deactivate_Deactivate_buttons" data-bs-dismiss="modal"
                  @click="confirmactivate()">
                  Reactivate
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ReactivatePartnerModal -->
      <div class="modal fade Deactivate_Reactivate_Modal" id="DeactivatePartnerModal" tabindex="-1"
        aria-labelledby="DeactivatePartnerModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-body p-4">
              <div class="">
                <h3 class="mb-3">Deactivate Group?</h3>
                <p class="text-muted">
                  Are you sure you want to Deactivate
                  <strong style="color: #000000">{{
                    deactivategroupname
                  }}</strong>
                  group.
                </p>
              </div>
              <div class="Deactivate_buttons row mt-5 justify-content-center">
                <button class="col-5 Deactivate_Cancel_buttons" data-bs-dismiss="modal" aria-label="btn-close">
                  Cancel
                </button>
                <button type="button" class="col-5 btn Deactivate_Deactivate_buttons" data-bs-dismiss="modal"
                  @click="confirmdeactive">
                  Deactivate
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <!-- GroupsModel  -->
      <div class="modal fade CreatePartnerModal_hp" id="GroupsModel" tabindex="-1" aria-labelledby="CreateGroupModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-body">
              <div class="row">
                <div class="col-6">
                  <button
                    type="button"
                    class="btn"
                    data-bs-toggle="modal"
                    data-bs-target="#CreateGroupModal"
>
                    Create Group
                  </button>

                </div>
                <div class="col-12 text-center">
                  <button type="button" class="btn" data-bs-toggle="modal" data-bs-target="#AssignGroup"
                    @click="GetGroupUserList()">
                    Assign Group
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from "vuex";
  import Paginate from "vuejs-paginate-next";
  import Addnewgroupmember from "./Addnewgroupmember.vue";
  import Multiselect from "@vueform/multiselect";
  import { getUserPermissionData } from "../utils/helper";
  import { superadmin } from "../utils/helper";
  
  export default{
    name: "ManagementPartnersGroupDetail",
    components: {
      paginate: Paginate,
      Addnewgroupmember,
      Multiselect,
    },

  async created(){
    this.currentclientId = window.atob(this.$route.params.id);
    this.PartnerData=await this.getpartner({ clientId:this.currentclientId });
    this.companyName=this.PartnerData.object.data.companyName;
    this.currentOffset = window.atob(this.$route.params.offset),
    this.currentPages   = window.atob(this.$route.params.current),
    this.currentSearch = window.atob(this.$route.params.search)
  },
    data() {
      return {
        activategroupname: "",
        deactivategroupname: "",
        selectedteams: [],
        regionCategories: [],
        regionCategoriesFilter: [],
        locations: [],
        team: {
          RegionCategoryId: 0,
          grpName: "",
          country_code: "",
          teamId: 0,
          locationName: "",
        },
        grouplist: [],
        activegroupCount: 0,
        deactivegroupCount: 0,
        deactiveregionid: "",
        input: "",
        search: false,
        result: [],
        show: false,
        offset: 1,
        limit: 10,
        Page_count: 1,
        grouplocation: [],
        grouplocationFilter: [],
        tabStatus: "active",
        flagurl: "",
        groupid: "",
        groupname: "",
        filters: {
          category: [],
          location: [],
          regionName: "",
        },
        filterTab: 1,
        members: [],
        addnew: false,
        searchfilter: "",
        filtercategory: [],
        filtercountry: [],
        region: [],
        users: [],
        showProgress: false,
        options: [],
        applyfilter: 0,
        selecteduser: [],
        progValue: 0,
        selectedFile: "",
        optionswithSelectAll: [],
        currentPage: 1,
        regioncategoriesFilter:[],
        regioncategories:[],
        groupLocationFilter:[],
        groupLocation:[],
        PartnerData:{},
        companyName:'',
        profileImage:'',
        showcheck:false,
        currentclientId:0,
        currentOffset:'',
        currentPages  :0,
        currentSearch:''
      };
    },
  
    computed: {
      ...mapState({
        user: (state) => state.auth.user,
      }),
    },
  
    watch: {
      tabStatus(newValue) {
        if (newValue) {
          this.offset = 1;
          this.currentPage = 1;
          this.getgroups(this.tabStatus);
        }
      },
    },
    methods: {
      getUserPermissionData,
      superadmin,
      ...mapActions([
        "getClientTeams",
        "getRegionCategories",
        "getRegionCategoriesNewData",
        "createTeam",
        "updateTeam",
        "getRegionByRegionId",
        "ShowWarningMessage",
        "GetTeams",
        "getGroupsWithPagination",
        "Getregionmembers",
        "deactivateregion",
        "activateregion",
        "getregionlocation",
        "getregionlocationnewData",
        "GetTeams",
        "getGroupsWithPaginationwithoutreload",
        "DownloadGroupTemplate",
        "bulkUploadGroups",
        "getGroupsWithPagination",
        "GetAllUsers",
        "AssignGroup",
        "regioncategory",
        "getlocation",
        "getpartner",
        "companylogo",
        "Companyheader"
      ]),
  
      // Searching box
      opensearch() {
        document.querySelector(".Searching_input-box").classList.add("open");
      },
      async closesearch() {
        document.querySelector(".Searching_input-box").classList.remove("open");
        this.result = [];
        this.input = "";
        this.filters.regionName = "";
        await this.getgroups(this.tabStatus);
      },
  
      disableClick(event) {
    event.preventDefault();
  },
      async closemobilesearch() {
        this.show = false;
        document
          .querySelector(".Searching_input_box_mobile_view")
          .classList.remove("open");
        this.result = [];
        this.filters.regionName = "";
        this.input = "";
        await this.getgroups(this.tabStatus);
      },
      openmobilesearch() {
        document
          .querySelector(".Searching_input_box_mobile_view")
          .classList.add("open");
        this.result = [];
        this.input = "";
        this.show = true;
      },
  
      async filteredList() {
        var id = window.atob(this.$route.params.id);
        if (this.input != "") {
          this.region = [];
          this.result = [];
          const group = await this.getGroupsWithPaginationwithoutreload({
            clientId: id,
            offset: 1,
            limit: 100,
            status: this.tabStatus,
            regionName: this.input,
          });
          var x = group.object.data.regions;
          x.forEach((element) => {
            if (this.region.indexOf(element.regionName) < 0) {
              this.region.push(element.regionName);
            }
          });
          this.result = this.region.filter((element) =>
            element.toLowerCase().startsWith(this.input.toLowerCase())
          );
          this.result = Object.freeze(JSON.parse(JSON.stringify(this.result)));
        } else {
          this.result = [];
          this.filters.regionName = "";
          await this.getgroups(this.tabStatus);
        }
      },
      stringTOHtml(item, idx) {
        setTimeout(() => {
          if (this.input != "") {
            let re = new RegExp(this.input.toLowerCase(), "g");
            var html = document.getElementById(idx);
            var ex = item
              .toLowerCase()
              .replace(
                re,
                `<span style="color: #7b68ee !important">${this.input}</span>`
              );
            if (html) html.innerHTML = ex;
          }
        }, 100);
      },
      //*********search end*********
  
      //*******pagination start********
      async clickCallback(pageNum) {
        this.offset = (pageNum - 1) * this.limit + 1;
        await this.getgroups(this.tabStatus);
      },
      async getgroups(status) {
        this.tabStatus = status;
        var id = window.atob(this.$route.params.id);
        const group = await this.getGroupsWithPagination({
          clientId: id,
          offset: this.offset,
          limit: this.limit,
          status: status,
          regionName: this.filters.regionName,
          countrycode: this.filters.location.toString(),
          regionCategoryId: this.filters.category.toString(),
        });
        const notActiveTab = await this.getGroupsWithPagination({
          clientId: id,
          offset: this.offset,
          limit: this.limit,
          status: status == "active" ? "inactive" : "active",
          regionName: this.filters.regionName,
          countrycode: this.filters.location.toString(),
          regionCategoryId: this.filters.category.toString(),
        });
  
        this.Page_count = group
          ? Math.ceil(group?.object.data.filterCount / this.limit)
          : 0;
        this.grouplist = group?.object.data.regions;
        // this.grouplist = [];
        this.activegroupCount =
          status == "active"
            ? group?.object.data.filterCount
            : notActiveTab.object.data.filterCount;
        this.deactivegroupCount =
          status == "inactive"
            ? group?.object.data.filterCount
            : notActiveTab.object.data.filterCount;
      },
      //*******pagination end********
  
      async getgroupmembers(regionid) {
        const members = await this.Getregionmembers({
          regionId: regionid,
        });
        if (members?.status) {
          var memberlist = members.object.data;
          return memberlist.length;
        }
        return 0;
      },
  
      /* ******activate and deactivate group start*********** */
      async deactivegroup(regionid, regionname) {
        this.deactiveregionid = regionid;
        this.deactivategroupname = regionname;
      },
      async confirmdeactive() {
        await this.deactivateregion({
          regionId: this.deactiveregionid,
        });
        await this.getgroups(this.tabStatus);
      },
      async activategroup(regionid, regionname) {
        this.activateregionid = regionid;
        this.activategroupname = regionname;
      },
      async confirmactivate() {
        await this.activateregion({
          regionId: this.activateregionid,
        });
        await this.getgroups(this.tabStatus);
      },
      /* ******activate and deactivate group end*********** */
  
      //Mayuresh
      GetFlagCode(value) {
        if (value) {
          var FlagName = value.toLowerCase().trim();
          return "../assets/Images/CounrtyFlags/" + FlagName + ".svg";
        }
        return "";
      },
      changeflag(flagiso, countryCode) {
        if (flagiso && countryCode) {
          var FlagName = countryCode.toLowerCase().trim();
          this.flagurl = "../assets/Images/CounrtyFlags/" + FlagName + ".svg";
          this.team.country_code = countryCode;
          this.team.locationName = flagiso;
          this.grouplocation = this.grouplocationFilter;
        }
      },
      async editTeam(index) {
        const team = this.grouplist[index];
        this.team.grpName = team.regionName;
        this.team.RegionCategoryId = team.regionCategoryId;
        this.team.country_code = team.countryCode;
        this.team.locationName = team.locationName;
        this.team.teamId = team.regionId;
        this.changeflag(team.locationName, team.countryCode);
      },
      resetForm() {
        this.team = {
          grpName: "",
          RegionCategoryId: 0,
          country_code: "",
          teamId: "",
        };
        this.flagurl = "";
      },
  
      isDisabled() {
        return (
          this.team.grpName.trim() === "" ||
          this.team.RegionCategoryId == 0 ||
          this.team.country_code == ""
        );
      },
  
      async saveTeam() {
        var id = window.atob(this.$route.params.id);
        if (this.team.grpName.length > 20) {
          this.ShowWarningMessage("Group Name cannot be more than 20 characters");
        } else {
          if (this.team.teamId == 0) {
            await this.createTeam({
              Name: this.team.grpName,
              clientId: id,
              RegionCategoryId: this.team.RegionCategoryId,
              country_code: this.team.country_code,
            });
          } else {
            await this.updateTeam({
              name: this.team.grpName,
              regionId: this.team.teamId,
              regionCategoryId: this.team.RegionCategoryId,
              country_code: this.team.country_code,
            });
          }
  
          window.$("#CreateGroupModal").modal("hide");
          this.getgroups(this.tabStatus);
          this.resetForm();
        }
      },
  
      async addmemberpopup(regionid, regionname) {
        (this.groupid = regionid), (this.groupname = regionname);
        this.addnew = true;
        // window.$("#Addmember").modal("show");
      },
      async showpop(val) {
        this.addnew = val;
        this.GetGroupUserList();
        await this.getgroups(this.tabStatus);
      },
  
      searchFlag(event) {
        if (event.target.value == "") this.flagurl = "";
        this.grouplocation = this.grouplocationFilter.filter((x) =>
          x.locationName.toLowerCase().includes(event.target.value.toLowerCase())
        );
      },
  
      async addFilter(value, e) {
        if (e.target.checked) {
          if (this.filterTab == 1) {
            this.filters.category.push(value);
          } else {
            this.filters.location.push(value);
          }
          this.applyfilter =
            this.filters.category.length + this.filters.location.length;
        } else {
          if (this.filterTab == 1) {
            this.filters.category.splice(
              Object.freeze(
                JSON.parse(JSON.stringify(this.filters.category))
              ).indexOf(value),
              1
            );
          } else {
            this.filters.location.splice(
              Object.freeze(
                JSON.parse(JSON.stringify(this.filters.location))
              ).indexOf(value),
              1 
            );
          }
          this.applyfilter =
            this.filters.category.length + this.filters.location.length;
          if(this.applyfilter==0)
          {
            await this.getgroups(this.tabStatus);
          }
        }
      },
      async clearFilters() {
        this.filters.category = [];
        this.filters.location = [];
        this.applyfilter = 0;
        this.searchfilter = "";
        this.Filterresult();
        document.getElementById("filterDropdown").click();
        await this.getgroups(this.tabStatus);
      },
      async applyFilters() {
        this.offset = 1;
        this.currentPage = 1;
        await this.getgroups(this.tabStatus);
        document.getElementById("filterDropdown").click();
      },
      Filterresult() {
        if (this.searchfilter != "") {
          if (this.filterTab == 1) {
            this.regionCategories = this.regionCategoriesFilter.filter(
              (element) =>
                element.categoryName
                  .toLowerCase()
                  .includes(this.searchfilter.toLowerCase())
            );
          } else {
            this.grouplocation = this.grouplocationFilter.filter((element) =>
              element.locationName
                .toLowerCase()
                .includes(this.searchfilter.toLowerCase())
            );
          }
        } else {
          this.regionCategories = this.regionCategoriesFilter;
          this.grouplocation = this.grouplocationFilter;
        }
      },
      filterclose() {
        document.getElementById("filterDropdown").click();
      },
      async searchregionname(val) {
        this.input = val;
        this.offset = 1;
        this.filters.regionName = val;
        this.currentPage = 1;
        this.result = [];
        await this.getgroups(this.tabStatus);
      },
  
      async DownloadTemplate() {
        var id = window.atob(this.$route.params.id);
        var a = await this.DownloadGroupTemplate({
          clientId: id,
        });
  
        const fileURL = window.URL.createObjectURL(new Blob([a]));
        const fileLink = document.createElement("a");
  
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "file.xlsx");
        document.body.appendChild(fileLink);
  
        fileLink.click();
      },
  
   
      handleFileUpload(event) {
      this.progValue = 0;
      this.selectedFile = event.target.files[0];
      this.showProgress = true;
      let i = 0;
      if (i == 0) {
        i = 1;
        var width = 1;
        const myInterval = setInterval(async () => {
          if (width >= 100) {
            this.showcheck=true;
            clearInterval(myInterval);
            i = 0;
          } else {
            width++;
            this.showcheck=false;
            this.progValue = width + "%";
          }
        }, 10);
      }
      event.target.value = "";
    },
      async bulkUpload() {
        var id = window.atob(this.$route.params.id);
      if (this.selectedFile) {
        const data = new FormData();
        data.append("file", this.selectedFile);
        var payLoad = { clientId: id, formData: data };
        var response = await this.bulkUploadGroups(payLoad);
        if (response.status) {
          window.$("#BulkUploadModal").modal("hide");
          document.getElementById("closePopup").click();
          this.getgroups(this.tabStatus);
        }
        else{
          window.$("#BulkUploadModal").modal("hide");
          document.getElementById("closePopup").click();
        }
        this.selectedFile = null;

        this.progValue = 0;
        this.showProgress = false;
      }
    },
      cancel() {
        this.selectedFile = null;
        this.progValue = 0;
        this.showProgress = false;
        window.$("#BulkUploadModal").modal("hide");
        document.getElementById("closePopup").click();

      },
      async GetGroupUserList() {
        var id = window.atob(this.$route.params.id);
        const user = await this.GetAllUsers({
          clientId: id,
          page: 1,
          limit: 100,
          status: 4,
        });
        this.users = user.object.data.users.map((i) => ({
          value: i.id,
          label: i.name,
        }));
        
        const group = await this.getGroupsWithPagination({
          clientId: id,
          offset: 1,
          limit: 100,
          status: "active",
        });
        this.options = group.object.data.regions.map((i) => ({
          value: i.regionId,
          label: i.regionName,
        }));
      },
  
      handleSelect(event) {
        if (parseInt(event) === 0) {
          // select all
          for (let item of this.options) {
            if (parseInt(item.value) > 0) {
              this.selectedteams.push(item);
              this.$emit("handle-select", item.value);
            } else {
              this.selectedteams.pop(1);
            }
          }
        } else {
          this.$emit("handle-select", event);
        }
      },
  
      async AssignGrouptoUser() {
        if (this.selecteduser.length == 0) {
          return this.ShowWarningMessage("Please select User");
        }
        if (this.selectedteams.length == 0) {
          return this.ShowWarningMessage("Please select Group.");
        }
  
        await this.AssignGroup({
          users: this.selecteduser,
          regionList: this.selectedteams,
        });
        if (this.selecteduser.length > 0 && this.selectedteams.length > 0) {
          document.getElementById("closeassigngroup").click();
        }
      },
      ResetForm() {
        this.selecteduser = [];
        this.selectedteams = [];
      },
      Resetgroupform() {
        this.team.teamId = 0;
        this.team.grpName = "";
        this.team.RegionCategoryId = "";
        this.team.country_code = "";
        this.team.locationName = "";
        this.flagurl = "";
      },
    },
  
    async mounted() {
      var id = window.atob(this.$route.params.id);
      var company = await this.Companyheader({
          clientId: id,
        });
        this.companyName = company.object.data.companyName;
        var profileImage = company.object.data.profileImage;
        if (profileImage != null) {
          var resp = await this.companylogo({
            fileName: profileImage,
          });
          this.profileImage = resp.object.data;
        } else {
          this.profileImage = null;
        }

      if(this.$route.query.offset && this.$route.query.current)
      {
        this.offset = parseInt(window.atob(this.$route.query.offset)),
        this.currentPage = parseInt(window.atob(this.$route.query.current))
      }
      this.GetGroupUserList();
      await this.getgroups(this.tabStatus);
      if(this.activegroupCount !== 0 || this.deactivegroupCount !== 0) {
        var categories = await this.getRegionCategoriesNewData({
          clientId: id,
        });
        if (categories.status) {
        this.regionCategories = categories.object.data;
        this.regionCategoriesFilter = categories.object.data;
        }
        var locationfilter = await this.getregionlocationnewData({
          clientId: id,
        });
        this.grouplocation = locationfilter.object.data;
        this.grouplocationFilter = locationfilter.object.data;  

       
        
      }
      //create group pop-up
      var categories1 = await this.regioncategory();
      if (categories1.status) {
        this.regioncategories = categories1.object.data;
        this.regioncategoriesFilter = categories1.object.data;
      }
  
      var locationfilter1 = await this.getlocation();
      this.groupLocation = locationfilter1.object.data;
      this.groupLocationFilter = locationfilter1.object.data;
  
      var x = await this.GetTeams({
        clientId: id,
      });
      
      var team = x.object.data.regions;
      team.forEach((element) => {
        this.region.push(element.regionName);
      });
    },
  };
  </script>
  
  <style scoped>
  .page-item.disabled .page-link {
    background-color: #fff !important;
    border-color: #fff !important;
  }
  
  .pagination .page-item .page-link {
    width: 30px;
    height: 32px;
    background: transparent;
    display: flex;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    margin: 5px;
    color: #000 !important;
    border: none;
    cursor: pointer;
  }
  
  .pagination {
    justify-content: flex-end;
  }
  
  .pagination .page-item.active .page-link {
    font-weight: 600;
    background: #ffc801;
  }
  
  .pagination .page-item:last-child .page-link {
    background-color: transparent;
    border: none;
    width: 100% !important;
  }
  
  .pagination .page-item:first-child .page-link {
    background-color: transparent;
    border: none;
    width: 100% !important;
  }
  
  .pagination .page-item.disabled .page-link {
    color: rgb(130, 130, 130) !important;
    cursor: no-drop !important;
    background: #7fffd400 !important;
  }
  
  .flagInput {
    border: none;
    padding-left: 2%;
    margin-top: 1%;
    width: 80%;
    cursor: pointer;
  }
  
  .flagInput:focus {
    border: none !important;
    outline: none !important;
  }
  
  .progress {
    height: 11px !important;
    border-radius: 1.25rem !important;
  }
  
  .progress-bar {
    border-radius: 1.25rem;
  }
  
  .btn-upload-kj {
    border-radius: 100px !important;
    padding: 10px 30px !important;
    font-size: 15px;
  }
  
  .btn-upload-border-kj {
    padding: 10px 30px !important;
    color: #7c68ee !important;
    border: 1px solid #7c68ee !important;
    border-radius: 100px !important;
    font-size: 15px;
  }
  
  .text-file-name {
    color: #babec7;
  }
  
  .customUploadBtn {
    background-color: #f2f0fd !important;
    color: #cec9ec !important;
    border: none !important;
  }
  </style>
  