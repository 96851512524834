<template>
    <div class="page-content">
      <!-- mobile view Searching -->
      <div class="Searching_input_box_mobile_view mb-3 position-relative">
        <div class="w-100" v-if="this.show">
          <input
            type="text"
            v-model="Searchinput"
            @keyup.enter="searchusername(Searchinput)"
            placeholder="Search..."
          />
          <!--update @keyup="filteredList"-->
          <!-- <div class="search_detail_list" v-if="this.result > 0">
            <div
              v-for="(item, index) in this.result"
              :key="index"
              class="search_detail_list_item"
              @click="searchusername(item)"
              style="cursor: pointer"
            >
              <span :id="index">
                {{ stringTOHtml(item, index) }}
              </span>
            </div>
          </div> -->
          <!--update-->
          <img
            src="../../public/dashboard-assets/images/M2/Close_icon.png"
            @click="closemobilesearch"
            alt=""
            class="close-icon"
          />
          <!-- </span> -->
        </div>
      </div>
  
      <!--  Activated Groups-Toast -->
       <!-- <router-link :to="{
                name: 'ManagementPartners',
              
              }" class="p-0" type="button">
                <img src="../../public/dashboard-assets/images/M2/BackButton.png" />
              </router-link> -->
      <div class="d-flex justify-content-between align-items-center flex-wrap mb-2">
        <div class="my-2 d-flex align-items-center">
          <router-link :to="{
                name: 'ManagementPartners',
                query: {
                    offset: this.$route.params.offset,
                    current: this.$route.params.current,
                    search: this.$route.params.search
                  },
              }" class="p-0" type="button">
                <img src="../../public/dashboard-assets/images/M2/BackButton.png" />
              </router-link>
        <div class="my-2">
          <h4 class="mb-2 mb-md-0 mx-2">Manage Partner Users</h4>
        </div>
        </div>
        <div
          class="d-flex justify-content-between align-items-center"
          v-if="
            AllUserList.length > 0 ||
            this.Searchinput !== '' ||
            this.applyfilter > 0
          "
        >
          <div class="top_heading_CreatePartner_btn" v-if="!seen">
            <button
              class="btn Assign_Group_buttons"
              @click="MultipleDeactiveCancel()"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary btn-icon-text"
              @click="MultipleDeactivateAction()"
            >
              Deactivate
            </button>
          </div>
          <div class="d-flex">
            <!-- Search  web view-->
            <div
              class="top_heading_CreatePartner_btn position-relative"
              v-if="seen || this.Searchinput !== ''"
            >
              <div class="Group_top_three_dot">
                <div class="Searching_input-box">
                  <input
                    type="text"
                    v-model="Searchinput"
                    @keyup.enter="searchusername(Searchinput)"
                    placeholder="Search..."
                  />
                  <!--update @keyup="filteredList"-->
                  <!-- <div class="search_detail_list" v-if="this.result.length > 0">
                    <div
                      v-for="(item, index) in this.result"
                      :key="index"
                      class="search_detail_list_item"
                      @click="searchusername(item)"
                      style="cursor: pointer"
                    >
                      <span :id="index" @click="searchusername(item)">
                        {{ stringTOHtml(item, index) }}
                      </span>
                    </div>
                  </div> -->
                  <!--update-->
                  <span
                    class="icon Group_top_three_dot_dropdown"
                    @click="opensearch"
                  >
                    <img
                      src="../../public/dashboard-assets/images/M2/Icon-Set.png"
                      alt=""
                      class="Searching_search_icon"
                    />
                  </span>
                  <img
                    src="../../public/dashboard-assets/images/M2/Close_icon.png"
                    @click="closesearch"
                    alt=""
                    class="close-icon"
                  />
                </div>
              </div>
            </div>
            <div
              class="top_heading_CreatePartner_btn d-flex top_heading_CreatePartner_btn-kj d-block mx-2"
            >
              <!-- Mobile View Searching Button -->
              <div
                class="top_heading_CreatePartner_btn mx-1 position-relative Searching_button_mobileView"
              >
                <div class="Group_top_three_dot">
                  <span
                    class="icon Group_top_three_dot_dropdown"
                    :style="{ display: this.show == true ? 'none' : 'block' }"
                    @click="openmobilesearch"
                  >
                    <img
                      src="../../public/dashboard-assets/images/M2/Icon-Set.png"
                      alt=""
                      class="Searching_search_icon"
                    />
                  </span>
                </div>
              </div>
              <div class="Group_top_three_dot">
                <div class="dropdown">
                  <button
                    class="btn p-0 Group_top_three_dot_dropdown"
                    type="button"
                    id="filterDropdown"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                    aria-haspopup="true"
                    style="line-height: 0.3"
                  >
                    <img
                      src="../../public/dashboard-assets/images/M2/Filter_icon.png"
                    />
                    <span
                      class="Noti-alert-kj translate-middle"
                      v-if="this.applyfilter > 0"
                    >
                      {{ this.applyfilter }} <span class="visually-hidden"></span>
                    </span>
                  </button>
  
                  <ul
                    class="dropdown-menu dropdown-menu-kj"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <div class="modal-header">
                      <h5
                        class="modal-title text-centerm-auto"
                        id="CreateGroupModalLabel"
                      >
                        Filter
                      </h5>
                      <button
                        type="button"
                        @click="filterclose"
                        class="btn-close"
                        data-bs-auto-close="inside"
                        aria-expanded="false"
                      ></button>
                    </div>
  
                    <ul
                      class="nav nav-pills mb-3 border-bottom"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li
                        class="nav-item w-50"
                        role="presentation"
                        v-if="
                          getUserPermissionData({
                            parent: 'Role Management',
                            child: 'Role Account',
                            permission: 'Can_Access_Role',
                          })
                        "
                      >
                        <button
                          class="nav-link text-muted text-center m-auto active"
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-home"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                          @click="
                            this.filterTab = 1;
                            this.searchfilter = '';
                            this.Filterresult();
                          "
                        >
                          Role
                          {{
                            this.filters.role.length > 0
                              ? "(" + this.filters.role.length + ")"
                              : ""
                          }}
                        </button>
                      </li>
                      <li
                        class="nav-item w-50"
                        role="presentation"
                        v-if="
                          getUserPermissionData({
                            parent: 'Groups Management',
                            child: 'Groups Account',
                            permission: 'Can_Access_Region',
                          })
                        "
                      >
                        <button
                          class="nav-link text-muted text-center m-auto"
                          id="pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-profile"
                          type="button"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                          @click="
                            this.filterTab = 2;
                            this.searchfilter = '';
                            this.Filterresult();
                          "
                        >
                          Region
                          {{
                            this.filters.groups.length > 0
                              ? "(" + this.filters.groups.length + ")"
                              : ""
                          }}
                        </button>
                      </li>
                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                      <div
                        class="tab-pane fade show active p-0"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      >
                        <div class="search-bar">
                          <form class="search-form">
                            <input
                              type="text"
                              v-model="searchfilter"
                              @keyup="Filterresult"
                              placeholder="Search"
                              name=""
                              class="form-control bg-white"
                            /><button class="btn">
                              <i class="fas fa-search"></i>
                            </button>
                          </form>
                          <div class="ul-li-checkbox-kj">
                            <ul v-if="this.filterTab == 1">
                              <li v-for="role in this.roleList" :key="role">
                                <input
                                  type="checkbox"
                                  :value="role.value"
                                  @change="addFilter(role.value, $event)"
                                  :checked="
                                    this.filters.role.includes(role.value)
                                  "
                                />
                                <span>{{ role.label }} </span>
                              </li>
                            </ul>
                            <ul v-else>
                              <li v-for="grp in this.options" :key="grp">
                                <input
                                  type="checkbox"
                                  name=""
                                  id=""
                                  :value="grp.value"
                                  @change="addFilter(grp.value, $event)"
                                  :checked="
                                    this.filters.groups.includes(grp.value)
                                  "
                                />
                                <span>{{ grp.label }} </span>
                              </li>
                            </ul>
                            <div class="align-items-center">
                              <button
                                type="button"
                                class="btn-Apply-kj-color"
                                v-if="this.applyfilter > 0"
                                @click="clearFilters()"
                              >
                                Clear Filter
                              </button>
                              <button
                                type="button"
                                class="modail_submit_button text-end"
                                :class="{
                                  active_form_button: this.applyfilter > 0,
                                }"
                                :disabled="this.applyfilter == 0"
                                @click="applyFilters()">
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ul>
                  <!-- </div> -->
                </div>
              </div>
            </div>
  
            <div
              class="top_heading_CreatePartner_btn mx-2"
              v-if="
                seen &&
                seen1 &&
                getUserPermissionData({
                  parent: 'User Management',
                  child: 'User Account',
                  permission: 'Can_Create_User',
                })
              "
            >
            <!--update-->
              <button
                type="button"
                class="btn btn-primary btn-icon-text"
                data-bs-toggle="modal"
                data-bs-target="#AddNewUserModal"
              >
                <i class="fa-solid fa-plus"></i> Add User
              </button>
            <!--update-->
            </div>
            <div
              class="top_heading_CreatePartner_btn d-block"
              v-if="seen && seen1"
            >
            <!--update-->
              <div class="Group_top_three_dot">
                <div class="dropdown">
                  <button
                    class="btn p-0 Group_top_three_dot_dropdown"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fa-solid fa-ellipsis-vertical"></i>
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <li
                      v-if="
                        getUserPermissionData({
                          parent: 'User Management',
                          child: 'User Account',
                          permission: 'Can_Create_User',
                        })
                      "
                    >
                      <button
                        type="button"
                        class="btn w-100 text-start"
                        data-bs-toggle="modal"
                        data-bs-target="#BulkUploadModal"
                      >
                        <i class="fa-solid fa-arrow-up-from-bracket"></i
                        >&nbsp;&nbsp; Bulk upload
                      </button>
                    </li>
                    <li
                      v-if="
                        getUserPermissionData({
                          parent: 'User Management',
                          child: 'User Account',
                          permission: 'Can_Deactivate_User',
                        })
                      "
                    >
                      <button
                        type="button"
                        :disabled="userStatus != 4"
                        class="btn w-100 text-start"
                        @click="MultiDeactivate()"
                      >
                        <i class="fa-regular fa-circle-xmark"></i>&nbsp;&nbsp;
                        Deactive users
                      </button>
                    </li>
                    <li
                      v-if="
                        getUserPermissionData({
                          parent: 'User Management',
                          child: 'User Account',
                          permission: 'Can_Create_User',
                        })
                      "
                    >
                      <button
                        type="button"
                        class="btn w-100 text-start"
                        @click="TemplateDownloads()"
                      >
                        <i class="fa-solid fa-download"></i>&nbsp;&nbsp; Download
                        Template
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            <!--update-->
            </div>
            <!--update-->
            <div
              class="mobile_Create_Partner_button"
              v-if="
                getUserPermissionData({
                  parent: 'User Management',
                  child: 'User Account',
                  permission: 'Can_Create_User',
                })
              "
            >
              <button
                type="button"
                class="btn"
                data-bs-toggle="modal"
                data-bs-target="#AddNewUserModal"
              >
                <i class="fa-solid fa-plus"></i>
              </button>
            </div>
            <!--update-->
          </div>
        </div>
      </div>
  
      <section class="my-4 token_secand_header_section mt-3">
          <div class="col-md-12">
          <div class="card p-0">
            <div class="card-body px-3 py-3">
              <div class="d-flex justify-content-between align-items-center">
                 
                
                <div
                  class="d-flex token_secand_header_company align-items-center"
                >
                <div class="PartnerTeamMembersProfile-area-kj">
                    <div class="PartnerTeamMembersProfile-kj name-logo-1" v-if="this.profileImage == null || this.profileImage == ''">
                      {{ this.companyName?.charAt(0) }}
                    </div>
                    <div class="" v-if="this.profileImage != null && this.profileImage != ''">
                     <img :src="this.profileImage" class="profile"/>
                    </div>
                  </div>

                  <div class="PartnerTeamMembersProfile-area-kj">
                    <h3>{{ this.companyName }}</h3>
                    <p class="partnerheading">Client Id: {{ this.currentclientId }} </p>
                  </div>

                  <!-- <div class="parnter_iten_content">
                  <div class="partner_item_img" v-if="this.profileImage != null && this.profileImage != ''">
                    <img :src="this.profileImage" />
                  </div>
                  <div class="partner_item_img" v-if="this.profileImage == null || this.profileImage == ''">
                    <div class="partner_FirstWord">
                      {{ this.companyName?.charAt(0) }}
                    </div>
                  </div>
                  <h4 class="my-2 text-dark" style="overflow-wrap: break-word;">{{ this.companyName }}</h4>
                 
                </div> -->




                </div>
              </div>
            </div>
          </div>
          </div>
        </section>


        <ul class="nav nav-pills mb-3 partners_top_menu_section_hp p-2 mt-2 user_tabing_nav_section" id="pills-tab"
        role="tablist">
        <li class="nav-item" role="presentation">
          <button @click="Tabchange(4)" class="nav-link btn px-3 active" id="User_Activated_hp-tab" data-bs-toggle="pill"
            data-bs-target="#User_Activated_hp" type="button" role="tab" aria-controls="User_Activated_hp"
            aria-selected="true">
            Activated
            <span v-if="UserActive.length > 0">({{ this.totalUserCount }})</span>
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button @click="Tabchange(1)" class="nav-link btn px-3" id="User_Pending_hp-tab" data-bs-toggle="pill"
            data-bs-target="#User_Pending_hp" type="button" role="tab" aria-controls="User_Pending_hp"
            aria-selected="false">
            Pending
            <span v-if="UserPending.length > 0">({{ this.totalUserCount }})</span>
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button @click="Tabchange(2)" class="nav-link btn px-3" id="User_Deactivated_hp-tab" data-bs-toggle="pill"
            data-bs-target="#User_Deactivated_hp" type="button" role="tab" aria-controls="User_Deactivated_hp"
            aria-selected="false">
            Deactivated
            <span v-if="UserDeactive.length > 0">({{ this.totalUserCount }})</span>
          </button>
        </li>
      </ul>
  
      <!-- empty section -->
      <section class="Partner_empty_main_section_hp mt-4" v-if="AllUserList.length == 0">
        <div class="row">
          <div class="Partner_empty_img_hp m-auto text-center">
            <img src="../../public/dashboard-assets/images/M2/Partner_blank.png" alt="" />
          </div>
          <div class="Partner_empty_content_hp m-auto text-center">
            <div class="mb-4">
              <h2>Hey, you still alone?</h2>
              <p class="text-muted mt-2">
                This is the best time to add users and start working
              </p>
            </div>
            <button type="button" class="btn btn-primary btn-icon-textx" data-bs-toggle="modal"
              data-bs-target="#AddNewUserModal" v-if="this.userStatus == 4">
              <i class="fa-solid fa-plus"></i> Add User
            </button>
          </div>
        </div>
      </section>
  
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="User_Activated_hp" role="tabpanel"
          aria-labelledby="User_Activated_hp-tab">
          <section class="row role_item_list_section mt-5" v-if="UserActive">
            <!-- check box pending in User Active section dynamically -->
  
            <div class="col-xl-3 col-lg-4 col-md-6 col-6 mb-4" v-for="user in UserActive" :key="user">
              <div class="role_item_box">
                <div class="d-flex justify-content-between role_item_box_new_item align-items-center">
                  <!-- <img v-bind:src="user.profilePic" alt="" /> -->
                  <!-- <div class="partner_item_action "> -->
                  <div class="partner_item_img" v-if="user.profilePic != null && user.profilePic != ''">
                    <img v-bind:src="user.profilePic" />
                  </div>
                  <div class="partner_item_img" v-if="user.profilePic == null || user.profilePic == ''">
                    <div class="partner_FirstWord">
                      {{ user.name.charAt(0) }}
                    </div>
                  </div>
                  <input type="checkbox" class="form-check-input" v-model="DeActivatedIds" :value="user.id"
                    v-if="user.id != this.user.userId && !seen" name="" id="" />
                  <div class="dropdown" v-if="seen">
                    <!-- mayuresh -->
                    <button class="btn p-0" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false" v-if="user.email != this.user.email">
                      <i class="fa-solid fa-ellipsis"></i>
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <li v-if="
                        getUserPermissionData({
                          parent: 'User Management',
                          child: 'User Account',
                          permission: 'Can_Update_User_Detail',
                        })
                      ">
                        <button type="button" @click="Edituser(user.id)" class="btn w-100 text-start" data-bs-toggle="modal"
                          data-bs-target="#AddNewUserModal">
                          Edit user
                        </button>
                      </li>
                      <li v-if="
                        getUserPermissionData({
                          parent: 'User Management',
                          child: 'User Account',
                          permission: 'Can_Deactivate_User',
                        })
                      ">
                        <button type="button" @click="Deactivate(user.id, user.name, 'deactivate')" class="btn"
                          data-bs-toggle="modal" data-bs-target="#DeactivateUserModal">
                          Deactivate user
                        </button>
                      </li>
  
                      <li>
                        <button type="button" class="btn"  @click="ManagePassword(user.id, user.name, user.email, 'ChangePassword')"
                          data-bs-toggle="modal" data-bs-target="#ChangePasswordModal">
                          Manage Password
                        </button>
                      </li>
  
  
  
  
                      <!-- <li>
                        <button type="button" @click="Deactivate(user.id, user.name, 'delete')"
                          class="btn w-100 text-start" data-bs-toggle="modal" data-bs-target="#DeleteUserModal">
                          Delete user
                        </button>
                      </li> -->
                    </ul>
                  </div>
                  <!-- </div> -->
                </div>
  
                <div>
                  <div class="role_item_box_heading border-0" style="overflow-wrap: break-word">
                    {{ user.name }}
                  </div>
                  <div class="role_item_box_email d-flex justify-content-between">
                    <p v-if="this.screenWidth < 400">
                      {{ user.email.slice(0, 15) + "..." }}
                    </p>
                    <p v-else>{{ user.email }}</p>
                    <samp @click="copytoClipboard(user.email)"><i class="fa-regular fa-clone"></i></samp>
                  </div>
                  <div class="d-flex align-items-center my-2">
                    <i class="fa-solid fa-briefcase me-2"></i>
                    <p class="font-size-12">
                      {{ user.displayName }}
                    </p>
                  </div>
                  <div class="d-flex flex-wrap" v-if="user.regiondata">
                    <div v-for="(region, index) in user.regiondata.slice(0, 4)" :key="index">
                      <span v-if="index < 3">
                        <button v-if="region.regionName.length > 5"
                          class="select_role_item_button text-muted Management_Users_box_button btn">
                          {{
                            region.regionName.substring(
                              0,
                              region.regionName.lastIndexOf(
                                region.regionName.substring(5)
                              )
                            ) + "..."
                          }}
                        </button>
                        <button v-else class="select_role_item_button text-muted Management_Users_box_button btn">
                          {{ region.regionName }}
                        </button>
                      </span>
  
                      <button v-if="index == 3"
                        class="select_role_item_button Management_Users_box_button offcanvas_button btn"
                        @click="viewData(user.id)" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
                        aria-controls="offcanvasRight">
                        + {{ user?.regiondata?.length - 3 }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="tab-pane fade" id="User_Pending_hp" role="tabpanel" aria-labelledby="User_Pending_hp-tab">
          <section class="row role_item_list_section mt-5">
            <!-- User pending section checkbox adding remainning -->
            <div class="col-xl-3 col-lg-4 col-md-6 col-6 mb-4" v-for="user in UserPending" :key="user">
              <div class="role_item_box p-0">
                <div class="p-3 pb-0 d-flex justify-content-between role_item_box_new_item align-items-center">
                  <!-- <img v-bind:src="user.profilePic" alt="" /> -->
                  <!-- <div class="partner_item_action"> -->
  
                  <div class="partner_item_img" v-if="user.profilePic != null && user.profilePic != ''">
                    <img v-bind:src="user.profilePic" />
                  </div>
                  <div class="partner_item_img" v-if="user.profilePic == null || user.profilePic == ''">
                    <div class="partner_FirstWord">
                      {{ user.name.charAt(0) }}
                    </div>
                  </div>
  
                  <input type="checkbox" class="form-check-input" v-model="DeActivatedIds" v-if="!seen" name="" id="" />
                  <div class="dropdown" v-if="seen">
                    <!-- <div class="dropdown"> -->
                    <button class="btn p-0" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                      <i class="fa-solid fa-ellipsis"></i>
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <li v-if="
                        getUserPermissionData({
                          parent: 'User Management',
                          child: 'User Account',
                          permission: 'Can_Delete_User',
                        })
                      ">
                        <button @click="Deactivate(user.id, user.name, 'delete')" type="button" class="btn w-100 text-start"
                          data-bs-toggle="modal" data-bs-target="#DeleteUserModal">
                          Delete user
                        </button>
                      </li>
                    </ul>
                  </div>
                  <!-- </div> -->
                </div>
                <div class="px-3">
                  <div class="role_item_box_heading border-0">
                    {{ user.name }}
                  </div>
                  <div class="role_item_box_email d-flex justify-content-between">
                    <p class="text-muted" v-if="this.screenWidth < 400">
                      {{ user.email.slice(0, 15) + "..." }}
                    </p>
                    <p v-else>{{ user.email }}</p>
                    <samp @click="copytoClipboard(user.email)"><i class="fa-regular fa-clone"></i></samp>
                  </div>
                  <div class="d-flex align-items-center my-2">
                    <img src="../../public/dashboard-assets/images/M2/mail_check.png" alt="" />
                    <p class="font-size-12 ms-2 text-muted">Email sent</p>
                  </div>
                </div>
                <div class="user_item_Reactivate" v-if="
                  getUserPermissionData({
                    parent: 'User Management',
                    child: 'User Account',
                    permission: 'Can_Create_User',
                  })
                ">
                  <button @click="UserInvite(user.id)" type="button"
                    class="btn w-100 text-center parnter_item_date border-0 p-0">
                    Resend Invite
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="tab-pane fade" id="User_Deactivated_hp" role="tabpanel" aria-labelledby="User_Deactivated_hp-tab">
          <section class="row role_item_list_section mt-5" v-if="UserDeactive.length > 0">
            <!-- Checkbox functionality pending -->
            <div class="col-xl-3 col-lg-4 col-md-6 col-6 mb-4" v-for="user in UserDeactive" :key="user">
              <div class="role_item_box p-0">
                <div class="p-3 pt-2 pb-0 d-flex justify-content-between role_item_box_new_item align-items-center">
                  <div>
                    <div class="partner_item_img" v-if="user.profilePic != null && user.profilePic != ''">
                      <img v-bind:src="user.profilePic" />
                    </div>
                    <div class="partner_item_img" v-if="user.profilePic == null || user.profilePic == ''">
                      <div class="partner_FirstWord">
                        {{ user.name.charAt(0) }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="px-3">
                  <div class="role_item_box_heading border-0">
                    {{ user.name }}
                  </div>
                  <div class="role_item_box_email d-flex justify-content-between mb-3">
                    <p v-if="this.screenWidth < 400">{{ user.email.slice(0, 15) + "..." }}</p>
                    <p v-else>{{ user.email }}</p>
  
                    <samp @click="copytoClipboard(user.email)"><i class="fa-regular fa-clone"></i></samp>
                  </div>
                </div>
                <div class="user_item_Reactivate" v-if="
                  getUserPermissionData({
                    parent: 'User Management',
                    child: 'User Account',
                    permission: 'Can_Activate_User',
                  })
                ">
                  <button @click="Reactivate(user.id, user.name)" type="button"
                    class="btn w-100 text-center parnter_item_date border-0 p-0" data-bs-toggle="modal"
                    data-bs-target="#ReactivateuserModal">
                    Reactivate User
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
  
      <paginate v-model="currentPage" v-if="AllUserList.length > 0" :page-count="page_count" :page-range="1"
        :margin-pages="1" :click-handler="clickCallback" :prev-text="'< Prev'" :next-text="'Next >'"
        :container-class="'pagination'" :page-class="'page-item'">
      </paginate>
  
      <!-- Add User -->
      <div class="modal fade CreatePartnerModal_hp" id="AddNewUserModal" tabindex="-1" data-bs-backdrop="static"
        data-bs-keyboard="false" aria-labelledby="AddNewUserModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title text-centerm-auto" id="AddNewUserModalLabel">
                {{ Userid ? "Edit User" : "Add User" }}
              </h4>
              <button type="button" class="btn-close" id="close" data-bs-dismiss="modal" aria-label="btn-close"
                @click="ResetForm"></button>
            </div>
            <div class="modal-body mt-4">
              <div class="row" id="CreateForm">
                <div class="form-group mb-4">
                  <label class="input-hero" for="Category">
                    <!--update-->
                    <select name="" v-model="this.role" class="form-select input_area select_ca" id="">
                      <!--update -->
                      <!-- <option selected disabled>Choose Role</option> -->
  
                      <option v-for="role in this.roleList" :key="role" :value="role.value">
                        {{ role.label }}
                      </option>
                    </select>
                    <span class="input-name">Role <span class="text-danger">*</span></span>
                  </label>
                </div>
  
                <div class="form-group mb-4">
                  <label class="input-hero" for="PartnerCompany">
                    <Multiselect v-model="group" placeholder="Choose Groups" mode="tags" :close-on-select="false"
                      :options="options" :searchable="true" :group-select="true">
                    </Multiselect>
                    <span class="input-name input-group-kj">Group <span class="text-danger">*</span></span>
                  </label>
                  <!-- </div> -->
                </div>
  
                <div class="form-group mb-4">
                  <label class="input-hero" for="FirstName">
                    <input class="input_area" v-model="firstname" type="text" required="" /><span class="input-name">First
                      Name <span class="text-danger">*</span></span>
                  </label>
                </div>
  
                <div class="form-group mb-4">
                  <label class="input-hero" for="LastName">
                    <input class="input_area" v-model="lastname" type="text" required="" /><span class="input-name">Last
                      Name <span class="text-danger">*</span></span>
                  </label>
                </div>
  
                <div class="form-group mb-4">
                  <label class="input-hero" for="Tokens">
                    <input class="input_area" type="email" :style="[Userid > 0 ? { color: '#9c9c9e!important' } : {}]"
                      :readonly="Userid > 0" v-model="email" required="" /><span class="input-name"
                      :style="[Userid > 0 ? { color: '#9c9c9e!important' } : {}]">Work Email <span
                        class="text-danger">*</span></span>
                  </label>
                </div>
  
                <div class="form-group mb-4">
                  <label class="input-hero" for="Category" >
                    <select class="form-select input_area select_ca"  v-model="CountryName"  @change="getTimeZones">
                    <option disabled selected>Select Preferred Country</option>
                    <option v-for="item in GetLocationData.object.data" :key="item" :value="item.iso2">{{ item.name }}</option>
                    </select>
             
                    <span class="input-name">Country<span class="text-danger">*</span></span>
                  </label>
                </div>
                <div class="form-group mb-4">
                  <label class="input-hero" for="Category">
                    <select class="form-select input_area select_ca" v-model="TimeZoneValue" @change="calculateLocalTime">
                    <option disabled selected>Select Preferred Time Zone</option>
                    <option v-for="item in GetTimeZoneData.object.data" :key="item" :value="item.gmtOffsetName">
                      {{ item.zoneName }}  {{ item.gmtOffsetName }}
                    </option>
                    </select>
                    <span class="input-name">Time Zone<span class="text-danger">*</span></span>
                  </label>
  
                </div>
  
                <div class="">
                  <button @click="savepartner(this.Userid)" class="modail_submit_button" :disabled="isDisabled()"
                    :class="{ active_form_button: !isDisabled() }">
                    {{ Userid ? "Update" : "Add" }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
  
      <!-- ReactivateuserModal -->
      <div class="modal fade" id="ReactivateuserModal" tabindex="-1" data-bs-keyboard="false"
        aria-labelledby="ReactivateuserModalLabel" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-body p-4">
              <div class="">
                <h3 class="mb-3">Reactivate User?</h3>
                <p class="text-muted">
                  Are you sure you want to reactivate
                  <strong class="text-dark">{{ ReactivatedName }}</strong> User.
                </p>
              </div>
              <div class="Deactivate_buttons row mt-5 justify-content-center">
                <button @click="ReactivateClose()" id="close3" data-bs-dismiss="modal" aria-label="Close"
                  class="col-5 Deactivate_Cancel_buttons">
                  Cancel
                </button>
                <button @click="ReactivateAction()" type="button" class="col-5 btn Deactivate_Deactivate_buttons">
                  Reactivate
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <!-- DeactivateUserModal -->
      <div class="modal fade" id="DeactivateUserModal" tabindex="-1" aria-labelledby="DeactivateUserModalLabel"
        data-bs-keyboard="false" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-body p-4">
              <div class="">
                <h3 class="mb-3">Deactivate User?</h3>
                <p class="text-muted">
                  Are you sure you want to Deactivate
                  <strong class="text-dark">{{ DeactivatedName }}</strong> User.
                </p>
              </div>
              <div class="Deactivate_buttons row mt-5 justify-content-center">
                <button id="close1" data-bs-dismiss="modal" aria-label="Close" class="col-5 Deactivate_Cancel_buttons"
                  @click="DeactivateClose">
                  Cancel
                </button>
                <button type="button" @click="DeactivateAction" class="col-5 btn Deactivate_Deactivate_buttons">
                  Deactivate
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
  
     
       <!-- ChangePasswordModal -->
       <div class="modal fade CreatePartnerModal_hp" id="ChangePasswordModal" tabindex="-1" aria-labelledby="ChangePasswordModalLabel"
        data-bs-keyboard="false" aria-hidden="true" data-bs-backdrop="static" >
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
  
  
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title text-centerm-auto" id="AddNewUserModalLabel">
                Manage Password 
              </h4>
              <button type="button" class="btn-close" id="close" data-bs-dismiss="modal" aria-label="btn-close"
                @click="ManagePasswordClear"></button>
            </div>
            <div class="modal-body">
              <p class="text-muted">
                  Are you sure you want to change password of <br> 
                  <strong class="text-dark">{{ ManageUserName }}</strong> User ?
                </p>
  
              <div class="row" id="CreateForm">
              
  
                <div class="form-group mb-4 my-4">
  
                  
                  <label class="input-hero" for="Category">
                    <label >
                      
                  <input class="input_area" type="text" v-model="ChangePassordUserEmail" disabled/>
                    </label>
                    <span class="input-name">Email <span class="text-danger">*</span></span>
                  </label>
                </div>
  
                <div my-5 login_button_area>
                <button aria-label="Close"  class="btn btn-primary mb-3" type="button" @click="reset" data-bs-dismiss="modal"> 
                  <i class="fa-solid fa-envelope" style="color: #ffffff;"></i>
                   Send Email
                </button>
                &nbsp;&nbsp;
                <button type="button" class="btn btn-primary mb-3"
                @click="sendResetLink"  v-if="this.superadmin()">
                  Get Link 
                  <i class="fa-solid fa-link" style="color: #ffffff;"></i>
            </button>
         
  
  <div class="form-group mb-4 my-4" v-if="resetPasswordUrl != 0">
    <label class="input-hero" for="Category">
      <label>
        <input class="input_area" type="text" v-model="resetPasswordUrl" ref="resetPasswordInput" />
      
      </label>
    </label>
  
  
  </div>
  
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
  
  
  
      <!-- DeleteUserModal -->
      <div class="modal fade" id="DeleteUserModal" tabindex="-1" aria-labelledby="DeleteUserModalLabel"
        data-bs-keyboard="false" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-body p-4">
              <div class="">
                <h3 class="mb-3">Delete User?</h3>
                <p class="text-muted">
                  Are you sure you want to delete
                  <strong class="text-dark">{{ DeactivatedName }}</strong> user.
                </p>
              </div>
              <div class="Deactivate_buttons row mt-5 justify-content-center">
                <button data-bs-dismiss="modal" aria-label="Close" id="close2" class="col-5 Deactivate_Cancel_buttons"
                  @click="DeactivateClose">
                  Cancel
                </button>
                <button type="button" class="col-5 btn Deactivate_Deactivate_buttons" @click="DeactivateAction">
                  Yes, Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Role Item view section -->
      <div class="offcanvas offcanvas-end role_item_view_detail_sidebar" tabindex="-1" id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel" data-bs-backdrop="static" style="visibility: hidden;">
        <div class="offcanvas-header border-bottom">
          <button type="button" class="btn-close text-reset" @click="viewUsersearchinput = ''" data-bs-dismiss="offcanvas"
            aria-label="Close"></button>
  
          <div class="row">
            <div class="col-3">
              <div class="PartnerTeamMembersProfile-area-kj">
                <div class="PartnerTeamMembersProfile-kj name-logo-1">
                  {{ ViewUserfirstletter }}
                </div>
              </div>
            </div>
            <div class="col-9">
              <h4>{{ viewUser.firstName + " " + viewUser.lastName }}</h4>
              <div>
                <span class="font-size-10-MV-kj text-muted">{{
                  viewUser.email
                }}</span>
              </div>
            </div>
          </div>
          <button type="button" class="btn-close text-reset role_item_view_close" data-bs-dismiss="offcanvas"
            aria-label="Close"></button>
          <div class="partner_item_action">
            <div class="dropdown">
              <button class="btn p-0" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
                <i class="fa-solid fa-ellipsis"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="offcanvas-body">
          <div class="mb-3">
            <p class="font-size-12 text-muted">Role</p>
            <h5>{{ viewUser.roleName }}</h5>
          </div>
          <div class="">
            <p class="font-size-12 text-muted">
              Groups ( {{ ViewUserRegionlength }})
            </p>
  
            <div class="input-group flex-nowrap mt-2">
              <span class="input-group-text border-0" id="addon-wrapping"><img
                  src="../../public/dashboard-assets/images/M2/Icon-Set.png" alt="" /></span>
              <input type="text" v-model="viewUsersearchinput" v-on:input="ViewUserSearchfilter"
                class="form-control input-group-text text-start border-0" placeholder="Search" />
            </div>
          </div>
          <div class="px-2 mt-2">
            <p class="text-muted border-bottom py-3" v-for="region in ViewUserRegion" :key="region">
              {{ region.regionName }}
            </p>
          </div>
        </div>
      </div>
  
      <!-- BulkUploadModal -->
      <div class="modal fade" id="BulkUploadModal" tabindex="-1" aria-labelledby="BulkUploadModalLabel" aria-hidden="true"
        data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content border-file-uploading">
            <div class="modal-body">
              <div class="text-end border-bottom-0" v-if="!prog">
                <button  type="button" data-bs-dismiss="modal" aria-label="Close" class="btn-close"
                  @click="BulkuploadModelclose()"></button>
              </div>
              <div class="file-upload-kj">
                <div class="d-flex align-items-center mb-3">
                  <h4 class="text-file-name" v-if="prog" :class="[progValue1 == 100 ? 'text-dark' : '']">
                    {{ this.bulkfilename }}
                  </h4>
  
                  <span v-if="newupload">
                    <i class="fa-solid fa-circle-check progress-check mx-2"></i>
  
                  </span>
                  <span v-if="!newupload && bulkfilename">
                    <i class="fa-solid fa-circle-xmark progress-close mx-2"></i>
                  </span>
  
                </div>
                <div class="image-upload-wrap">
                  <input id="fileInput" class="file-upload-input" type="file" v-on:change="handleFileUpload($event)"
                    accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                  <div class="progress" v-if="prog">
                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                      aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" :style="{ width: progValue }"></div>
                  </div>
                  <div class="text-center" v-if="!prog">
                    <!-- <i class="fas fa-file-alt"></i> -->
                    <img src="../../public/dashboard-assets/images/M2/Upload-File-icon.png" alt="" />
                    <h4>Upload File</h4>
                    <h3>Supported formats: XLSX</h3>
                  </div>
                </div>
                <div class="file-upload-content">
                  <img class="file-upload-image" src="#" alt="your image" />
                </div>
              </div>
              <div class="d-flex m-auto mt-5 justify-content-end">
                <div class="mx-2">
  
                  <button type="button" v-if="prog" id="close4" data-bs-dismiss="modal" aria-label="Close"  v-bind:disabled="!this.newupload" @click="cancelUploadModal" class="btn"
                    v-bind:class="[
                      this.newupload ? 'btn-primary btn-upload-kj' : 'customUploadBtn']">
                    Cancel
                  </button>
                </div>
                <div>
                  <button v-if="prog" v-bind:disabled="this.progValue1 != 100" type="button" @click="BulkuserUpload()"
                    class="btn" v-bind:class="[
                      this.progValue1 == 100 ? 'btn-primary btn-upload-kj' : 'customUploadBtn']">
                    Upload
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import Multiselect from "@vueform/multiselect";
  import { mapState, mapActions, mapMutations } from "vuex";
  import Paginate from "vuejs-paginate-next";
  import { getUserPermissionData } from "../utils/helper";
  import { validateEmail } from "@/utils";
  import { validateName } from "../utils";
  import { superadmin } from "../utils/helper";
  
  const moment = require('moment-timezone');
  
  
  // import teams from "../store/modules/teams";
  
  export default {
    name: "Management-Users",
    components: {
      Multiselect,
      paginate: Paginate,
    },
    data() {
      return {
        companyName: "",
        userStatus: 4,
        page_count: 1,
        DeActivatedIds: [],
        seen: true,
        action: "",
        DeactivateId: null,
        DeactivatedName: "",
        ManageUserName:"",
        ReactivateId: null,
        ReactivatedName: "",
        Userid: null,
        AllUserList: [],
        UserActive: [],
        UserPending: [],
        UserDeactive: [],
        viewUser: {},
        ViewUserfirstletter: "",
        ViewUserRegionFilter: [],
        optionswithSelectAll: [],
        viewUsersearchinput: "",
        role: [],
        filters: {
          role: [],
          groups: [],
          roleinput: "",
        },
        roleList: [],
        roleListFilter: [],
        optionsFilter: [],
        TeamList: [],
        page: 1,
        limit: 10,
        group: [],
        seen1: true,
        firstname: "",
        email: "",
        lastname: "",
        options: [],
        Searchinput: "",
        file: "",
        prog: false,
        progValue: 0,
        progValue1: 0,
        uploadbuttons: false,
        totalUserCount: 0,
        bulkfilename: "",
        filterTab: 1,
        newupload: false,
        ViewUserRegionlength: 0,
        ViewUserRegion: [],
        applyfilter: 0,
        users: [],
        result: [],
        show: false,
        list: false,
        currentPage: 1,
        screenWidth: 0,
        ChangePassordUserEmail:"",
        resetPasswordUrl:"",
        GetLocationData:{
          object: {
            data: []
          }
        },
        CountryName:"",
        TimeZoneValue:"",
        GetTimeZoneData:{
          object: {
            data: []
          }
        },
        utcTime: new Date().toISOString(),
        localTime: null ,
        currentclientId:0
  
      };
    },
    async created() {
      this.screenWidth = window.outerWidth;
      this.GetLocationData = await this.getlocation();
      this.GetTimeZoneData = await this.getTimeZone({
        countryCode: this.CountryName
      });
      this.currentclientId = window.atob(this.$route.params.id);
      var company = await this.Companyheader({ clientId: this.currentclientId });
      this.companyName = company.object.data.companyName;


    },
    async mounted() {
      await this.getAllUser();
      var id = window.atob(this.$route.params.id);
      var a = await this.GetTeams({
        clientId: id,
      });
      this.TeamList = await a.object.data;
      this.options = await this.TeamList.regions.map((i) => ({
        value: i.regionId,
        label: i.regionName,
      }));
      this.optionswithSelectAll = this.options;
      this.optionsFilter = this.TeamList.regions.map((i) => ({
        value: i.regionId,
        label: i.regionName,
      }));
  
      var x = await this.AllRolesList({
        clientId: id,
        PageNo: 1,
        Limit: 100,
        Search: "",
      });
      this.roleList = x.object.data.items.map((i) => ({
        value: i.roleId,
        label: i.roleName,
      }));
      this.roleListFilter = x.object.data.items.map((i) => ({
        value: i.roleId,
        label: i.roleName,
      }));
      this.calculateLocalTime();
  
  
      // this.role = this.roleList.find((x) => x.label == "Admin").value;
    },
    computed: {
      ...mapState({
        authenticated: (state) => state.auth.authenticate,
        user: (state) => state.auth.user,
        clientId: (state) => state.auth.user.clientId,
      }),
    },
  
    methods: {
      getUserPermissionData,
      ...mapActions([
        "BulkUseruploading",
        "TemplateDownload",
        "MultipleUserDeactivate",
        "DeleteUserById",
        "DeactivateUser",
        "ReactivateUser",
        "userList",
        "UpdateUserData",
        "UserRegionData",
        "UserEditData",
        "GetTeams",
        "AllRolesList",
        "AllTeamList",
        "GetAllUsers",
        "AddUser",
        "inviteUser",
        "AllPartnerList",
        "ShowWarningMessage",
        "getallTeams",
        "managePassword", 
        "ShowWarningMessage",
        "SetRestLink",
        "getlocation",
        "getTimeZone",
        "Companyheader"
      ]),
      ...mapMutations(["setLoading"]),
      superadmin,
  
      clickCallback(pageNum) {
        this.page = pageNum;
        this.getAllUser();
      },
  
      async viewData(userid) {
        var res = await this.UserEditData({
          userId: userid,
        });
  
        if (res.status) {
          this.viewUser = res.object.data;
          this.ViewUserfirstletter = await this.viewUser.firstName.charAt(0);
          if (this.viewUser.regions) {
            this.ViewUserRegionlength = await this.viewUser.regions.length;
            this.ViewUserRegion = await this.viewUser.regions;
            this.ViewUserRegionFilter = await this.viewUser.regions;
          }
        }
      },
  
     
      async ViewUserSearchfilter() {
        if (this.viewUsersearchinput.trim() != "") {
          this.ViewUserRegion = this.ViewUserRegionFilter.filter((i) =>
            i.regionName
              .toLowerCase()
              .includes(this.viewUsersearchinput.toLowerCase())
          );
        } else {
          this.ViewUserRegion = await this.viewUser.regions;
        }
      },
  
      async copytoClipboard(mytext) {
        try {
          await navigator.clipboard.writeText(mytext);
        } catch ($e) {
          console.log($e);
        }
      },
  
      Reuploadevent() {
        this.file = "";
        this.bulkfilename = "";
        this.prog = false;
        this.progValue = 0;
        this.progValue1 = 0;
        document.getElementById("fileInput").click();
      },
  
      async reset() {
        await this.managePassword({ email: this.ChangePassordUserEmail });
        this.resetPasswordUrl="";
      },
  
      async BulkuserUpload() {
        let formData = new FormData();
        formData.append("file", this.file);
        var id = window.atob(this.$route.params.id);
        var response = await this.BulkUseruploading({
          clientId: id,
          formData,
        });
        if (response.status) {
          window.$("#BulkUploadModal").modal("hide");
        document.getElementById("close4").click();
        }
        else{
          window.$("#BulkUploadModal").modal("hide");
          document.getElementById("close4").click();
        }
        this.file = "";
        this.bulkfilename = "";
        this.prog = false;
        this.progValue1 = 0;
        this.uploadbuttons = false;
        this.newupload = false;
  
      },
  
      BulkuploadModelclose() {
        this.file = "";
        this.bulkfilename = "";
        this.prog = false;
        this.progValue1 = 0;
        this.uploadbuttons = false;
        this.newupload = false;
       
        window.$("#BulkUploadModal").modal("hide");
      },
      handleFileUpload(event) {
        if (event) {
          this.progValue = 0;
          // this.newupload = false;
          this.file = event.target.files[0];
          this.bulkfilename = this.file.name;
          // }
          this.prog = true;
  
          let i = 0;
          if (i == 0) {
            i = 1;
            var width = 1;
            const fileInterval = setInterval(() => {
              if (width >= 100) {
                this.uploadbuttons = true;
                this.newupload = true;
                i = 0;
                clearInterval(fileInterval);
              } else {
                width++;
                this.progValue = width + "%";
                this.progValue1 = width;
              }
            }, 10);
          }
  
          setTimeout(() => {
            event.target.value = "";
          }, 1000);
          event.target.value = "";
        }
      },
  
      async searchusername(val) {
        this.Searchinput = val;
        this.result = [];
        this.page = 1;
        this.filters.roleinput = val;
        this.currentPage = 1;
        await this.getAllUser();
      },
  
      async sendResetLink(){
        var apiResponse = await this.SetRestLink({ Email: this.ChangePassordUserEmail });
        this.resetPasswordUrl = apiResponse.object.data.resetUrl;
        this.$refs.resetPasswordInput.select();
      document.execCommand("copy");
      window.getSelection().removeAllRanges(); 
      },
  
  
  
      async filteredList() {
        if (this.Searchinput.trim() != "") {
          this.users = [];
          this.result = [];
          var id = window.atob(this.$route.params.id);
          var model = {
            status: this.userStatus,
            clientId: id,
            limit: 100,
            page: 1,
            searchinput: this.Searchinput.trim(),
          };
  
          var users = await this.GetAllUsers(model);
          var x = users.object.data.users;
          x.forEach((element) => {
            if (this.users.indexOf(element.name) < 0) {
              this.users.push(element.name);
            }
          });
          this.result = this.users.filter((element) =>
            element.toLowerCase().startsWith(this.Searchinput.toLowerCase())
          );
          this.result = Object.freeze(JSON.parse(JSON.stringify(this.result)));
          if (this.result) {
            this.list = true;
          }
        } else {
          this.result = [];
          this.list = false;
          this.filters.roleinput = "";
          await this.getAllUser();
        }
      },
      stringTOHtml(item, idx) {
        setTimeout(() => {
          if (this.Searchinput != "") {
            let re = new RegExp(this.Searchinput.toLowerCase(), "g");
            var html = document.getElementById(idx);
            var ex = item
              .toLowerCase()
              .replace(
                re,
                `<span style="color:#7b68ee !important">${this.Searchinput}</span>`
              );
            if (html) html.innerHTML = ex;
          }
        }, 100);
      },
  
      // Searching box
      opensearch() {
        document.querySelector(".Searching_input-box").classList.add("open");
      },
      async closesearch() {
        document.querySelector(".Searching_input-box").classList.remove("open");
        this.result = [];
        this.Searchinput = "";
        this.filters.roleinput = "";
        this.seen1 = true;
        await this.getAllUser();
      },
      async closemobilesearch() {
        this.show = false;
        document
          .querySelector(".Searching_input_box_mobile_view")
          .classList.remove("open");
        this.result = [];
        this.Searchinput = "";
        this.filters.roleinput = "";
        this.seen1 = true;
        await this.getAllUser();
      },
      openmobilesearch() {
        document
          .querySelector(".Searching_input_box_mobile_view")
          .classList.add("open");
        this.result = [];
        this.input = "";
        this.show = true;
      },
  
      async TemplateDownloads() {
        var id = window.atob(this.$route.params.id);
        var a = await this.TemplateDownload({
          clientId: id,
        });
  
        const fileURL = window.URL.createObjectURL(new Blob([a]));
        const fileLink = document.createElement("a");
  
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "file.xlsx");
        document.body.appendChild(fileLink);
  
        fileLink.click();
      },
  
      async Edituser(id) {
        this.Userid = id;
        var res = await this.UserEditData({
          userId: this.Userid,
        });
        if (res.status) {
          var data = res.object.data;
          (this.role = data.roleId), (this.firstname = data.firstName);
          this.lastname = data.lastName;
          this.email = data.email;
          this.group = data?.regions.map((i) => i.regionId);
          this.CountryName=data.countryCode;
          this.TimeZoneValue=data.timezone;
        }
      },
  
      async Deactivate(id, name, type) {
        this.action = type;
        this.DeactivateId = id;
        this.DeactivatedName = name;
      },
  
      async ManagePassword(id,name,email,type){
        this.PasswordChangeType = type;
        this.ManageUserId = id;
        this.ManageUserName = name;
        this.ChangePassordUserEmail= email
      },
  
      async DeactivateAction() {
        if (this.action == "deactivate") {
          await this.DeactivateUser({
            userId: this.DeactivateId,
          });
  
          this.DeactivateId = null;
          this.DeactivatedName = "";
          this.action = "";
          await this.getAllUser();
          document.getElementById("close1").click();
          window.$("#DeactivateUserModal").modal("hide");
        }
  
        if (this.action == "delete") {
          await this.DeleteUserById({
            userId: this.DeactivateId,
          });
  
          this.DeactivateId = null;
          this.DeactivatedName = "";
          this.action = "";
          await this.getAllUser();
          document.getElementById("close2").click();
          window.$("#DeleteUserModal").modal("hide");
        }
      },
      DeactivateClose() {
        this.DeactivateId = null;
        this.DeactivatedName = "";
        this.action = "";
        window.$("#DeactivateUserModal").modal("hide");
        window.$("#DeleteUserModal").modal("hide");
      },
  
      async getTimeZones() {
        this.GetTimeZoneData = await this.getTimeZone({
          countryCode: this.CountryName
        });
      },
  
      async calculateLocalTime() {
        const offsetMatch = this.TimeZoneValue.match(/([+-]\d{2}):?(\d{2})?/);
        const hours = parseInt(offsetMatch[1]);
        const minutes = offsetMatch[2] ? parseInt(offsetMatch[2]) : 0;
        const offset = hours * 60 + minutes;
        const localTime = moment.utc(this.utcTime).utcOffset(offset).format('YYYY-MM-DD HH:mm:ss');
  
        this.localTime = localTime;
      },
  
      ChangePasswordCloseModal() {
        this.ManageUserId = null;
        this.PasswordChangeType = "";
        this.ManageUserName = "";
        window.$("#ChangePasswordModal").modal("hide");
      },
      async Reactivate(id, name) {
        this.ReactivateId = id;
        this.ReactivatedName = name;
      },
  
      async ReactivateAction() {
        await this.ReactivateUser({
          userId: this.ReactivateId,
        });
  
        this.ReactivateId = null;
        this.ReactivatedName = "";
  
        await this.getAllUser();
        document.getElementById("close3").click();
        window.$("#ReactivateuserModal").modal("hide");
      },
      async ReactivateClose() {
        this.ReactivateId = null;
        this.ReactivatedName = "";
        document.getElementById("close1").click();
        window.$("#ReactivateuserModal").modal("hide");
      },
      async MultiDeactivate() {
        this.seen = false;
        this.DeActivatedIds = [];
      },
      async Tabchange(status) {
        this.userStatus = status;
        this.seen = true;
        this.DeActivatedIds = [];
        (this.page = 1), (this.currentPage = 1);
        await this.getAllUser();
      },
      async MultipleDeactiveCancel() {
        this.seen = true;
        this.DeActivatedIds = [];
      },
  
      async MultipleDeactivateAction() {
        var ress = await this.MultipleUserDeactivate(this.DeActivatedIds);
        if (ress.status) {
          this.seen = true;
          this.DeActivatedIds = [];
          await this.getAllUser();
        }
      },
  
      confirm() {
        this.updateUser(this.indexId);
        window.$("#userUpdate").modal("hide");
      },
      cancel() {
        window.$("#userUpdate").modal("hide");
        this.isShow = true;
      },
      cancelPopup() {
        window.$("#AddUserModal").modal("hide");
      },
  
      cancelUploadModal() {
        (this.progValue = ""),
          (this.prog = ""),
          (this.progValue1 = ""),
          (this.newupload = ""),
          (this.bulkfilename = ""),
          window.$("#BulkUploadModal").modal("hide");
          document.getElementById('close4').click();
      },
      updateUser(id) {
        window.$("#s-" + id).css("display", "inline-block");
        window.$("#i-" + id).css("display", "none");
      },
      userTodelete(id) {
        this.deleteUser({ userId: id });
      },
  
      async ResetForm() {
        this.Userid = null;
        this.group = [];
        this.firstname = "";
        this.lastname = "";
        this.email = "";
        this.role = [];
        this.CountryName="";
        this.TimeZoneValue="";
      },
  
      async ManagePasswordClear(){
  
        this.resetPasswordUrl="";
      },
      isDisabled() {
        return (
          this.firstname == "" ||
          this.role == "" ||
          this.lastname == "" ||
          this.email == "" ||
          this.group.length == 0
        );
      },
      async savepartner(id) {
        var clientId = window.atob(this.$route.params.id);
        if (!this.role || this.role === "") {
          return this.ShowWarningMessage("Please select any role.");
        }
        if (this.group.length == 0 || this.group == null || this.group == "") {
          return this.ShowWarningMessage("Please select atleast one groups.");
        }
  
        //copied function
  
        if (this.firstname.trim() === "" || this.firstname == null) {
          return this.ShowWarningMessage("Please enter your First Name.");
        }
  
        if (this.firstname.length > 20) {
          return this.ShowWarningMessage(
            "Please enter First Name upto 20 character."
          );
        }
        if (!validateName(this.firstname)) {
          return this.ShowWarningMessage("First Name must be alphabetic .");
        }
        if (this.lastname.length > 20) {
          return this.ShowWarningMessage(
            "Please enter Last Name upto 20 character."
          );
        }
  
        if (this.lastname.trim() === "" || this.lastname == null) {
          return this.ShowWarningMessage("Please enter your Last Name.");
        }
  
        if (!validateName(this.lastname)) {
          return this.ShowWarningMessage("Last Name must be alphabetic .");
        }
  
        // copied function end
  
        if (!validateEmail(this.email)) {
          return this.ShowWarningMessage("Please enter correct email.");
        }
  
        if (this.email.length > 50) {
          return this.ShowWarningMessage(
            "Work email should not exceed 50 characters."
          );
        }
        if(this.CountryName===''){
          return this.ShowWarningMessage("Please enter Country");
        }
        if(this.TimeZoneValue===''){
          return this.ShowWarningMessage("Please select prefered Time Zone");
        }
        const model = {
          Email: this.email,
          Role: this.role,
          FirstName: this.firstname,
          LastName: this.lastname,
          Regions: this.group,
          CountryCode :this.CountryName,
          Timezone :this.TimeZoneValue
        };
        // var clientId = window.atob(this.$route.params.id);

        if (id == null) {
          const createdUser = await this.AddUser({
            model,
            clientId: clientId,
          });
  
          if (createdUser.status) {
            await this.inviteUser({
              clientId: clientId,
              userId: createdUser?.object?.data?.id,
            });
            this.ResetForm();
            window.$("#AddNewUserModal").modal("hide");
            document.getElementById("close").click();
            await this.getAllUser();
          } else {
            window.$("#AddNewUserModal").modal("hide");
            document.getElementById("close").click();
          }
        } else {
          const updateUser = await this.UpdateUserData({
            userId: id,
            body: model,
          });
          if (updateUser.status) {
            this.ResetForm();
            document.getElementById("close").click();
            window.$("#AddNewUserModal").modal("hide");
            await this.getAllUser();
          }
        }
      },

      async UserInvite(id) {
        var clientId = window.atob(this.$route.params.id);
        await this.inviteUser({
          clientId: clientId,
          userId: id,
        });
      },

      async getAllUser() {
        //   //Get all Users
        this.UserPending = [];
        this.UserActive = [];
        this.UserDeactive = [];
        var id = window.atob(this.$route.params.id);
        var model = {
          status: this.userStatus,
          clientId: id,
          limit: this.limit,
          page: this.page,
        };
  
        if (this.filters.role.length > 0) {
          model.searchRole = this.filters.role.toString();
        }
        if (this.filters.groups.length > 0) {
          model.searchDept = this.filters.groups.toString();
        }
        if (this.filters.roleinput.trim() != "") {
          model.searchinput = this.filters.roleinput;
        }
  
        var users = await this.GetAllUsers(model);
        this.AllUserList = await users.object.data.users;
        this.totalUserCount = users.object.data.totalItems;
  
        this.page_count = await users.object.data.totalPages;
  
        if (users.object.data.totalItems == 0) {
          this.seen1 = false;
        }
        if (users.object.data.totalItems > 0) {
          this.seen1 = true;
        }
        if (this.AllUserList.length > 0) {
          this.AllUserList.forEach(async (user) => {
            var model = {
              userId: user.id,
            };
            var regions = await this.UserRegionData(model);
            if (regions?.status) {
              user.regiondata = await regions.object.data;
            } else {
              user.regiondata = [];
            }
          });
  
          if (this.userStatus == 1) {
            this.UserPending = this.AllUserList;
          }
          if (this.userStatus == 2) {
            this.UserDeactive = this.AllUserList;
          }
          if (this.userStatus == 4) {
            this.UserActive = this.AllUserList;
          }
        }
      },
  
      addFilter(value, e) {
        if (e.target.checked) {
          if (this.filterTab == 1) {
            this.filters.role.push(value);
          } else {
            this.filters.groups.push(value);
          }
          this.applyfilter =
            this.filters.role.length + this.filters.groups.length;
        } else {
          if (this.filterTab == 1) {
            this.filters.role.splice(
              Object.freeze(
                JSON.parse(JSON.stringify(this.filters?.role))
              ).indexOf(value),
              1
            );
          } else {
            this.filters.groups.splice(
              Object.freeze(
                JSON.parse(JSON.stringify(this.filters.groups))
              ).indexOf(value),
              1
            );
          }
          this.applyfilter =
            this.filters.role.length + this.filters.groups.length;
          if (this.applyfilter == 0) {
            this.getAllUser();
          }
        }
      },
      async clearFilters() {
        this.filters.role = [];
        this.filters.groups = [];
        this.applyfilter = 0;
        this.filterclose();
        await this.getAllUser();
      },
      async applyFilters() {
        this.page = 1;
        this.currentPage = 1;
        this.filterclose();
        await this.getAllUser();
      },
      Filterresult() {
        if (this.searchfilter != "") {
          if (this.filterTab == 1) {
            this.roleList = this.roleListFilter.filter((element) =>
              element.label
                .toLowerCase()
                .includes(this.searchfilter.toLowerCase())
            );
          } else {
            this.options = this.optionsFilter.filter((element) =>
              element.label
                .toLowerCase()
                .includes(this.searchfilter.toLowerCase())
            );
          }
        } else {
          this.roleList = this.roleListFilter;
          this.options = this.optionsFilter;
        }
      },
      filterclose() {
        document.getElementById("filterDropdown").click();
      },
    },
  };
  </script>
  <style src="@vueform/multiselect/themes/default.css"></style>
  
  <style scoped>
  .role_item_list_section .role_item_box {
    cursor: auto !important;
  }
  
  .multiselect-placeholder {
    color: var(--ms-placeholder-color, #000);
  }
  
  .namesp {
    display: block;
  }
  
  .nameInp {
    display: none;
  }
  
  .input-group-kj {
    position: absolute !important;
    top: -7px !important;
  }
  
  .page-item.disabled .page-link {
    background-color: #fff !important;
    border-color: #fff !important;
  }
  
  .pagination .page-item .page-link {
    width: 30px;
    height: 32px;
    background: transparent;
    display: flex;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    margin: 5px;
    color: #000 !important;
    border: none;
    cursor: pointer;
  }
  
  .pagination {
    justify-content: flex-end;
  }
  
  .pagination .page-item.active .page-link {
    font-weight: 600;
    background: #ffc801;
  }
  
  .pagination .page-item:last-child .page-link {
    background-color: transparent;
    border: none;
    width: 100% !important;
  }
  
  .pagination .page-item:first-child .page-link {
    background-color: transparent;
    border: none;
    width: 100% !important;
  }
  
  .pagination .page-item.disabled .page-link {
    color: rgb(130, 130, 130) !important;
    cursor: no-drop !important;
    background: #7fffd400 !important;
  }
  
  .progress {
    border-radius: 4.5rem !important;
    background-color: #e5e1fc !important;
    /* opacity: 0.2; */
  }
  
  .progress-bar {
    background-color: #7c68ee !important;
  }
  
  .progress-bar-striped {
    background-image: none !important;
  }
  
  .progress-check {
    color: #7c68ee;
  }
  
  .progress-close {
    color: #666666;
  }
  
  .btn-upload-kj {
    border-radius: 100px !important;
    padding: 10px 40px !important;
    font-size: 20px;
  }
  
  .btn-upload-border-kj {
    padding: 10px 40px !important;
    color: #7c68ee !important;
    border: 1px solid #7c68ee !important;
    border-radius: 100px !important;
    font-size: 20px;
  }
  
  .text-file-name {
    color: #babec7;
  }
  
  .customUploadBtn {
    background-color: #f2f0fd !important;
    color: #cec9ec !important;
    border: none !important;
    border-radius: 100px !important;
    padding: 10px 40px !important;
  }
  @media (max-width: 767px){
    .top_heading_CreatePartner_btn {
      display: block;
      z-index: 1;
  }
  }
  
  </style>
  