<template>
  <div class="page-content">
    <section class="dashboard_profile_new">
      <div class="row justify-content-center">
        <div class="col-xl-12 col-lg-12">
          <div class="card text-left">
            <div class="card-body p-0">
              <ul class="nav nav-pills">
                <li class="active">
                  <a href="#Profile" @click="tab" data-toggle="tab">Profile</a>
                </li>
                <!-- <li>
                  <a href="#CompanyCustomization" @click="tab" data-toggle="tab"
                    >Company Customization</a
                  >
                </li> -->
                <li>
                  <a href="#ChangePassword" @click="tab" data-toggle="tab"
                    >Change Password</a
                  >
                </li>
                <li>
                  <a href="#2FA" data-toggle="tab" @click="tab">2FA</a>
                </li>
                <!-- <li>
                  <a href="#AccountRemoval" @click="tab" data-toggle="tab"
                    >Account Removal</a
                  >
                </li> -->
              </ul>
              <div class="tab-content clearfix">
                <div class="tab-pane active" id="Profile">
                  <form class="my-4">
                    <div class="Profile-img-area">
                      <div class="Profile-img">
                        <img
                          :src="url"
                          class="profile-pic"
                          alt="profile-pic"
                          v-if="url"
                        />
                        <a
                          class="profile-pic profile-char"
                          alt="profile-pic "
                          v-else
                        >
                          {{ firstName.charAt(0) + "" + lastName.charAt(0) }}
                        </a>
                        <div class="Profile-img-edit">
                          <input
                            type="file"
                            name=""
                            id="file-input_icon1"
                            class="file-upload"
                            accept="image/*"
                            @change="onSelectFile($event)"
                          />
                          <label style="cursor: pointer" for="file-input_icon1">
                            <span class="fa-solid fa-camera upload-button">
                            </span
                          ></label>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-5">
                      <div class="col-xl-5 col-lg-5 col-md-6">
                        <div class="form-group mb-4">
                          <label class="input-hero" for="Firstname">
                            <input
                              type="text"
                              class="input_area"
                              v-model="firstName"
                              id="firstName"
                              required
                              name=""
                            />

                            <span class="input-name"> First name </span>
                          </label>
                        </div>
                      </div>
                      <div class="col-xl-5 col-lg-5 col-md-6">
                        <div class="form-group mb-4">
                          <label class="input-hero" for="Lastname">
                            <input
                              type="text"
                              class="input_area"
                              v-model="lastName"
                              id="lastName"
                              name=""
                            />
                            <span class="input-name"> Last name </span>
                          </label>
                        </div>
                      </div>
                      <div class="col-xl-5 col-lg-5 col-md-6">
                        <div class="form-group mb-4">
                          <label class="input-hero" for="Emailaddress">
                            <input
                              disabled
                              type="email"
                              name=""
                              class="input_area1"
                              v-model="email"
                              id="email"
                            />
                            <span class="input-name"> Email address </span>
                          </label>
                        </div>
                      </div>
                      <div class="col-xl-5 col-lg-5 col-md-6">

                        <div class="form-group mb-4">
                          <label class="input-hero" for="MobileNo">
                            <div class="input-group mb-3">
                              <span
                                class="input-group-text bg-white"
                                id="basic-addon1"
                                style="width: 35%"
                              >
                                <div class="dropdown county_code_flag ps-2">
                                  <img
                                    width="30px"
                                    height="20px"
                                    alt=""
                                    class="flagsImg"
                                    v-if="flagurl"
                                    v-bind:src="flagurl"
                                  />
                                  <input
                                    class="dropdown-toggle input-foues-none-kj"
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    type="text"
                                    v-model="countryCode"
                                    @input="searchFlag($event)"
                                  />

                                  <ul
                                    class="dropdown-menu county_code_flag_dropdown_Option"
                                    aria-labelledby="dropdownMenuButton1"
                                  >
                                    <li
                                      v-for="countryCode in countries"
                                      :key="countryCode.id"
                                      :value="countryCode.phonecode"
                                      class="dropdown-item"
                                      href="#"
                                      @click="
                                        changeflag(
                                          countryCode.iso2,
                                          countryCode.phonecode
                                        )
                                      "
                                    >
                                      <a>
                                        <img
                                          class="flagsImg"
                                          v-bind:src="[
                                            GetFlagCode(countryCode.iso2),
                                          ]"
                                          width="30px"
                                          height="20px"
                                          alt=""
                                        />
                                        {{ countryCode.phonecode }}({{
                                          countryCode.iso2
                                        }})</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </span>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="mobile number"
                                v-model="phoneNumber"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                              />
                            </div>
                            <!-- <div class="input_area p-0">
                              <div class="input-group">
                                <div class="dropdown county_code_flag ps-2">
                                  <img
                                    width="30px"
                                    height="20px"
                                    alt=""
                                    class="flagsImg"
                                    v-if="flagurl"
                                    v-bind:src="flagurl"
                                  />
                                  <input
                                    class="btn btn-white dropdown-toggle flag_dorpdown_box_hp"
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    type="text"
                                    v-model="countryCode"
                                    @input="searchFlag($event)"
                                  />
                                  
                                  <ul
                                    class="dropdown-menu county_code_flag_dropdown_Option"
                                    aria-labelledby="dropdownMenuButton1"
                                  >
                                    <li
                                      v-for="countryCode in countries"
                                      :key="countryCode.id"
                                      :value="countryCode.phonecode"
                                      class="dropdown-item"
                                      href="#"
                                      @click="
                                        changeflag(
                                          countryCode.iso2,
                                          countryCode.phonecode
                                        )
                                      "
                                    >
                                      <a>
                                        <img
                                          class="flagsImg"
                                          v-bind:src="[
                                            GetFlagCode(countryCode.iso2),
                                          ]"
                                          width="30px"
                                          height="20px"
                                          alt=""
                                        />
                                        {{ countryCode.phonecode }}({{
                                          countryCode.iso2
                                        }})</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                                <input
                                  type="text"
                                  aria-label="mobile number"
                                  class="form-control"
                                  v-model="phoneNumber"
                                  style="height: 37px; border: 0"
                                />
                              </div>
                            </div> -->
                            <span class="input-name label-for-moblie-number">
                              Mobile No.
                            </span>
                          </label>
                        </div>
                      </div>
                      <div class="col-xl-5 col-lg-5 col-md-6">
                        <div class="form-group mb-4">
                          <label class="input-hero" for="Country">
                            <select
                              class="input_area form-select"
                              v-model="count"
                              @change="getTimeZones"
                            >
                              <option selected disabled>
                                Select Country Code
                              </option>
                              <option
                                v-for="countryCode in countries"
                                :key="countryCode.id"
                                :value="countryCode.iso2"
                              >
                                {{ countryCode.name }}
                              </option>
                            </select>
                            <span class="input-name"> Country </span>
                          </label>
                        </div>
                      </div>
                      <div class="col-xl-5 col-lg-5 col-md-6">
                        <div class="form-group mb-4">
                          <label class="input-hero" for="Country">
                            <select
                              class="input_area form-select"
                              v-model="this.TimeZoneValue">
                              <option selected disabled>
                                Select Preferred Time Zone
                              </option>
                              <option
                                v-for="item in GetTimeZoneData.object.data"
                                :key="item"
                                :value="item.gmtOffsetName"
                              >{{ item.zoneName }} {{ item.gmtOffsetName }}</option>
                            </select>
                            <span class="input-name">Time Zone</span>
                          </label>
                        </div>
                      </div>
                      <!-- <div class="col-xl-5 col-lg-5 col-md-6">
                        <div class="form-group mb-4">
                          <label class="input-hero" for="City">
                            <span class="input-name"> City </span>
                            <Multiselect class="input_area multiselect-clear" :searchable="true" :options="options"
                              v-model="city" :clear-on-select="false" />
                          </label>
                        </div>
                      </div> -->
                    </div>

                    <div class="my-5 login_button_area">
                      <button
                        class="btn btn-primary"
                        @click="Save"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#ChangesConfirmation"
                      >
                        Save changes
                      </button>
                    </div>
                  </form>
                </div>
                <div
                  class="modal fade Deactivate_Reactivate_Modal"
                  id="ChangesConfirmation"
                  tabindex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div
                    class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
                    v-show="showConfirmationModal"
                  >
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">
                          Date and Time updates will be reflected onces you
                          re-Login the session
                        </h5>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>

                      <div class="modal-footer">
                        <div class="profile_modal">
                          <button
                            type="button"
                            class="btn btn-primary"
                            data-bs-dismiss="modal"
                          >
                            Okay
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="tab-pane" id="CompanyCustomization">
                  <form class="my-4">
                    <h4 for="" class="font-weight-600 mb-3">Company Logo</h4>
                    <div class="profile_img_new">
                      <img
                        class="profile-pic"
                        src="../assets/Images/profile_pic.png"
                      />
                      <div class="Upload_profile_img_new">
                        <input type="file" class="file-upload" />
                        <i class="fa-solid fa-camera upload-button"></i>
                      </div>
                    </div>
                    <div style="clear: both"></div>

                    <div class="row mt-5">
                      <div class="col-xl-5 col-lg-5 col-md-6">
                        <label class="font-weight-600 mb-3"
                          >Company Details</label
                        >
                        <div class="form-group mb-4">
                          <label class="input-hero" for="Firstname">
                            <input
                              type="text"
                              name=""
                              class="input_area"
                              v-model="companyName"
                            />
                            <span class="input-name"> Company name </span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div class="my-5 login_button_area">
                      <button
                        class="btn btn-primary"
                        type="submit"
                        disabled
                        @click="Company"
                      >
                        Save changes
                      </button>
                    </div>
                  </form>
                </div>

                <div class="tab-pane" id="ChangePassword">
                  <form class="my-4">
                    <div class="row mt-5">
                      <div class="col-xl-5 col-lg-5 col-md-6">
                        <h4 for="" class="font-weight-600 mb-3">
                          Change password
                        </h4>
                        <div class="form-group mt-5">
                          <label
                            v-bind:class="[
                              IsValidOldPassword
                                ? 'input-hero'
                                : ' input-hero input_error',
                            ]"
                            for="OldPassword"
                          >
                            <input
                              v-bind:type="[toggle1 ? 'text' : 'password']"
                              class="input_area"
                              name=""
                              id="Oldpassword"
                              v-model="Oldpassword"
                              @change="
                                ValidationOnOldPasswordChange(
                                  $event.target.value
                                )
                              "
                            />
                            <span class="input-name"> Old Password </span>
                            <span
                              class="toggle-password"
                              @click="toggle1 = !toggle1"
                            >
                              <i
                                class="far fa-eye 1"
                                :class="[toggle1 ? 'fa-eye' : 'fa-eye-slash']"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </label>
                          <span
                            v-if="ErrorOldPasswordMessage"
                            class="Error_Message"
                            ><i class="fa-solid fa-circle-exclamation me-1"></i>
                            {{ ErrorOldPasswordMessage }}</span
                          >
                        </div>
                        <div class="form-group mt-5">
                          <label
                            v-bind:class="[
                              IsValidNewPassword
                                ? 'input-hero'
                                : ' input-hero input_error',
                            ]"
                            for="NewPassword"
                          >
                            <input
                              v-bind:type="[toggle2 ? 'text' : 'password']"
                              name=""
                              class="input_area"
                              id="Newpassword"
                              v-model="Newpassword"
                              @change="
                                ValidationOnNewPasswordChange(
                                  $event.target.value
                                )
                              "
                            />
                            <span class="input-name"> New Password </span>
                            <span
                              class="toggle-password"
                              @click="toggle2 = !toggle2"
                            >
                              <i
                                class="far fa-eye 2"
                                :class="[toggle2 ? 'fa-eye' : 'fa-eye-slash']"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </label>
                          <span
                            v-if="ErrorNewPasswordMessage"
                            class="Error_Message"
                            ><i class="fa-solid fa-circle-exclamation me-1"></i>
                            {{ ErrorNewPasswordMessage }}</span
                          >
                        </div>
                        <div class="form-group mt-5">
                          <label
                            v-bind:class="[
                              IsValidConfirmPassword
                                ? 'input-hero'
                                : 'input-hero input_error',
                            ]"
                            for="ConfirmPassword"
                          >
                            <input
                              v-bind:type="[toggle3 ? 'text' : 'password']"
                              name=""
                              class="input_area"
                              id="Confirmnewpassword"
                              v-model="Confirmnewpassword"
                              @change="
                                ValidationOnConfirmPassordChange(
                                  $event.target.value
                                )
                              "
                            />
                            <span class="input-name"> Confirm Password </span>
                            <span
                              class="toggle-password"
                              @click="toggle3 = !toggle3"
                            >
                              <i
                                class="far fa-eye 3"
                                :class="[toggle3 ? 'fa-eye' : 'fa-eye-slash']"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </label>
                          <span
                            v-if="ErrorNewConfirmPasswordMessage"
                            class="Error_Message"
                            ><i class="fa-solid fa-circle-exclamation me-1"></i>
                            {{ ErrorNewConfirmPasswordMessage }}</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="my-5 login_button_area">
                      <button
                        class="btn btn-primary"
                        @click="submitPassword"
                        type="button"
                      >
                        Save changes
                      </button>
                    </div>
                  </form>
                </div>

                <div class="tab-pane" id="2FA">
                  <div class="my-4">
                    <div class="row mt-5">
                      <div class="col-xl-5 col-lg-5 col-md-6">
                        <h4 for="" class="font-weight-600 mb-3">
                          Two-Factor Authentication
                        </h4>
                        <p class="font-size-14">
                          Two-Factor is a good way to add an extra layer of
                          security to your account to make sure that only you
                          have the ability to log in.
                        </p>
                        <div class="form-group mt-5">
                          <label for="">Choose your method:</label>
                          <select name="" id="" class="form-select mt-2">
                            <!-- <option value="">Phone number (SMS)</option>
                            <option value="">Authenticator app</option> -->
                            <option value="">Email</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="my-5 login_button_area">
                      <button
                        type="button"
                        class="btn btn-primary button"
                        @click="enabled($event)"
                        id="ArchieveTest"
                        :hidden="hidden"
                        :v-model="checked"
                      >
                        {{ checkboxVal }}
                      </button>
                    </div>
                  </div>
                </div>

                <div class="tab-pane" id="AccountRemoval">
                  <p class="my-4">
                    If you close your account you and your team will not be able
                    to access any of your work and boards. To validate this
                    action please contact our customer success team.
                  </p>

                  <div class="AccountRemoval_img_area my-4"></div>

                  <div class="my-5 login_button_area">
                    <button class="btn btn-primary">Contact support</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { validateEmail } from "@/utils";
import { validateName } from "../utils";
export default {
  components: {},
  name: "Profile",
  data() {
    return {
      option: null,
      options: ["Maharashtra", "Mumbai", "Malegaon"],
      toggle1: false,
      toggle2: false,
      toggle3: false,
      data: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      Oldpassword: "",
      Newpassword: "",
      Confirmnewpassword: "",
      successMsg: "",
      url: "",
      flagurl: "",
      countryCode: "",
      countries: "",
      userProfileImage: "",
      checked: "",
      checkboxVal: "",
      IsValidNewPassword: true,
      IsValidOldPassword: true,
      IsValidConfirmPassword: true,
      ErrorNewPasswordMessage: "",
      ErrorOldPasswordMessage: "",
      ErrorNewConfirmPasswordMessage: "",
      Passwordpattern: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
      companyName: "",
      SelectedFlag: "",
      SelectedFlagCountryCode: 0,
      fileByte: "",
      fileExtension: "",
      country: "",
      count: "",
      profilePhoto: "",
      picList: "",
      countryFilter: "",
      GetTimeZoneData:{
        object: {
          data: []
        }
      },
      CountryName:"",
      TimeZoneValue:'',
      showConfirmationModal: false,
    };
  },

  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticate,
      user: (state) => state.auth.user,
      userId: (state) => state.auth.user.userId,
    }),
  },

  async mounted() {
    var pic = await this.profilePic({
      userId: this.userId,
    });
    this.url = pic.object.data.profilePic;
    if (this.url.includes("https://")) {
      this.profilePhoto = false;
    }
    var count = await this.getlocation();
    if (count.status) {
      this.countries = count.object.data;
      this.countries.forEach((element) => {
        element.phonecode = element.phonecode.slice(0, 6);
      });
      this.countryFilter = count.object.data;
      // var lres = this.countries.find((ff) => ff.name == "India");
      //var flagobj = Object.freeze(JSON.parse(JSON.stringify(lres)));
      // if (flagobj.iso2) {
      //   this.changeflag(flagobj.iso2, flagobj.phonecode);
      // }
    }
    this.isLoading = true;
    var response = await this.GetUserInfo({ userId: this.user.userId });
    var status = await this.GetUserStatus({ userId: this.user.userId });
    this.checked = status.object.data.status;
    //update
    this.checkboxVal =
      status.object.data.status == false ? "Enable" : "Disable";
    //update
    if (response != undefined && response.status) {
      this.firstName = response.object.data.firstName;
      this.lastName = response.object.data.lastName;
      this.email = response.object.data.email;
      this.phoneNumber = response.object.data.phoneNumber;
      this.count = response.object.data.country;
      // this.TimeZoneValue=response.object.data.timezone;
      
      if (this.count != null) {
        var flagobj = Object.freeze(
          JSON.parse(
            JSON.stringify(this.countries.filter((x) => x.iso2 == this.count))
          )
        );
        this.changeflag(this.count, flagobj[0].phonecode);
      }
    
      return;
    }

    this.isLoading = false;
  },
  async created() {
    this.isLoading = true;
    var response = await this.GetUserInfo({ userId: this.userId });
    if (response != undefined && response.status) {
        this.firstName = response.object.data.firstName;
        this.lastName = response.object.data.lastName;
        this.email = response.object.data.email;
        this.phoneNumber = response.object.data.phoneNumber;
        this.count= response.object.data.country;
        var timezone = response.object.data.timezone;

      if (this.count != null) {
        this.GetTimeZoneData = await this.getTimeZone({
          countryCode: this.count
        });
      }
      if (timezone != null) {
        var selectedTimezone = this.GetTimeZoneData.object.data.find(zone => zone.gmtOffsetName === timezone);
        this.TimeZoneValue = selectedTimezone.gmtOffsetName;
      }
        // this.changeflag(this.count, this.countries.filter(x=> x.iso2 == this.count).phonecode);

        return;
      }
    this.isLoading = false;
  },
  methods: {
    ...mapActions([
      "TwoFactorAuth",
      "GetUserInfo",
      "UpdateUserInfo",
      "GetUserStatus",
      "ChangeUserPassword",
      "getlocation",
      "logout",
      "ShowWarningMessage",
      "updateProfilePic",
      "profilePic",
      "getTimeZone"
    ]),
    ...mapMutations(["setLoading", "setErrorPopup"]),
    async searchFlag(event) {
      if (event.target.value == "") this.flagurl = "";
      this.countries = this.countryFilter.filter((x) =>
        x.iso2.toLowerCase().includes(event.target.value.toLowerCase())
      );
    },
    tab() {
      this.$emit("tab");
    },
    async getTimeZones() {
      this.GetTimeZoneData = await this.getTimeZone({
        countryCode: this.count
      });
    },

    async enabled(event) {
      var operation = "";
      //update
      if (event.target.innerHTML == "Disable") {
        operation = "disable";
      } else {
        operation = "enable";
      }
      this.isLoading = true;
      var result = await this.TwoFactorAuth({
        userId: this.userId,
        operation: operation,
      });
      if (result.status) {
        //update
        this.checkboxVal = operation == "enable" ? "Disable" : "Enable";
      }
    },
    async Save() {
      this.isLoading = true;

      if (!this.firstName || this.firstName.trim() === "") {
        return this.ShowWarningMessage("Please enter First Name.");
      }
      if (!validateName(this.firstName)) {
        return this.ShowWarningMessage("First Name must be alphabetic .");
      }
      if (!this.lastName || this.lastName.trim() === "") {
        return this.ShowWarningMessage("Please enter Last Name.");
      }
      if (!validateName(this.lastName)) {
        return this.ShowWarningMessage("last Name must be alphabetic .");
      }
      if (!validateEmail(this.email)) {
        return this.ShowWarningMessage("Please enter correct Email.");
      }
      if (!this.phoneNumber || this.phoneNumber.trim() === "") {
        return this.ShowWarningMessage("Please enter mobile Number.");
      }
      if (!this.count || this.count === "") {
        return this.ShowWarningMessage("Please select your country.");
      }
      if (!this.TimeZoneValue || this.TimeZoneValue === "") {
        return this.ShowWarningMessage("Please select required timezone.");
      }
      // if (!this.city || this.city === "") {
      //   return this.ShowWarningMessage("Please select your city.");
      // }
      this.isLoading = true;
      const model = {
        method: "PUT",
        params: {
          userId: this.userId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.user.token,
        },
        body: JSON.stringify({
          firstName: this.firstName,
          lastName: this.lastName,
          phoneNumber: this.phoneNumber,
          country: this.count,
          timezone: this.TimeZoneValue
        }),
      };
      this.UpdateUserInfo(model);
      if (this.fileByte && this.fileExtension) {
        await this.updateProfilePic({
          userId: this.userId,
          FileBytes: this.fileByte,
          FileExtension: this.fileExtension,
        });
      }

      this.isLoading = false;
      this.showConfirmationModal = true; 

    },

    async submitPassword() {
      this.isLoading = true;
      this.ValidationOnOldPasswordChange(this.Oldpassword);
      this.ValidationOnNewPasswordChange(this.Newpassword);
      this.ValidationOnConfirmPassordChange(this.Confirmnewpassword);
      if (this.Oldpassword != this.Newpassword) {
        if (this.IsValidNewPassword && this.IsValidConfirmPassword) {
          this.isLoading = true;
          const Passmodel = {
            method: "PUT",
            params: {
              userId: this.userId,
            },
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.user.token,
            },
            body: JSON.stringify({
              CurrentPassword: this.Oldpassword,
              NewPassword: this.Newpassword,
            }),
          };
          this.ChangeUserPassword(Passmodel).then(async (res) => {
            if (res.status) {
              this.Confirmnewpassword =
                this.Newpassword =
                this.Oldpassword =
                  "";

              // await this.logout({
              //   userId: this.user.userId,
              // });
            }
          });
        }
      }
    },
    ValidationOnOldPasswordChange(value) {
      var password = value.trim();
      if (!password) {
        this.setErrorPopup({
          bool: true,
          message: "Please enter old password!",
        });
        this.ErrorOldPasswordMessage = "Please enter old password!";
        this.IsValidOldPassword = false;
      } else {
        if (this.Oldpassword == this.Newpassword) {
          this.setErrorPopup({
            bool: true,
            message: "Old password and new password should not be same.",
          });
          this.ErrorOldPasswordMessage =
            "Old password and new password should not be same.";
          this.IsValidOldPassword = false;
        } else {
          this.ErrorOldPasswordMessage = "";
          this.IsValidOldPassword = true;
        }
      }
    },

    ValidationOnNewPasswordChange(value) {
      var password = value.trim();
      if (!password) {
        this.ErrorNewPasswordMessage = "Please enter new password!";
        this.IsValidNewPassword = false;
      } else if (!password.match(this.Passwordpattern)) {
        this.ErrorNewPasswordMessage =
          "Password must be contain at least of 8 character, one Uppercase, one Lowercase and one Special Character.";
        this.IsValidNewPassword = false;
      } else if (password != this.Newpassword) {
        this.ErrorNewConfirmPasswordMessage =
          "The confirm password did not match the new password. Please enter matching passwords in both fields.";
        this.IsValidConfirmPassword = false;
      } else {
        this.ErrorNewPasswordMessage = "";
        this.IsValidNewPassword = true;
      }
    },

    ValidationOnConfirmPassordChange(value) {
      var password = value.trim();
      if (!password) {
        this.ErrorNewConfirmPasswordMessage = "Please enter confirm password!";
        this.IsValidConfirmPassword = false;
      } else if (password != this.Newpassword) {
        this.ErrorNewConfirmPasswordMessage =
          "The confirm password did not match the new password. Please enter matching passwords in both fields.";
        this.IsValidConfirmPassword = false;
      } else {
        this.ErrorNewConfirmPasswordMessage = "";
        this.IsValidConfirmPassword = true;
      }
    },

    //******************** To be checked in next Milestone *********//
    // Company() {
    //   const userAuth = getAuthUserData();
    //   this.isLoading = true;

    //   fetch(
    //     "https://web-api.pmapstest.in/v1/accounts/profile/company/" +
    //       userAuth.userId,
    //     {
    //       method: "PUT",
    //       headers: { "Content-Type": "application/json" },
    //       body: JSON.stringify({
    //         fileBytes: this.fileBytes,
    //         fileExtension: this.fileExtension,
    //         companyColor: this.companyColor
    //       })
    //     }
    //   )
    //     .then(response => response.json())
    //     .then(data => {
    //       /*eslint-disable*/
    //       debugger;
    //       if (data.statusCode) {url
    //         this.isLoading = false;
    //         this.$toast.show(data.msg.message, {
    //           type: "warning",
    //           position: "top-right",
    //           duration: 3000
    //         });
    //         return;
    //       }
    //     });
    // },
    //******************** To be checked in next Milestone *********//

    onSelectFile(event) {
      this.url = "";
      if (event.target.files && event.target.files[0]) {
        this.userProfileImage = event.target.files[0];

        this.fileExtension = this.userProfileImage.name
          .split(".")[1]
          .toLowerCase();
        if (
          this.fileExtension == "jpeg" ||
          this.fileExtension == "jpg" ||
          this.fileExtension == "png" ||
          this.fileExtension == "svg" ||
          this.fileExtension == "webp"
        ) {
          var reader = new FileReader();
          reader.readAsDataURL(event.target.files[0]);
          reader.onload = (event) => {
            this.url = event.target.result;
            this.fileByte = this.url.split(",").pop();
          };
        } else {
          this.ShowWarningMessage("please select only image Format");
          return false;
        }
        var fileSize = this.userProfileImage.size;
        if (fileSize >= 2097152) {
          this.ShowWarningMessage("please select image below 2 mb");
          return false;
        }
      }
    },
    GetFlagCode(value) {
      if (value) {
        var FlagName = value.toLowerCase().trim();
        return "../assets/Images/CounrtyFlags/" + FlagName + ".svg";
      }
      return "";
    },

    changeflag(flagiso, countryCode) {
      if (flagiso && countryCode) {
        var FlagName = flagiso.toLowerCase().trim();
        this.flagurl = "../assets/Images/CounrtyFlags/" + FlagName + ".svg";
        this.countryCode = countryCode + "(" + flagiso + ")";
      }
    },

    //******************** To be checked in next Milestone *********//
    // SelectFile(event) {
    //   this.companyUrl = "";
    //   if (event.target.files && event.target.files[0]) {
    //     this.companyLogo = event.target.files[0];
    //     var extension = this.companyLogo.name.split(".")[1].toLowerCase();
    //     if (extension == "jpeg" || extension == "jpg" || extension == "png") {
    //       var reader = new FileReader();
    //       reader.readAsDataURL(event.target.files[0]);
    //       reader.onload = event => {
    //         this.companyUrl = event.target.result;
    //         this.fileExtension = extension;
    //       };
    //     } else {
    //       this.tostrservice.showWarning("Warning", "please select image Format");
    //     }
    //   }
    // }
    //******************** To be checked in next Milestone *********//
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.input_area1 {
  width: 100%;
  height: 100%;
  display: block;
  top: 0;
  left: 0;
  position: absolute;
  font-weight: normal;
  background: #f1f1f1;
  font-size: 16px;
  border-radius: 4px;
  border: 0.125rem solid #666666;
  padding: 0 0.7rem;
  transition: all 0.25s ease 0s;
}

.profile-char {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  background: #e2ddff;
  font-size: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flag_dorpdown_box_hp {
  min-width: 100px;
  text-align: center;
  border-right: 1px solid #c7c7c7;
}

.label-for-moblie-number {
  position: absolute;
  top: -11px;
}

/* 
.input-foues-none-kj :focus {
  border: 1px solid red;
}
.input-foues-none-kj :focus-visible {
  border: 1px solid red;
} */
.input-foues-none-kj {
  outline: none;
  border: none;
}

.border-profile-kj {
  border: 0.125rem solid #d4d4d4;
  border-radius: 8px;
}
.county_code_flag .county_code_flag_dropdown_Option {
  transform: translate(0px, 22px) !important;
}
</style>
