// // Show Password "OnClink"

// var ojoState = false;

// function toggle() {
//     if (ojoState) {
//         document.getElementById('inputPassword').
//         setAttribute('type', 'password');
//         document.getElementById('ojo').classList.remove('fa-eye-slash');
//         ojoState = false;
//     } else {
//         document.getElementById('inputPassword').
//         setAttribute('type', 'text');
//         document.getElementById('ojo').classList.add('fa-eye-slash');
//         ojoState = true;
//     }
// }

// // OTP CODE TIMER

// startTimer();

// function startTimer() {
//     var presentTime = document.getElementById('timer').innerHTML;
//     var timeArray = presentTime.split(/[:]+/);
//     var m = timeArray[0];
//     var s = checkSecond((timeArray[1] - 1));
//     if (s == 59) { m = m - 1 }
//     if ((m + '').length == 1) {
//         m = '0' + m;
//     }
//     if (m < 0) {
//         m = '59';
//     }
//     document.getElementById('timer').innerHTML = m + ":" + s;
//     setTimeout(startTimer, 1000);
// }

// function checkSecond(sec) {
//     if (sec < 10 && sec >= 0) { sec = "0" + sec }; // add zero in front of numbers < 10
//     if (sec < 0) { sec = "59" };
//     return sec;
// }

// // OTP CODE INPUR CHANGE

// let digitValidate = function(ele) {
//     console.log(ele.value);
//     ele.value = ele.value.replace(/[^0-9]/g, '');
// }

// let tabChange = function(val) {
//     let ele = document.querySelectorAll('input');
//     if (ele[val - 1].value != '') {
//         ele[val].focus()
//     } else if (ele[val - 1].value == '') {
//         ele[val - 2].focus()
//     }
// }


/*update*/

(function isLoggedout() {
  const idleDurationSecs = 3600;
  const redirectUrl = "/login";
  let idleTimeout;
  const resetIdleTimeout = () => {
    if (idleTimeout) clearTimeout(idleTimeout);
    idleTimeout = setTimeout(() => {
      localStorage.clear();
      location.href = redirectUrl;
    }, idleDurationSecs * 1000);
  };

//   // Key events for reset time
  resetIdleTimeout();
  window.onkeypress = resetIdleTimeout;
  window.click = resetIdleTimeout;
  window.onclick = resetIdleTimeout;
  window.touchstart = resetIdleTimeout;
  window.onfocus = resetIdleTimeout;
  window.onchange = resetIdleTimeout;
  window.onmousedown = resetIdleTimeout;
  window.onmouseup = resetIdleTimeout;
  window.onkeypress = resetIdleTimeout;
  window.onkeydown = resetIdleTimeout;
  window.onkeyup = resetIdleTimeout;
  window.onsubmit = resetIdleTimeout;
  window.onreset = resetIdleTimeout;
  window.onselect = resetIdleTimeout;
})();

/*update*/

// if (window.performance) {
//     var navEntries = window.performance.getEntriesByType('navigation');
//     if (navEntries.length > 0 && navEntries[0].type === 'back_forward') {
//          console.log('As per API lv2, this page is load from back/forward');
//     } else if (window.performance.navigation
//          && window.performance.navigation.type == window.performance.navigation.TYPE_BACK_FORWARD) {
//          console.log('As per API lv1, this page is load from back/forward');
//     } else {
//          console.log('This is normal page load');
//     }
// } else {
//     console.log("Unfortunately, your browser doesn't support this API");
// }

/*update*/

// window.history.pushState(null, null, window.location.href);
// window.onpopstate = function () {
//   window.history.go(1);
// };

/*update*/

// Upload profile Image
// $(document).ready(function () {
//     var readURL = function (input) {
//         if (input.files && input.files[0]) {
//             var reader = new FileReader();
//             reader.onload = function (e) {
//                 $('.profile-pic').attr('src', e.target.result);
//             }
//             reader.readAsDataURL(input.files[0]);
//         }
//     }
//     $(".file-upload").on('change', function () {
//         readURL(this);
//     });
//     $(".upload-button").on('click', function () {
//         $(".file-upload").click();
//     });
// });

// Password Show and Hide
// $(".toggle-password").click(function () {
//     $(this).toggleClass("fa-eye fa-eye-slash");
//     input = $(this).parent().find("input");
//     if (input.attr("type") == "password") {
//         input.attr("type", "text");
//     } else {
//         input.attr("type", "password");
//     }
// });

// Button disable
// $('#check-in').click(function (e) {
//     if ($('#check-in').is(":checked")) {
//         $("#submit-btn").removeAttr("disabled");
//         $('#submit-btn').addClass('btnactve');

//     } else {
//         $("#submit-btn").attr("disabled", "disabled");
//         $('#submit-btn').addClass('btn-accept');
//         $('#submit-btn').removeClass('btnactve');
//     }
// });

