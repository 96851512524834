<template>
  <div class="page-content">
    <div class="card p-0" style="border-radius: 15px;" v-if="this.show">
      <div class="table-responsive">
        <table class="table text-center">
          <thead>
            <tr>
              <th>
                <span style="display: flex; align-items: center; justify-content: center;">
                  <div style="color: #7c68ee; font-size:17px; display: block; margin-right: 10%;">Monthly</div>
                  <div class="form-check form-switch">
                    <input class="form-check-input custom-toggle" type="checkbox" id="flexSwitchCheckChecked" checked
                      v-model="planType">
                  </div>
                  <div style="color: #7c68ee; font-size:17px; display: block;margin-left: 4%; ">Yearly</div>
                </span>
                <span style="display: block; margin-top: 5%;">Per Organization/Month</span>
                <span
                  style="display: block; margin-top: 12%; color: #7c68ee;font-size:20px; font-weight: 600x;">Features</span>
              </th>

              <th v-for="plan in filteredPlans" :key="plan" >
  <span class="planheading">{{ plan.displayName }}</span>
  <span style="display: block; margin-top: 10%;">
      <router-link
  :to="{ name: 'Signup' }"
  class="UpgradeButton"
  type="button"
>
  <span>Take a Free Trial</span>
</router-link>

  </span>
  <span style="display: block; margin-top: 5%; color: #7c68ee; font-size:20px; font-weight: 600;">
    {{ this.fetchCurrency(plan.currencyCode) }} {{ plan.price }}
    <span style="font-size: 12px;">per Candidate</span>
  </span>
</th>

              <th>
                <span class="planheading">Enterprise</span>
                <span style="display: block; margin-top: 10%;">
               

                    <a :href="'https://calendly.com/nkamble/pmaps-quick-support-new-requirements'" type="button" class="UpgradeButton"
                    target="_blank" >
                    Connect Now
                  </a>

                

                </span>
                <span style="display: block;  margin-top: 7.5%; color: #a898ff; font-size:17.5px; font-weight: 600;">Custom
                  Plan</span>
              </th>
            </tr>
          </thead>

        <tbody v-for="module in PlanFeatures" :key="module.moduleName">
          <tr>
            <td style="font-size: 26px; text-align: left; font-weight: 600;">
              {{ module.moduleName }}
            </td>
            </tr>
            <tr v-for="feature in module.features" :key="feature.id" >
              <td style="text-align: left; width: 30%;">{{ feature.name }}</td>
              <td v-for="plan in feature.plans" :key="plan.planName" >
                              <span v-if="plan.value!=null">{{ plan.value}} {{ plan.unit }}</span>
                              <span v-else>
                                <i :class="plan.hasFeature ? 'fa-solid fa-circle-check fa-lg' : 'fa-regular fa-circle-xmark fa-lg'"
                                  style="color: #7c68ee;"></i>
                              </span>
                            </td>
              <td class="col-md-2"><span><i class="fa-solid fa-circle-check fa-lg"
                                  style="color: #7c68ee;"></i></span></td>

            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- <div class="text-center" v-if="PlanDetails == false">
      <img src="../../public/assets/Images/Technical-Issue.png" style="width: 20%" class="my-5" />
      <h3 class="ms-5 mt-2">No Records Found</h3>
    </div> -->

    <!-- <tr>
                                  <td style="font-weight: 500; font-size:15px; text-align: left !important;">{{ item.name }}</td>
                                  <td v-for="plan in item.plans" :key="plan">
                                             <span>
                                        <i :class="plan.hasFeature ? 'fa-solid fa-circle-check fa-lg' : 'fa-regular fa-circle-xmark fa-lg'" style="color: #7c68ee;"></i>
                                         </span>

                                     </td>
                                  <td><span><i class="fa-solid fa-circle-check fa-lg" style="color: #7c68ee;"></i></span></td>

                           
                                  </tr> -->


  </div>
</template>
  
  <script>
  import { mapActions, mapMutations, mapState } from "vuex";
  
  export default {
    name: "BillingPlanGlobal",
    data() {
      return {
        PlanFeatures: [],
        allFeatures:[] ,
        PlanDetails:[],
        planData:[],
        planType:false,
        show:false,
        data:[]
  
      };
    },
    async created() {
    await this.planContent();
    },
  
    computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    ...mapState('features', {
      allFeatures: (state) => state.features.features,
    }),
    filteredPlans() {
      let type = this.planType ? 'Yearly' : 'Monthly';
      return this.PlanDetails.filter((plan) => plan.type === type);
    },
  },
  
   
    methods: {
      ...mapActions(['globalPlatformFeatures']),
      ...mapMutations(["setLoading"]),
  
      async planContent(){
      this.planData = await this.globalPlatformFeatures({
        currency:"INR"
      });
      // console.log(this.planData);
      this.PlanFeatures = this.planData.object.data.features;
      this.PlanDetails=this.planData.object.data.allPlans;
      this.show=true;
      },

      fetchCurrency(data) {
        if (data == 'INR') {
          return '₹';
        } else {
          return '$';
        }
      },
  
    },
  };
  </script>
  <style scoped>
  
  .table-responsive {
    height: 710px; 
    overflow-y: auto;
    display: block;  
  }
  .table-responsive thead th {
    position: sticky;
    top: 0;
    background-color: #f2f0ff; 
    z-index: 2;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  }
  
  .UpgradeButton{
  border-radius: 5px !important;
  border: 1px solid #7c68ee !important;
  background: #fff !important;
  color: #7c68ee !important;
  margin: 0px 6px 0 0;
  width: 180px;
  height: 35px;
  font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;

  }
  .UpgradeButton:hover{
  background: #7c68ee !important;
  color: #ffffff !important;
  margin: 0px 6px 0 0;
  }
  .planheading{
  color: #7c68ee; font-size:25px; font-weight: 600;display: block
  }
  
  .custom-toggle{
  padding: 10px 25px;
    font-size: 20px;
  }
  
  .custom-toggle:checked{
  background-color: #7c68ee!important; 
  
  }
  .table thead th {
    border-top: 0;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    color: #7987a1;
}
  
  </style>
  

   