<template>
    <div class="Overview_dashboard_map_section my-4">
        <div class="card p-2">
            <div class="card-body p-0" id="test-construct-chart">
                <h3 style="font-weight: 600;" class="mb-5 mt-3 mx-3">Assessment Test Structure
                    <!-- <img src="../../../../public/dashboard-assets/images/M7/Actions.png" width="25"
                        class="cursor-pointer ms-2" alt="" data-bs-toggle="tooltip" data-bs-placement="top"
                        title=" Analyzing the frequency of assessment usage over time provides valuable insights into the number of candidates assessed during defined intervals." /> -->
                </h3>
                <div class="table-responsive mb-4" style="width: 100%; min-height: 250px; max-height: 500px;">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Test Section</th>
                                <th>Questions</th>
                                <th>Time Allocated</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in this.assessmentData" :key="item">
                                <td> {{ item.sectionName }}</td>
                      <td>{{ item.noOfQuestion }}</td>
                                       <td>
                        {{ Math.round(convertTimeToMinutes(item.sectionTime)) }} min
                      </td>
                    </tr>
                    <tr style="background-color: aliceblue; font-weight: 600;">
                        <td>
                            Total
                        </td>
                        <td>{{ totalQuestions }}</td>
                        <td>{{ totalDuration() }} min</td>
                    </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
// import {  mapMutations } from "vuex";
import moment from "moment";
import { Tooltip } from "bootstrap/dist/js/bootstrap.esm.min.js";

export default {
    name: "TableTestAttemptAnalysis",
    props: {
        assessmentData: Array
    },
    data() {
        return {
            processedData: []
        };
    },
 

    async mounted() {
    
        Array.from(
            document.querySelectorAll('img[data-bs-toggle="tooltip"]')
        ).forEach((tooltipNode) => new Tooltip(tooltipNode));

        await this.totalDuration();
    },
    methods: {
        // ...mapMutations(["setLoading"]),
        dateConvert: function (value) {
            return moment(String(value)).format("DD/MM/YYYY");
        },
        convertTimeToMinutes(timeString) {
            const duration = moment.duration(timeString);
            return duration.asMinutes();
        }, 
    // formatSectionTime(timeString) {
    //   const duration = moment.duration(timeString);
    //   return `${duration.hours() * 60 + duration.minutes()} min`;
    // },
   totalDuration() {
    return Math.round(this.assessmentData.reduce((sum, item) => sum + this.convertTimeToMinutes(item.sectionTime), 0));
  }
    },
    computed: {
        totalQuestions() {
      return this.assessmentData.reduce((sum, item) => sum + item.noOfQuestion, 0);
    },
  },
};
</script>

<style scoped>
.table-responsive thead th {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 2;
}
.table-header {
  text-align: center;
  margin-bottom: 10px;
}

.table-title {
  font-size: 18px;
  font-weight: bold;
}

</style>