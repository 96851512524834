<template>
    <!-- Header Start with Name, Date, Graph Table button -->
    <div class="d-flex flex-column flex-md-row justify-content-between align-items-start">
        <div class="mb-3 p-4">
            <h3 style="font-weight: 500;">Recent Invited Candidates</h3>
            <h6 class="mt-1" style="color: grey;">{{ this.displayDate }}</h6>
        </div>
        <!-- <div class="d-flex flex-row mb-3 p-4" style="align-items: center;">
            <div class="primary-button">Graph</div>
            Table
        </div> -->
    </div>
    <!-- Header End with Name, Date, Graph Table button -->
    <div id="stackContainer" class="stack-container mt-2"></div>
    <!-- 3 Cards Layout Start -->

    <div class="container mt-3 p-4"
        v-if="this.clientRole.roleName == 'Owner' || this.clientRole.roleName == 'Admin' || this.clientRole.roleName == 'PMaps SuperAdmin'">
        <div class="row">
            <div class="col-12 col-md-4 mb-4">
                <div class="invite-card">
                    <div class="invite-card-body p-3">
                        <!-- <h5 class="invite-card-title">Card 1</h5> -->
                        <div ref="donutChartContainer" class="chart-container mt-2"></div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4 mb-4">
                <div class="invite-card">
                    <div class="invite-card-body p-3">
                        <h5 class="invite-card-title mt-3">Public Invite</h5>
                        <div class="mt-4">
                            <div class="progress">
                                <div class="progress-bar" role="progressbar"
                                    :style="{ width: widthPublicCompleted + '%', backgroundColor: CompletedColor }"
                                    :aria-valuenow="widthPublicCompleted" aria-valuemin="0" aria-valuemax="100"></div>
                                <div class="progress-bar" role="progressbar"
                                    :style="{ width: widthPublicInprogress + '%', backgroundColor: InprogressColor }"
                                    :aria-valuenow="widthPublicInprogress" aria-valuemin="0" aria-valuemax="100">
                                </div>
                                <div class="progress-bar" role="progressbar"
                                    :style="{ width: widthPublicPending + '%', backgroundColor: PendingColor }"
                                    :aria-valuenow="widthPublicPending" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div class="mt-5" style="display: flex; justify-content: space-between; align-items: center;">
                            <div style="display: flex; align-items: center;">
                                <div class="color-box" :style="{ backgroundColor: CompletedColor }"></div>
                                <div class="mx-2" style="font-size: large;">Completed</div>
                            </div>
                            <div style="font-weight: 600; font-size: large;">{{ widthPublicCompleted }}</div>
                        </div>

                        <div class="mt-2" style="display: flex; justify-content: space-between; align-items: center;">
                            <div style="display: flex; align-items: center;">
                                <div class="color-box" :style="{ backgroundColor: InprogressColor }"></div>
                                <div class="mx-2" style="font-size: large;">Inprogress</div>
                            </div>
                            <div style="font-weight: 600; font-size: large;">{{ widthPublicInprogress }}</div>
                        </div>

                        <div class="mt-2" style="display: flex; justify-content: space-between; align-items: center;">
                            <div style="display: flex; align-items: center;">
                                <div class="color-box" :style="{ backgroundColor: PendingColor }"></div>
                                <div class="mx-2" style="font-size: large;">Not Started (Pending)</div>
                            </div>
                            <div style="font-weight: 600; font-size: large;">{{ widthPublicPending }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4 mb-4">
                <div class="invite-card">
                    <div class="invite-card-body p-3">
                        <h5 class="invite-card-title mt-3">Private Invite</h5>
                        <div class="mt-4">
                            <div class="progress">
                                <div class="progress-bar" role="progressbar"
                                    :style="{ width: widthPrivateCompleted + '%', backgroundColor: CompletedColor }"
                                    :aria-valuenow="widthPrivateCompleted" aria-valuemin="0" aria-valuemax="100"></div>
                                <div class="progress-bar" role="progressbar"
                                    :style="{ width: widthPrivateInprogress + '%', backgroundColor: InprogressColor }"
                                    :aria-valuenow="widthPrivateInprogress" aria-valuemin="0" aria-valuemax="100">
                                </div>
                                <div class="progress-bar" role="progressbar"
                                    :style="{ width: widthPrivatePending + '%', backgroundColor: PendingColor }"
                                    :aria-valuenow="widthPrivatePending" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div class="mt-5" style="display: flex; justify-content: space-between; align-items: center;">
                            <div style="display: flex; align-items: center;">
                                <div class="color-box" :style="{ backgroundColor: CompletedColor }"></div>
                                <div class="mx-2" style="font-size: large;">Completed</div>
                            </div>
                            <div style="font-weight: 600; font-size: large;">{{ widthPrivateCompleted }}</div>
                        </div>

                        <div class="mt-2" style="display: flex; justify-content: space-between; align-items: center;">
                            <div style="display: flex; align-items: center;">
                                <div class="color-box" :style="{ backgroundColor: InprogressColor }"></div>
                                <div class="mx-2" style="font-size: large;">Inprogress</div>
                            </div>
                            <div style="font-weight: 600; font-size: large;">{{ widthPrivateInprogress }}</div>
                        </div>

                        <div class="mt-2" style="display: flex; justify-content: space-between; align-items: center;">
                            <div style="display: flex; align-items: center;">
                                <div class="color-box" :style="{ backgroundColor: PendingColor }"></div>
                                <div class="mx-2" style="font-size: large;">Not Started (Pending)</div>
                            </div>
                            <div style="font-weight: 600; font-size: large;">{{ widthPrivatePending }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- 3 Cards Layout End -->

    <div class="mt-3 p-4"
        v-if="this.clientRole.roleName == 'Owner' || this.clientRole.roleName == 'Admin' || this.clientRole.roleName == 'PMaps SuperAdmin'">
        <!-- <h3 class="py-4" style="font-weight: 600;">
            
        </h3> -->
        <h3 class="pt-4">Private Invite Insight</h3>
        <h6 class="mt-1" style="color: grey;">{{ this.displayDate }}</h6>
        <div class="table-responsive  mt-5" style="width: 100%; min-height: 250px; max-height: 500px;" v-if="userData.length > 0">
            <table class="table">
                <thead>
                    <tr>
                        <th>Sr No</th>
                        <th>User</th>
                        <th>Group</th>
                        <th>Candidate Invited</th>
                        <th>Test Completed</th>
                        <th>Pass Rate %</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in this.userData" :key="item">
                        <td> {{ ++index }}</td>
                        <td> {{ item.inviteFrom }}</td>
                        <td> {{ item.region }}</td>
                        <td> {{ item.invited }}</td>
                        <td> {{ item.completed }}</td>
                        <td> {{ item.passingRate }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else>
            <div class="text-center pt-5">
                <h4 class="ms-5 my-4 p-4" style="line-height: 35px;">The insights couldn't be generated because there wasn't enough private invites available.     </h4>
                </div>
        </div>
    </div>

</template>

<script>
import Highcharts from "highcharts";
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "InviteCandidateGraph",
    props: ['inviteData', 'start', 'end', 'userData'],
    data() {
        return {
            widthPrivateCompleted: 0,
            widthPrivateInprogress: 0,
            widthPrivatePending: 0,

            widthPublicCompleted: 0,
            widthPublicInprogress: 0,
            widthPublicPending: 0,

            CompletedColor: '#5E45EB',
            InprogressColor: '#FF549E',
            PendingColor: '#F7B200',

            publicInvite: 0,
            privateInvite: 0,
            displayDate: '',

            stackCategories: [],
            stackCompletedData: [],
            stackInProgressData: [],
            stackNotStartedData: [],
            clientRole:''
        };
    },

    async mounted() {
        var parent = localStorage.getItem("Userpermission");
        this.clientRole = JSON.parse(parent);

        this.displayDate = `${this.formatDate(this.start)} - ${this.formatDate(this.end)} `;

        await this.InviteDetails();
        await this.renderColumnChart();
        await this.renderChart();

       
    },
    computed: {
        ...mapState({
            user: (state) => state.auth.user,
        }),
    },
    methods: {
        ...mapActions(["GetInvitationAnalysis"]),

        formatDate(date) {
            return moment(date).format("DD MMM YYYY (ddd)");
        },
        async InviteDetails() {
            var listData = this.inviteData;
            this.widthPublicCompleted = listData.invitation_Model.publicCompleted;
            this.widthPublicInprogress = listData.invitation_Model.publicInprogress;
            this.widthPublicPending = listData.invitation_Model.publicPending;
            this.widthPrivateCompleted = listData.invitation_Model.privateCompleted;
            this.widthPrivateInprogress = listData.invitation_Model.privateInprogress;
            this.widthPrivatePending = listData.invitation_Model.privatePending;
            this.publicInvite = listData.invitation_Model.publicInvite;
            this.privateInvite = listData.invitation_Model.privateInvite;

            this.stackCategories = listData.regionWise_Invite_Model.map(item => item.region);
            this.stackCompletedData = listData.regionWise_Invite_Model.map(item => item.completed);
            this.stackInProgressData = listData.regionWise_Invite_Model.map(item => item.inProgress);
            this.stackNotStartedData = listData.regionWise_Invite_Model.map(item => item.notStarted);
        },
        renderChart() {
            Highcharts.chart(this.$refs.donutChartContainer, {
                colors: ['#5E45EB', '#01BAF2', '#71BF45', '#FAA74B', '#B37CD2'],
                chart: {
                    type: 'pie',
                    height: 220,
                },
                tooltip: {
                    formatter: function () {
                        const total = this.series.yData.reduce((a, b) => a + b, 0);
                        const percentage = ((this.y / total) * 100).toFixed(1);
                        return `<b>${this.point.name}</b>: ${percentage}% <br> Count:${this.y}`;
                    }
                },
                title: {
                    text: null,
                },
                credits: {
                    enabled: false,
                },
                plotOptions: {
                    pie: {
                        innerSize: "80%",
                        size: "100%",
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            format: '{y} '
                        },
                        showInLegend: true
                    }
                },
                series: [{
                    name: 'Percentage',
                    colorByPoint: true,
                    innerSize: '75%',
                    data: [{
                        name: 'Private',
                        y: this.privateInvite
                    }, {
                        name: 'Public',
                        y: this.publicInvite
                    }]
                }]
            });
        },
        renderColumnChart(){
            Highcharts.chart('stackContainer', {
                chart: {
                    type: 'column'
                },
                title: {
                    text: 'Region Project Status',
                    style: {
                        fontWeight: "600",
                        display: 'none',
                    }
                },
                xAxis: {
                    categories: this.stackCategories
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'Count'
                    },
                    stackLabels: {
                        enabled: true,
                        style: {
                            fontWeight: 'bold',
                            color: 'gray'
                        }
                    }
                },
                tooltip: {
                    headerFormat: '<b>{point.x}</b><br/>',
                    pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: true
                        },
                        maxPointWidth: 50 
                    }
                },
                credits: {
                    enabled: false
                },
                series: [
                    {
                        name: 'Completed',
                        data: this.stackCompletedData,
                        color: '#5E45EB'
                    },
                    {
                        name: 'In Progress',
                        data: this.stackInProgressData,
                        color: '#FF549E'
                    },
                    {
                        name: 'Not Started',
                        data: this.stackNotStartedData,
                        color: '#F7B200'
                    }
                ]
            });
        },
    },
};
</script>

<style scoped>
.headerCard {
    padding: 15px;
    border-radius: 16px;
    min-width: 160px;
    box-shadow: 1px 1px 7px 0.1px #e0e0e0;
}

.primary-button {
    background-color: #7b68ee;
    font-size: medium !important;
    border-radius: 8px;
    color: #ffffff;
    border: 2px solid #7b68ee !important;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    padding: 0.3rem 0.5rem;
    cursor: pointer;
}

.invite-card {
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 25px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    height: 100%;
}

.invite-card-title {
    font-weight: 600;
    font-size: larger;
}

.container {
    max-width: 1100px;
}

.chart-container {
    width: 100%;
    position: relative;
    overflow: hidden;
}


.stack-container {
    width: 100%;
    /* position: relative;
    overflow: hidden; */
}

.color-box {
    height: 15px;
    width: 15px;
    border-radius: 3px;
}

.table-responsive thead th {
    position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 2;
}

.table-header {
    text-align: center;
    margin-bottom: 10px;
}

.table-title {
    font-size: 18px;
    font-weight: bold;
}
</style>