<template>
    <div class="page-content">
        <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
            <div class="my-2">
                <h3 class="mb-2 mb-md-0">Descriptive Analytics</h3>
                <p class="font-size-12"> {{ this.displayDate }} </p>
            </div>
            <div class="d-flex justify-content-between align-items-end">
                <div class="top_heading_CreatePartner_btn d-block" data-bs-toggle="modal" data-bs-target="#choose-Date">
                    <div class="Group_top_three_dot">
                        <div class="dropdown">
                            <button class="btn p-0 Group_top_three_dot_dropdown" type="button">
                                <img src="../../../../public/dashboard-assets/images/M2/date-icon.png"
                                    @click="opencal" />
                                <input type="text" name="daterange" hidden="true" id="calendar_icon" class="fade" />
                            </button>
                        </div>
                    </div>
                </div>
                <div v-if="hidePdfDownload === false" class="top_heading_CreatePartner_btn d-block mx-3">
                    <div class="Group_top_three_dot">
                        <div class="dropdown">
                            <a :href="reportUrl" target="_blank">
                                <button class="btn p-0 Group_top_three_dot_dropdown" type="button">
                                    <img src="../../../../public/dashboard-assets/images/M2/download-icon.png" />
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="d-flex justify-content-between Overview_dashborad_header_md_flex">
                <div class="d-flex mb-2">
                    <div class="top_heading_CreatePartner_btn d-block" data-bs-toggle="modal"
                        data-bs-target="#choose-Date">
                        <div class="Group_top_three_dot">
                            <div class="dropdown">
                                <button class="btn p-0 Group_top_three_dot_dropdown" type="button"
                                    id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false" style="line-height: 0.3">
                                    <img src="../../../../public/dashboard-assets/images/M2/date-icon.png" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="
                        top_heading_CreatePartner_btn top_heading_CreatePartner_btn-kj
                        d-block
                        mx-2
                    ">
                        <div class="Group_top_three_dot mx-2">
                            <div class="dropdown">
                                <button class="btn p-0 Group_top_three_dot_dropdown position-relative" type="button"
                                    id="dropdownMenuButton1" data-bs-toggle="dropdown" data-bs-auto-close="outside"
                                    aria-expanded="false" aria-haspopup="true" style="line-height: 0.3">
                                    <img src="../../../../public/dashboard-assets/images/M2/Filter_icon.png" />
                                    <span class="Noti-alert-kj translate-middle">
                                        5 <span class="visually-hidden"></span>
                                    </span>
                                </button>

                                <ul class="dropdown-menu dropdown-menu-kj" aria-labelledby="dropdownMenuButton1">
                                    <div class="modal-header">
                                        <h4 class="modal-title text-centerm-auto" id="CreateGroupModalLabel">
                                            Filter
                                        </h4>
                                        <button type="button" class="btn-close" data-bs-toggle="dropdown"
                                            data-bs-auto-close="inside"></button>
                                    </div>

                                    <ul class="nav nav-pills justify-content-between mb-3" id="pills-tab"
                                        role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill"
                                                data-bs-target="#pills-home" type="button" role="tab"
                                                aria-controls="pills-home" aria-selected="true">
                                                Partner
                                            </button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                                                data-bs-target="#pills-profile" type="button" role="tab"
                                                aria-controls="pills-profile" aria-selected="false">
                                                Test
                                            </button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                                                data-bs-target="#pills-profile1" type="button" role="tab"
                                                aria-controls="pills-profile" aria-selected="false">
                                                Language
                                            </button>
                                        </li>
                                    </ul>
                                    <div class="tab-content" id="pills-tabContent">
                                        <div class="tab-pane fade show active p-0" id="pills-home" role="tabpanel"
                                            aria-labelledby="pills-home-tab">
                                            <div class="search-bar">
                                                <form class="search-form">
                                                    <input type="text" placeholder="Search" name=""
                                                        class="form-control bg-white" /><button class="btn">
                                                        <i class="fas fa-search"></i>
                                                    </button>
                                                </form>
                                                <div class="ul-li-checkbox-kj">
                                                    <ul>
                                                        <li>
                                                            <input type="checkbox" name="" id="" />
                                                            <span>Cogent </span>
                                                        </li>
                                                        <li>
                                                            <input type="checkbox" name="" id="" />
                                                            <span>Infosys</span>
                                                        </li>
                                                        <li>
                                                            <input type="checkbox" name="" id="" />
                                                            <span>Mindtree</span>
                                                        </li>
                                                        <li>
                                                            <input type="checkbox" name="" id="" />
                                                            <span>Startech </span>
                                                        </li>
                                                        <li>
                                                            <input type="checkbox" name="" id="" />
                                                            <span>Techmahindra </span>
                                                        </li>
                                                        <li>
                                                            <input type="checkbox" name="" id="" />
                                                            <span>Vodafone </span>
                                                        </li>
                                                    </ul>
                                                    <div class="text-end">
                                                        <button type="button" class="btn-Apply-kj-color">
                                                            Clear Filter
                                                        </button>
                                                        <button type="button" class="btn-primary btn-Apply-kj">
                                                            Apply
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade p-0" id="pills-profile" role="tabpanel"
                                            aria-labelledby="pills-profile-tab">
                                            <div class="search-bar">
                                                <form class="search-form">
                                                    <input type="text" placeholder="Search" name=""
                                                        class="form-control bg-white" /><button class="btn">
                                                        <i class="fas fa-search"></i>
                                                    </button>
                                                </form>
                                            </div>
                                            <div class="ul-li-checkbox-kj">
                                                <ul>
                                                    <li>
                                                        <input type="checkbox" name="" id="" />
                                                        <span>CSO Test </span>
                                                    </li>
                                                    <li>
                                                        <input type="checkbox" name="" id="" />
                                                        <span>HR Test </span>
                                                    </li>
                                                    <li>
                                                        <input type="checkbox" name="" id="" />
                                                        <span>Language Test </span>
                                                    </li>
                                                    <li>
                                                        <input type="checkbox" name="" id="" />
                                                        <span>PDX Recruiter Test 2020 </span>
                                                    </li>
                                                    <li>
                                                        <input type="checkbox" name="" id="" />
                                                        <span>Startech Test </span>
                                                    </li>
                                                    <li>
                                                        <input type="checkbox" name="" id="" />
                                                        <span>Test Try </span>
                                                    </li>
                                                </ul>

                                                <div class="text-end">
                                                    <button type="button" class="btn-Apply-kj-color">
                                                        Clear Filter
                                                    </button>
                                                    <button type="button" class="btn-primary btn-Apply-kj">
                                                        Apply
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade p-0" id="pills-profile1" role="tabpanel"
                                            aria-labelledby="pills-profile-tab">
                                            <div class="search-bar">
                                                <form class="search-form">
                                                    <input type="text" placeholder="Search" name=""
                                                        class="form-control bg-white" /><button class="btn">
                                                        <i class="fas fa-search"></i>
                                                    </button>
                                                </form>
                                            </div>
                                            <div class="ul-li-checkbox-kj">
                                                <ul>
                                                    <li>
                                                        <input type="checkbox" name="" id="" />
                                                        <span>English </span>
                                                    </li>
                                                    <li>
                                                        <input type="checkbox" name="" id="" />
                                                        <span>Gujarati </span>
                                                    </li>
                                                    <li>
                                                        <input type="checkbox" name="" id="" />
                                                        <span>Marathi </span>
                                                    </li>
                                                    <li>
                                                        <input type="checkbox" name="" id="" />
                                                        <span>Tamil </span>
                                                    </li>
                                                    <li>
                                                        <input type="checkbox" name="" id="" />
                                                        <span>Kannada </span>
                                                    </li>
                                                    <li>
                                                        <input type="checkbox" name="" id="" />
                                                        <span>French </span>
                                                    </li>
                                                </ul>
                                                <div class="text-end">
                                                    <button type="button" class="btn-Apply-kj-color">
                                                        Clear Filter
                                                    </button>
                                                    <button type="button" class="btn-primary btn-Apply-kj">
                                                        Apply
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="top_heading_CreatePartner_btn d-block" data-bs-toggle="modal"
                        data-bs-target="#ConsolidatedSheet">
                        <div class="Group_top_three_dot">
                            <div class="dropdown">
                                <button class="btn p-0 Group_top_three_dot_dropdown" type="button"
                                    id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false" style="line-height: 0.3">
                                    <img src="../../../../public/dashboard-assets/images/M2/download-icon.png" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="partner_item_action">
                    <div class="dropdown group_partenr_dropdown_button">
                        <button class="btn p-0 Group_top_three_dot_dropdown" type="button" id="dropdownMenuButton"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Partner
                            <i class="fa-solid fa-chevron-down ms-3"></i>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <li>
                                <router-link :to="{ name: 'DashboardGroup' }" class="w-100 btn active text-start">
                                    Group
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div> 
            </div> -->
        </div>

        <div id="pdfContent">
            <PieChart v-if="isParent !== 'true'" @hidePdfDownload="showOrHidePdfDownload" :from="this.fromDate" :to="this.toDate" :key="counter" />      
            <LineChart v-if="this.assessmentFlows.length > 0" v-bind:assessmentData="assessmentFlows" :key="counter" />
            <TestSummary v-if="testSummaries.length > 0" v-bind:assessmentData="testSummaries" :key="counter" />
            <TestWiseRegionSummary v-if="testSummaries.length > 0" v-bind:assessmentData="testSummaries" :key="counter" />
            <!-- <ScoreAnalysisGraphsLoop :from="this.fromDate" :to="this.toDate" :key="counter"/> -->
            <!-- <StackedColumnChartProctoring />
            <HeatmapChart /> -->
        </div>
        <!-- <div id="chartContainer" ref="chartContainer" style="width:100vh; max-width: 100%;"></div>
        <div id="chart2Container" ref="chart2Container" style="width:100vh; max-width: 100%;"></div> -->

        <!-- <div class="Overview_dashborad_map_section my-4">
            <div class="card p-4">
                <div class="card-body p-0">
                    <div class="d-md-flex justify-content-between align-items-center">
                        <div class="mb-3">
                            <h3 class="mb-2 mb-md-0">
                                Test Distribution Location
                                <img src="../../../../public/dashboard-assets/images/M2/Actions.png" alt="" />
                            </h3>
                            <p class="font-size-12 mt-1">Total test used: 15,610</p>
                        </div>

                        <div class="Descriptive_Analytics_tabs_section mb-3">
                            <router-link :to="{ name: 'DashboardDescriptiveAnalystics' }" class="
                  btn
                  Descriptive_Analytics_tabs_link
                  active
                  btn-outline-primary btn-icon-text
                ">
                                World Map
                            </router-link>

                            <router-link :to="{ name: 'DescriptiveAnalysticsCountry' }"
                                class="btn Descriptive_Analytics_tabs_link">
                                Country
                            </router-link>

                            <router-link :to="{ name: '' }" class="btn Descriptive_Analytics_tabs_link">
                                State
                            </router-link>
                        </div>
                    </div>
                    <div class="Overview_dashborad_map_img mt-5">
                        <img src="../../../../public/dashboard-assets/images/M2/Descriptive_Map.png" alt="" />
                    </div>
                </div>
            </div>
        </div>

        <div class="Overview_dashborad_map_section my-4">
            <div class="card p-4">
                <div class="card-body p-0">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="">
                            <h3 class="mb-2 mb-md-0">
                                Test Activity
                                <img src="../../../../public/dashboard-assets/images/M2/Actions.png" alt="" />
                            </h3>
                            <p class="font-size-12 mt-1">Total test used: 15,610</p>
                        </div>

                        <div class="partner_item_action">
                            <div class="dropdown group_partenr_dropdown_button">
                                <button class="btn p-0 Group_top_three_dot_dropdown" type="button"
                                    id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    Last 7 Days
                                    <i class="fa-solid fa-chevron-down ms-4"></i>
                                </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <li>
                    <button type="button" class="btn w-100 text-start">
                      2015
                    </button>
                  </li>
                  <li>
                    <button type="button" class="btn w-100 text-start">
                      2016
                    </button>
                  </li>
                  <li>
                    <button type="button" class="btn w-100 text-start">
                      2017
                    </button>
                  </li>
                  <li>
                    <button type="button" class="btn w-100 text-start">
                      2018
                    </button>
                  </li>
                  <li>
                    <button type="button" class="btn w-100 text-start">
                      2019
                    </button>
                  </li>
                  <li>
                    <button type="button" class="btn w-100 text-start">
                      2020
                    </button>
                  </li>
                </ul> 
                            </div>
                        </div>
                    </div>
                    <div class="Overview_dashborad_map_img mt-5">
                        <img src="../../../../public/dashboard-assets/images/M2/TestAllocation.png" alt="" />
                    </div>
                </div>
            </div>
        </div>

        <div class="Overview_dashborad_map_section">
            <div class="card p-4">
                <div class="card-body p-0">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="">
                            <h3 class="mb-2 mb-md-0">
                                Test Activity
                                <img src="../../../../public/dashboard-assets/images/M2/Actions.png" alt="" />
                            </h3>
                        </div>
                    </div>
                    <div class="Overview_dashborad_map_img mt-5">
                        <img src="../../../../public/dashboard-assets/images/M2/TestUsageOverTime.png" alt="" />
                    </div>
                </div>
            </div>
        </div>

        <div class="Overview_dashborad_map_section mt-3">
            <div class="card p-4">
                <div class="card-body p-0">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="">
                            <h3 class="mb-2 mb-md-0">
                                Summary of Active Tests
                                <img src="../../../../public/dashboard-assets/images/M2/Actions.png" alt="" />
                            </h3>
                            <div>
                                <router-link :to="{ name: 'DescriptiveAnalysticsdetails' }" class="btn btn-primary">
                                    All view
                                </router-link>
                            </div>

                        </div>
                    </div>
                    <div class="Overview_dashborad_map_img mt-5">
                        <div class="row">
                            <div class="col-6 col-lg-3  me-0 pe-0">
                                <div>
                                    <table class="table">
                                        <thead class="table-header-bg-active-kj">
                                            <tr>
                                                <th>Test Name</th>
                                            </tr>
                                        </thead>
                                        <tbody class="bg-white">
                                            <tr>
                                                <td>Customer Orientation Test </td>
                                            </tr>
                                            <tr>
                                                <td>Sales Orientation Test </td>
                                            </tr>
                                            <tr>
                                                <td>Psychometric Test </td>
                                            </tr>
                                            <tr>
                                                <td>Language Test </td>
                                            </tr>
                                            <tr>
                                                <td>Pmaps Test </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-6 col-lg-9  ms-0 ps-0">
                                <div class="table-responsive ">
                                    <table class="table bg-white">
                                        <thead class="table-header-bg-active-kj">
                                            <tr>
                                                <th>Partners</th>
                                                <th>Test Used</th>
                                                <th>Overall Passe</th>
                                                <th>Avg Time spent (min)</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr>

                                                <td>Techmahindra</td>
                                                <td>800</td>
                                                <td>90%</td>
                                                <td>04:00</td>


                                            </tr>
                                            <tr>
                                                <td>Mindtree</td>
                                                <td>300</td>
                                                <td>80%</td>
                                                <td>14.00</td>
                                            </tr>
                                            <tr>
                                                <td>Cogent</td>
                                                <td>200</td>
                                                <td>75%</td>
                                                <td>20.00</td>
                                            </tr>
                                            <tr>
                                                <td>Startech</td>
                                                <td>502</td>
                                                <td>50%</td>
                                                <td>20.00</td>
                                            </tr>
                                            <tr>
                                                <td>Mindtree</td>
                                                <td>15</td>
                                                <td>80%</td>
                                                <td>10.20</td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

        <!-- Welcome Modal -->
        <div class="modal fade" id="WelcomePopupModal" tabindex="-1" aria-labelledby="WelcomePopupModalLabel"
            aria-hidden="false">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body p-4 text-center">
                        <h3 class="text-center mb-3">Welcome to PMaps, {{ this.user.firstName }}</h3>

                        <p class="text-muted text-justify" style="font-size: 13px">
                            Sends assessment to candidates and access to real-time reports on
                            their performance and suitability.
                        </p>

                        <div class="loadPopupImage my-4">
                            <img src="../../../../public/dashboard-assets/images/Welcome_ill_img.png" width="60%"
                                class="loadPopupImage_img" alt="">
                        </div>

                        <button class="btn btn-primary w-100" @click="hideWelcome" aria-label="btn-close">
                            Get started
                        </button>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Highcharts from 'highcharts';
import html2pdf from 'html2pdf.js';
//import highchartsExporting from 'highcharts/modules/exporting';
import PieChart from './PieChart.vue';
import TestWiseRegionSummary from './TestWiseRegionSummary.vue';
// import StackedColumnChartProctoring from './StackedColumnChartProctoring.vue';
// import HeatmapChart from './HeatmapChart.vue';
import LineChart from './LineChart.vue';
import TestSummary from './TestSummary.vue';
// import ScoreAnalysisGraphsLoop from "./ScoreAnalysisGraphsLoop.vue";
import moment from "moment";

export default {
    name: "dashboard-descriptive-analystics",
    components: {
        PieChart,
        TestWiseRegionSummary,
        // StackedColumnChartProctoring,
        // HeatmapChart,
        LineChart,
        TestSummary,
        // ScoreAnalysisGraphsLoop
    },
    data() {
        return {
            fromDate: this.dateformat(
                new Date(new Date().setMonth(new Date().getMonth() - 1))
            ),
            toDate: this.todateformat(new Date()),
            displayDate: '',
            lastLoggedInDate: "",
            pageNumber: 1,
            testSummaries: [],
            reportUrl: '',
            counter: 0,
            assessmentFlows: [],
            isParent: 'false',
            hidePdfDownload: false
        };
    },
    computed: {
        ...mapState({
            authenticated: (state) => state.auth.authenticate,
            user: (state) => state.auth.user,
            Date: (state) => state.auth.user.lastLoggedInDate,
        }),
    },
    methods: {
        ...mapActions(["AssessmentFlowDesignSummary", "ShowWarningMessage"]),
        dateformat: (val) => {
            return moment(String(val)).format("YYYY-MM-DD 00:00:00");
        },
        todateformat: (val) => {
            return moment(String(val)).format("YYYY-MM-DD 23:59:59");
        },
        hideWelcome() {
            window.$("div").removeClass("modal-backdrop");
            window.$("#WelcomePopupModal").modal("hide")
        },
        //     generatePDF() {
        //   const element = document.getElementById('pdfContent');
        //   html2pdf().from(element).save();
        // }
        generatePDF() {
            const chartContainer = document.getElementById('pdfContent');
            const element = document.createElement('div');

            const chartSpans = chartContainer.querySelectorAll('span');

            chartSpans.forEach((chartSpan) => {
                const sectionWrapper = document.createElement('div');
                sectionWrapper.classList.add('pdf-section');
                sectionWrapper.appendChild(chartSpan.cloneNode(true));
                element.appendChild(sectionWrapper);
                sectionWrapper.style.pageBreakAfter = 'always';
            });

            const options = {
                filename: 'your_file_name.pdf',
                image: { type: 'svg', quality: 1 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
            };

            html2pdf().set(options).from(element).save();
        },
        opencal() {
            document.getElementById("calendar_icon").click();
            // this.showw = true;
        },
        async fetchLineAndRegionWiseGraph() {
            // this is a common method to be called from the daterange filter and also from the mounted lifecycle hook
            var graphData = await this.AssessmentFlowDesignSummary({
                clientId: this.user.clientId,
                TestId: 0,
                RegionId: 0,
                FromDate: this.fromDate,
                ToDate: this.toDate
            });

            this.testSummaries = graphData.object.data.testUseSummaries;
            this.assessmentFlows = graphData.object.data.assessmentFlows;
        },
        showOrHidePdfDownload(value) {
            this.hidePdfDownload = value;
        }
    },
    async mounted() {
        this.isParent = localStorage.getItem('isParent');
        this.displayDate = moment(String(this.fromDate)).format("YYYY MMM DD") + " - " + moment(String(this.toDate)).format("YYYY MMM DD");
        window.$('input[name="daterange"]').daterangepicker(
            {
                maxDate: new Date(),
                locale: { cancelLabel: "Clear" },
                opens: "center",
            },
            async (start, end) => {
                if (start && end) {
                    var x = start.format("YYYY MMM DD");
                    var y = end.format("YYYY MMM DD");
                    this.fromDate = this.dateformat(x);
                    this.toDate = this.todateformat(y);
                    // this.showw = false;
                }
            }
        );
        window
            .$('input[name="daterange"]')
            .on("cancel.daterangepicker", async (ev, picker) => {
                picker.startDate._d = new Date();
                picker.endDate._d = new Date();
                this.fromDate = this.dateformat(
                    new Date(new Date().setMonth(new Date().getMonth() - 6))
                ),
                    this.toDate = this.todateformat(new Date(new Date())),
                    window.$('input[name="daterange"]').val("");
            });
        window
        .$('input[name="daterange"]')
        .on("apply.daterangepicker",async(ev, picker)=>{
            var x = moment(String(picker.startDate._d)).format("YYYY MMM DD");
            var y = moment(String(picker.endDate._d)).format("YYYY MMM DD");
            var start = moment(String(picker.startDate._d)).format("YYYY-MM-DD 00:00:00")
            var end = moment(String(picker.endDate._d)).format("YYYY-MM-DD 00:00:00")
            if (
            Math.ceil(
                Math.abs(new Date(start) - new Date(end)) /
                (1000 * 60 * 60 * 24)
            ) <= 30
            ) {
                this.displayDate = x + " - " + y;
                var encodeObject = {
                    ClientId: this.user.clientId,
                    TestId: 0,
                    RegionId: 0,
                    From: this.fromDate,
                    To: this.toDate
                }
                var base64EncodedString = btoa(JSON.stringify(encodeObject));
                this.reportUrl = window.app_base_url + "/descriptiveDashboard/DownloadDashboard?token=" + base64EncodedString;
                await this.fetchLineAndRegionWiseGraph();
                this.counter++;
            } else {
                return this.ShowWarningMessage("Please select range within 1 months");
            }
        });

        await this.fetchLineAndRegionWiseGraph();

        var encodeObject = {
            ClientId: this.user.clientId,
            TestId: 0,
            RegionId: 0,
            From: this.fromDate,
            To: this.toDate
        }
        var base64EncodedString = btoa(JSON.stringify(encodeObject));
        this.reportUrl = window.app_base_url + "/descriptiveDashboard/DownloadDashboard?token=" + base64EncodedString;

        //highchartsExporting(Highcharts);
        Highcharts.setOptions({
            exporting: {
                enabled:false,
                // chartOptions: {
                //     print: {
                //         dpi: 300
                //     },
                //     exportFormat: 'svg',
                //     style: {
                //         fontFamily: 'DM Sans, sans-serif'
                //     },
                //     exporting: {
                //         enabled: false,

                //     }
                // }
            },
            chart: {
                style: {
                    fontFamily: 'DM Sans, sans-serif'
                }
            }
        });
    },
};
</script>