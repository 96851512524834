<template>
  <!--  Invite Candidates model -->
  <div class="modal fade" id="InviteCandidatesNew" tabindex="-1" aria-labelledby="IncreaseTokenModalLabel"
    aria-hidden="true" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable model-sm">
      <div class="modal-content border-radius-kj-20">
        <div class="modal-header token-model-header-color">
          <h3 class="modal-title text-centerm-auto" id="IncreaseTokenModalLabel">
            Invite Candidates
            <span class="stepper-text mx-2"> TestId : {{ this.testid }}</span>

          </h3>

          <button type="button" @click="closeinvite" class="btn-close" aria-label="btn-close"></button>
        </div>
        <div class="modal-body">
          <div class="container d-flex px-2 my-3">
            <div class="progresses">
              <div style="text-align:-webkit-center;">
                <span class="steps" v-if="firstStepper != true">1</span>
                <span class="steps step-success" v-else><i class="fa fa-check"></i></span>
                <span class="stepper-text">Basic Details</span>
              </div>

              <span class="line mb-4 mx-2" v-if="firstStepper != true"></span>
              <span class="line line-success mb-4 mx-2" v-else></span>

              <div style="text-align: -webkit-center;">
                <span class="steps" v-if="secondStepper != true">2</span>
                <span class="steps step-success" v-else><i class="fa fa-check"></i></span>
                <span class="stepper-text">Candidate Details</span>
              </div>


              <span class="line mb-4 mx-2"></span>
              <!-- <span class="line line-success mb-4 mx-2" v-else></span> -->

              <div style="text-align: -webkit-center;">
                <span class="steps">3</span>
                <!-- <span class="steps step-success" v-else><i class="fa fa-check"></i></span> -->
                <span class="stepper-text">Select Template</span>
              </div>


            </div>


          </div>
          <div class="row mt-7 mb-7">
            <div class="col-md-4 mb-2 col-lg-3">
              <div class="form-group Multiselect-kj position-relative">
                <label class="input-hero" for="Group">
                  <Multiselect v-model="this.invite.from" :options="this.fromuser" mode="single" :searchable="true"
                    @select="fromemailverificationstatus" @deselect="invitedisable" ref="mul">
                  </Multiselect>
                  <span class="input-name input-group-kj">From <span class="text-danger">*</span></span>
                </label>
                <!-- <div class="close-btn-position" @click="clearFrom">
                    <i class="fa-solid fa-xmark"></i>
                  </div> -->
                <div class="text-end">
                  <a class="verify-email" @click="fromemailverification" v-if="this.verify">Verify</a>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-2 col-lg-3">
              <div class="form-group Multiselect-kj">
                <label class="input-hero" for="Group">
                  <Multiselect v-model="this.invite.fromgroupname" :options="this.groupname" mode="single"
                    :searchable="true" @select="invitedisable" @deselect="invitedisable">
                  </Multiselect>
                  <!-- <div class="close-btn-position"
                      @click="clearGroup"
                    >
                      <i class="fa-solid fa-xmark"></i>
                    </div> -->
                  <span class="input-name input-group-kj">
                    From Group <span class="text-danger">*</span></span>
                </label>
              </div>
            </div>
            <div class="col-md-4 mb-2 col-lg-3">
              <div class="form-group Multiselect-kj">
                <label class="input-hero" for="Group">
                  <Multiselect v-model="this.invite.fromgroupname" :options="this.groupname" mode="single"
                    :searchable="true" @select="invitedisable" @deselect="invitedisable">
                  </Multiselect>
                  <!-- <div class="close-btn-position"
                      @click="clearGroup"
                    >
                      <i class="fa-solid fa-xmark"></i>
                    </div> -->
                  <span class="input-name input-group-kj">
                    From Group <span class="text-danger">*</span></span>
                </label>
              </div>
            </div>
            <div class="col-md-4 mb-2 col-lg-3">
              <button type="button" class="btn btn-primary text-end" @click="storedata1" style="border-radius: 25px;">
                Save & Next
              </button>
            </div>
            <!-- <div class="text-end" v-if="this.firstStepper == false" >
              <button type="button" class="btn btn-primary text-end mt-5" @click="storedata1" style="border-radius: 25px;">
                Save & Next
              </button>
            </div>
            <div class="text-end" v-else>
              <button type="button" class="btn btn-primary text-end mt-5"  style="border-radius: 25px;">
                Edit Basic Details
              </button>
            </div> -->

      <hr class="my-5" v-if="this.firstStepper == true">
            <div v-if="this.firstStepper == true">
              <h4>
                <div class="row">
                  <div class="col-12 col-lg-5 col-md-12 col-sm-12">
                    Add Candidates
                  </div>
                  <div class="col-12 col-lg-4 col-md-6 col-sm-12">
                    <button type="button" class=" float-end modail_submit_button text-end bulkButton" >
                      <i class="fa-solid fa-download" style="color: #7b68ee;"></i>
                      Download Bulk Upload Sheet
                    </button>
                  </div>
                  <div class="col-12 col-lg-3 col-md-5 col-sm-12">
                    <button type="button" class=" float-end modail_submit_button text-end bulkButton" >
                      <i class="fa-solid fa-upload" style="color: #7b68ee;"></i>
                      Upload Bulk Upload Sheet
                    </button>
                  </div>
                </div>
              </h4>
              <ul class="
            nav nav-pills
            mb-3
            partners_top_menu_section_hp
            p-2
            mt-4
            user_tabing_nav_section
          " id="pills-tab" role="tablist" >
      <li class="nav-item" role="presentation">
        <button class="nav-link btn px-3 active" id="completed-candidates-tab" data-bs-toggle="pill"
          data-bs-target="#completed-candidates" type="button" role="tab" aria-controls="completed-candidates"
          aria-selected="true" >
          <!-- @click="changeTab('Completed')" -->
          Completed (0)
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link btn px-3" id="pending-candidates-tab" data-bs-toggle="pill"
          data-bs-target="#pending-candidates" type="button" role="tab" aria-controls="pending-candidates"
          aria-selected="false" >
          <!-- @click="changeTab('Pending')" -->
          Duplicate (0)
        </button>
      </li>
    </ul>
              <div class="row mt-1">
                <div class="col-md-12 ">
                  <div class="card p-0 " style="border-radius: 15px;">
                    <div class="table-responsive">
                      <table class="table text-center">
                        <thead style="background-color: #F1F0FF !important;">
                          <tr>
                            <th>Sr No</th>
                            <th>Phone Number</th>
                            <th>Email Address</th>
                            <th>Download</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>9876543212</td>
                            <td>mayuresh.am.2000@gmail.com</td>
                            <td>
                              <i class="fa-solid fa-pen-to-square fa-lg mx-3" style="color: #7b68ee;"></i>
                              <i class="fa-solid fa-circle-xmark  fa-lg" style="color: #7b68ee;"></i>
                            </td>
                            <!-- <i class="fa-solid fa-floppy-disk fa-lg" style="color: #7b68ee;"></i> -->
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>9876543212</td>
                            <td>mayuresh.am.2000@gmail.com</td>
                            <td>
                              <i class="fa-solid fa-pen-to-square fa-lg mx-3" style="color: #7b68ee;"></i>
                              <i class="fa-solid fa-circle-xmark  fa-lg" style="color: #7b68ee;"></i>
                            </td>
                            <!-- <i class="fa-solid fa-floppy-disk fa-lg" style="color: #7b68ee;"></i> -->
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>9876543212</td>
                            <td>mayuresh.am.2000@gmail.com</td>
                            <td>
                              <i class="fa-solid fa-pen-to-square fa-lg mx-3" style="color: #7b68ee;"></i>
                              <i class="fa-solid fa-circle-xmark  fa-lg" style="color: #7b68ee;"></i>
                            </td>
                            <!-- <i class="fa-solid fa-floppy-disk fa-lg" style="color: #7b68ee;"></i> -->
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>9876543212</td>
                            <td>mayuresh.am.2000@gmail.com</td>
                            <td>
                              <i class="fa-solid fa-pen-to-square fa-lg mx-3" style="color: #7b68ee;"></i>
                              <i class="fa-solid fa-circle-xmark  fa-lg" style="color: #7b68ee;"></i>
                            </td>
                            <!-- <i class="fa-solid fa-floppy-disk fa-lg" style="color: #7b68ee;"></i> -->
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>9876543212</td>
                            <td>mayuresh.am.2000@gmail.com</td>
                            <td>
                              <i class="fa-solid fa-pen-to-square fa-lg mx-3" style="color: #7b68ee;"></i>
                              <i class="fa-solid fa-circle-xmark  fa-lg" style="color: #7b68ee;"></i>
                            </td>
                            <!-- <i class="fa-solid fa-floppy-disk fa-lg" style="color: #7b68ee;"></i> -->
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>9876543212</td>
                            <td>mayuresh.am.2000@gmail.com</td>
                            <td>
                              <i class="fa-solid fa-pen-to-square fa-lg mx-3" style="color: #7b68ee;"></i>
                              <i class="fa-solid fa-circle-xmark  fa-lg" style="color: #7b68ee;"></i>
                            </td>
                            <!-- <i class="fa-solid fa-floppy-disk fa-lg" style="color: #7b68ee;"></i> -->
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>9876543212</td>
                            <td>mayuresh.am.2000@gmail.com</td>
                            <td>
                              <i class="fa-solid fa-pen-to-square fa-lg mx-3" style="color: #7b68ee;"></i>
                              <i class="fa-solid fa-circle-xmark  fa-lg" style="color: #7b68ee;"></i>
                            </td>
                            <!-- <i class="fa-solid fa-floppy-disk fa-lg" style="color: #7b68ee;"></i> -->
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>9876543212</td>
                            <td>mayuresh.am.2000@gmail.com</td>
                            <td>
                              <i class="fa-solid fa-pen-to-square fa-lg mx-3" style="color: #7b68ee;"></i>
                              <i class="fa-solid fa-circle-xmark  fa-lg" style="color: #7b68ee;"></i>
                            </td>
                            <!-- <i class="fa-solid fa-floppy-disk fa-lg" style="color: #7b68ee;"></i> -->
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>9876543212</td>
                            <td>mayuresh.am.2000@gmail.com</td>
                            <td>
                              <i class="fa-solid fa-pen-to-square fa-lg mx-3" style="color: #7b68ee;"></i>
                              <i class="fa-solid fa-circle-xmark  fa-lg" style="color: #7b68ee;"></i>
                            </td>
                            <!-- <i class="fa-solid fa-floppy-disk fa-lg" style="color: #7b68ee;"></i> -->
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>9876543212</td>
                            <td>mayuresh.am.2000@gmail.com</td>
                            <td>
                              <i class="fa-solid fa-pen-to-square fa-lg mx-3" style="color: #7b68ee;"></i>
                              <i class="fa-solid fa-circle-xmark  fa-lg" style="color: #7b68ee;"></i>
                            </td>
                            <!-- <i class="fa-solid fa-floppy-disk fa-lg" style="color: #7b68ee;"></i> -->
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>9876543212</td>
                            <td>mayuresh.am.2000@gmail.com</td>
                            <td>
                              <i class="fa-solid fa-pen-to-square fa-lg mx-3" style="color: #7b68ee;"></i>
                              <i class="fa-solid fa-circle-xmark  fa-lg" style="color: #7b68ee;"></i>
                            </td>
                            <!-- <i class="fa-solid fa-floppy-disk fa-lg" style="color: #7b68ee;"></i> -->
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>9876543212</td>
                            <td>mayuresh.am.2000@gmail.com</td>
                            <td>
                              <i class="fa-solid fa-pen-to-square fa-lg mx-3" style="color: #7b68ee;"></i>
                              <i class="fa-solid fa-circle-xmark  fa-lg" style="color: #7b68ee;"></i>
                            </td>
                            <!-- <i class="fa-solid fa-floppy-disk fa-lg" style="color: #7b68ee;"></i> -->
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>9876543212</td>
                            <td>mayuresh.am.2000@gmail.com</td>
                            <td>
                              <i class="fa-solid fa-pen-to-square fa-lg mx-3" style="color: #7b68ee;"></i>
                              <i class="fa-solid fa-circle-xmark  fa-lg" style="color: #7b68ee;"></i>
                            </td>
                            <!-- <i class="fa-solid fa-floppy-disk fa-lg" style="color: #7b68ee;"></i> -->
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>9876543212</td>
                            <td>mayuresh.am.2000@gmail.com</td>
                            <td>
                              <i class="fa-solid fa-pen-to-square fa-lg mx-3" style="color: #7b68ee;"></i>
                              <i class="fa-solid fa-circle-xmark  fa-lg" style="color: #7b68ee;"></i>
                            </td>
                            <!-- <i class="fa-solid fa-floppy-disk fa-lg" style="color: #7b68ee;"></i> -->
                          </tr>


                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="text-end" v-if="this.secondStepper == false" >
              <button type="button" class="btn btn-primary text-end mt-5" @click="storedata2" style="border-radius: 25px;">
                Save & Next
              </button>
            </div>
            <div class="text-end" v-else>
              <button type="button" class="btn btn-primary text-end mt-5" style="border-radius: 25px;">
                Edit Basic Details
              </button>
            </div>

            <hr class="my-5">

            Select Template
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import Multiselect from "@vueform/multiselect";
// import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { mapState, mapActions } from "vuex";
// import { validateEmail, validatePhoneNumber } from "@/utils";
// import readXlsxFile from 'read-excel-file';

export default {
  name: "InviteCandidatesNew",
  props: ["testname", "testid", "testclientid"],
  components: {
    // QuillEditor,
    Multiselect
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      clientId: (state) => state.auth.user.clientId,
    }),
    //Added by Pmaps team
    checkDuplicate() {
      return this.sendinvitelist.Duplicate.length > 0 ? true : false;
    },
    // showExcludedCandidatesList() {
    //   return this.hideExcludedCandidates;
    // },
    showIconsInCandidateData() {
      return this.totalToInviteLength > 0 ? true : false;
    }
    //Added by Pmaps team
  },
  async created() {
    // this.isSmsSettingEnabled = await this.IsSmsInvitePreference({
    //   clientId: this.testclientid
    // });
    // if (this.isSmsSettingEnabled === undefined) {
    //   this.isEmailSelected = 'true'
    // }
  },
  data() {
    return {
      isDisabled: true,
      invitesend: [],
      excludedCandidateList: [], //Added by Pmaps team
      filter: 0,
      groupList: [],
      groupname: [],
      selectTemplate: [],
      showlist: false,
      // hideExcludedCandidates: false, //Added by Pmaps team
      totalToInvite: [],
      totalToInviteLength: 0,
      example5: {
        mode: "tags",
        closeOnSelect: false,
        searchable: true,
        createOption: true,
        object: true,
      },
      isPaste: false,
      pasteErrorCount: 0,
      total: [],
      invite: {
        content: "",
        subject: "",
        to: [],
        from: "",
        id: "",
        fromgroupname: "",
        customObj: [],
      },
      sub: "",
      texteditor: "",
      fromuser: [],
      isSmsSettingEnabled: {},
      isEmailSelected: '',
      verify: false,
      sendinvitelist: {
        withoutDuplicate: [],
        Duplicate: [],
        excludedList: [] //Added by Pmaps team
      },
      val: "",
      toolbarOptions: [
        ["bold", "italic", "underline", "strike"],
        [
          {
            list: "ordered",
          },
          {
            list: "bullet",
          },
        ],
        [
          {
            script: "sub",
          },
          {
            script: "super",
          },
        ],
        [
          {
            indent: "-1",
          },
          {
            indent: "+1",
          },
        ],

        [
          {
            color: [],
          },
          {
            background: [],
          },
        ],
        ["image", "link"],
      ],
      pstmail: "",
      x: "",
      cpyPst: "",
      firstStepper: false,
      secondStepper: false
    };
  },
  async mounted() {
    window.$("#InviteCandidatesNew").modal("show");
    // const candidatedata = await this.getInviteCandidate({
    //   testId: this.testid,
    // });
    // this.texteditor = candidatedata.object.data.contentData;
    // var p = document.querySelector(".ql-editor");
    // p.innerHTML = this.texteditor;
    // this.invite.subject = candidatedata.object.data.subjectline
    //   ? candidatedata.object.data.subjectline
    //   : "";
    // const fromemail = await this.invitefromemail({
    //   userId: this.user.userId,
    // });
    // this.fromuser = fromemail.object.data;
    // var group;
    // if (this.clientId == 1001) {
    //   group = await this.adminregionList({
    //     userId: this.testclientid,
    //   });
    //   this.groupList = group.object.data.regions;
    // } else {
    //   group = await this.UserRegionData({
    //     userId: this.user.userId,
    //   });
    //   this.groupList = group.object.data;
    // }

    // this.groupList.forEach((element) => {
    //   if (element.isActive) this.groupname.push(element.regionName);
    // });
  },

  methods: {
    ...mapActions([
      "UserRegionData",
      "invitefromemail",
      "ShowWarningMessage",
      "ShowErrorMessage",
      "emailverifystatus",
      "emailverification",
      "previewtemplate",
      "emailtemplates",
      "saveemailtemplates",
      "selectemailtemplates",
      "resendInviteEmailCandidate",
      "CreateCandidates",
      "adminregionList",
      "getInviteCandidate",
      "sendselecttemplates",
      "IsSmsInvitePreference",
      "sendSmsInvitation"
    ]),

    async storedata1() {
      this.firstStepper = true;
    },
    async storedata2() {
      this.secondStepper = true;
    },
    closeinvite() {
      this.$emit("clicked", "false");
      window.$("#InviteCandidatesNew").modal("hide");
      window.$("#InviteCandidatesRemove").modal("hide");
      window.$("#SelectTemplate").modal("hide");
      window.$("#DeleteTemplate").modal("hide");
    },
  },
};
</script>
  
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.bulkButton{
  font-size: medium; 
  height: 40px;
  font-weight: 600;

}
.multiselect-dropdown {
  display: none !important;
}

.multiselect-options {
  display: none !important;
}

.multiselect-no-results {
  display: none !important;
}

.select-Template {
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem !important;
}

.select-list-name {
  color: #292e34;
  font-weight: 500;
}

.select-list-time {
  color: #7b818d;
  font-size: 13px;
}

.select-Template-radio {
  align-items: center;
  display: flex !important;
}

.w-24 {
  width: 24px;
  cursor: pointer;
}

.select-list-Template {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#DeleteTemplate .Deactivate_buttons .Deactivate_Cancel_buttons {
  border-radius: 50px;
  border: 2px solid #7c68ee;
  background: #fff;
  color: #7c68ee;
  margin: 0px 6px 0 0;
}

.table-responsive {
  height: 100px;
  overflow-y: auto;
  display: block;
}

.table-responsive thead th {
  position: sticky;
  top: 0;
  background-color: #f2f0ff;
  z-index: 2;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

#DeleteTemplate .Deactivate_buttons .Deactivate_Deactivate_buttons {
  border-radius: 50px;
  border: 2px solid #7c68ee;
  background: #7b68ee;
  color: #fff;
  margin: 0 0 0px 6px;
}

.QuillEditor-kj {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column-reverse;
  /* margin-top: 45px; */
  border: 2px solid #d1d5db;
  border-radius: 10px;
}

.QuillEditor-inside-dot-relative {
  position: relative;
  right: 0;
}

.QuillEditor-inside-dot-fixed {
  position: absolute;
  right: 20px;
  top: 10px;
}

.verify-email {
  color: #7b68ee;
  text-decoration: underline;
  cursor: pointer;
}

.multiselect-options {
  overflow: hidden;
}

.totaltoinvite {
  position: absolute;
  right: 28%;
  z-index: 1;
  top: 22%;
  color: #7b68ee;
  border: 1px solid #7b68ee;
  background: #ffffff00;
  padding: 1px 10px;
  border-radius: 34px;
}

.remove-candidate-invite {
  position: absolute;
  right: 18%;
  z-index: 1;
  top: 22%;
  color: #fff;
  padding: 1px 10px;
  border-radius: 34px;
}

.close-btn-position {
  position: absolute;
  z-index: 1;
  right: 25px;
  top: 9px;
}

.file-upload-input {
  margin-top: -23px;
  height: 100%;
  width: 6%;
  margin-left: 8px;
}

/* body {

  background-color: #eee;
  font-family: "Poppins", sans-serif;
  font-weight: 300;


} */

.container {

  height: 50px;
}

.progresses {
  display: flex;
  align-items: center;
}

.line {
  width: 100px;
  height: 6px;
  background: #dbdbdb;
}

.line.line-success {
  background: #7b68ee;
}

.steps {
  display: flex;
  background-color: #dbdbdb;
  color: #fff;
  font-size: 14px;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

}

.stepper-text {
  font-size: 13.2px;
  color: #7b818d;
  /* font-weight: 600; */
}

@media (max-width: 1200px){
  .bulkButton{
  font-size: 10px; 
  height: 30px;
  font-weight: 600;
}
}
@media (max-width:400px) {

  .table-responsive tbody td {
    font-size: small;
  }

  .line {
    width: 50px;
    height: 6px;
    background: #dbdbdb;
  }

  .steps {
    display: flex;
    background-color: #dbdbdb;
    color: #fff;
    font-size: 10px;
    width: 25px;
    height: 25px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

  }

  .stepper-text {
    font-size: 10px;
    color: #7b818d;
    /* font-weight: 600; */
  }
}



.steps.step-success {
  background-color: #7b68ee;

}
</style>
  