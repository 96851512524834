<template>
    <div class="Overview_dashboard_map_section my-4">
        <div class="card p-4">
            <div class="card-body p-0" id="proctoring-analysis-chart">
                <h3 style="font-weight: 600;" class="mb-5">Proctoring Analysis
                        <!-- <img src="../../../../public/dashboard-assets/images/M7/Actions.png" width="25"
                            class="cursor-pointer ms-2" alt="" data-bs-toggle="tooltip" data-bs-placement="top" title=" Analyzing the frequency of assessment usage over time provides valuable insights into the number of
                                    candidates assessed during defined intervals." /> -->
                    </h3>
                    <!-- <h3 style="font-weight: 600;">Test Wise Graph Usage</h3> -->
                    <div class="stacked-column-chart" >
                        <div ref="chartContainer"></div>
                    </div>
            </div>
        </div>
    </div>
</template>

<script>
import Highcharts from 'highcharts';
import { Tooltip } from "bootstrap/dist/js/bootstrap.esm.min.js";

export default {
    name: 'StackedColumnChartProctoring',
    props: {
        assessmentData: Array
    },
    async mounted() {


        const regions = this.assessmentData.tests[0].regions;
        const categories = regions.map(region => region.regionName);
        const seriesData = {};

        regions.forEach(region => {
            region.sections.forEach(section => {
                if (!seriesData[section.proctoringFitment]) {
                    seriesData[section.proctoringFitment] = [];
                }
                seriesData[section.proctoringFitment].push(section.proctoringCount);
            });
        });

        const colors = ['#7C68EE', '#F8B200', '#FF90C0'];
        const series = Object.keys(seriesData).map((fitment, index) => ({
            name: fitment,
            data: seriesData[fitment],
            color: colors[index % colors.length]
        }));

        Highcharts.chart(this.$refs.chartContainer, {
            chart: {
                type: 'column',
            },
            title: {
                text: 'Proctoring Analysis',
                style: {
                    fontWeight: "600",
                    display: 'none',
                }
            },
            xAxis: {
                categories: categories,
                title: {
                        text: 'Regions',
                        style: {
                        fontWeight: "600",
                        }
                    },
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'No. of candidates',
                    style: {
                        fontWeight: "600",
                    }
                },
                stackLabels: {
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        color: (
                            Highcharts.defaultOptions.title.style &&
                            Highcharts.defaultOptions.title.style.color
                        ) || 'gray'
                    }
                }
            },
            legend: {
                align: 'center',
                verticalAlign: 'bottom',
                backgroundColor: '#FFFFFF',
                shadow: false
            },
            // legend: {
            //       align: 'right',
            //       x: -30,
            //       verticalAlign: 'top',
            //       y: 25,
            //       floating: true,
            //       backgroundColor:
            //         Highcharts.defaultOptions.legend.backgroundColor || 'white',
            //       borderColor: '#CCC',
            //       borderWidth: 1,
            //       shadow: false
            //     },
            credits: {
                enabled: false
            },
            tooltip: {
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            series: series
        });


        Array.from(
            document.querySelectorAll('img[data-bs-toggle="tooltip"]')
        ).forEach((tooltipNode) => new Tooltip(tooltipNode));

    }

};
</script>

<style scoped>
.stacked-column-chart {
    width: 100%;
    height: 400px;
}
</style>