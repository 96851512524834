<template>
    <div></div>
</template>
<script>
import Shepherd from "shepherd.js";
import { mapActions, mapState } from "vuex";

export default {
    name: "App",
    computed: {
        ...mapState({
            user: (state) => state.auth.user,
        }),
    },
    data() {
        return {
            activeTestTourSteps: [
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Test Card<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>Quick overview of Test details, Candidate Data, and navigation to Detailed Test view.</div>",
                    attachTo: {
                        element: "#tour-test-card",
                        on: "right"
                    },
                    beforeShowPromise: function () {
                        const dropdownParent = document.querySelector('.sidebar-test-management .collapse');
                        if (dropdownParent) {
                            dropdownParent.classList.add('show');
                        }
                        const nextDropdownParent = document.querySelector('.sidebar-team-management-item .collapse.show');
                        if (nextDropdownParent) {
                            nextDropdownParent.classList.remove('show');
                            nextDropdownParent.classList.add('hide');
                        }

                        return new Promise(function (resolve) {
                            setTimeout(resolve, 100);
                        });
                    },
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        // {
                        //   action: this.tourGoBack,
                        //   text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        // },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Test ID<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>Unique identifier for tracking and referencing each test in the system.</div>",
                    attachTo: {
                        element: "#tour-card-id",
                        on: "right"
                    },
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Test Name<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>Title of the test for quick identification and easy reference.</div>",
                    attachTo: {
                        element: "#tour-card-name",
                        on: "right"
                    },
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Partner<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>Displays the name of the Company or Partner using this test design.</div>",
                    attachTo: {
                        element: "#tour-card-partner",
                        on: "right"
                    },
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Overview Graph<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>Provides a quick overview of candidates' test attempts: Completed, In progress, and Pending.</div>",
                    attachTo: {
                        element: "#tour-card-graph",
                        on: "right"
                    },
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Share Public Test Invitation<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:16px ; padding-left:2% ;padding-right:2%'>Share public test invitation by copying and pasting the link in email, SMS, or social media.</div>",
                    attachTo: {
                        element: "#tour-share-url",
                        on: "bottom"
                    },
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.OpenModalNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Select Group<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Select the Group whom you want to share the test link either through email or SMS.</div>",
                    attachTo: {
                        element: ".tour-share-tour-modal",
                        on: "right"
                    },

                    buttons: [
                         {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.CloseModalBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.CloseModalNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Navigation Dots<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Click to open the Test Invitation section and to Know the test sections, benchmarks, duration, etc.</div>",
                    attachTo: {
                        element: ".tour-card-dot-dropdown",
                        on: "right"
                    },
                    
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.OpenModalBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Search<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Quickly find specific tests or candidates using search functionality.</div>",
                    attachTo: {
                        element: "#tour-search-web-icon",
                        on: "bottom"
                    },
                    
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Filter<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Apply filters to narrow down the list of tests or candidates based on criteria.</div>",
                    attachTo: {
                        element: "#tour-filter-web-icon",
                        on: "bottom"
                    },
                    
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Consolidated Sheet<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Download and View all test data consolidated in a single, comprehensive sheet.</div>",
                    attachTo: {
                        element: "#tour-consolidated-web-icon",
                        on: "bottom"
                    },
                    
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:18px; padding-left:2% ;padding-right:2%'>Test Card Pagination<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:16px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:15px ; padding-left:2% ;padding-right:2%'>Navigate to other test cards available in your account through pagination for easy browsing.</div>",
                    attachTo: {
                        element: "#tour-active-test-pagination",
                        on: "top"
                    },
                    
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourComplete,
                            text: "Finish <i class='fa-solid fa-thumbs-up fa-lg mx-2'></i>"
                        }
                    ]
                },
            ],




            activeTestTourResponsiveSteps: [
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Test Card<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Quick overview of Test details, Candidate Data, and navigation to Detailed Test view.</div>",
                    attachTo: {
                        element: "#tour-test-card",
                        on: "bottom"
                    },
                    beforeShowPromise: function () {
                        const dropdownParent = document.querySelector('.sidebar-test-management .collapse');
                        if (dropdownParent) {
                            dropdownParent.classList.add('show');
                        }
                        const nextDropdownParent = document.querySelector('.sidebar-team-management-item .collapse.show');
                        if (nextDropdownParent) {
                            nextDropdownParent.classList.remove('show');
                            nextDropdownParent.classList.add('hide');
                        }
                        const mobileSidebar = document.querySelector('.main-wrapper');
                        if (mobileSidebar) {
                            mobileSidebar.classList.remove('sidebar-open');
                        }
                        return new Promise(function (resolve) {
                            setTimeout(resolve, 100);
                        });
                    },
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        // {
                        //   action: this.tourGoBack,
                        //   text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        // },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Test ID<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Unique identifier for tracking and referencing each test in the system.</div>",
                    attachTo: {
                        element: "#tour-card-id",
                        on: "bottom"
                    },              
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                          action: this.tourGoBack,
                          text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Test Name<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Title of the test for quick identification and easy reference.</div>",
                    attachTo: {
                        element: "#tour-card-name",
                        on: "bottom"
                    },              
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                          action: this.tourGoBack,
                          text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Partner<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Displays the name of the Company or Partner using this test design.</div>",
                    attachTo: {
                        element: "#tour-card-partner",
                        on: "bottom"
                    },              
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                          action: this.tourGoBack,
                          text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Overview Graph<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Provides a quick overview of candidates' test attempts: Completed, In progress, and Pending.</div>",
                    attachTo: {
                        element: "#tour-card-graph",
                        on: "bottom"
                    },              
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                          action: this.tourGoBack,
                          text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Share Public Test Invitation<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Share public test invitation by copying and pasting the link in email, SMS, or social media.</div>",
                    attachTo: {
                        element: "#tour-share-url",
                        on: "bottom"
                    },
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.tourGoBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.OpenModalNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Select Group<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Select the Group whom you want to share the test link either through email or SMS.</div>",
                    attachTo: {
                        element: ".tour-share-tour-modal",
                        on: "bottom"
                    },

                    buttons: [
                        {
                            action: this.CloseModalBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.CloseModalNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Navigation Dots<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Click to open the Test Invitation section and to Know the test sections, benchmarks, duration, etc.</div>",
                    attachTo: {
                        element: ".tour-card-dot-dropdown",
                        on: "bottom"
                    },

                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                            action: this.OpenModalBack,
                            text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Search<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Quickly find specific tests or candidates using search functionality.</div>",
                    attachTo: {
                        element: "#search-mobile-icon",
                        on: "bottom"
                    },              
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                          action: this.tourGoBack,
                          text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Filter<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Apply filters to narrow down the list of tests or candidates based on criteria.</div>",
                    attachTo: {
                        element: "#tour-filter-web-icon",
                        on: "bottom"
                    },              
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                          action: this.tourGoBack,
                          text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Consolidated Sheet<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Download and View all test data consolidated in a single, comprehensive sheet.</div>",
                    attachTo: {
                        element: "#tour-consolidated-web-icon",
                        on: "bottom"
                    },              
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                          action: this.tourGoBack,
                          text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourGoNext,
                            text: "Next <i class='fa-solid fa-circle-chevron-right fa-lg mx-2'></i>"
                        }
                    ]
                },
                {
                    text: "<div style='margin-top:3%;font-weight:600;font-size:16px; padding-left:2% ;padding-right:2%'>Test Card Pagination<div class='float-end' style='color:#7C68EE; font-weight:600; font-size:14px ;'> </div></div><div style='margin-top:3%; margin-bottom:3%;font-size:14px ; padding-left:2% ;padding-right:2%'>Navigate to other test cards available in your account through pagination for easy browsing.</div>",
                    attachTo: {
                        element: "#tour-active-test-pagination",
                        on: "bottom"
                    },              
                    buttons: [
                        {
                            action: this.tourCancel,
                            classes: "shepherd-button-third",
                            text: "Skip"
                        },
                        {
                          action: this.tourGoBack,
                          text: "Back <i class='fa-solid fa-circle-chevron-left fa-lg mx-2'></i>"
                        },
                        {
                            action: this.tourComplete,
                            text: "Finish <i class='fa-solid fa-thumbs-up fa-lg mx-2'></i>"
                        }
                    ]
                },
            ],
        };
    },
    props: {
        page: String,
        tourStatus: String,
        shareModalStatus: Boolean
    },
    mounted() {
        const windowWidth = window.innerWidth;
        if (this.page === 'ActiveTests') {
            this.activeTestTour = new Shepherd.Tour({
                useModalOverlay: true,
                tourName: "ActiveTestTour",
                defaultStepOptions: {
                    scrollTo: {
                        behavior: "smooth",
                        block: "center"
                    }
                }
            });

            if (windowWidth < 999) {
                this.activeTestTourResponsiveSteps.forEach(step => {
                    this.activeTestTour.addStep(step);
                });
            }
            else {
                this.activeTestTourSteps.forEach(step => {
                    this.activeTestTour.addStep(step);
                });

            }
            this.activeTestTour.start();
        }

    },
    methods: {
        ...mapActions(["AllActiveTest"]),
        tourGoNext() {
            if (this.page === 'ActiveTests') {
                this.activeTestTour.next();
            }
        },
        tourGoBack() {
            if (this.page === 'ActiveTests') {
                this.activeTestTour.back();
            }
        },
        tourCancel() {
            if (this.page === 'ActiveTests') {
                this.$emit('share-modal-close');
                this.$emit('tour-close');
                localStorage.setItem("isNewUser", false);
                this.activeTestTour.cancel();
            }
        },
        tourComplete() {
            if (this.page === 'ActiveTests') {
                localStorage.setItem("isNewUser", false);
                this.$emit('share-modal-close');
                this.$emit('tour-close');
                this.activeTestTour.complete();
            }
        },
        OpenModalNext() {
            this.$emit('share-modal-open');
            this.activeTestTour.next();
        },
        OpenModalBack() {
            this.$emit('share-modal-open');
            this.activeTestTour.back();
        },
        CloseModalBack() {
            this.$emit('share-modal-close');
            this.activeTestTour.back();
        },
        CloseModalNext() {
            this.$emit('share-modal-close');
            this.$emit('dropdown-open');

            this.activeTestTour.next();
        },
    }
};
</script>
